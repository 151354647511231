import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ReactSelect from "react-select";
import usePayoutFilter from "./useScheduleClassFilter";

const ScheduleClassFilter = ({ isStickyFooter, handleFilterClick, activityFilter }) => {
  const { formik, selectVal, selectActivity, statusObjects, userData, basicData, clearFilter, userVal,getFieldError, storedData, handleInputChange, useDataOptions, handleParentId } =
  usePayoutFilter(handleFilterClick, activityFilter);
  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search for a specialization...";
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"From Date"}
          type="date"
          id="startDate"
          value={formik.values.startDate}
          name="startDate"
          className={`pro-input lg ${getFieldError("startDate") && " error"}`}
          onChange={(e) => formik.setFieldValue("startDate", e.target.value)}
          error={getFieldError("startDate")}
          errorMessage={getFieldError("startDate")}
        />
        <Input
          label={"To Date"}
          type="date"
          id="endDate"
          value={formik.values.endDate}
          name="endDate"
          min={formik.values.startDate}
          onChange={(e) => formik.setFieldValue("endDate", e.target.value)}
          disabled={!formik.values.startDate}
          className={`pro-input lg ${getFieldError("endDate") && " error"}`}
        
          error={getFieldError("endDate")}
          errorMessage={getFieldError("endDate")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mode Of Class
          </label>
          <ReactSelect
            id="mode_of_class"
            name="mode_of_class"
            placeholder={"Select Status"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            options={basicData ?? []}
            value={selectVal}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("mode_of_class")}
            onChange={(e) =>
              formik?.setFieldValue("mode_of_class", e?.value || null)
            }
            menuPlacement="auto"
          />
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Activity
          </label>
          <ReactSelect
            id="activity"
            name="activity"
            placeholder={"Select Activity"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            options={statusObjects ?? []}
            value={selectActivity}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("activity")}
            onChange={(e) =>
              formik?.setFieldValue("activity", e?.value || null)
            }
            menuPlacement="auto"
          />
        </div>
        <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
        Specialization
        </label>
        <ReactSelect
          id="specialization"
          placeholder="Search for a specialization..."
          name="specialization"
          noOptionsMessage={noOptionsMessage}
          isClearable={false}
          options={useDataOptions}
          value={userVal}
          onInputChange={(value) => handleInputChange(value)}
          className={`pro-input multi-select lg ${
            formik.errors.specialization && formik.touched.specialization && " error"
          }`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("specialization")}
          onChange={(value) => handleParentId(value)}
        />
      </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button className={"pro-btn-link lg pro-px-5"} onClick={clearFilter}>
            Clear
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 `}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleClassFilter;
