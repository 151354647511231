import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  getSubjectData,
  updateConfig,
} from "../../../store/slices/Subjects/subjectSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  useGetSubjectsListDataQuery,
  useUpdateSubjectStatusMutation,
  useUpdateTableListFieldsDataMutation,
} from "../../../store/queries/Subjects";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import { subjects } from "../../../store/queries/Subjects";
import { current } from "@reduxjs/toolkit";

const useSubjectList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );

}, []);
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const subjectsState = useSelector((state) => state.subjects);
  const [showImageModal, setShowImageModal] = useState(false);
  const [skip, setSkip] = useState(true);
  const [imageData, setImageData] = useState("");
  const {
    data: subjectsList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetSubjectsListDataQuery({
    page_size: subjectsState.currentPageSize,
    page: subjectsState.currentPage,
    sort_by: subjectsState.sortBy,
    sort_order: subjectsState.sortOrder,
    filter: subjectsState.currentFilter,
    search: subjectsState.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();
  const [updateSubjectStatus] = useUpdateSubjectStatusMutation();
  const filters = useMemo(() => {
    if (subjectsList?.data?.filters) {
      const buttonGroups = subjectsList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, subjectsList]);

  useEffect(() => {
    if (subjectsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [subjectsState.clearSelection]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddSubjectsModal = !state.showAddSubjectsModal;
        state.isEdit = false;
        state.selectedSubject = "";
        state.subjectData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    return subjectsList?.data?.create_permission ?? false;
  }, [subjectsList]);
  const hasEditPermission = useMemo(() => {
    return subjectsList?.data?.edit_permission ?? false;
  }, [subjectsList]);
  const hasDeletePermission = useMemo(() => {
    return subjectsList?.data?.delete_permission ?? false;
  }, [subjectsList]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      is_active: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.is_active}
              // onChange={(e) => updateSubjectStatus(data?._id)}
              onChange={(e) => handleChangeStatus(data?.is_active, data?._id)}
            />
          </div>
        );
      },
      name: (field, data) => (
        <a className="pro-pnt" onClick={() => handleFetchData(data)}>
          {data?.name}
        </a>
      ),
      image: (feild, data) => {
        return (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleChangeStatus = (status, id) => {
    updateSubjectStatus(id);
    dispatch(
      subjects.util.updateQueryData(
        "getSubjectsListData",
        {
          page_size: subjectsState.currentPageSize,
          page: subjectsState.currentPage,
          sort_by: subjectsState.sortBy,
          sort_order: subjectsState.sortOrder,
          filter: subjectsState.currentFilter,
          search: subjectsState.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache?.data?.subjects?.map((res) =>
            res._id === id
              ? {
                  ...res,
                  is_active: status === true ? false : true,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              subjects: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handleFetchData = (data) => {
    localStorage.setItem("subjectData", JSON.stringify(data));
    navigate(`/subjects/${data?._id}`);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  // const handleDelete = () => {
  // deleteBranch(subjectsState?.selectedStudent).then((response) => {
  //   if (response?.data?.status_code === 200) {
  //     toast.success("Student deleted successfully");
  //     handleDeleteModal();
  //     refetch();
  //   } else {
  //     toast.error("Something went wrong");
  //     handleDeleteModal();
  //   }
  // });
  // };

  // const handleDeleteModal = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showDeleteModal = !state.showDeleteModal;
  //       state.selectedSubject = e?.[0];
  //     })
  //   );
  // };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddSubjectsModal = true;
        state.selectedSubject = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getSubjectData(e?.[0]));
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.currentFilter = null;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleSort = (label) => {
    if (subjectsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = subjectsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedSubject = "";
      })
    );
  };

  useEffect(() => {
    if (subjectsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [subjectsState.clearSelection]);

  return {
    subjectsState,
    showEditModal,
    tableFields,
    subjectsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    // handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    filters,
    handleFilter,
    activeFilter: subjectsState.currentFilter,
    currentPage: subjectsState.currentPage,
    showImageModal,
    imageData,
    closeModal,
    closeEditModal,
    updateTableFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  };
};

export default useSubjectList;
