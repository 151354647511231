import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useNavigate } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import {
  useGetPlanListQuery,
  useUpdatePlanListTableMutation,
  useUpdatePlanStatusMutation,
} from "../../../store/queries/PremiumPlan";
import {
  deletePlan,
  getPlanData,
  updateConfig,
} from "../../../store/slices/PremiumPlan/premiumPlanSlice";
import Style from "./addPlan.module.scss";
import { toast } from "react-toastify";

const usePremiumPlanLists = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const planState = useSelector((state) => state.premiumPlan);

  const {
    data: planList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetPlanListQuery({
    page_size: planState.currentPageSize,
    page: planState.currentPage,
    sort_by: planState.sortBy,
    sort_order: planState.sortOrder,
    search: planState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdatePlanListTableMutation();
  const [updatePlanStatus] = useUpdatePlanStatusMutation();

  useEffect(() => {
    if (planState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [planState.clearSelection]);

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = true;
      })
    );
  };

  const hasDeletePermission = useMemo(() => {
    return planList?.data?.delete_permission ?? false;
  }, [planList]);

  const getRow = (field, data) => {
    const rows = {
      amount: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      is_active: (field, data) => {
        if (data[field]) {
          return <p className="pro-mb-0">Active</p>;
        } else {
          return <p className="pro-mb-0">Inactive</p>;
        }
      },
      features: (field, data) => {
        return (
          <>
            {data?.features?.map((content, index) => (
              <div key={index} className={Style.inner_row}>
                <span>{content}</span>
              </div>
            ))}
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  useEffect(() => {
    if (planState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [planState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (planState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = planState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.selectedPlan = "";
        state.clearSelection = true;
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedPlan = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getPlanData(e?.[0]));
  };

  const handleDelete = async () => {
    const response = await deletePlan(planState?.selectedPlan);
    if (response && response.status === 200) {
      toast.success(`Premium Plan deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedPlan = e?.[0];
      })
    );
  };
  return {
    planState,
    showEditModal,
    tableFields,
    planList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: planState.currentFilter,
    currentPage: planState.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    handleModalClick,
    handleEditAction,
    handleDelete,
    handleDeleteModal,
    hasDeletePermission
  };
};

export default usePremiumPlanLists;
