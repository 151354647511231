import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetReportedListQuery,
  useUpdateReportStatusMutation,
  useUpdateReportedListTableMutation,
} from "../../../store/queries/ReportedChat";
import { updateConfig } from "../../../store/slices/ReportedChat/reportedChatSlice";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "../reports.module.scss";
import Assets from "../../../assets/Assets";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useReportedLists = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const reportedState = useSelector((state) => state.reportedChat);

  const {
    data: reportedList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetReportedListQuery({
    page_size: reportedState.currentPageSize,
    page: reportedState.currentPage,
    sort_by: reportedState.sortBy,
    sort_order: reportedState.sortOrder,
    filter: reportedState.currentFilter,
    search: reportedState?.search,
    start_date: reportedState.startDate,
    end_date: reportedState.endDate,
    status_type: reportedState?.statusType,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateReportedListTableMutation();
  const [updateStatus] = useUpdateReportStatusMutation();

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isFilter = true;
      })
    );
  };

  const getRow = (field, data) => {
    const basicData = [
      { value: "RESOLVED", label: "RESOLVED" },
      { value: "UNRESOLVED", label: "UNRESOLVED" },
    ];
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      report_from_name: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/${
                  data?.report_from_usertype === "STUDENT"
                    ? "students-profile"
                    : "tutors-profile"
                }/${data?.report_from_id}`,
              });

              sessionStorage.setItem("active", `${data?.report_from_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.report_from_image !== "" && data?.report_from_image
                    ? data?.report_from_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.report_from_name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.report_from_name)}
            </span>
          </div>
        );
      },
      report_to_name: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/${
                  data?.report_to_usertype === "STUDENT"
                    ? "students-profile"
                    : "tutors-profile"
                }/${data?.report_to_id}`,
              });

              sessionStorage.setItem("active", `${data?.report_to_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.report_to_image !== "" && data?.report_to_image
                    ? data?.report_to_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.report_to_name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.report_to_name)}
            </span>
          </div>
        );
      },
      reported_on: (field, data) => {
        // if (data?.reported_on) {
        // 	const dateObject = new Date(data?.reported_on);

        // 	// Format the date as 'YYYY-MM-DD'

        // 	const formattedDate = dateObject.toISOString().split('T')[0];
        // 	return (
        // 		<p className="pro-mb-0">
        // 			{formattedDate}
        // 		</p>
        // 	);
        // }

        if (data?.reported_on) {
          const dateObject = new Date(data.reported_on);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },

      message: (field, data) => (
        <p className={`${Style.enquiry_message} pro-mb-0`}>
          {data[field]}
        </p>
      ),

      status: (field, data) => {
        let selectVal = basicData.find((item) => item.value === data[field]);
        return (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg ${Style.select_box}`}
            classNamePrefix="pro-input"
            options={basicData}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={selectVal}
            onChange={(selectedOption) => {
              const formData = {
                status: selectedOption?.value,
              };

              updateStatus({ body: formData, id: data?._id })
                .then((response) => {
                  if (response?.data?.success) {
                    toast.success("Status Updated Successfully!");
                    refetch?.();
                  } else if (!response?.data?.success) {
                    toast.error("Failed to Update Status!");
                  } else {
                    toast.error("Failed to Update Status!");
                  }
                })
                .catch(() => {
                  toast.error("Failed to Update Status!");
                });
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            // isDisabled={dashboard ? true : false}
          />
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  useEffect(() => {
    if (reportedState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [reportedState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (reportedState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reportedState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  return {
    reportedState,
    showEditModal,
    tableFields,
    reportedList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: reportedState.currentFilter,
    currentPage: reportedState.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
  };
};

export default useReportedLists;
