import { useSelector } from "react-redux";
import { useMemo } from "react";
import Dashboard from "../components/Dashboard";
import Student from "../components/Student";
import FAQs from "../components/FAQs";
import Premiumplans from "../components/PremiumPlans";
import Tutor from "../components/Tutor";
import Subjects from "../components/Subjects/SubjectList";
import EducationalLevel from "../components/EducationalLevel";
import Banners from "../components/Banners";
import AreaOfExpertise from "../components/AreaOfExpertise";
import TermsAndConditions from "../components/CMS/TermsAndConditions";
import Testimonials from "../components/CMS/Testimonials";
import ContactInfo from "../components/CMS/ContactInfo";
import PrivacyAndPolicy from "../components/CMS/PrivacyAndPolicy";
import Courses from "../components/Courses";
import Reports from "../components/Reports";
// import Order from "../components/Orders/Order";
import TransactionList from "../components/Payment/TransactionList/index";
import RefundList from "../components/Payment/RefundList/index";
import OurClients from "../components/CMS/OurClients";
import More from "../components/CMS/More";
import BgCheck from "../components/Student/BgCheck";
import AboutUs from "../components/CMS/AboutUs";
import Blog from "../components/CMS/Blog";
import Coupons from "../components/Coupon";
import CouponRedeemList from "../components/Coupon/CouponRedeemList";
import Revenue from "../components/Payment/Revenue";
import Roles from "../components/Roles/Role";
import Policies from "../components/CMS/Policies";
import Staffs from "../components/Roles/Staffs";
import PremiumPlan from "../components/PremiumPlan";
import PremiumPlanPurchase from "../components/Payment/PremiumPlanPurchase";
import BgVerificationPayment from "../components/Payment/BgVerification";
import Payout from "../components/Payment/Payouts";
import PayoutsSettlement from "../components/Payment/PayoutsSettlment";
import RefundSettlement from "../components/Payment/RefundSettlement";
import InactiveStudents from "../components/Student/InactiveStudents";
import TutorReviews from "../components/TutorReviews";
import InactiveTutors from "../components/Tutor/InactiveTutors";
import TutorBackgroundVerification from "../components/Tutor/TutorBackgroundVerification";
import PayoutList from "../components/Tutor/PayoutList";
import TutorTaxForm from "../components/Tutor/TutorTaxForm";
import AllTutorDocuments from "../components/Tutor/AllTutorDocuments";
import OnboardingTutors from "../components/Tutor/OnboardingTutors";
import LocationSearchMeta from "../components/CMS/LocationSearchMeta";
import DeletedTutors from "../components/Tutor/DeletedTutors";
import DeletedStudents from "../components/Student/DeletedStudents";
import DynamicMeta from "../components/CMS/DynmaicMeta";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];
  const StudentsComponents = [
    {
      component: <Student />,
      permission: "Active Students",
      path: "active-students",
    },
    {
      component: <InactiveStudents />,
      permission: "Inactive Students",
      path: "inactive-students",
    },
    {
      component: <DeletedStudents />,
      permission: "Deleted Students",
      path: "deleted-students",
    },
    {
      component: <BgCheck />,
      permission: "Background Check",
      path: "bg-check",
    },
  ];

  const CourseComponents = [
    {
      component: <Courses />,
      permission: "Course Schedules",
      path: "/courses",
    },
  ];

  const ReportedChat = [
    {
      component: <Reports />,
      permission: "Reported Users",
      path: "/reported-chat",
    },
  ];

  const PremiumPlanComponent = [
    {
      component: <PremiumPlan />,
      permission: "premium-plan",
      path: "/premium-plan",
    },
  ];

  const RoleComponent = [
    {
      component: <Roles />,
      permission: "Role",
      path: "role-management",
    },
    {
      component: <Staffs />,
      permission: "Admin Users",
      path: "staffs",
    },
  ];

  const TutorComponents = [
    {
      component: <Tutor />,
      permission: "Active Tutors",
      path: "/active-tutors",
    },
    {
      component: <InactiveTutors />,
      permission: "Inactive Tutors",
      path: "/inactive-tutors",
    },
    {
      component: <DeletedTutors />,
      permission: "Deleted Tutors",
      path: "/deleted-tutors",
    },
    {
      component: <TutorReviews />,
      permission: "Tutor Reviews",
      path: "/tutor-reviews",
    },
    {
      component: <TutorBackgroundVerification />,
      permission: "Tutor Bg Verification",
      path: "/bg-verification",
    },
    {
      component: <PayoutList />,
      permission: "Settled Payouts",
      path: "/payouts-list",
    },
    {
      component: <TutorTaxForm />,
      permission: "Tax Form 1099",
      path: "/tax-form",
    },
    {
      component: <AllTutorDocuments />,
      permission: "Documents",
      path: "/documents",
    },
    {
      component: <OnboardingTutors />,
      permission: "Onboarding",
      path: "/onboarding-tutors",
    },
  ];

  const CouponComponents = [
    {
      component: <Coupons />,
      permission: "Coupons",
      path: "coupons",
    },
    {
      component: <CouponRedeemList />,
      permission: "Coupon Redeem",
      path: "coupon-redeem",
    },
  ];
  const PaymentComponents = [
    {
      component: <TransactionList />,
      permission: "Course Purchase",
      path: "transaction-list",
    },
    {
      component: <RefundList />,
      permission: "Refund",
      path: "refunds",
    },
    {
      component: <Revenue />,
      permission: "Revenue",
      path: "revenue",
    },
    {
      component: <PremiumPlan />,
      permission: "premium-plan",
      path: "/premium-plan",
    },
    {
      component: <PremiumPlanPurchase />,
      permission: "Premium Plan Purchase",
      path: "plans-purchase",
    },
    {
      component: <BgVerificationPayment />,
      permission: "Bg Verification",
      path: "bg-verification",
    },
    {
      component: <Payout />,
      permission: "Payouts",
      path: "payouts",
    },
    {
      component: <PayoutsSettlement />,
      permission: "Payouts Settlement",
      path: "payout-settlement",
    },
    {
      component: <RefundSettlement />,
      permission: "Refund Settlement",
      path: "refund-settlement",
    },
  ];

  const SubjectsComponents = [
    {
      component: <Subjects />,
      permission: "Subject",
      path: "/subjects",
    },
  ];

  const CmsComponents = [
    {
      component: <AboutUs />,
      permission: "About Us",
      path: "about-us",
    },
    {
      component: <FAQs />,
      permission: "FAQ",
      path: "faqs",
    },

    {
      component: <Banners />,
      permission: "Banner List",
      path: "banners",
    },
    {
      component: <Blog />,
      permission: "Blog",
      path: "blog",
    },
    {
      component: <Premiumplans />,
      permission: "Premium plans",
      path: "premium-plans",
    },
    {
      component: <AreaOfExpertise />,
      permission: "Area Of Expertise List",
      path: "area-of-expertise",
    },
    {
      component: <EducationalLevel />,
      permission: "Education Level List",
      path: "educational-level",
    },
    {
      component: <TermsAndConditions />,
      permission: "Terms and Conditions",
      path: "terms-and-conditions",
    },
    {
      component: <Testimonials />,
      permission: "Testimonials",
      path: "testimonials",
    },
    {
      component: <ContactInfo />,
      permission: "Contact Us",
      path: "contactus",
    },
    {
      component: <Policies />,
      permission: "Privacy And Policy",
      path: "privacy-and-policy",
    },
    {
      component: <PrivacyAndPolicy />,
      permission: "Policies",
      path: "policies",
    },
    {
      component: <LocationSearchMeta />,
      permission: "Location Search Meta",
      path: "location-search-meta",
    },
    {
      component: <OurClients />,
      permission: "Our Clients",
      path: "our-clients",
    },
    {
      component: <More />,
      permission: "More",
      path: "more",
    },
    {
      component: <DynamicMeta />,
      permission: "Dynamic Meta Update",
      path: "dynamic-meta",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    // Initialize 'menus' by directly constructing it from 'dashboard_permissions'
    const menus =
      globalState.dashboard_permissions?.permissions?.flatMap((menu) => {
        const menuNames = [menu.menu_name];

        if (menu.sub_menu_permissions?.length) {
          const subMenuNames = menu.sub_menu_permissions.map(
            (sub_menu) => sub_menu.sub_menu_name
          );
          return menuNames.concat(subMenuNames);
        }

        return menuNames;
      }) || [];

    const filteredMenus = [
      ...menus.filter((item) => item !== undefined),
      "Appearance",
    ];

    return filteredMenus;
    // eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedReportedChatComponent = ReportedChat.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedStudentsComponent = StudentsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPlanComponent = PremiumPlanComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedRoleComponent = RoleComponent.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCourseComponent = CourseComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedTutorComponent = TutorComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCouponComponent = CouponComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedPaymentComponent = PaymentComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedSubjectsComponent = SubjectsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedCmsComponent = CmsComponents?.filter(({ permission }) =>
    hasPermission(permission)
  );
  
  return {
    allowedDashboardComponent,
    allowedReportedChatComponent,
    allowedStudentsComponent,
    allowedPlanComponent,
    allowedTutorComponent,
    allowedPaymentComponent,
    allowedSubjectsComponent,
    allowedRoleComponent,
    allowedCmsComponent,
    allowedCourseComponent,
    allowedCouponComponent,
  };
};

export default useRoutes;
