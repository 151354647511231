import { useSelector } from "react-redux";
import { useGetBannersListDataQuery, useUpdateTableListFieldsDataMutation } from "../../store/queries/Banners";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { updateConfig } from "../../store/slices/Banners/bannersSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getBannerData } from "./api";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useBanners = () => {

  const { checkIfActiveRoute } = useRouteUtils();
  // const drawerMenu = [
  //   {
  //     title: "Banners",
  //     label: "Banners",
  //     link: "/banners",
  //     iconAsset: "DrawerIcon1",
  //     icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
  //     active:
  //       checkIfActiveRoute("/banners", true)
  //   },
  // ];
  
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const bannersState = useSelector((state) => state.banners);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [skip, setSkip] = useState(true);
  const {
    data: bannersList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBannersListDataQuery({
    page_size: bannersState.currentPageSize,
    page: bannersState.currentPage,
    sort_by: bannersState.sortBy,
    sort_order: bannersState.sortOrder,
    filter: bannersState.currentFilter,
  });

  // const [deletePremiumPlan] = useDeletePremiumPlanMutation();
  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = bannersList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [bannersList]);

  useEffect(() => {
    if (bannersState?.selectedBanner && bannersState?.isEdit) {
      fillbannersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannersState?.isEdit, bannersState?.showAddBannersModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddBannersModal = !state.showAddBannersModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      is_active: (field, data) => {
        if(data?.is_active) {
          return (
            <p className="pro-mb-0">
              Active
            </p>
          );
        }
        else {
          return (
            <p className="pro-mb-0">
              Inactive
            </p>
          );
        }
      },
      media: (feild, data) => {
        return (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
        )

      }
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  // const handleDelete = () => {
  //   deletePremiumPlan(bannersState?.selectedBanner).then((response) => {
  //     if (response?.data?.statusCode === 200) {
  //       toast.success("Plan deleted successfully");
  //       handleDeleteModal();
  //       refetch();
  //     } else {
  //       toast.error("Something went wrong");
  //       handleDeleteModal();
  //     }
  //   });
  // };

  const fillbannersData = () => {
    getBannerData(bannersState?.selectedBanner).then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateConfig((state) => (state.BannersData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch banners data");
      }
    });
  };

  // const handleDeleteModal = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showDeleteModal = !state.showDeleteModal;
  //       state.selectedBanner = e?.[0];
  //     })
  //   );
  // };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddBannersModal = true;
        state.selectedBanner = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedBanner = "";
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (bannersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = bannersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  useEffect(() => {
    if (bannersState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [bannersState.clearSelection]);

  const filters = useMemo(() => {
    if (bannersList?.data?.filters) {
      const buttonGroups = bannersList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);


  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  return {
    // drawerMenu,
    bannersState,
    showEditModal,
    tableFields,
    bannersList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    // handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateTableFields,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilter,
    activeFilter: bannersState.currentFilter,
    currentPage: bannersState.currentPage,
    filters,
    showImageModal,
    imageData,
    closeModal,
    closeEditModal
  };
};

export default useBanners;
