import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/ProtectRoute";
import useRoutes from "../routes/useRoutes";

const Index = () => {
  const navigate = useNavigate();
  const {
    allowedDashboardComponent,
    allowedReportedChatComponent,
    allowedStudentsComponent,
    allowedPlanComponent,
    allowedTutorComponent,
    allowedPaymentComponent,
    allowedSubjectsComponent,
    allowedRoleComponent,
    allowedCmsComponent,
    allowedCourseComponent,
    allowedCouponComponent,
    allowedTutorReviewComponent
  } = useRoutes();

  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      
      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedReportedChatComponent?.length > 0) {
        navigate(
          `${allowedReportedChatComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedStudentsComponent?.length > 0) {
        navigate(
          `/student/${allowedStudentsComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedPlanComponent?.length > 0) {
        navigate(
          `${allowedPlanComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedTutorComponent?.length > 0) {
        navigate(
          `/tutors${allowedTutorComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedPaymentComponent?.length > 0) {
        navigate(
          `/payments/${allowedPaymentComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedSubjectsComponent?.length > 0) {
        navigate(
          `${allowedSubjectsComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedRoleComponent?.length > 0) {
        navigate(
          `/role${allowedRoleComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCmsComponent?.length > 0) {
        navigate(
          `/cms/${allowedCmsComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCourseComponent?.length > 0) {
        navigate(
          `${allowedCourseComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedCouponComponent?.length > 0) {
        navigate(
          `/coupon${allowedCouponComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      
    }
    // eslint-disable-next-line
  }, [allowedDashboardComponent]);

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
