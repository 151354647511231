import React from "react";
import { Input } from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../../contactUs.module.scss";
import { Editor } from "react-draft-wysiwyg";

export const ItemField = ({
  formik,
  itmIndex,
  fields,
  handleAddLink,
  handleContentChange,
}) => {
  const { handleRemoveFieldCS, handleRemoveFieldLink } = useItemField(formik);

  return (
    <>
      <div className={Style.box_root}>
        <div className={Style.add_more_fields}>
        {formik?.values?.content?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className="row gx-2">
          <div className="col-md-12">
            <Input
              type="text"
              id={`title`}
              name={`title`}
              label={`Title`}
              onBlur={formik.handleBlur(`content.${itmIndex}.title`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title &&
                " error"
              }`}
              {...formik.getFieldProps(`content.${itmIndex}.title`)}
              error={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
            />
          </div>
          <div className="col-md-12">
            <Editor
              wrapperClassName={Style.pro_editor_wrapper}
              editorClassName={Style.pro_editor_main}
              toolbarClassName={Style.pro_editor_toolbar}
              editorState={formik.values?.content?.[itmIndex]?.description}
              readOnly={false}
              onEditorStateChange={(editorState) =>
                handleContentChange(editorState, itmIndex)
              }
              // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
              toolbar={{
                options: [
                  "blockType",
                  "inline",
                  "list",
                  "textAlign",
                  "fontSize",
                  "fontFamily",
                ],
                blockType: {
                  inDropdown: true,
                  className: Style.dropdown_wrapper,
                  dropdownClassName: Style.dropdown_menu,
                },
              }}
            />

            {formik.touched?.content?.[itmIndex]?.description &&
              formik.errors?.content?.[itmIndex]?.description && (
                <span className={Style.error_text}>
                  {formik.errors?.content?.[itmIndex]?.description}
                </span>
              )}
          </div>

          {formik?.values?.title === "Still not finding what you need?" && (
            <div className="col-md-12">
              <Input
                type="text"
                id={`mail_text`}
                name={`mail_text`}
                label={`Mail Text`}
                onBlur={formik.handleBlur(`content.${itmIndex}.mail_text`)}
                className={`pro-input lg ${
                  formik.touched?.content?.[itmIndex]?.mail_text &&
                  formik.errors?.content?.[itmIndex]?.mail_text &&
                  " error"
                }`}
                {...formik.getFieldProps(`content.${itmIndex}.mail_text`)}
                error={
                  formik.touched?.content?.[itmIndex]?.mail_text &&
                  formik.errors?.content?.[itmIndex]?.mail_text && (
                    <span className="error-text">
                      {formik.errors?.content?.[itmIndex]?.mail_text}
                    </span>
                  )
                }
                errorMessage={
                  formik.touched?.content?.[itmIndex]?.mail_text &&
                  formik.errors?.content?.[itmIndex]?.mail_text && (
                    <span className="error-text">
                      {formik.errors?.content?.[itmIndex]?.mail_text}
                    </span>
                  )
                }
              />
            </div>
          )}

          {formik?.values?.content?.[itmIndex]?.links?.map((fields, i) => (
            <div className="col-md-12">
              <div className={`${Style.add_more_fields} ${Style.inner_add_more}`}>
              {i >= 0 && (
                <button
                  className={`${Style.btn_close} btn-close`}
                  onClick={() => handleRemoveFieldLink(itmIndex, i)}
                ></button>
              )}


                <Input
                  type="text"
                  id={`links`}
                  name={`text`}
                  label={`Text`}
                  onBlur={formik.handleBlur(
                    `content.${itmIndex}.links.${i}.text`
                  )}
                  className={`pro-input lg ${
                    formik.touched?.content?.[itmIndex]?.links?.[i]?.text &&
                    formik.errors?.content?.[itmIndex]?.links?.[i]?.text &&
                    " error"
                  }`}
                  {...formik.getFieldProps(
                    `content.${itmIndex}.links.${i}.text`
                  )}
                  error={
                    formik.touched?.content?.[itmIndex]?.links?.[i]?.text &&
                    formik.errors?.content?.[itmIndex]?.links?.[i]?.text && (
                      <span className="error-text">
                        {formik.errors?.content?.[itmIndex]?.links?.[i]?.text}
                      </span>
                    )
                  }
                  errorMessage={
                    formik.touched?.content?.[itmIndex]?.links?.[i]?.text &&
                    formik.errors?.content?.[itmIndex]?.links?.[i]?.text && (
                      <span className="error-text">
                        {formik.errors?.content?.[itmIndex]?.links?.[i]?.text}
                      </span>
                    )
                  }
                />
                <Input
                  type="text"
                  id={`url`}
                  name={`url`}
                  label={`Url`}
                  onBlur={formik.handleBlur(
                    `content.${itmIndex}.links.${i}.url`
                  )}
                  className={`pro-input lg ${
                    formik.touched?.content?.[itmIndex]?.links?.[i]?.url &&
                    formik.errors?.content?.[itmIndex]?.links?.[i]?.url &&
                    " error"
                  }`}
                  {...formik.getFieldProps(
                    `content.${itmIndex}.links.${i}.url`
                  )}
                  error={
                    formik.touched?.content?.[itmIndex]?.links?.[i]?.url &&
                    formik.errors?.content?.[itmIndex]?.links?.[i]?.url && (
                      <span className="error-text">
                        {formik.errors?.content?.[itmIndex]?.links?.[i]?.url}
                      </span>
                    )
                  }
                  errorMessage={
                    formik.touched?.content?.[itmIndex]?.links?.[i]?.url &&
                    formik.errors?.content?.[itmIndex]?.links?.[i]?.url && (
                      <span className="error-text">
                        {formik.errors?.content?.[itmIndex]?.links?.[i]?.url}
                      </span>
                    )
                  }
                />
              </div>
            </div>
          ))}
          {formik?.values?.title !== "Still not finding what you need?" && (
            <div className={`${Style.add_more_btn} pro-d-flex pro-items-center pro-justify-end`}  onClick={() => handleAddLink(itmIndex)}>
              <span className="material-symbols-outlined">
                add_circle 
              </span>Add More
            </div>
          )}
        </div>
        </div>
      </div>
    </>
  );
};

export default ItemField;
