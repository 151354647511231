import { useSelector } from "react-redux";
import {
  useGetMoreListDataQuery,
  useUpdateClientStatusMutation,
  useUpdateOurClientTableListFieldsDataMutation,
} from "../../../store/queries/CMS/index.js";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";
import Style from "./ourClients.module.scss";
import {
  getEditMore,
  updateConfig,
} from "../../../store/slices/CMS/More/moreSlice.js";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteClient } from "./api.js";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets.js";
import { useNavigate } from "react-router-dom";

const useMore = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );

}, []);
  const dataState = useSelector((state) => state.more);
  const { editLoading } = useSelector((state) => state.more);
  const [updateTableFields] =
    useUpdateOurClientTableListFieldsDataMutation();

    const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");
    const [updateStudentStatus] = useUpdateClientStatusMutation();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: dataList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetMoreListDataQuery({
    page_size: dataState.currentPageSize,
    page: dataState.currentPage,
    sort_by: dataState.sortBy,
    sort_order: dataState.sortOrder,
    start: dataState.startDate,
    end: dataState.endDate,
    search: dataState.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = dataList?.data;
      })
    );
    // eslint-disable-next-line
  }, [dataList]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = !state.showAddModal;
        state.isEdit = false;
        state.editOurClientsData = [];
        state.imageName = "";
        state.imagePreview = "";
        state.clearSelection = true;
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.selectedData = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getEditMore(e?.[0]));
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      image: (feild, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image)}
            />
          </div>
        );
      },
      name: (field, data) => {
        return (
          <>
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data?.image !== "" ? data?.image : Assets.GENDER_IMAGE}
                  width={16}
                  height={16}
                  alt={data?.name}
                />
              </div>
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
              >
                {data?.name}
              </span>
            </div>
          </>
        );
      },
      status: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.status}
              onChange={(e) => updateStudentStatus(data?._id)}
            />
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedData = e?.[0];
      })
    );
  };

  const handleDelete = () => {
    deleteClient(dataState?.selectedData).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Client deleted successfully");
        refetch();
      } else {
        toast.error("Something went wrong");
      }
      handleDeleteModal();
      dispatch(
        updateConfig((state) => {
          state.clearSelection = true;
        })
      );
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  useEffect(() => {
    if (dataState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [dataState.clearSelection]);
  const handleSort = (label) => {
    if (dataState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dataState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  return {
    dataState,
    showEditModal,
    tableFields,
    dataList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDeleteModal,
    handleDelete,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    closeEditModal,
    updateTableFields,
    editLoading,
    showImageModal,
    imageData,
    closeImageModal
  };
};

export default useMore;
