import { Button } from "@wac-ui-dashboard/wac_component_library";
import useEditPolicy from "./useEditPolicy";
import { Editor } from "react-draft-wysiwyg";

import Style from "./editPolicy.module.scss";

const EditPolicy = ({ closeModal, refetch }) => {
  const { formik, selectedItemsDetails, handleContentChange } = useEditPolicy({
    closeModal,
    refetch,
  });

  return (
    <div className="pro-p-5">
      <div className={Style.editor_container}>
        <Editor
          wrapperClassName={Style.pro_editor_wrapper}
          editorClassName={Style.pro_editor_main}
          toolbarClassName={Style.pro_editor_toolbar}
          editorState={formik?.values?.policies}
          readOnly={selectedItemsDetails === "" ? true : false}
          onEditorStateChange={handleContentChange}
          // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
          toolbar={{
            // options: ["blockType","inline", "list", "link"],
            blockType: {
              inDropdown: true,
              className: Style.dropdown_wrapper,
              dropdownClassName: Style.dropdown_menu,
            },
          }}
        />
        {formik?.touched?.policies && formik?.errors?.policies && (
          <span className={Style.error_text}>{formik.errors?.policies}</span>
        )}{" "}
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EditPolicy;
