import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import usePremiumPlan from "./usePremiumPlan";

const PremiumPlan = () => {
  const drawerMenu = usePremiumPlan();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu?.drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default PremiumPlan;
