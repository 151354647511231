import React from "react";
import useAvailabilityCalender from "./useAvailabilityCalender";
import Style from "./avilabilityCalender.module.scss";

const AvailabilityCalendar = ({ title, data }) => {
  const { renderHourHeaderRow, renderDayRow, days } =
    useAvailabilityCalender(data);

  return (
    <>
      <h6 className="pro-ttl h6">{title}</h6>
      <div className={`pro-w-100 ${Style.table_wrapper}`}>
        <table className={Style.availability_table}>
          <thead>{renderHourHeaderRow()}</thead>
          <tbody>
            {days.map((day, dayIndex) => renderDayRow(day, dayIndex))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AvailabilityCalendar;
