import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/CMS/AboutUs/aboutUsSlice";

export const useItemField = (
  {formik}
) => {
  
  const planState = useSelector((state) => state.premiumPlan);

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.feature?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik?.setFieldValue("feature", data);
  };

  

  return {
    handleRemoveFieldCS,
    planState,
  };
};

export default useItemField;
