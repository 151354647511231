import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Staffs/staffsSlice";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  useUpdateCreateStaffDataMutation,
  useUpdateCreateStaffsMutation,
} from "../../../../store/queries/Roles";
import useValidations from "../../../../utils/hooks/useValidations";

const useAddStaff = ({ refetch ,handleModalClick, dropdown}) => {
  const dispatch = useDispatch();
  const { staffData, isEdit, selectedStaff } =
    useSelector((state) => state.staffs);

    const [showPassword, setShowPassword] = useState(true);
    const { validateEmail } = useValidations();

  const [updateStaffsData] = useUpdateCreateStaffDataMutation();
  const [updateCreateStaffsData] = useUpdateCreateStaffsMutation();

const outputArray = dropdown?.map(item => {
    return { value: item?._id, label: item?.name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') };
});

  const validation = Yup.object({
    name: Yup.string().required("Enter name"),
    role_id: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      is_active: true,
      role_id: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email ID Required";
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      if(!isEdit) {
        if (!values.password) {
          errors.password = "*Password Required";
        }
      }
      
      return errors;
    },
    onSubmit: (values) => {

      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });

      if (isEdit) {
        updateStaffsData({ selectedStaff, formData }).then(
          (response) => {
            
            if (response?.data?.statusCode === 200) {
              handleModalClick();
              toast.success("User Details Updated");
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
            } else if (response?.error?.data?.statusCode === 422) {
              // displays backend errors
              const errors = response?.error?.data?.errors[0]?.message;
              formik.setErrors(errors);
              toast.error(errors);
            } else {
              toast.error("Something went wrong");
            }
          }
        );
      } else {
        updateCreateStaffsData(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            toast.success("New User Created");
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            toast.error(response?.error?.data?.errors[0]?.message);
            Object.keys(errors).forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {

      formik?.setFieldValue("name", staffData?.name);
      formik?.setFieldValue("role_id", staffData?.role_id)
      formik?.setFieldValue("email", staffData?.email)
      formik?.setFieldValue("is_active", staffData?.is_active)
    }
    // eslint-disable-next-line
  }, [isEdit, staffData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  let selectVal = outputArray.find(
    (item) => item.value === formik.values.role_id
  );

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    staffData,
    getFieldError,
    isEdit,
    outputArray,
    selectVal,
    showPassword,
    handleShowPassword
  };
};

export default useAddStaff;
