import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetStudentOverViewDataQuery } from "../../../store/queries/Student";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "../Students/students.module.scss";
import { updateConfig } from "../../../store/slices/Student/studentsProfileSlice";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useStudentDetails = () => {
  const profileState = useSelector((state) => state?.studentsProfile);
  const { studentID } = useParams();
  const navigate = useNavigate();
  const activeProfile = studentID ?? sessionStorage.getItem("active");

  const {
    data: profileData,
    isFetching,
    // refetch,
  } = useGetStudentOverViewDataQuery({
    user_id: activeProfile,
    page_size: profileState.currentPageSize,
  });

  const getClassesRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      tutor: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.image !== "" && data?.image
                    ? data?.image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },

      start_time: (field, data) => {

				// if (data?.start_time) {
				// 	const dateObject = new Date(data?.start_time);

				// 	// Format the date as 'YYYY-MM-DD'

				// 	const formattedDate = dateObject.toISOString().split('T')[0];
				// 	return (
				// 		<p className="pro-mb-0">
				// 			{formattedDate}
				// 		</p>
				// 	);
				// }

        if (data?.start_time) {
          const dateObject = new Date(data.start_time);
        
          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: userTimezone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split('/');

  // Reformat to dd/mm/yyyy
  const formattedDateDMY = `${day}/${month}/${year}`;
        
          return (
            <p className="pro-mb-0">
              {formattedDateDMY}
            </p>
          );
        }

			},

      mode_of_class: (field, data) => {
        return (
          <>
            {data.mode_of_class === "ONLINE" ? (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-success pro-fw-medium`}
              >
                Online
              </span>
            ) : (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-warning pro-fw-medium`}
              >
                In Person
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const getEnquiryRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      tutor: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.image !== "" && data?.image
                    ? data?.image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },

      date: (field, data) => {

				// if (data?.date) {
				// 	const dateObject = new Date(data?.date);

				// 	// Format the date as 'YYYY-MM-DD'

				// 	const formattedDate = dateObject.toISOString().split('T')[0];
				// 	return (
				// 		<p className="pro-mb-0">
				// 			{formattedDate}
				// 		</p>
				// 	);
				// }

        if (data?.date) {
          const dateObject = new Date(data.date);
        
          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: userTimezone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split('/');

  // Reformat to dd/mm/yyyy
  const formattedDateDMY = `${day}/${month}/${year}`;
        
          return (
            <p className="pro-mb-0">
              {formattedDateDMY}
            </p>
          );
        }
			},
      mode_of_class: (field, data) => {
        return (
          <>
            {data.mode_of_class === "ONLINE" ? (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-success pro-fw-medium`}
              >
                Online
              </span>
            ) : (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-warning pro-fw-medium`}
              >
                In Person
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const getTransactionsRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      fee: (field, data) => <p className="pro-mb-0">{`$${data[field]}`}</p>,
      tutor: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },

      date: (field, data) => {

				// if (data?.date) {
				// 	const dateObject = new Date(data?.date);

				// 	// Format the date as 'YYYY-MM-DD'

				// 	const formattedDate = dateObject.toISOString().split('T')[0];
				// 	return (
				// 		<p className="pro-mb-0">
				// 			{formattedDate}
				// 		</p>
				// 	);
				// }

        if (data?.date) {
          const dateObject = new Date(data.date);
        
          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: userTimezone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split('/');

  // Reformat to dd/mm/yyyy
  const formattedDateDMY = `${day}/${month}/${year}`;
        
          return (
            <p className="pro-mb-0">
              {formattedDateDMY}
            </p>
          );
        }

			},
      
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const getReportRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.user_id}`,
              });

              sessionStorage.setItem("active", `${data?.user_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.image !== "" && data?.image
                    ? data?.image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.name)}
            </span>
          </div>
        );
      },
      reported_on: (field, data) => {

				// if (data?.reported_on) {
				// 	const dateObject = new Date(data?.reported_on);

				// 	// Format the date as 'YYYY-MM-DD'

				// 	const formattedDate = dateObject.toISOString().split('T')[0];
				// 	return (
				// 		<p className="pro-mb-0">
				// 			{formattedDate}
				// 		</p>
				// 	);
				// }

        if (data?.reported_on) {
          const dateObject = new Date(data.reported_on);
        
          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: userTimezone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split('/');

  // Reformat to dd/mm/yyyy
  const formattedDateDMY = `${day}/${month}/${year}`;
        
          return (
            <p className="pro-mb-0">
              {formattedDateDMY}
            </p>
          );
        }

			},
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    profileState,
    getClassesRow,
    getEnquiryRow,
    getTransactionsRow,
    getReportRow,
    isFetching,
    profileData,
  };
};

export default useStudentDetails;
