import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ReactSelect from "react-select";
import useRevenueFilter from "./useRevenueFilter";

const RevenueFilter = ({
  isStickyFooter,
  handleFilterClick,
  activityFilter,
  sources,
}) => {
  const {
    formik,
    clearFilter,
    getFieldError,
    statusObjects,
    selectActivity,
    selectSource,
    sourceObjects,
  } = useRevenueFilter(handleFilterClick, activityFilter, sources);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"From Date*"}
          type="date"
          id="date_from"
          value={formik?.values?.date_from}
          name="date_from"
          className={`pro-input lg ${getFieldError("date_from") && " error"}`}
          onChange={(e) => formik.setFieldValue("date_from", e.target.value)}
          error={getFieldError("date_from")}
          errorMessage={getFieldError("date_from")}
        />
        <Input
          label={"To Date*"}
          type="date"
          id="date_to"
          value={formik.values.date_to}
          name="date_to"
          min={formik.values.date_from}
          onChange={(e) => formik.setFieldValue("date_to", e.target.value)}
          disabled={!formik.values.date_from}
          className={`pro-input lg ${getFieldError("date_to") && " error"}`}
          error={getFieldError("date_to")}
          errorMessage={getFieldError("date_to")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Source
          </label>
          <ReactSelect
            id="source"
            name="source"
            placeholder={"Select Status"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            options={sourceObjects ?? []}
            value={selectSource}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("source")}
            onChange={(e) => formik?.setFieldValue("source", e?.value || null)}
            menuPlacement="auto"
          />
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Revenue Type
          </label>
          <ReactSelect
            id="revenue_type"
            name="revenue_type"
            placeholder={"Select Status"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            options={statusObjects ?? []}
            value={selectActivity}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("revenue_type")}
            onChange={(e) =>
              formik?.setFieldValue("revenue_type", e?.value || null)
            }
            menuPlacement="auto"
          />
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button className={"pro-btn-link lg pro-px-5"} onClick={clearFilter}>
            Clear
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 `}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RevenueFilter;
