import {
  Button,
  HeadingGroup,
  Image,
  Input,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import usePremiumPlanDetails from "./usePremiumPlanDetails";
import Select from "react-select";
// import { Editor } from "react-draft-wysiwyg";
import Style from "./editPolicy.module.scss";
import { useEffect } from "react";
import Assets from "../../../../assets/Assets";
import { getFormatedDate } from "../../../../utils/functions/table";

const PremiumPlanDetails = ({ closeModal, refetch }) => {
  const { planData, getRow } = usePremiumPlanDetails({ closeModal, refetch });

  const dateObject = new Date(planData?.premium_plan_purchase_details?.date);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Define the options for formatting the date
  const options = {
    timeZone: userTimezone,
  	weekday: 'short', // Short weekday name (e.g., Fri)
  	month: 'short',   // Short month name (e.g., Oct)
  	day: 'numeric',    // Day of the month (e.g., 13)
  	year: 'numeric',   // Year (e.g., 2023)
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateObject);

  // const dateStartObject = new Date(planData?.premium_plan_purchase_details?.start_time);
  // const dateEndObject = new Date(planData?.premium_plan_purchase_details?.end_time);

  // // Format the time in 12-hour format with AM/PM
  // const formattedStartTime = dateStartObject.toLocaleTimeString('en-US', {
  // 	hour: 'numeric',
  // 	minute: '2-digit',
  // 	hour12: true,
  // });

  // const formattedEndTime = dateEndObject.toLocaleTimeString('en-US', {
  // 	hour: 'numeric',
  // 	minute: '2-digit',
  // 	hour12: true,
  // });

  return (
    <>
      <div className="pro-p-5">
        <div className={`pro-p-2 ${Style.header_root}`}>
          <div className="row row-cols-4 gx-4">
            <div className={`pro-d-flex`}>
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={
                    planData?.premium_plan_purchase_details?.user_image !==
                      "" && planData?.premium_plan_purchase_details?.user_image
                      ? planData?.premium_plan_purchase_details?.user_image
                      : Assets.GENDER_IMAGE
                  }
                  width={10}
                  height={10}
                  alt={planData?.premium_plan_purchase_details?.full_name}
                />
              </div>
              <div className="pro-ps-2">
                <h6 className="pro-ttl h6 pro-mb-0">
                  {planData?.premium_plan_purchase_details?.full_name}
                </h6>
                <p
                  className={`pro-mb-0 pro-mt-1 pro-fw-medium ${Style.alt_text}`}
                >
                  #{planData?.premium_plan_purchase_details?.transaction_id}
                </p>
              </div>
            </div>

            <div>
              <h6 className="pro-ttl h6 pro-mb-0">{"Amount"}</h6>
              <p className="pro-mt-1 pro-fw-medium pro-mb-0">
                {"$" + planData?.premium_plan_purchase_details?.amount}
              </p>
            </div>

            <div>
              <h6 className="pro-ttl h6 pro-mb-0">{"Tutor Plan"}</h6>
              <p className="pro-mt-1 pro-fw-medium pro-mb-0">
                {planData?.premium_plan_purchase_details?.featured_tutor_plan}
              </p>
            </div>
            <div>
              <h6 className="pro-ttl h6 pro-mb-0">{"Date"}</h6>
              <p className="pro-mt-1 pro-fw-medium pro-mb-0">
                {formattedDate}
              </p>
            </div>
          </div>
        </div>
        {/* <div>
						<span>  # {planData?.premium_plan_purchase_details?.transaction_id} </span>
					</div>
					<div>
						<span>Tutor Plan = {planData?.premium_plan_purchase_details?.featured_tutor_plan} </span>
					</div>
					<div>
						<span>Amount = {planData?.premium_plan_purchase_details?.amount} </span>
					</div> */}

        {/* <div className={`${Style.align_prof_img}`}>
						<div
							className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
						>
							<Image
								src={
									planData?.premium_plan_purchase_details?.tutor_image !== "" && planData?.premium_plan_purchase_details?.tutor_image
										? planData?.premium_plan_purchase_details?.tutor_image
										: Assets.GENDER_IMAGE
								}
								width={10}
								height={10}
								alt={planData?.premium_plan_purchase_details?.tutor}
							/>
						</div>
					</div>

					<div>
						<div>{planData?.premium_plan_purchase_details?.tutor}</div>
						<div>
							{planData?.premium_plan_purchase_details?.subject} - {planData?.premium_plan_purchase_details?.specialization}
						</div>
						<div>
							Fee = {planData?.premium_plan_purchase_details?.fee}
						</div>
					</div> */}

        {/* <div>
						<p>Meet Provider: {planData?.premium_plan_purchase_details?.meet_provider}</p>
						<p>Meet Id: {planData?.premium_plan_purchase_details?.meet_id}</p>
					</div> */}

        {/* <div>
						<p>
							Hours: {planData?.premium_plan_purchase_details?.hours} hrs ={' '}
							<span>
								Session start time : {formattedStartTime} Session end time : {formattedEndTime}
							</span>
						</p>
						<p>Meet Id: {planData?.premium_plan_purchase_details?.meet_id}</p>
					</div> */}
        <HeadingGroup title={"Activity"} extraClassName={`pro-mt-5`} />
        <div className="col-12 ">
          <Table
            multiSelect={false}
            data={planData?.premium_plan_purchase_details?.events || []}
            uniqueID={"_id"}
            fields={planData?.fields}
            getRow={getRow}
            showCheckBox={false}
          />
        </div>
      </div>
    </>
  );
};

export default PremiumPlanDetails;
