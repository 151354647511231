import React from 'react';
import { Outlet } from 'react-router-dom';
import CommonLayout from '../../pages/Layouts/CommonLayout';
import useSubjects from './useSubjects';

const Subjects = () => {
    const drawerMenu = useSubjects();
  return (
    <CommonLayout dashboard >
      <Outlet />
    </CommonLayout>
  );
}

export default Subjects;
