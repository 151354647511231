import { Button, Input, IconText, media } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddBanner from "./useAddBanner";
import Style from "./addBanner.module.scss";
import { limitStrLength } from "../../../utils/functions/table";
import Select from "react-select";

const AddBanner = (refetch) => {
	const {
		formik,
		isEdit,
		BannersData,
		handleMedia,
		imagePreview,
		profilefileInputRef,
		getFieldError,
		handleCloseModal,
		handleChecked,
		isChecked,
		selectedOption,
		options,
		handleChange
	} = useAddBanner(refetch);

	return (
		<div className={`row`}>

			<Input
				label={"Type"}
				type="text"
				id="type"
				name="type"
				className={`pro-input lg ${getFieldError("type") && " error"
					}`}
				{...formik.getFieldProps("type")}
				error={getFieldError("type")}
				errorMessage={getFieldError("type")}
			/>

<div className="input-wrap pro-mb-4">
				<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
					Description
				</label>
				<textarea
					id="description"
					rows={4}
					name="description"
					className={`pro-input lg ${getFieldError("description") && " error"}`}
					{...formik.getFieldProps("description")}
				></textarea>
				{getFieldError("description") && (
					<span className="error-text">{getFieldError("description")}</span>
				)}
			</div>

			<Input
				label={"Link"}
				type="text"
				id="link"
				name="link"
				className={`pro-input lg ${getFieldError("link") && " error"
					}`}
				{...formik.getFieldProps("link")}
				error={getFieldError("link")}
				errorMessage={getFieldError("link")}
			/>


				<div className="pro-check-box row">
					<div className="col-md-3">
						<input
							type="checkbox"
							className="pro-check"
							id="is_active"
							name="pro-checkbox"
							onChange={(e) => handleChecked(e, "is_active")}
							checked={isChecked}
							disabled={false}
						/>
						<label htmlFor="is_active" className="pro-check-label">
							Is Active
						</label>
					</div>
				</div>

	  <Select
                  id="media_type"
                  placeholder="Select media type"
                  name="media_type"
                  isClearable={true}
                  options={options}
                  value={selectedOption}
                  className={`pro-input multi-select lg ${
                    formik.errors.media_type && formik.touched.media_type && " error"
                  }`}
                  classNamePrefix="pro-input"
                  onBlur={formik.handleBlur("media_type")}
                  onChange={(value) => handleChange(value)}
                ></Select>

				<div className="input-wrap pro-mb-4">
				<label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
					Media*
				</label>
				<div className="input-drag">
					<input
						type="file"
						placeholder="placeholder"
						accept=".jpeg, .jpg , .png"
						ref={profilefileInputRef}
						className={`pro-input ${formik.errors.media && formik.touched.media && " error"
							}`}
						id="media"
						name="media"
						onBlur={formik.handleBlur("media")}
						onChange={(e) => handleMedia(e)}
					/>

					<span className="input-drag-box">
						<IconText
							title={
								formik?.values?.media?.name !== undefined
									? limitStrLength(formik?.values?.media?.name, 30)
									: BannersData !== ""
										? BannersData?.media_details?.name
										: `Drop files to attach or browse`
							}
						/>
					</span>
					{formik.touched.media && formik.errors.media && (
						<span className="error-text">{formik.errors.media}</span>
					)}
				</div>
			</div>
			<div className={`col-2 pro-my-2`}>
				<div className={`${Style.root_image_inner}`}>
					<media
						width={100}
						height={100}
						src={imagePreview ? imagePreview : BannersData?.media_details?.url}
						alt={`media - 01`}
					/>
				</div>
			</div>
			<span className="pro-font-sm ">Max upload file size 5MB</span>
			

			<div
				className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={handleCloseModal}
				>
					Cancel
				</Button>

				<Button
					className={"pro-btn-primary lg pro-ms-3"}
					type="submit"
					onClick={formik.handleSubmit}
				>
					{isEdit ? "Update" : "Create"}
				</Button>
			</div>
		</div>
	);
};

export default AddBanner;
