import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedData: "",
  isEdit: false,
  itemData: [],
  selectedItemsDetails: ""
};

export const udateEntry = createAsyncThunk(
  "admin/api/policies",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put("admin/api/policies", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const policiesSlice = createSlice({
  name: "policies",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = policiesSlice.actions;

export default policiesSlice.reducer;
