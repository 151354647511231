import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useTransactionList from "./useTransactionList";
import TransactionFilter from "./TransactionFilter";
import TransactionDetails from "./TransactionDetails";
import Style from './transaction.module.scss';

const TransactionList = () => {
  const {
    transactionState,
    showEditModal,
    transactionList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilterClick,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
    closeModal
  } = useTransactionList();
  return (
    <div>
      <HeadingGroup title={"Course Purchase"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: transactionState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={transactionList?.data?.transactionList || []}
          uniqueID={"_id"}
          fields={transactionList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={transactionState?.currentPageSize}
          assignable={false}
          deletable={transactionList?.data?.delete_permission}
          editable={transactionList?.data?.edit_permission}
          showCheckBox={false}
        />
        {transactionList?.data?.transactionList?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              transactionList.data.filtered_count /
                transactionState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === transactionState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(
                transactionList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = transactionList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={transactionList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={transactionState?.showCreateModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <TransactionFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
          />
        </OffCanvasLayout>

        <ModalLayout
						centered={false}
						show={transactionState.showDetailsModal}
						handleClose={() => closeModal()}
						title={"Transaction Details"}
						closeIcon={<span className="material-symbols-outlined">close</span>}
						backdrop="static"
						propStyle={{ root: Style.modal_root }}
					>
						<TransactionDetails closeModal={() => closeModal()} refetch={refetch}/>
					</ModalLayout>
      </div>
    </div>
  );
};

export default TransactionList;
