import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../components/Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useCourses from "./useCourses";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import ScheduleClassFilter from "./ScheduleClassFilter";
import ScheduleDetails from "./ScheduleDetails";
import Style from "./course.module.scss";
import EmptyData from "../Global/EmptyData";

function Courses({ dashboard = false }) {
  const {
    drawerMenu,
    coursesState,
    showEditModal,
    tableFields,
    coursesList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    closeEditModal,
    updateTableFields,
    filters,
    handleFilter,
    activeFilter,
    currentPage,
    handleFilterClick,
    closeModal,
    handleDashboardRedirect,
  } = useCourses({ dashboard });

  const propertiesToInclude = ["name", "status"];

  return (
    <div>
      <CommonLayout drawerMenu={drawerMenu} dashboard={dashboard}>
        <HeadingGroup
          title={"Scheduled Classes"}
          className={`pro-mb-4`}
          // buttonTitle={"Add new"}
          // handleClick={handleModalClick}
        />
        <div className="col-auto pro-pt-3 pro-pb-6">
          {!dashboard ? (
            <div className="row gx-2">
              <div className="col">
                <SearchFilters
                  searchable={true}
                  handleButtonGroupChange={handleFilter}
                  onSearchInput={handleSearch}
                  showActions={true}
                  handleActionClick={handleEditColumnsClick}
                  loading={isLoading}
                  SearchIcon={
                    <span className="material-symbols-outlined"> search </span>
                  }
                  showClearFilters
                  handleClear={handleClearClick}
                  searchInputProps={{ value: coursesState?.search }}
                />
              </div>
              <div className="col-auto">
                <button
                  className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
                  onClick={handleFilterClick}
                >
                  <span className="material-symbols-outlined">tune</span>
                  <span>Filter</span>
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className={`pro-pt-3 pro-pb-5 `}>
            {coursesList?.data?.scheduled_class_lists?.length === 0 ? (
              <EmptyData />
            ) : (
              <div
                onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
                className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
              >
                <Table
                  multiSelect={false}
                  data={coursesList?.data?.scheduled_class_lists || []}
                  uniqueID={"_id"}
                  fields={tableFields}
                  SortIcon={<FaSort />}
                  editIcon={
                    <span className="material-symbols-outlined">edit</span>
                  }
                  deleteIcon={
                    <span className="material-symbols-outlined">delete</span>
                  }
                  handleSort={handleSort}
                  getRow={getRow}
                  loading={isFetching}
                  perpage={coursesState?.currentPageSize}
                  assignable={false}
                  handleEdit={handleEditAction}
                  handleDelete={handleDeleteModal}
                  deletable={false}
                  showCheckBox={false}
                />
              </div>
            )}
          </div>
          {coursesList?.data?.scheduled_class_lists?.length > 0 &&
            !dashboard && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={Math.ceil(
                  coursesList.data.filtered_count / coursesState.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}

          <ModalLayout show={showEditModal} handleClose={closeEditModal}>
            <div className="pro-m-5">
              <OrderColumn
                title={"Choose which columns you see"}
                refetch={refetch}
                tableFields={Object.keys(
                  coursesList?.data?.fields ?? {}
                ).reduce((filteredObj, property) => {
                  filteredObj[property] = coursesList?.data?.fields[property];
                  return filteredObj;
                }, {})}
                moduleId={coursesList?.data?.module_id}
                updateData={updateTableFields}
              />
            </div>
          </ModalLayout>
          <OffCanvasLayout
            show={coursesState?.showFilterModal}
            handleClose={handleFilterClick}
            title={"Filters"}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
            backdrop={"static"}
          >
            <ScheduleClassFilter
              isStickyFooter
              handleFilterClick={handleFilterClick}
              activityFilter={coursesList?.data?.sessionStatusValues}
            />
          </OffCanvasLayout>

          <ModalLayout
            centered={false}
            show={coursesState.showDetailsModal}
            handleClose={() => closeModal()}
            title={"Scheduled Class Details"}
            closeIcon={<span className="material-symbols-outlined">close</span>}
            backdrop="static"
            propStyle={{ root: Style.modal_root }}
          >
            <ScheduleDetails
              closeModal={() => closeModal()}
              refetch={refetch}
            />
          </ModalLayout>
        </div>
      </CommonLayout>
    </div>
  );
}

export default Courses;
