import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useRole = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Role",
      label: "Role",
      link: "/role/role-management",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">task</span>,
      active: checkIfActiveRoute("/role/role-management", true),
    },
    {
      title: "Admin Users",
      label: "Admin Users",
      link: "/role/staffs",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">group</span>,
      active: checkIfActiveRoute("/role/staffs", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenuPermission };
  // return { drawerMenu };
};

export default useRole;
