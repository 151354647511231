import { getAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/api/admin-users/login", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/api/admin-users/forgot-password", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/api/admin-users/reset-password", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const changePassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/api/admin-users/change-password", params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
}
