import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}${getParams("mode_of_class")}${getParams(
            "specialization"
          )}${getParams("activity")}${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams(
            "search"
          )}page_no=${params?.page || 1}&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const tutorReviews = createApi({
  reducerPath: "tutorsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Tutor-Reviews"],
  endpoints: (builder) => ({
    // branches starts
    getTutorReviewData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/reviews`,
      }),
      providesTags: ["Tutor-Reviews"],
    }),
    updateTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Tutor-Reviews"],
    }),
    updateTutorReviewStatus: builder.mutation({
      query: (id) => ({
        method: "put",
        endpoint: `admin/api/toggle-review/${id}`,
      }),
      // invalidatesTags: ["Tutor"],
    }),
  }),
});

export const {
  useGetTutorReviewDataQuery,
  useUpdateTableListFieldsDataMutation,
  useUpdateTutorReviewStatusMutation,
} = tutorReviews;
