import { Image, Table } from "@wac-ui-dashboard/wac_component_library";
import { getFormatedDateRange } from "../../../../utils/functions/table";
import Accordion from "react-bootstrap/Accordion";
import EmptyData from "../../../Global/EmptyData";
import usePayoutDetail from "./usePayoutDetail";
import Style from "../tutor.module.scss";
import Assets from "../../../../assets/Assets";

const PayoutDetail = ({ payoutDetailData }) => {
  const { getRow } = usePayoutDetail();

  return (
    <>
      <div className="pro-p-5">
        <div className={`${Style.header_root}  pro-p-2`}>
          <div className="row row-cols-4 gx-4">
            <div className="pro-d-flex">
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={
                    payoutDetailData?.payout_details?.[0]?.tutor_image !== ""
                      ? payoutDetailData?.payout_details?.[0]?.tutor_image
                      : Assets.GENDER_IMAGE
                  }
                  width={10}
                  height={10}
                  alt={payoutDetailData?.payout_details?.[0]?.tutor}
                />
              </div>
              <div className="pro-ps-2">
                <h6 className="pro-ttl h6 pro-mb-0">
                  {payoutDetailData?.payout_details?.[0]?.tutor}
                </h6>
                <p
                  className={`pro-mb-0 pro-mt-1 pro-fw-medium ${Style.alt_text}`}
                >
                  {payoutDetailData?.payout_details?.[0]?._id}#{}
                </p>
              </div>
            </div>
            <div>
              <h6 className="pro-mb-0 h6 pro-ttl"> {"Amount"} </h6>
              <p className="pro-mb-0 pro-mt-1 pro-fw-medium">
                {" "}
                ${payoutDetailData?.payout_details?.[0]?.total_amount}{" "}
              </p>
            </div>
            <div>
              <h6 className="pro-mb-0 h6 pro-ttl"> {"Payments"} </h6>
              <p className="pro-mb-0 pro-mt-1 pro-fw-medium">
                {" "}
                {payoutDetailData?.payout_details?.[0]?.payment_count}{" "}
              </p>
            </div>
            <div>
              <h6 className="pro-mb-0 h6 pro-ttl"> {"Date"} </h6>
              <p className="pro-mb-0 pro-mt-1 pro-fw-medium">
                {" "}
                {getFormatedDateRange(
                  payoutDetailData?.payout_details?.[0]?.date
                )}{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="pro-mt-5">
          <Accordion defaultActiveKey={0} className={Style.accordion}>
          {payoutDetailData?.payout_details?.[0]?.payout_details?.map(
            (item, index) => (
                <Accordion.Item eventKey={index} className={Style.item}>
                  <Accordion.Header as={'h6'} className={`${Style.header}`}>
                    <span>Payment {index + 1}</span>
                    <span className={`icon`}><span class="material-symbols-outlined x4"> expand_more </span> </span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className={`pro-w-100`}>
                      {item?.length === 0 ? (
                        <EmptyData />
                      ) : (
                        <div className={"pro-pt-0"}>
                          <Table
                            multiSelect={false}
                            data={item || []}
                            uniqueID={"transaction_id"}
                            fields={payoutDetailData?.fields}
                            getRow={getRow}
                          />
                        </div>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
            )
            )}
            </Accordion>
        </div>
      </div>
    </>
  );
};

export default PayoutDetail;
