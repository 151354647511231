import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../../store/slices/Payment/Revenue/RevenueSlice";

const useRevenueFilter = (handleFilterClick, activityFilter, sources) => {
  const dispatch = useDispatch();

  const [statusObjects, setStatusObjects] = useState([]);
  const [sourceObjects, setSourceObjects] = useState([]);
  const { isFilter, revenue_type, source, date_from, date_to } = useSelector(
    (state) => state.Revenue
  );
  const validationSchema = Yup.object({});
  const formik = useFormik({
    initialValues: {
      revenue_type: "",
      source: "",
      date_from: "",
      date_to: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      dispatch(
        updateConfig((state) => {
          Object.assign(state, subData);
        })
      );
      handleFilterClick();
    },
  });
  const clearFilter = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        Object.assign(state, formik.initialValues);
        // state.isFilter = false;
      })
    );
    handleFilterClick();
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  useEffect(() => {
    if (activityFilter) {
      const newStatusObjects = activityFilter.map((status) => {
        return { value: status, label: status.replace(/_/g, " ") };
      });
      setStatusObjects(newStatusObjects);
    }
  }, [activityFilter]);

  useEffect(() => {
    if (sources) {
      const newSourcesObjects = sources.map((status) => {
        return { value: status, label: status.replace(/_/g, " ") };
      });
      setSourceObjects(newSourcesObjects);
    }
  }, [sources]);

  useEffect(() => {
    if (isFilter) {
      formik.setValues({
        revenue_type: revenue_type || "",
        source: source || "",
        date_from: date_from || "",
      date_to: date_to || "",
      });
    }
  }, [isFilter]);
  

  let selectActivity = statusObjects.find(
    (item) => item.value === formik.values.revenue_type
  );

  let selectSource = sourceObjects.find(
    (item) => item.value === formik.values.source
  );

  return {
    formik,
    getFieldError,
    clearFilter,
    statusObjects,
    selectActivity,
    selectSource,
    sourceObjects,
  };
};

export default useRevenueFilter;
