import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ReactSelect from "react-select";
import useCouponListFilter from "./useCouponListFilter";

const CouponListFilter = ({ isStickyFooter, handleFilterClick, dropdown }) => {
  const { formik, selectVal, clearFilter , outputArray} = useCouponListFilter({handleFilterClick,dropdown});
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            status
          </label>
          <ReactSelect
            id="coupon_type"
            name="coupon_type"
            placeholder={"Select Status"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            options={outputArray ?? []}
            value={selectVal}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("coupon_type")}
            onChange={(e) => formik?.setFieldValue("coupon_type", e?.value || null)}
            menuPlacement="auto"
          />
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={clearFilter}
          >
            Clear
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 `}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CouponListFilter;
