import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isFilter: false,
  showCreateModal: false,
  showFilterModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  couponData: [],
  startDate:"",
  endDate:"",
  coupon_type: "",
  selectedCoupon: "",
  isEdit: false,
};

export const getCouponData = createAsyncThunk(
  "/coupon/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/coupon/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const couponRedeemSlice = createSlice({
  name: "couponRedeem",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCouponData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getCouponData.fulfilled, (state, action) => {
        state.couponData = action.payload?.data?.coupon_details[0];
        state.editLoading = false;
      })
      .addCase(getCouponData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = couponRedeemSlice.actions;

export default couponRedeemSlice.reducer;
