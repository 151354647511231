import React from "react";
import Select from "react-select";
import {
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../../addPlan.module.scss";

export const ItemField = ({ formik, itmIndex, fields, isEdit }) => {
  const {
    handleRemoveFieldCS,
  } = useItemField({fields, itmIndex, formik, isEdit});
  return (
    <>
      <div className={Style.box_root}>
        {formik?.values?.feature?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className="row gx-2">
          <div className="col-md-12">
            <Input
              type="text"
              id={`feature`}
              name={`feature`}
              label={`Feature`}
              onBlur={formik.handleBlur(`feature.${itmIndex}.feature`)}
              className={`pro-input lg ${
                formik.touched?.feature?.[itmIndex]?.feature &&
                formik.errors?.feature?.[itmIndex]?.feature &&
                " error"
              }`}
              {...formik.getFieldProps(`feature.${itmIndex}.feature`)}
              error={
                formik.touched?.feature?.[itmIndex]?.feature &&
                formik.errors?.feature?.[itmIndex]?.feature && (
                  <span className="error-text">
                    {formik.errors?.feature?.[itmIndex]?.feature}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.feature?.[itmIndex]?.feature &&
                formik.errors?.feature?.[itmIndex]?.feature && (
                  <span className="error-text">
                    {formik.errors?.feature?.[itmIndex]?.feature}
                  </span>
                )
              }
            />
          </div>
          
        </div>
      </div>
    </>
  );
};

export default ItemField;
