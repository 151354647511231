import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetLocationSearchListQuery, useUpdateMetaTableListFieldsDataMutation } from "../../../store/queries/CMS";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import Style from "./locationSearch.module.scss";
import { getEditMetaData, updateConfig } from "../../../store/slices/CMS/LocationSearchMeta/LocationSearchSlice";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";

const useLocationSearchMeta = () => {

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const locationSearchState = useSelector((state) => state.locationSearch);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const {
    data: LocationSearchData,
    isLoading,
    isFetching,
    refetch,
  } = useGetLocationSearchListQuery({
    page_size: locationSearchState.currentPageSize,
    page: locationSearchState.currentPage,
    sort_by: locationSearchState.sortBy,
    sort_order: locationSearchState.sortOrder,
    search: locationSearchState?.search,
  });

  const dispatch = useDispatch();
  const { showEditModal } = useSelector((state) => state.global);
  const [updateTableFields] = useUpdateMetaTableListFieldsDataMutation();

  useEffect(() => {
    if (locationSearchState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [locationSearchState.clearSelection]);

  const getRow = (field, data) => {
    const rows = {
      content_title: (field, data) => {
        return (
          <>
            {data?.content?.map((content) => (
              <div key={content._id} className={Style.inner_row}>
                <span>{content?.content_title}</span>
              </div>
            ))}
          </>
        );
      },
      content_description: (field, data) => {
        return (
          <>
            {data?.content?.map((content) => (
              <div key={content._id} className={Style.inner_row}>
                <span>{content?.content_description}</span>
              </div>
            ))}
          </>
        );
      },
      image: (feild, data) => {
        return (
          <>
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data[field])}
            />
          </>
        );
      },
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };


  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.selectedData = e?.[0];
        state.isEdit = true;
        // state.selectedItemDetails = LocationSearchData.data?.location_search_meta_list?.filter(
        //   (item) => item?._id === e?.[0]
        // );
      })
    );
    dispatch(getEditMetaData(e?.[0]));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = !state.showAddModal;
        state.isEdit = false;
        // state.editAboutUsData = [];
        state.imageName = "";
        state.imagePreview = "";
        state.clearSelection = true;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
      })
    );
  };

  const handleSort = (label) => {
    if (locationSearchState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = locationSearchState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  return {
    locationSearchState,
    isLoading,
    LocationSearchData,
    isFetching,
    showImageModal,
    imageData,
    closeImageModal,
    getRow,
    handleClearClick,
    handleEditColumnsClick,
    handleSearch,
    refetch,
    showEditModal,
    updateTableFields,
    closeEditModal,
    handleEditAction,
    handleModalClick,
    handleCreateClick,
    handlePagination,
    paginationOptions,
    handlePageSize,
    handleSort
  };
};

export default useLocationSearchMeta;
