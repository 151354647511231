import {
  Button,
  HeadingGroup,
  Image,
  Input,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useScheduleDetails from "./useScheduleDetails";
import Select from "react-select";
// import { Editor } from "react-draft-wysiwyg";
import Style from "./scheduleDetails.module.scss";
import Assets from "../../../assets/Assets";
import { useEffect } from "react";

const ScheduleDetails = ({ closeModal, refetch }) => {
  const { CourseData, getRow } = useScheduleDetails({ closeModal, refetch });

  const dateObject = new Date(CourseData?.scheduledClass?.[0]?.start_time);

  // Define the options for formatting the date
  const options = {
    weekday: "short", // Short weekday name (e.g., Fri)
    month: "short", // Short month name (e.g., Oct)
    day: "numeric", // Day of the month (e.g., 13)
    year: "numeric", // Year (e.g., 2023)
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObject
  );

  const dateStartObject = new Date(CourseData?.scheduledClass?.[0]?.start_time);
  const dateEndObject = new Date(CourseData?.scheduledClass?.[0]?.end_time);

  // Format the time in 12-hour format with AM/PM
  const formattedStartTime = dateStartObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const formattedEndTime = dateEndObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <>
      <div className="pro-p-5">
        <div className={`${Style.align_stud_img} pro-text-center`}>
          <div
            className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
          >
            <Image
              src={
                CourseData?.scheduledClass?.[0]?.student_image !== "" &&
                CourseData?.scheduledClass?.[0]?.student_image
                  ? CourseData?.scheduledClass?.[0]?.student_image
                  : Assets.GENDER_IMAGE
              }
              width={10}
              height={10}
              alt={CourseData?.scheduledClass?.[0]?.student}
            />
          </div>
          <h5 className="pro-ttl h5  pro-mb-0 pro-mt-1">
            {CourseData?.scheduledClass?.[0]?.student}
          </h5>
        </div>

        <div className={`${Style.event_box} pro-my-2`}>
          <div> {CourseData?.scheduledClass?.[0]?.status} </div>
          <div className="pro-ms-3"> {formattedDate}</div>
          <div className={Style.time_wrap}>
            {formattedStartTime} - {formattedEndTime}
          </div>
          <div className="pro-ms-3">
            <span className={Style.online_badge}>
              {CourseData?.scheduledClass?.[0]?.mode_of_class}
            </span>
          </div>
        </div>

		<div className={`${Style.content_wrap} pro-p-5`}>
			<div className={`${Style.align_prof_img} pro-d-flex pro-mb-4`}>
				<div className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img}`}>
					<Image
					src={
						CourseData?.scheduledClass?.[0]?.tutor_image !== "" &&
						CourseData?.scheduledClass?.[0]?.tutor_image
						? CourseData?.scheduledClass?.[0]?.tutor_image
						: Assets.GENDER_IMAGE
					}
					width={10}
					height={10}
					alt={CourseData?.scheduledClass?.[0]?.tutor}
					/>
				</div>
				<div className="pro-ps-2">
					<h6 className="pro-mb-1 pro-text-capitalize">{CourseData?.scheduledClass?.[0]?.tutor}</h6>
					<p className="pro-mb-0 pro-font-sm">
						{CourseData?.scheduledClass?.[0]?.subject} -{" "}
						{CourseData?.scheduledClass?.[0]?.specialization}
					</p>
				</div>
			</div>

			<div>
				<p className="pro-mb-1">Meet Provider : <span className="pro-ms-2">{CourseData?.scheduledClass?.[0]?.meet_provider}</span></p>
			</div>

			<div>
			<p className="pro-mb-1">
				Hours : <span className="pro-fw-medium">{CourseData?.scheduledClass?.[0]?.hours}hrs </span>
				<span className={Style.time_wrap}>
				Session start time : 
					<span className="pro-fw-medium"> {formattedStartTime} </span>
				</span>
				<span className={Style.time_wrap}>Session end time : 
					<span className="pro-fw-medium"> {formattedEndTime}</span>
				</span>
			</p>
			<p className="pro-mb-0">Meet Id : <span className={Style.link}>{CourseData?.scheduledClass?.[0]?.meet_id}</span></p>
			</div>
		</div>

			<div className="pro-w-100 pro-pt-5">
				<h6 className="pro-ttl h6 pro-mb-0">Activity</h6>
				<Table
				multiSelect={false}
				data={CourseData?.scheduledClass?.[0]?.events || []}
				uniqueID={"_id"}
				fields={CourseData?.fields}
				getRow={getRow}
				showCheckBox={false}
				/>
			</div>
          
      </div>
    </>
  );
};

export default ScheduleDetails;
