import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetBgVerificationListQuery,
  useUpdatePayoutTableMutation,
} from "../../../store/queries/Payment";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  getBGData,
  updateConfig,
} from "../../../store/slices/Payment/BgVerification/BgVerificationPaymentSlice";

import { useEffect } from "react";
import Style from "../payment.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import Assets from "../../../assets/Assets";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useBgVerificationPayment = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const bgVerficationState = useSelector(
    (state) => state.BgVerificationPayment
  );

  const {
    data: bgVerficationList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetBgVerificationListQuery({
    page_size: bgVerficationState?.currentPageSize,
    page: bgVerficationState?.currentPage,
    sort_by: bgVerficationState?.sortBy,
    sort_order: bgVerficationState?.sortOrder,
    search: bgVerficationState?.search,
    start_date: bgVerficationState?.startDate,
    end_date: bgVerficationState?.endDate,
    status_type: bgVerficationState?.statusType,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdatePayoutTableMutation();
  const navigate = useNavigate();

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state?.showCreateModal;
        state.isFilter = true;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      paid_amount: (field, data) => (
        <p className="pro-mb-0">{"$" + data[field]}</p>
      ),
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      transaction_id: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              dispatch(getBGData(data?._id)).then((res) => {
                if (res?.payload?.data?.transaction_details) {
                  dispatch(
                    updateConfig((state) => {
                      state.showDetailsModal = true;
                    })
                  );
                }
              });
            }}
          >
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {data?.transaction_id}
            </span>
          </div>
        );
      },
      date: (field, data) => {
        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      submitted: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              data?.submitted_user_type === "STUDENT"
                ? navigate({
                    pathname: `/students-profile/${data?.submitted_id}`,
                  })
                : navigate({
                    pathname: `/tutors-profile/${data?.submitted_id}`,
                  });

              sessionStorage.setItem("active", `${data?.submitted_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.submitted_image !== "" && data?.submitted_image
                    ? data?.submitted_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.submitted}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.submitted)}
            </span>
          </div>
        );
      },
      check: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.check_id}`,
              });

              sessionStorage.setItem("active", `${data?.check_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.check_image !== "" && data?.check_image
                    ? data?.check_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.check}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.check)}
            </span>
          </div>
        );
      },
      action: (field, data) => {
        return (
          <button
            className={`pro-btn pro-btn-link`}
            disabled={false}
            onClick={() => {
              dispatch(getBGData(data?._id)).then((res) => {
                if (res?.payload?.data?.transaction_details) {
                  dispatch(
                    updateConfig((state) => {
                      state.showDetailsModal = true;
                    })
                  );
                }
              });
            }}
          >
            Show Details
          </button>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  useEffect(() => {
    if (bgVerficationState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [bgVerficationState?.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (bgVerficationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            bgVerficationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailsModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/payments/bg-verification");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  return {
    bgVerficationState,
    showEditModal,
    tableFields,
    bgVerficationList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: bgVerficationState?.currentFilter,
    currentPage: bgVerficationState?.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    closeModal,
    handleDashboardRedirect
  };
};

export default useBgVerificationPayment;
