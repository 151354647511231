import { useParams } from "react-router-dom";

import { useGetOverViewDataQuery } from "../../../store/queries/Tutor";

const useTutorDetails = () => {
  const { tutorID } = useParams();
  const activeProfile = tutorID ?? sessionStorage.getItem("active");

  const {
    data: OverViewData = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetOverViewDataQuery(activeProfile);

  const headerLabel = {
    rating: "Rating",
    hours_tutoring: "Hours Tutoring",
    reviews: "Reviews",
    students: "Students",
  };

  const overView = OverViewData?.data?.tutorDetails?.over_view || {};

  const HeaderValue = {
    rating: `${overView.rating || "0"}`,
    hours_tutoring: `${overView.total_hours_taken || "0"}`,
    reviews: `${overView.reviews_count || "0"}`,
    students: `${overView.student_count || "0"}`,
  };

  const headerDetails = Object.keys(HeaderValue).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  const academicPortifolio =
    OverViewData?.data?.tutorDetails?.over_view?.academic_portifolio || [];

  const academicDetails = academicPortifolio.map((entry, index) => {
    const label = {
      name: "Education",
      institute: "University/Institute",
      course: "Specialization",
      duration: "Course Duration",
    };

    return {
      title: `Education Details - ${index + 1}`,
      data: Object.keys(label).map((key) => ({
        label: label[key],
        value:
          key === "duration"
            ? `${entry.course_start_year || "Not specified"} - ${
                entry.course_end_year || "Not specified"
              }`
            : entry[key] || "Not specified",
      })),
    };
  });

  const certificates =
    OverViewData?.data?.tutorDetails?.over_view?.certifications || [];

  const certificateDetails = certificates.map((certificate, index) => {
    const label = `Certificate ${index + 1}`;
    return {
      label,
      value: certificate || "Not specified",
    };
  });

  const assessment = OverViewData?.data?.tutorDetails?.assessment || [];

  const assessmentDetails = assessment.map((entry, index) => {
    return {
      title: entry.subject_name,
      data: entry.assessment_subject.map((subject) => ({
        label: subject.name,
        value: subject.is_passed ? "Completed" : "Not Completed",
      })),
    };
  });

  const expereance =
    OverViewData?.data?.tutorDetails?.over_view?.experience || [];

  const expereanceLabel = {
    total_experience: `Total experience`,
  };
  const expereanceValue = {
    total_experience: `${expereance?.total_experience ?? "Not specified"}`,
  };
  const expereanceDetails = Object.keys(expereanceValue).map((key) => {
    return {
      label: expereanceLabel[key],
      value:
        typeof expereanceValue[key] === "string"
          ? expereanceValue?.[key]
          : typeof expereanceValue[key] === "number"
          ? expereanceValue?.[key]
          : expereanceValue?.[key]?.name,
    };
  });
  const bankDetail =
    OverViewData?.data?.tutorDetails?.over_view?.bank_details || [];
  const bankDetailsLabel = {
    account_holder_name: `Account Holder Name`,
    routing_number: `Routing Number`,
    account_number: `Account Number`,
    bank_name: `Bank Name`,
    account_type: `Account type`,
  };
  const bankDetailsValue = {
    account_holder_name: `${
      bankDetail?.account_holder_name ?? "Not specified"
    }`,
    routing_number: `${bankDetail?.routing_number ?? "Not specified"}`,
    account_number: `${bankDetail?.account_number ?? "Not specified"}`,
    bank_name: `${bankDetail?.bank_name ?? "Not specified"}`,
    account_type: `${(bankDetail?.account_type ?? "Not specified")
      .charAt(0)
      .toUpperCase()}${(bankDetail?.account_type ?? "Not specified").slice(1)}`,
  };

  const bankDetails = Object.keys(bankDetailsValue).map((key) => {
    return {
      label: bankDetailsLabel[key],
      value:
        typeof bankDetailsValue[key] === "string"
          ? bankDetailsValue?.[key]
          : typeof bankDetailsValue[key] === "number"
          ? bankDetailsValue?.[key]
          : bankDetailsValue?.[key]?.name,
    };
  });
  const pricing =
    OverViewData?.data?.tutorDetails?.over_view?.session_type_and_pricing || [];
  const expertise =
    OverViewData?.data?.tutorDetails?.over_view?.subject_expertise || [];

  return {
    headerDetails,
    certificateDetails,
    OverViewData,
    academicDetails,
    expereanceDetails,
    bankDetails,
    assessmentDetails,
    pricing,
    expertise,
    isLoading,
  };
};

export default useTutorDetails;
