import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { updateConfig } from "../../../store/slices/Courses/coursesSlice";
import { updateConfig as specializationConfig } from "../../../store/slices/Subjects/Specialization/specializationSlice";
import { getAutoCompleteData } from "../api";

const useScheduleClassFilter = (handleFilterClick, activityFilter) => {
  const dispatch = useDispatch();
  const storedData = JSON.parse(localStorage.getItem("subjectData"));
  const basicData = [
    { value: "IN_PERSON", label: "IN PERSON" },
    { value: "ONLINE", label: "ONLINE" },
  ];

  const {
    startDate,
    endDate,
    isFilter,
    mode_of_class,
    specialization,
    activity
  } = useSelector((state) => state.courses);

  const {
    autoCompleteData,
  } = useSelector((state) => state.specialization);

  const [statusObjects, setStatusObjects] = useState([]);

  const validationSchema = Yup.object({
    endDate: Yup.string().when(
      "startDate",
      (startDate, schema) =>
        startDate &&
        schema.test({
          test: function (value) {
            const { startDate } = this.parent;
            if (!startDate) {
              // If start date is not provided, no need for validation
              return true;
            }
            // Check if end date is provided
            return !!value;
          },
          message: "Select End Date",
        })
        .test({
          test: function (value) {
            const { startDate } = this.parent;
            if (!startDate) {
              // If start date is not provided, no need for validation
              return true;
            }
            // Check if end date is greater than or equal to start date
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(value);
            return endDateObj >= startDateObj; // Changed operator to allow equality
          },
          message: "End date must be greater than or equal to start date", // Adjusted message
        })
    ),
});


  useEffect(() => {
    if (activityFilter) {
      
      const newStatusObjects = activityFilter.map(status => {
        return { value: status, label: status.replace(/_/g, ' ') };
    });
    setStatusObjects(newStatusObjects);
    }  
  }, [activityFilter]);
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      mode_of_class: "",
      specialization: "",
      activity: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      dispatch(
        updateConfig((state) => {
          Object.assign(state, subData);
        })
      );
      handleFilterClick();

    },
  });
  const clearFilter = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        Object.assign(state, formik.initialValues);
        // state.isFilter = false;
      })
    );
    handleFilterClick();
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
 

  let selectVal = basicData.find(
    (item) => item.value === formik.values.mode_of_class
  );

  let selectActivity = statusObjects.find(
    (item) => item.value === formik.values.activity
  );

  const handleInputChange = (item) => {
    if (item?.length >= 1) {
      getAutoCompleteData(item).then((response) => {
        dispatch(
          specializationConfig((state) => {
            state.autoCompleteData = response?.data?.data;
          })
        );
      });
    }
  };

  const useDataOptions = useMemo(
    () =>
      autoCompleteData?.map((opt) => {
        return { value: opt?._id, label: opt?.name, id: opt?._id };
      }),
    // eslint-disable-next-line
    [autoCompleteData]
  );
  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.specialization
  );

  const handleParentId = (val) => {
    formik.setFieldValue("specialization", val?.value);
  };

  useEffect(() => {
    if (isFilter) {
      formik.setValues({
        startDate: startDate || "",
        endDate: endDate || "",
        mode_of_class: mode_of_class || "",
        specialization: specialization || "",
        activity: activity || ""
      });
    }
  }, [isFilter]);

  return { formik, basicData,getFieldError, clearFilter, selectVal, selectActivity, storedData, userVal, handleInputChange, useDataOptions, handleParentId, statusObjects};
};

export default useScheduleClassFilter;
