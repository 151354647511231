import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/AboutUs/aboutUsSlice";
import { toast } from "react-toastify";
import { updateAboutUs } from "../api";
import { useEffect } from "react";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useAddAboutUs = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const { isEdit, selectedData, editData, selectedItemDetails } = useSelector(
    (state) => state.aboutUs
  );

  const [imagePreview, setImagePreview] = useState("");
  const [imageName, setImageName] = useState("");

  // Function to sanitize HTML and convert it to EditorState
  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Return empty editor state if no HTML

    const sanitizedHtml = DOMPurify.sanitize(html);
    const contentBlock = htmlToDraft(sanitizedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [initialData, setInitialData] = useState({
    header: selectedItemDetails[0]?.header ?? "",
    content:
      selectedItemDetails[0]?.content?.map((item, index) => ({
        title: item?.title ?? "",
        description: item?.description
          ? convertHtmlToEditorState(item?.description)
          : "",
        status: item?.status ?? true,
        image: item?.image ?? "",
        image_path: item?.image_path ?? "",

        item_id: item?._id,
        index: index,
      })) || [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validate: (values) => {
      let errors = {};
      // Check if content is not undefined and has length
      if (values.content && values.content.length > 0) {
        // Initialize content array in errors object
        errors.content = [];

        values.content.forEach((value, index) => {
          // Initialize an empty object for holding errors of this item
          let contentErrors = {};

          // Assuming `description` is an EditorState object from Draft.js or similar library
          const plainText = value.description
            ? value.description.getCurrentContent().getPlainText()
            : "";

          // Check if the content is empty or only contains whitespace
          if (!plainText.trim()) {
            // Assign the error message to the description field of the current item
            contentErrors.description = "*Description is required";
          }

          // If there are any errors for the current item, push them to the errors.content array
          if (Object.keys(contentErrors).length > 0) {
            errors.content[index] = contentErrors;
          }
        });
        // If after processing all content items there are no errors, remove the content key from errors
        if (errors.content.length === 0) {
          delete errors.content;
        }
      }

      return errors;
    },

    validationSchema: Yup.object({
      header: Yup.string().required("Header is required"),
      content: Yup.array().of(
        Yup.object().shape({
          title: Yup.string(),
          // description: Yup.mixed().required("Description is required"),
          image: !isEdit && Yup.string().required("Status is required"),
        })
      ),
    }),

    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("header", values["header"]);

      values?.content?.forEach((value, index) => {
        let contentState;
        if (value.description.getCurrentContent) {
          contentState = value.description.getCurrentContent();
        } else {
          // Assuming value.description might directly be a string or another format in some cases
          contentState = value.description; // Or handle this case based on your actual data structure
        }
        const contentRaw = convertToRaw(contentState);
        const plainText = draftToHtml(contentRaw);

        // Checking whether the new item is added to the list
        //  checking whether the new item is added to the list,if new item added
        //  then selectedItemDetails[0]?.content[index]?._id will be undefined
        if (selectedItemDetails[0]?.content[index]?._id) {
          formData.append(
            `content[${index}][_id]`,
            selectedItemDetails[0]?.content[index]?._id
          );
        }
        formData.append(`content[${index}][title]`, value?.title);
        formData.append(`content[${index}][description]`, plainText);
        formData.append(`content[${index}][status]`, value?.status);

        if (typeof value?.image === "object") {
          formData.append(`content[${index}][image]`, value?.image);
        }
      });

      updateAboutUs(formData, selectedData).then((response) => {
        if (response?.data?.statusCode === 200) {
          handleModalClick();
          refetch();
          toast.success("Policy updated");
        } else if (response?.statusCode === 422) {
          const errors = response?.errors;
          errors.forEach((error) => {
            formik.setFieldError(error.field, error.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });

  const handleAddField = () => {
    let { content } = formik?.values;
    content = [
      ...content,
      {
        title: "",
        description: "",
        status: true,
        image: "",
      },
    ];
    formik.setFieldValue("content", content);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  // useEffect(() => {
  //   if (editData && isEdit) {
  //     setInitialData({
  //       header: editData?.privacyandPolicy?.header,
  //       content: editData?.privacyandPolicy?.content,
  //     });
  //   }
  // }, [editData, isEdit]);

  const handleContentChange = (editorState, index) => {
    const fieldName = `content[${index}].description`;
    formik?.setFieldValue(fieldName, editorState);
  };

  return {
    formik,
    imagePreview,
    imageName,
    isEdit,
    getFieldError,
    closeModal,
    handleAddField,
    handleContentChange,
    setImageName,
    setImagePreview,
  };
};

export default useAddAboutUs;
