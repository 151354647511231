import { useSelector } from "react-redux";
import { useGetPremiumPlansListDataQuery, useDeletePremiumPlanMutation, useUpdateTableListFieldsDataMutation } from "../../store/queries/PremiumPlans";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { updateConfig } from "../../store/slices/PremiumPlans/premiumPlansSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPremiumPlanData } from "./api";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useNavigate } from "react-router-dom";

const usePremiumPlans = () => {

  const { checkIfActiveRoute } = useRouteUtils();
  // const drawerMenu = [
  //   {
  //     title: "Premium Plans",
  //     label: "Premium Plans",
  //     link: "/premium-plans",
  //     iconAsset: "DrawerIcon1",
  //     icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
  //     active:
  //       checkIfActiveRoute("/premium-plans", true)
  //   },
  // ];
  
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );

}, []);

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const premiumPlansState = useSelector((state) => state.premiumPlans);
  const [skip, setSkip] = useState(true);
  const {
    data: premiumPlansList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetPremiumPlansListDataQuery({
    page_size: premiumPlansState.currentPageSize,
    page: premiumPlansState.currentPage,
    sort_by: premiumPlansState.sortBy,
    sort_order: premiumPlansState.sortOrder,
    start: premiumPlansState.startDate,
    end: premiumPlansState.endDate,
    search: premiumPlansState.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

  const [deletePremiumPlan] = useDeletePremiumPlanMutation();
//   const [updateBranchFields] = useUpdateBranchTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = premiumPlansList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [premiumPlansList]);

  useEffect(() => {
    if (premiumPlansState?.selectedPremiumPlan && premiumPlansState?.isEdit) {
      fillPremiumPlanData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPlansState?.isEdit, premiumPlansState?.showAddPremiumPlanModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddPremiumPlanModal = !state.showAddPremiumPlanModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDelete = () => {
    deletePremiumPlan(premiumPlansState?.selectedPremiumPlan).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Plan deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillPremiumPlanData = () => {
    getPremiumPlanData(premiumPlansState?.selectedPremiumPlan).then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateConfig((state) => (state.premiumPlanData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch brach data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedPremiumPlan = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddPremiumPlanModal = true;
        state.selectedPremiumPlan = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  useEffect(() => {
    if (premiumPlansState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [premiumPlansState.clearSelection]);

  const handleSort = (label) => {
    if (premiumPlansState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = premiumPlansState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedPremiumPlan = "";
      })
    );
  };


  return {
    // drawerMenu,
    premiumPlansState,
    showEditModal,
    tableFields,
    premiumPlansList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage: premiumPlansState.currentPage,
    closeEditModal,
		updateTableFields
  };
};

export default usePremiumPlans;
