import { useSelector } from "react-redux";
import {
  useGetAboutUsListDataQuery,
  useUpdateAboutUsStatusMutation,
  useUpdateOurClientTableListFieldsDataMutation,
} from "../../../store/queries/CMS/index.js";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";
import Style from "./ourClients.module.scss";
import { updateConfig } from "../../../store/slices/CMS/AboutUs/aboutUsSlice.js";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteClient } from "./api.js";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

const useAboutUs = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);
  const dataState = useSelector((state) => state.aboutUs);
  const { editLoading } = useSelector((state) => state.aboutUs);
  const [updateTableFields] = useUpdateOurClientTableListFieldsDataMutation();

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");
  const [updateAboutUsStatus] = useUpdateAboutUsStatusMutation();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const {
    data: dataList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetAboutUsListDataQuery({
    page_size: dataState.currentPageSize,
    page: dataState.currentPage,
    sort_by: dataState.sortBy,
    sort_order: dataState.sortOrder,
    start: dataState.startDate,
    end: dataState.endDate,
    search: dataState.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = dataList?.data;
      })
    );
    // eslint-disable-next-line
  }, [dataList]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = !state.showAddModal;
        state.isEdit = false;
        state.editAboutUsData = [];
        state.imageName = "";
        state.imagePreview = "";
        state.clearSelection = true;
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.selectedData = e?.[0];
        state.isEdit = true;
        state.selectedItemDetails = dataList.data?.about_us?.filter(
          (item) => item?._id === e?.[0]
        );
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    return dataList?.data?.create_permission ?? false;
  }, [dataList]);
  const hasEditPermission = useMemo(() => {
    return dataList?.data?.edit_permission ?? false;
  }, [dataList]);
  const hasDeletePermission = useMemo(() => {
    return dataList?.data?.delete_permission ?? false;
  }, [dataList]);

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      image: (feild, data) => {
        return (
          <>
            {data?.content?.map((content) => (
              <div key={content._id} className={Style.inner_row}>
                <IconText
                  icon={
                    <span className="material-symbols-outlined x4">
                      imagesmode
                    </span>
                  }
                  title={`${"View Photo"}`}
                  onClick={() => handleViewImage(content?.image)}
                />
              </div>
            ))}
          </>
        );
      },
      title: (field, data) => {
        return (
          <>
            {data?.content?.map((content) => (
              <div key={content._id} className={Style.inner_row}>
                <span>{content?.title}</span>
              </div>
            ))}
          </>
        );
      },
      description: (field, data) => {
        return (
          <>
            {data?.content?.map((content) => (
              <div key={content._id} className={Style.inner_row}>
                <span>{parse(content?.description)}</span>
              </div>
            ))}
          </>
        );
      },
      status: (field, data) => {
        return (
          <div>
            {data?.content?.map((content) => (
              <div className={Style.inner_row}>
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={content?.status}
                    onChange={(e) =>
                      updateAboutUsStatus({
                        contentId: content?._id,
                        status: !content?.status,
                      })
                    }
                    // onChange={(e) => handleChangeStatus(content)}
                  />
                </div>
              </div>
            ))}
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedData = e?.[0];
      })
    );
  };

  const handleDelete = () => {
    deleteClient(dataState?.selectedData).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Client deleted successfully");
        refetch();
      } else {
        toast.error("Something went wrong");
      }
      handleDeleteModal();
      dispatch(
        updateConfig((state) => {
          state.clearSelection = true;
        })
      );
    });
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  useEffect(() => {
    if (dataState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [dataState.clearSelection]);

  const handleSort = (label) => {
    if (dataState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = dataState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };

  return {
    dataState,
    showEditModal,
    tableFields,
    dataList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDeleteModal,
    handleDelete,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    closeEditModal,
    updateTableFields,
    editLoading,
    showImageModal,
    imageData,
    closeImageModal,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  };
};

export default useAboutUs;
