import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useTutorFilter from "./useTutorFilter";

const TutorFilter = ({ isStickyFooter, handleFilterClick }) => {
  const { formik, clearFilter, useDataOptions, handleInputChange, userVal } =
    useTutorFilter(handleFilterClick);
  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search for a Zip Code...";

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Zip Code
          </label>
          <Select
            id="tutorLocation"
            name="tutorLocation"
            placeholder={"Select Zip Code"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            noOptionsMessage={noOptionsMessage}
            isClearable={true}
            options={useDataOptions}
            value={userVal}
            onInputChange={(value) => handleInputChange(value)}
            onBlur={formik.handleBlur("tutorLocation")}
            onChange={(e) =>
              formik.setFieldValue("tutorLocation", e?.value || null)
            }
            menuPlacement="auto"
          />
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button className={"pro-btn-link lg pro-px-5"} onClick={clearFilter}>
            Clear
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 `}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TutorFilter;
