import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useGetScheduledDataQuery, useUpdateSchedulesTableFieldsMutation,  } from "../../../store/queries/Tutor";

import Style from "../Tutors/tutor.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Tutor/Scheduled/ScheduledSlice";
import { toast } from "react-toastify";


const useScheduled = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { tutorID } = useParams();
  const [updateTableFields] =
  useUpdateSchedulesTableFieldsMutation();
  const activeProfile = tutorID ?? sessionStorage.getItem("active");
  const scheduleState = useSelector((state) => state.scheduled);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: scheduleList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetScheduledDataQuery({
    user_id: activeProfile,
    page_size: scheduleState?.currentPageSize,
    page: scheduleState?.currentPage,
    sort_by: scheduleState?.sortBy,
    sort_order: scheduleState?.sortOrder,
    search: scheduleState?.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (scheduleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = scheduleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      slots: (field, data) => {
        const timeRanges = data.slots;
        return (
          <>
          {timeRanges.map((timeRange, index) => (
          <div
            key={index}
            className="time-range"
            style={{
              borderTop: index === 0 ? 'none' : '1px solid rgb(223 227 235 / 89%)',
              borderBottom: index === timeRanges.length - 1 ? 'none' : '1px solid rgb(223 227 235 / 69%)',
            }}
          >
            {timeRange}
          </div>
        ))}
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    scheduleList,
    paginationOptions,
    scheduleState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    updateTableFields,
    closeEditModal
    ,handleEditColumnsClick
  };
};
export default useScheduled;
