import Dropdown from "react-bootstrap/Dropdown";
import Assets from "../../../../assets/Assets";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import {
  Image,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import ProfileDetails from "../../../../components/Global/ProfileView/AddForm";

const HeaderActions = ({ isNotifinaction = true }) => {
  const {
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleCloseProfileView,
    isProfileViewVisible,
    setProfileViewVisible,
  } = useHeaderActions();

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      <Dropdown className="pro-me-3">
        <Dropdown.Menu className={Style.dropdown}>
          <Dropdown.Item className={Style.notification_wrap}>
            <div className={`${Style.notification_item} pro-d-flex`}>
              <div
                className={`${Style.img_wrap} pro-align-self-center pro-me-2`}
              >
                <Image
                  width={53}
                  height={53}
                  alt={`img`}
                  src={`https://picsum.photos/200/300`}
                />
              </div>
              <div
                className={`${Style.content} pro-align-self-center pro-d-flex pro-flex-column`}
              >
                <p className="pro-mb-1">
                  Lorem ipsum dolor sit amet, rcitation consectetur adipiscing.
                </p>
                <span className={Style.date}>12 May</span>
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item className={Style.notification_wrap}>
            <div className={`${Style.notification_item} pro-d-flex`}>
              <div
                className={`${Style.img_wrap} pro-align-self-center pro-me-2`}
              >
                <Image
                  width={53}
                  height={53}
                  alt={`img`}
                  src={`https://picsum.photos/200/300`}
                />
              </div>
              <div
                className={`${Style.content} pro-align-self-center pro-d-flex pro-flex-column`}
              >
                <p className="pro-mb-1">
                  Lorem ipsum dolor sit amet, rcitation consectetur adipiscing.
                </p>
                <span className={Style.date}>12 May</span>
              </div>
            </div>
          </Dropdown.Item>
          <p className="pro-mb-0 pro-text-center pro-pb-2 pro-pt-3">
            View more
          </p>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            <img src={Assets.PROFILE} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleViewProfile}>Profile</Dropdown.Item>
          {/* <Dropdown.Item onClick={handleSettings}>Settings</Dropdown.Item> */}
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <OffCanvasLayout
        show={isProfileViewVisible}
        handleClose={handleCloseProfileView}
        title={"Profile"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
      >
        <ProfileDetails
          setProfileViewVisible={setProfileViewVisible}
          isStickyFooter
        />
      </OffCanvasLayout>
    </div>
  );
};

export default HeaderActions;
