import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api/index";
import moment from "moment";

const localTime = moment(new Date()).local();
    const formattedLocalTime = localTime.format("MM-YYYY");

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showAddTutorsModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedTutor: {},
  isEdit: false,
  tutorsData: [],
  isFilter: false,
  imagePreview: "",
  imageName: "",
  showFilterModal: false,
  tutorLocation: "",
  statusType: "",
  startDate:"",
  endDate:"",
  autoCompleteData: [],
  editLoading: false,
  editError: "",
  month: formattedLocalTime
};


const payoutListSlice = createSlice({
  name: "payoutList",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  
});

export const { updateConfig } = payoutListSlice.actions;

export default payoutListSlice.reducer;
