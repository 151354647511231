import React from "react";
// import useTutorDocuments from "./useTutorDocuments";
import {
  Table,
  Pagination,
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import useAllTutorDocuments from "./useAllTutorDocuments";
import DocumentForm from "./DocumentForm";
import OrderColumn from "../../../components/Global/OrderColumn";
import Select from "react-select";

function AllTutorDocuments() {
  const {
    AllDocumentData,
    AllTutorDocumentState,
    isFetching,
    isLoading,
    actionOptions,
    // handleEditClick,
    paginationOptions,
    showEditModal,
    DocumentStatuses,
    handleSelectStatusFilter,
    updateTableFields,
    closeEditModal,
    handlePageSize,
    handlePagination,
    refetch,
    handleEditAction,
    handleClearClick,
    handleEditColumnsClick,
    handleSearch,
    handleClose,
    handleSort,
    getRow,
    hasEditPermission
  } = useAllTutorDocuments();
  return (
    <>
      <HeadingGroup
        title={"All Documents"}
        className={`pro-mb-4`}
      />

      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <div className="row">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              showActions={true}
              // handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              // actionOptions={actionOptions?.filter((item) => item.value === 0)}
              // onActionOptionChange={handleActionChange}
              handleActionClick={handleEditColumnsClick}
              handleClear={handleClearClick}
              searchInputProps={{ value: AllTutorDocumentState?.search }}
              dropDownFilter={
                <>
                  <div className="col-3 dropdown-filter">
                    <Select
                      onChange={handleSelectStatusFilter}
                      options={DocumentStatuses}
                      getOptionLabel={(option) => option?.value}
                      getOptionValue={(option) => option?.value}
                      value={DocumentStatuses?.filter(
                        (item) =>
                          item?.value === AllTutorDocumentState?.status?.value
                      )}
                      placeholder="Select Status"
                      classNamePrefix={`pro-input`}
                      className={`pro-input lg search-filter-select`}
                      isClearable={true}
                    />
                  </div>
                </>
              }
            />
          </div>
        </div>

        <div className={`pro-pt-3 pro-pb-5 align-top`}>
          {AllDocumentData?.data?.TutorDocuments?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={AllDocumentData?.data?.TutorDocuments || []}
              uniqueID={"tutor_id"}
              fields={AllDocumentData?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleEdit={handleEditAction}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={AllTutorDocumentState?.clearSelection}
              perpage={AllTutorDocumentState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={hasEditPermission}
              showCheckBox={hasEditPermission}
            />
          )}
        </div>

        {AllDocumentData?.data?.TutorDocuments?.length > 0 && (
          <Pagination
            currentPage={AllTutorDocumentState?.currentPage}
            totalPageCount={Math.ceil(
              AllDocumentData?.data.total_count /
                AllTutorDocumentState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={AllTutorDocumentState?.showCreateModal}
          handleClose={handleClose}
          title={"Edit Documents"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <DocumentForm
            refetch={refetch}
            closeModal={handleClose}
            DocumentStatuses={DocumentStatuses}
            isStickyFooter
          />
        </OffCanvasLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              tableFields={Object.keys(
                AllDocumentData?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = AllDocumentData?.data?.fields[property];
                return filteredObj;
              }, {})}
              refetchData={refetch}
              moduleId={AllDocumentData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
}

export default AllTutorDocuments;
