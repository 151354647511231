import { useSelector } from "react-redux";
import { useGetAreaOfExpertiseListDataQuery, useDeleteAreaOfExpertiseMutation, useUpdateAreaOfExpertiseMutation, useUpdateTableListFieldsDataMutation } from "../../store/queries/AreaOfExpertise";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { updateConfig } from "../../store/slices/AreaOfExpertise/areaOfExpertiseSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getAreaOfExpertiseData } from "./api";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useAreaOfExpertise = () => {

  const { checkIfActiveRoute } = useRouteUtils();
  // const drawerMenu = [
  //   {
  //     title: "Area Of Expertise",
  //     label: "Area Of Expertise",
  //     link: "/area-of-expertise",
  //     iconAsset: "DrawerIcon1",
  //     icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
  //     active:
  //       checkIfActiveRoute("/area-of-expertise", true)
  //   },
  // ];
  
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const areaOfExpertiseState = useSelector((state) => state.areaOfExpertise);
  const [skip, setSkip] = useState(true);
  const {
    data: areaOfExpertiseList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetAreaOfExpertiseListDataQuery({
    page_size: areaOfExpertiseState.currentPageSize,
    page: areaOfExpertiseState.currentPage,
    sort_by: areaOfExpertiseState.sortBy,
    sort_order: areaOfExpertiseState.sortOrder,
    filter: areaOfExpertiseState.currentFilter,
  });

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();
  const [deleteAreaOfExpertise] = useDeleteAreaOfExpertiseMutation();
//   const [updateBranchFields] = useUpdateBranchTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = areaOfExpertiseList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [areaOfExpertiseList]);

  useEffect(() => {
    if (areaOfExpertiseState?.selectedAreaOfExpertise && areaOfExpertiseState?.isEdit) {
      fillAreaOfExpertiseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaOfExpertiseState?.isEdit, areaOfExpertiseState?.showAddAreaOfExpertiseModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddAreaOfExpertiseModal = !state.showAddAreaOfExpertiseModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDelete = () => {
    deleteAreaOfExpertise(areaOfExpertiseState?.selectedAreaOfExpertise).then((response) => {
      if (response?.data?.statusCode === 200) {
        toast.success("Area Of Expertise deleted successfully");
        handleDeleteModal();
        refetch();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
  };

  const fillAreaOfExpertiseData = () => {
    getAreaOfExpertiseData(areaOfExpertiseState?.selectedAreaOfExpertise).then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateConfig((state) => (state.AreaOfExpertiseData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch areaOfExpertise data");
      }
    });
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedAreaOfExpertise = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddAreaOfExpertiseModal = true;
        state.selectedAreaOfExpertise = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (areaOfExpertiseState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = areaOfExpertiseState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const filters = useMemo(() => {
    if (areaOfExpertiseList?.data?.filters) {
      const buttonGroups = areaOfExpertiseList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (areaOfExpertiseState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [areaOfExpertiseState.clearSelection]);


  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedAreaOfExpertise = "";
      })
    );
  };

  return {
    // drawerMenu,
    areaOfExpertiseState,
    showEditModal,
    tableFields,
    areaOfExpertiseList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilter,
    activeFilter: areaOfExpertiseState.currentFilter,
    currentPage: areaOfExpertiseState.currentPage,
    filters,
    closeEditModal,
		updateTableFields
  };
};

export default useAreaOfExpertise;
