import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { login } from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useValidations from "../../../utils/hooks/useValidations";
import allowedDashboardComponent from "../../../routes/useRoutes";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";

const useLogin = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const { allowedDashboardComponent } = useRoutes();

  // const {
  //   allowedDashboardComponent,
  //   allowedStudentsComponent,
  //   allowedReportedChatComponent,
  //   allowedPremiumPlansComponent,
  //   allowedTutorComponent,
  //   allowedPaymentComponent,
  //   allowedSubjectsComponent,
  //   allowedCmsComponent,
  //   allowedCourseComponent,
  //   allowedRoleComponent,
  //   allowedCouponComponent,
  //   allowedPlanComponent,
  // } = useRoutes();

  const { validateEmail } = useValidations();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginStatus === "success") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
      navigate(
        `/`
      );
      // window.location.reload()
      // setTimeout(() => {
      //    window.location.reload()
      // }, 100);
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email ID Required";
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      if (!values.password) {
        errors.password = "*Password Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      setLoginStatus("pending");
      login(values).then((response) => {
        if (response?.data?.statusCode === 200) {
          // toast.success("Authentication Success");
          setLoginInfo(response?.data?.data);
          localStorage.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.token
          );
          // localStorage.setItem("USER_DETAILS", response?.data?.data?.user?._id);
          setLoginStatus("success");
        } else if (response?.statusCode === 422) {
          setLoginStatus("failed");
          setLoginInfo({ password: response?.message });
        } else {
          setLoginStatus("An error occurred");
          toast.error("Something went wrong");
        }
      });
    },
  });

  const handleForgotPassword = () => {
    navigate("/login/forgot-password");
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  return {
    formik,
    showPassword,
    loginStatus,
    handleForgotPassword,
    handleShowPassword,
  };
};

export default useLogin;
