import { useFormik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/PremiumPlans/premiumPlansSlice";
// import { addBillingData, editBillingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useUpdateCreatePremiumPlanMutation, useUpdatePremiumPlanMutation } from "../../../store/queries/PremiumPlans";


const useAddPremiumPlans = ({ refetch }) => {
	const dispatch = useDispatch();
	const { premiumPlanData, isEdit } = useSelector((state) => state.premiumPlans);
	const [updateCreatePremiumPlanData] = useUpdateCreatePremiumPlanMutation();
	const [updatePremiumPlanData] = useUpdatePremiumPlanMutation();
	const [premiumPlanId, setPremiumPlanId] = useState("")

	useEffect(() => {
		if (premiumPlanData && isEdit) {
			Object.keys(premiumPlanData || {}).forEach((key) => {
				if(key === "name") {
					formik?.setFieldValue("name", premiumPlanData?.[key]);
				}
				else if(key === "amount") {
					formik?.setFieldValue("amount", premiumPlanData?.[key]);
				}
				else if(key === "expiry_in_days") {
					formik?.setFieldValue("expiry_in_days", premiumPlanData?.[key]);
				}
				else if(key === "_id") {
					setPremiumPlanId(premiumPlanData?.[key])
					// formik?.setFieldValue("_id", premiumPlanData?.[key]);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [premiumPlanData]);

	const validation = Yup.object({
		name: Yup.string().required("Enter Plan Name"),
		amount: Yup.string().required("Enter Amount"),
		expiry_in_days: Yup.string().required("Enter Expiry date in days"),
	});

	const formik = useFormik({
		initialValues: {
			name: "",
			amount: "",
			expiry_in_days: "",
		},
		validationSchema: validation,

		onSubmit: (values) => {
			let data = {
				name: values.name,
				amount: values?.amount ? values?.amount : "",
				expiry_in_days: values?.expiry_in_days ? values?.expiry_in_days : "",
			};
			
			if(isEdit) {
				updatePremiumPlanData({premiumPlanId, data}).then((response) => {
					if (response?.data?.statusCode === 200) {
						handleCloseModal();
							toast.success("Premium Plan Details Updated");
						refetch();
						dispatch(
							updateConfig((state) => {
							  state.clearSelection = true;
							})
						  );
					} else if (response?.error?.data?.statusCode === 422) {
						// displays backend errors
						const errors = response?.error?.data?.errors[0]?.message;
						formik.setErrors(errors);
						toast.error(errors);
					} else {
						toast.error("Something went wrong");
					}
				});
			}
			else {
				updateCreatePremiumPlanData(data).then((response) => {
					if (response?.data?.statusCode === 200) {
						handleCloseModal();
							toast.success("New Premium Plan Created");
						refetch();
					} else if (response?.error?.data?.statusCode === 422) {
						// displays backend errors
						const errors = response?.error?.data?.errors[0]?.message;
						formik.setErrors(errors);
						toast.error(errors);
					} else {
						toast.error("Something went wrong");
					}
				});
			}
			
		}
	});

	const getFieldError = (fieldName) => {
		if (formik.touched[fieldName] && formik.errors[fieldName]) {
			return formik.errors[fieldName];
		}
		return "";
	};

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showAddPremiumPlanModal = false;
			})
		);
	};
	return {
		formik,
		premiumPlanData,
		// basicData: basicData?.data,
		serviceItems: premiumPlanData?.serviceItems,
		getFieldError,
		handleCloseModal,
		isEdit
	};
};

export default useAddPremiumPlans;
