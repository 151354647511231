import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddModal: false,
  showDeleteModal: false,
  selectedData: "",
  isEdit: false,
  editData: [],
  clearSelection: false,
  userData: [],
  selectedUserId: {},
  editLoading : false,
  editError:""

};

export const getEditPolicyData = createAsyncThunk(
  "/client/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/privacy-and-policy/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const privacyAndPolicySlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditPolicyData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getEditPolicyData.fulfilled, (state, action) => {
        state.editData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getEditPolicyData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = privacyAndPolicySlice.actions;

export default privacyAndPolicySlice.reducer;

