import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../components/Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useBanners from "./useBanners";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import AddBanner from "./AddBanner";
import { ImageCard } from "../Subjects/ImageCard";
function Banners() {
  const {
    // drawerMenu,
    bannersState,
    showEditModal,
    tableFields,
    bannersList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    // handleDeleteModal,
    handleEditAction,
    handleSearch,
    updateTableFields,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    filters,
    handleFilter,
    activeFilter,
    showImageModal,
    closeModal,
    imageData,
    closeEditModal,
  } = useBanners();

  const propertiesToInclude = ["name", "status"];

  return (
    <div>
      {/* <CommonLayout drawerMenu={drawerMenu}> */}
      <HeadingGroup
        title={"All Banners"}
        className={`pro-mb-4`}
        // buttonTitle={"Add new"}
        // handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          data={filters}
          searchable={false}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: bannersState?.search }}
        />
        <Table
          multiSelect={false}
          data={bannersList?.data?.banners || []}
          uniqueID={"_id"}
          fields={tableFields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={bannersState?.currentPageSize}
          clear={bannersState.clearSelection}
          assignable={false}
          handleEdit={handleEditAction}
          // handleDelete={handleDeleteModal}
          deletable={false}
          showCheckBox={true}
        />
        {bannersList?.data?.banners?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              bannersList.data.filtered_count / bannersState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        {/* <ModalLayout
					show={bannersState?.showDeleteModal}
					handleClose={handleDeleteModal}
				>
					<div className="pro-m-5">
						<ConfirmationBox
							title={"Are you sure delete the selected Premium Plan ?"}
							subTitle={"This action can't be undo "}
							cancelText={"No"}
							cancelAction={handleDeleteModal}
							submitText={"Yes"}
							submitAction={handleDelete}
							isRight={true}
						/>
					</div>
				</ModalLayout> */}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(bannersList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = bannersList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={bannersList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showImageModal} handleClose={closeModal}>
          <ImageCard data={imageData} handleClose={closeModal} />
        </ModalLayout>
        <OffCanvasLayout
          show={bannersState?.showAddBannersModal}
          handleClose={handleModalClick}
          title={
            bannersState?.isEdit ? "Update banners Level" : "Add banners Level"
          }
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddBanner refetch={refetch} />
        </OffCanvasLayout>
      </div>
      {/* </CommonLayout> */}
    </div>
  );
}

export default Banners;
