import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useLocationSearchMeta from "./useLocationSearchMeta";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import { ImageCard } from "../../Global/ImageCard";
import OrderColumn from "../../Global/OrderColumn";
import AddMeta from "./AddMeta";

const LocationSearchMeta = () => {
  const {
    handleSearch,
    isLoading,
    handleClearClick,
    locationSearchState,
    handleEditColumnsClick,
    LocationSearchData,
    isFetching,
    showImageModal,
    imageData,
    closeImageModal,
    getRow,
    handleEditAction,
    refetch,
    showEditModal,
    updateTableFields,
    closeEditModal,
    handleModalClick,
    handleCreateClick,
    handlePagination,
    paginationOptions,
    handlePageSize,
    handleSort

  } = useLocationSearchMeta();

  return (
    <div>
      {/* <HeadingGroup title={"Location Search Meta"} className={`pro-mb-4`} /> */}
      <HeadingGroup
            title={"Location Search Meta"}
            className={`pro-mb-4`}
            buttonTitle={"Add new"}
            handleClick={handleCreateClick}
          />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
                  handleClear={handleClearClick}
              searchInputProps={{ value: locationSearchState?.search }}
                  handleActionClick={handleEditColumnsClick}
            />
          </div>
        </div>

        <div className={`pro-pt-3 pro-pb-5 `}>
          {LocationSearchData?.data?.location_search_meta_list?.length === 0 ? (
            <EmptyData />
          ) : (
            <div className={"pro-pt-3"}>
              <Table
                multiSelect={false}
                data={LocationSearchData?.data?.location_search_meta_list || []}
                uniqueID={"_id"}
                fields={LocationSearchData?.data?.fields}
                SortIcon={<FaSort />}
                editIcon={<span className="material-symbols-outlined">edit</span>}
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                 handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={locationSearchState?.currentPageSize}
                assignable={false}
                 handleEdit={handleEditAction}
                //   handleDelete={handleDeleteModal}
                showCheckBox={true}
                deletable={LocationSearchData?.data?.delete_permission}
                editable={true}
                clear={locationSearchState.clearSelection}
              />
            </div>
          )}
        </div>

        {LocationSearchData?.data?.location_search_meta_list.length > 0 && (
          
          <Pagination
            currentPage={locationSearchState.currentPage}
            totalPageCount={Math.ceil(
              // dataList?.data?.pagination?.total_count / dataState.currentPageSize
              LocationSearchData?.data?.total_count / locationSearchState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(LocationSearchData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = LocationSearchData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={LocationSearchData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={locationSearchState?.showAddModal}
          handleClose={handleModalClick}
          title={locationSearchState?.isEdit ? "Update Location Meta" : "Add Location Meta"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddMeta refetch={refetch} handleModalClick={handleModalClick} />
        </OffCanvasLayout>

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default LocationSearchMeta;
