import React from 'react';
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useCMS from './useCMS';

function CMS() {
    const { drawerMenu } = useCMS();
    return (
      <CommonLayout drawerMenu={drawerMenu}>
        <Outlet />
      </CommonLayout>
    );
}

export default CMS
