import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  coupon,
  useGetCouponListQuery,
  useUpdateCouponListTableMutation,
  useUpdateCouponStatusMutation,
} from "../../../store/queries/Coupon";
import {
  getCouponData,
  updateConfig,
} from "../../../store/slices/Coupon/couponSlice";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useNavigate } from "react-router-dom";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const useCouponLists = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const couponState = useSelector((state) => state.coupon);

  const {
    data: couponList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetCouponListQuery({
    page_size: couponState.currentPageSize,
    page: couponState.currentPage,
    sort_by: couponState.sortBy,
    sort_order: couponState.sortOrder,
    search: couponState?.search,
    coupon_type: couponState?.coupon_type,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateCouponListTableMutation();
  const [updateCouponStatus] = useUpdateCouponStatusMutation();

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = true;
      })
    );
  };
  const hasCreatePermission = useMemo(() => {
    return couponList?.data?.create_permission ?? false;
  }, [couponList]);
  const hasEditPermission = useMemo(() => {
    return couponList?.data?.edit_permission ?? false;
  }, [couponList]);
  const hasDeletePermission = useMemo(() => {
    return couponList?.data?.delete_permission ?? false;
  }, [couponList]);

  const getRow = (field, data) => {
    const rows = {
      discount_amount: (field, data) => (
        <p className="pro-mb-0">{"$" + data[field]}</p>
      ),
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      is_active: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.is_active}
              // onChange={(e) => updateCouponStatus(data?._id)}
              onChange={(e) => handleChangeStatus(data?.is_active, data?._id)}
            />
          </div>
        );
      },
      date_from: (field, data) => {
        // if (data?.date_from) {
        //   const dateObject = new Date(data?.date_from);

        //   // Format the date as 'YYYY-MM-DD'

        //   const formattedDate = dateObject.toISOString().split("T")[0];
        //   return <p className="pro-mb-0">{formattedDate}</p>;
        // }
        if (data?.date_from) {
          const dateObject = new Date(data.date_from);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      date_to: (field, data) => {
        // if (data?.date_to) {
        //   const dateObject = new Date(data?.date_to);

        //   // Format the date as 'YYYY-MM-DD'

        //   const formattedDate = dateObject.toISOString().split("T")[0];
        //   return <p className="pro-mb-0">{formattedDate}</p>;
        // }

        if (data?.date_to) {
          const dateObject = new Date(data.date_to);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleChangeStatus = (status, id) => {
    updateCouponStatus(id);
    dispatch(
      coupon.util.updateQueryData(
        "getCouponList",
        {
          page_size: couponState.currentPageSize,
          page: couponState.currentPage,
          sort_by: couponState.sortBy,
          sort_order: couponState.sortOrder,
          search: couponState?.search,
          coupon_type: couponState?.coupon_type,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache?.data?.coupon_details?.map((res) =>
            res._id === id
              ? {
                  ...res,
                  is_active: status === true ? false : true,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              coupon_details: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };

  useEffect(() => {
    if (couponState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [couponState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (couponState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = couponState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.selectedSubject = "";
        state.subjectData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedCoupon = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getCouponData(e?.[0]));
  };
  return {
    couponState,
    showEditModal,
    tableFields,
    couponList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: couponState.currentFilter,
    currentPage: couponState.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    handleModalClick,
    handleEditAction,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  };
};

export default useCouponLists;
