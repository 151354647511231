import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  payment,
  useGetPayoutListQuery,
  useUpdatePayoutTableMutation,
} from "../../../store/queries/Payment";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  CreatePayoutSettle,
  updateConfig,
} from "../../../store/slices/Payment/Payouts/PayoutsSlice";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import Assets from "../../../assets/Assets";
import Style from "./payout.module.scss";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const usePayouts = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const payoutsState = useSelector((state) => state.payouts);
  const navigate = useNavigate();

  const {
    data: payoutList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetPayoutListQuery({
    page_size: payoutsState?.currentPageSize,
    page: payoutsState?.currentPage,
    sort_by: payoutsState?.sortBy,
    sort_order: payoutsState?.sortOrder,
    search: payoutsState?.search,
    start_date: payoutsState?.startDate,
    end_date: payoutsState?.endDate,
    status_type: payoutsState?.statusType,
  });
  
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdatePayoutTableMutation();

  const createLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };


  const getRow = (field, data) => {
    const rows = {
      amount: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      commission_amount: (field, data) => (
        <p className="pro-mb-0">{"$" + data[field]}</p>
      ),
      total_amount: (field, data) => (
        <p className="pro-mb-0">{"$" + data[field]}</p>
      ),
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      date: (field, data) => {
        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },

      tutor_name: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor_name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor_name)}
            </span>
          </div>
        );
      },

      student_name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/students-profile/${data?.student_id}`,
              });

              sessionStorage.setItem("active", `${data?.student_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.student_image !== "" && data?.student_image
                    ? data?.student_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.student_name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.student_name)}
            </span>
          </div>
        );
      },

      status: (field, data) => {
        return <p className="pro-mb-0">{createLabel(data[field])}</p>;
      },
      action: (feild, data) => (
        <button
          className="pro-btn pro-btn-primary"
          onClick={() => handleSettleClick(feild, data)}
          disabled={
            data["status"] === "PAYOUT_SETTLEMENT_CREATED" ||
            data["status"] === "SETTLED"
              ? true
              : false
          }
        >
          Create Settlement
        </button>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSettleClick = (field, data) => {
    dispatch(CreatePayoutSettle(data?._id)).then((res) => {
      if (res?.payload?.data?.length !== 0) {
        toast.success(res?.payload?.message);
        refetch();
        dispatch(payment.util.invalidateTags(["Payouts Settlement"]));
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (payoutsState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [payoutsState?.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (payoutsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = payoutsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    payoutsState,
    showEditModal,
    tableFields,
    payoutList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: payoutsState?.currentFilter,
    currentPage: payoutsState?.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
  };
};

export default usePayouts;
