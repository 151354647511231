import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filters=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams(
            "search"
          )}page_no=${params?.page || 1}&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const subjects = createApi({
  reducerPath: "subjectsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Subject", "Specialization"],
  endpoints: (builder) => ({
    // Sbject starts here
    getSubjectsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/subjects`,
      }),
      providesTags: ["Subject"],
    }),
    updateSubjectsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/subjects`,
      }),
      invalidatesTags: ["Subject"],
    }),
    updateTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Subject"],
    }),
    updateCreateSubjectData: builder.mutation({
      query(data) {
        const body = data.formData;
        const id = data.selectedSubject;
        return {
          endpoint: `admin/api/subjects/${id}`,
          method: "put",
          body,
        };
      },
      invalidatesTags: ["Subject"],
    }),
    updateSubjectStatus: builder.mutation({
      query: (id) => ({
        method: "post",
        endpoint: `admin/api/subject-status/${id}`,
      }),
      // invalidatesTags: ["Subject"],
    }),
    // subjects End here
    //Specialization starts here
    getSpecializationListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/specializations/${values?.id}`,
      }),
      providesTags: ["Specialization"],
    }),
    updateSpecializationTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Specialization"],
    }),
    createSpecializationData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/specilization`,
      }),
      invalidatesTags: ["Specialization"],
    }),
    updateSpecializationData: builder.mutation({
      query(data) {
        const body = data.formData;
        const id = data.selectedId;
        return {
          endpoint: `admin/api/specilization/${id}`,
          method: "put",
          body,
        };
      },
      invalidatesTags: ["Specialization"],
    }),
    updateSpecializationStatus: builder.mutation({
      query: (id) => ({
        method: "post",
        endpoint: `admin/api/subject-status/${id}`,
      }),
      invalidatesTags: ["Specialization"],
    }),
    //  Specialization End here
  }),
});

export const {
  useGetSubjectsListDataQuery,
  useUpdateSubjectsDataMutation,
  useUpdateCreateSubjectDataMutation,
  useUpdateTableListFieldsDataMutation,
  useGetSpecializationListDataQuery,
  useUpdateSpecializationTableMutation,
  useCreateSpecializationDataMutation,
  useUpdateSpecializationDataMutation,
  useUpdateSubjectStatusMutation,
  useUpdateSpecializationStatusMutation
} = subjects;
