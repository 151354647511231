import { useDispatch, useSelector } from "react-redux";

const useTransactionDetails = ({ closeModal, refetch }) => {
  const { transactionData } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      created_time: (field, data) => {

        const dateObject = new Date(data?.created_time);
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const options = {
  timeZone: currentTimezone,
  month: 'short',   // Short month name (e.g., Aug)
  day: 'numeric', 
  year: "numeric",   // Day of the month (e.g., 18)
  hour: 'numeric',   // Hour (e.g., 5)
  minute: 'numeric', // Minute (e.g., 30)
  hour12: true,      // Use 12-hour format with AM/PM
};

const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateObject);
        return (
          <p className="pro-mb-0">
              {formattedDate}
            </p>
        );
// if (data?.created_time) {
//   const dateObject = new Date(data.created_time);

//   // Get the user's current timezone
//   const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//   // Format the date with the current timezone
//   const formattedDate = dateObject.toLocaleString('en-US', {
//     timeZone: userTimezone,
//     year: 'numeric',
//     month: '2-digit',
//     day: '2-digit',
//   });

//   return (
//     <p className="pro-mb-0">
//       {formattedDate}
//     </p>
//   );
// }
      },

      

      
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    transactionData,
    getRow
  };
};

export default useTransactionDetails;
