import { createSlice } from "@reduxjs/toolkit";
// import { getAxiosInstance } from "../../../../api/index";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showAddTutorsModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedTutor: {},
  isEdit: false,
  tutorsData: [],
  isFilter: false,
  imagePreview: "",
  imageName: "",
  showFilterModal: false,
  tutorLocation: "",
  statusType: "",
  startDate:"",
  endDate:"",
  autoCompleteData: [],
  editLoading: false,
  editError: "",
};


const taxFormSlice = createSlice({
  name: "taxForm",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  
});

export const { updateConfig } = taxFormSlice.actions;

export default taxFormSlice.reducer;
