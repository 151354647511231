import React from "react";
import useDynamicMeta from "./useDynamicMeta";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import { ImageCard } from "../../Global/ImageCard";
import AddDynamicMeta from "./AddDynamicMeta";
function DynamicMeta() {
  const {
    dataState,
    showEditModal,
    tableFields,
    dataList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    editLoading,
    handleDelete,
    updateTableFields,
    closeEditModal,
    showImageModal,
    imageData,
    closeImageModal,
  } = useDynamicMeta();

  return (
    <div>
      <HeadingGroup title={"Dynamic Meta Update"} className={`pro-mb-4`}
      buttonTitle={"Edit"}
      handleClick={handleEditAction}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          searchable={false}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
        />
        <Table
          multiSelect={false}
          data={dataList?.data?.app_settings || []}
          uniqueID={"serrvice_settings"}
          fields={tableFields?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={dataState?.currentPageSize}
          clear={dataState.clearSelection}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={dataList?.data?.delete_permission}
          editable={dataList?.data?.edit_permission}
          showCheckBox={dataList?.data?.edit_permission || dataList?.data?.delete_permission}
        />
        {dataList?.data?.app_settings?.length > 0 && (
          <Pagination
            currentPage={dataState.currentPage}
            totalPageCount={Math.ceil(
              // dataList?.data?.pagination?.total_count / dataState.currentPageSize
              dataList?.data?.total_count / dataState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={dataState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(dataList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = dataList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={dataList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>

        <OffCanvasLayout
          show={dataState?.showAddModal && !editLoading}
          handleClose={handleModalClick}
          title={"Update Dynamic Meta"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddDynamicMeta refetch={refetch} handleModalClick={handleModalClick} />
        </OffCanvasLayout>
      </div>
    </div>
  );
}

export default DynamicMeta;
