import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetRevenueListQuery,
  useUpdateRevenueTableMutation,
} from "../../../store/queries/Payment";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Payment/Revenue/RevenueSlice";

import { useEffect } from "react";
import { toast } from "react-toastify";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import Assets from "../../../assets/Assets";
import Style from "./revenue.module.scss";
import { getTransactionData } from "../../../store/slices/Payment/Transaction/TransactionSlice";
import { getPlanData } from "../../../store/slices/Payment/PremiumPlanPurchase/PremiumPlanPurchaseSlice";
import { getBGData } from "../../../store/slices/Payment/BgVerification/BgVerificationPaymentSlice";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useRevenue = ({ dashboard }) => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const revenueState = useSelector((state) => state.Revenue);
  const navigate = useNavigate();

  const {
    data: revenueList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetRevenueListQuery({
    page_size: revenueState?.currentPageSize,
    page: revenueState?.currentPage,
    sort_by: revenueState?.sortBy,
    sort_order: revenueState?.sortOrder,
    search: revenueState?.search,
    source: revenueState?.source,
    revenue_type: revenueState?.revenue_type,
    start_date: revenueState?.date_from,
    end_date: revenueState?.date_to,
  });
  
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);
  const [updateTableFields] = useUpdateRevenueTableMutation();

  const createLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      amount: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      date: (field, data) => {
        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },

      paid_from: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/${
                  data?.user_type === "STUDENT"
                    ? "students-profile"
                    : "tutors-profile"
                }/${data?.user_id}`,
              });

              sessionStorage.setItem("active", `${data?.user_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.user_image !== "" && data?.user_image
                    ? data?.user_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },

      revenue_type: (field, data) => {
        return <p className="pro-mb-0">{createLabel(data[field])}</p>;
      },
      // transaction_id: (field, data) => {
      //   return (
      //     <div
      //       className={`pro-d-flex avatar-container ${Style.avatar_container}`}
      //       onClick={() => {
      //         if (data?.revenue_type === "SERVICE_FEE") {
      //           dispatch(getTransactionData(data?.payment_id)).then((res) => {
      //             if (res?.payload?.data?.course_purchase_details) {
      //               dispatch(
      //                 updateConfig((state) => {
      //                   state.showDetailsModal = true;
      //                   state.activeValue = 1;
      //                 })
      //               );
      //             }
      //           });
      //         } else if (data?.revenue_type === "PREMIUM_PLAN_PURCHASE") {
      //           dispatch(getPlanData(data?.payment_id)).then((res) => {
      //             if (res?.payload?.data?.premium_plan_purchase_details) {
      //               dispatch(
      //                 updateConfig((state) => {
      //                   state.showDetailsModal = true;
      //                   state.activeValue = 2;
      //                 })
      //               );
      //             }
      //           });
      //         } else if (data?.revenue_type === "TUTOR_BG_VERIFICATION") {
      //           dispatch(getBGData(data?.payment_id)).then((res) => {
      //             if (res?.payload?.data?.transaction_details) {
      //               dispatch(
      //                 updateConfig((state) => {
      //                   state.showDetailsModal = true;
      //                   state.activeValue = 3;
      //                 })
      //               );
      //             }
      //           });
      //         }
      //       }}
      //     >
      //       <span
      //         className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
      //       >
      //         {data?.transaction_id}
      //       </span>
      //     </div>
      //   );
      // },
      action: (field, data) => {
        return (
          <button
            className={`pro-btn pro-btn-link`}
            disabled={dashboard ? true : false}
            onClick={() => {
              if (data?.revenue_type === "SERVICE_FEE") {
                dispatch(getTransactionData(data?.payment_id)).then((res) => {
                  if (res?.payload?.data?.course_purchase_details) {
                    dispatch(
                      updateConfig((state) => {
                        state.showDetailsModal = true;
                        state.activeValue = 1;
                      })
                    );
                  }
                });
              } else if (data?.revenue_type === "PREMIUM_PLAN_PURCHASE") {
                dispatch(getPlanData(data?.payment_id)).then((res) => {
                  if (res?.payload?.data?.premium_plan_purchase_details) {
                    dispatch(
                      updateConfig((state) => {
                        state.showDetailsModal = true;
                        state.activeValue = 2;
                      })
                    );
                  }
                });
              } else if (data?.revenue_type === "TUTOR_COMMISSION") {
                dispatch(getTransactionData(data?.payment_id)).then((res) => {
                  if (res?.payload?.data?.course_purchase_details) {
                    dispatch(
                      updateConfig((state) => {
                        state.showDetailsModal = true;
                        state.activeValue = 3;
                      })
                    );
                  }
                });
              }
            }}
          >
            Show Details
          </button>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  useEffect(() => {
    if (revenueState?.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [revenueState?.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (revenueState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = revenueState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/payments/revenue");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailsModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  return {
    revenueState,
    showEditModal,
    tableFields,
    revenueList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: revenueState?.currentFilter,
    currentPage: revenueState?.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    handleDashboardRedirect,
    closeModal,
  };
};

export default useRevenue;
