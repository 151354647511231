import React from 'react';
import useRouteUtils from '../../utils/hooks/useRouteUtils';

const useSubjects = () => {
    const { checkIfActiveRoute } = useRouteUtils();
    const drawerMenu = [
      {
        title: "Subject",
        label: "Subject",
        link: "/subjects",
        iconAsset: "DrawerIcon1",
        icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
        active: checkIfActiveRoute("/subjects", true),
      },
    ];
    return { drawerMenu };
}

export default useSubjects;
