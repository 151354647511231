import { getAxiosInstance } from "../../../api";
import { createApi } from "@reduxjs/toolkit/query/react";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams(
            "search"
          )}${getParams("subject_id")}${getParams(
            "specialization_id"
          )}${getParams("premium_plan")}page_no=${params?.page || 1}&status=${
            params?.status_type || ""
          }&${getParams("user_type")}&${getParams("revenue_type")}&${getParams(
            "source"
          )}&timezone=${currentTimezone}`,
          body
        );

        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const payment = createApi({
  reducerPath: "paymentApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Refund",
    "Transactions",
    "PremiumPlanPurchase",
    "Refund-settlement",
    "Payouts",
    "Payouts Settlement",
    "BgVerification",
    "Revenue",
  ],
  endpoints: (builder) => ({
    // Transactions section starts here
    getTransactionList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/payment-transactions`,
      }),
      providesTags: ["Transactions"],
    }),
    updateTransactionTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Transactions"],
    }),
    // Transactions section ends here
    // Refund section starts here
    getRefundList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/refund-request`,
      }),
      providesTags: ["Refund"],
    }),

    getRefundSettlementList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/refund-request/settlement-list`,
      }),
      providesTags: ["Refund-settlement"],
    }),
    updateRefundSettlementTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Refund-settlement"],
    }),
    updateRefundTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Refund"],
    }),
    // Refund section ends here
    // PremiumPlanPurchase section starts here
    getPremiumPlanPurchaseList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/premium-plan-purchase`,
      }),
      providesTags: ["PremiumPlanPurchase"],
    }),
    updatePremiumPlanPurchaseTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["PremiumPlanPurchase"],
    }),
    getPremiumPlanList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/premium-plan-list`,
      }),
      providesTags: ["Refund"],
    }),
    getPayoutList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/payouts`,
      }),
      providesTags: ["Payouts"],
    }),

    getPayoutSettlementList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/payouts/settlement-list`,
      }),
      providesTags: ["Payouts Settlement"],
    }),
    updatePayoutTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Payouts"],
    }),

    updatePayoutSettlementTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Payouts Settlement"],
    }),
    getBgVerificationList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/background-verification-payment`,
      }),
      providesTags: ["BgVerification"],
    }),
    updateBgVerificationTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["BgVerification"],
    }),

    createRefundSettlementData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/refund-request/create-settlement`,
      }),
      invalidatesTags: ["Refund-settlement"],
    }),

    createPayoutSettlementData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/payouts/create-settlement`,
      }),
      invalidatesTags: ["Payouts Settlement"],
    }),

    updateStatus: builder.mutation({
      query: ({ body, id }) => ({
        method: "post",
        body,
        endpoint: `admin/api/refund-request-settlements/${id}/change-status`,
      }),

      invalidatesTags: ["Refund-settlement"],
    }),

    updatePayoutSettlementStatus: builder.mutation({
      query: ({ body, id }) => ({
        method: "post",
        body,
        endpoint: `admin/api/payouts/${id}/change-status`,
      }),

      invalidatesTags: ["Payouts Settlement"],
    }),

    // PremiumPlanPurchase section ends here

    getRevenueList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/revenue`,
      }),
      providesTags: ["Revenue"],
    }),
    updateRevenueTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Revenue"],
    }),
  }),
});

export const {
  useGetTransactionListQuery,
  useUpdateTransactionTableMutation,
  useGetRefundListQuery,
  useUpdateRefundTableMutation,
  useGetPremiumPlanPurchaseListQuery,
  useUpdatePremiumPlanPurchaseTableMutation,
  useGetPremiumPlanListQuery,
  useGetPayoutListQuery,
  useUpdatePayoutTableMutation,
  useGetBgVerificationListQuery,
  useUpdateBgVerificationTableMutation,
  useGetPayoutSettlementListQuery,
  useGetRefundSettlementListQuery,
  useUpdatePayoutSettlementTableMutation,
  useUpdateRefundSettlementTableMutation,
  useCreatePayoutSettlementDataMutation,
  useCreateRefundSettlementDataMutation,
  useUpdateStatusMutation,
  useUpdatePayoutSettlementStatusMutation,
  useGetRevenueListQuery,
  useUpdateRevenueTableMutation,
} = payment;
