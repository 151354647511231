import { useSelector } from "react-redux";
import { useGetEducationalListDataQuery, useUpdateCreateEducationalMutation, useUpdateEducationalMutation, useUpdateTableListFieldsDataMutation } from "../../store/queries/EducationalLevel";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { updateConfig } from "../../store/slices/EducationalLevel/educationalLevelSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getEducationalData } from "./api";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useEducationalLevel = () => {

  const { checkIfActiveRoute } = useRouteUtils();
  // const drawerMenu = [
  //   {
  //     title: "Educational Level",
  //     label: "Educational Level",
  //     link: "/educational-level",
  //     iconAsset: "DrawerIcon1",
  //     icon: <span className="material-symbols-outlined">bar_chart_4_bars</span>,
  //     active:
  //       checkIfActiveRoute("/educational-level", true)
  //   },
  // ];
  
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const educationalState = useSelector((state) => state.educational);
  const [skip, setSkip] = useState(true);
  const {
    data: educationalList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetEducationalListDataQuery({
    page_size: educationalState.currentPageSize,
    page: educationalState.currentPage,
    sort_by: educationalState.sortBy,
    sort_order: educationalState.sortOrder,
    filter: educationalState.currentFilter,
  });
  const [updateTableFields] = useUpdateTableListFieldsDataMutation();
  // const [deletePremiumPlan] = useDeletePremiumPlanMutation();
//   const [updateBranchFields] = useUpdateBranchTableHeadDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = educationalList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [educationalList]);

  useEffect(() => {
    if (educationalState?.selectedEducationalLevel && educationalState?.isEdit) {
      fillEducationalData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educationalState?.isEdit, educationalState?.showAddEducationalLevelModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddEducationalLevelModal = !state.showAddEducationalLevelModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      is_active: (field, data) => {
        if(data?.is_active) {
          return (
            <p className="pro-mb-0">
              Active
            </p>
          );
        }
        else {
          return (
            <p className="pro-mb-0">
              Inactive
            </p>
          );
        }
      }
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  // const handleDelete = () => {
  //   deletePremiumPlan(educationalState?.selectedEducationalLevel).then((response) => {
  //     if (response?.data?.statusCode === 200) {
  //       toast.success("Plan deleted successfully");
  //       handleDeleteModal();
  //       refetch();
  //     } else {
  //       toast.error("Something went wrong");
  //       handleDeleteModal();
  //     }
  //   });
  // };

  const fillEducationalData = () => {
    getEducationalData(educationalState?.selectedEducationalLevel).then((response) => {
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateConfig((state) => (state.EducationalLevelData = response?.data?.data))
        );
      } else {
        toast.error("Something went wrong to fetch educational data");
      }
    });
  };

  // const handleDeleteModal = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showDeleteModal = !state.showDeleteModal;
  //       state.selectedEducationalLevel = e?.[0];
  //     })
  //   );
  // };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddEducationalLevelModal = true;
        state.selectedEducationalLevel = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (educationalState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = educationalState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  useEffect(() => {
    if (educationalState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [educationalState.clearSelection]);

  const filters = useMemo(() => {
    if (educationalList?.data?.filters) {
      const buttonGroups = educationalList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, educationalList]);


  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedEducationalLevel = "";
      })
    );
  };

  return {
    // drawerMenu,
    educationalState,
    showEditModal,
    tableFields,
    educationalList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    // handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilter,
    activeFilter: educationalState.currentFilter,
    currentPage: educationalState.currentPage,
    filters,
    closeEditModal,
		updateTableFields
  };
};

export default useEducationalLevel;
