import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';

const TableConfigModal = ({ show, onHide, onSubmit }) => {
  const [columns, setColumns] = useState(3);
  const [rows, setRows] = useState(3);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ columns, rows });
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Insert Table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formColumns">
            <Form.Label>Columns</Form.Label>
            <Form.Control
              type="number"
              value={columns}
              onChange={(e) => setColumns(Number(e.target.value))}
              min="1"
            />
          </Form.Group>
          <Form.Group controlId="formRows">
            <Form.Label>Rows</Form.Label>
            <Form.Control
              type="number"
              value={rows}
              onChange={(e) => setRows(Number(e.target.value))}
              min="1"
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Insert
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

TableConfigModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TableConfigModal;
