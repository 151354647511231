import {
  Table,
  HeadingGroup,
  Pagination,
  Button,
  OffCanvasLayout,
  MultiColumnTable,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useTutorDetails from "./useTutorDetails";
import {
  SimpleReport,
  DataContainer,
} from "@wac-ui-dashboard/wac_component_library";

import Style from "./tutorDetails.module.scss";
import AvilabilityCalender from "./AvilabilityCalender";
import OverviewShimmer from "../../Global/Shimmers/OverviewShimmer/index";

const TutorDetails = () => {
  const {
    headerDetails,
    certificateDetails,
    OverViewData,
    academicDetails,
    expereanceDetails,
    bankDetails,
    assessmentDetails,
    pricing,
    expertise,
    isLoading,
  } = useTutorDetails();
  return (
    <>
      {isLoading ? (
        <OverviewShimmer />
      ) : (
        <>
          <div className="pro-m-4 pro-mx-5">
            <DataContainer propStyle={{ root: Style.container_root }}>
              <SimpleReport data={headerDetails} />
            </DataContainer>
          </div>
          <div className="pro-m-4 pro-mx-5">
            {OverViewData?.data?.tutorDetails?.over_view?.profile_overview && (
              <div className={`${Style.dash_border} pro-pb-4 pro-mb-4`}>
                <MultiColumnTable
                  title={"Profile Overview"}
                  data={[
                    {
                      value: `${OverViewData?.data?.tutorDetails?.over_view?.profile_overview}`,
                    },
                  ]}
                  extraClassNames={`${Style.table_data}`}
                />
              </div>
            )}

            {academicDetails?.length > 0 && (
              <div className={`${Style.dash_border} pro-mb-4`}>
                {academicDetails.map((academicEntry, index) => (
                  <div key={index}>
                    <MultiColumnTable
                      title={academicEntry.title}
                      data={academicEntry.data}
                      extraClassNames={`pro-mb-5 ${Style.table_wrap}`}
                    />
                  </div>
                ))}
              </div>
            )}

            {expereanceDetails?.length > 0 && (
              <div className={`${Style.dash_border} pro-mb-4`}>
                <MultiColumnTable
                  title={"Experiences"}
                  data={expereanceDetails}
                  extraClassNames={`pro-mb-5 ${Style.table_wrap}`}
                />
              </div>
            )}

            {certificateDetails?.length > 0 && (
              <div className={`${Style.dash_border} pro-mb-4`}>
                <MultiColumnTable
                  title={"Certificates"}
                  data={certificateDetails}
                  extraClassNames={`pro-mb-5 ${Style.table_wrap}`}
                />
              </div>
            )}

            <div className={`${Style.dash_border} pro-pb-4 pro-mb-4`}>
              <AvilabilityCalender
                title={"Availability Calender"}
                data={
                  OverViewData?.data?.tutorDetails?.over_view
                    ?.availability_calender
                }
              />
            </div>

            {assessmentDetails?.length > 0 && (
              <div className={`${Style.dash_border} pro-mb-4`}>
                <h6 className="pro-ttl h6">Assessment</h6>
                {assessmentDetails.map((assessmentEntry, index) => (
                  <div key={index}>
                    <h6 className="pro-font-sm pro-mb-1 pro-text-capitalize">
                      {assessmentEntry.title}
                    </h6>
                    <MultiColumnTable
                      data={assessmentEntry.data}
                      extraClassNames={`pro-mb-5 ${Style.table_wrap}`}
                    />
                  </div>
                ))}
              </div>
            )}

            {expertise?.length > 0 && (
              // {/* custom pricing component */}
              <div className={`${Style.dash_border} pro-mb-4 `}>
                <h6 className="pro-ttl h6">Subject Expertise</h6>
                {expertise.map(
                  (subject, index) =>
                    subject?.specialisations?.length > 0 && (
                      <div key={index} className="pro-mb-5">
                        <h6 className="pro-mb-2 pro-font-sm pro-text-capitalize">
                          {subject.subject_name}
                        </h6>
                        <div className="pro-d-flex pro-flex-wrap pro-gap-2">
                          {subject.specialisations.map(
                            (specialization, specIndex) => (
                              <div
                                key={specIndex}
                                className="pro-badge badge-grey lg"
                              >
                                {specialization.name}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )
                )}
              </div>
            )}

            {pricing?.length > 0 && (
              // {/* custom pricing component */}
              <div className={`${Style.dash_border} pro-mb-4`}>
                <h6 className="pro-ttl h6">Session Type & Pricing</h6>
                {pricing.map((subject, index) => (
                  <div key={index} className="pro-mb-5">
                    <h6 className={`pro-font-sm pro-mb-2 pro-text-capitalize`}>
                      {subject.subject_name}
                    </h6>
                    {subject.specialisations.map(
                      (specialization, specIndex) => (
                        <div
                          className={`${Style.specialization} pro-py-1 pro-d-flex pro-items-center`}
                          key={specIndex}
                        >
                          <span>{specialization.name}</span>
                          {specialization.hourly_rate && (
                            <span className={`${Style.online}`}>
                              Online - ${specialization.hourly_rate}
                            </span>
                          )}
                          {specialization.offline_hourly_rate && (
                            <span className={`${Style.in_person}`}>
                              In-Person - ${specialization.offline_hourly_rate}
                            </span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                ))}
              </div>
            )}

            {bankDetails?.length > 0 && (
              <div className={`${Style.dash_border} pro-mb-4`}>
                <MultiColumnTable
                  title={"Bank details"}
                  data={bankDetails}
                  extraClassNames={`pro-mb-5 ${Style.table_wrap}`}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TutorDetails;
