import React from "react";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useReports from "./useReports";

const Reports = () => {
  const drawerMenu = useReports();
  return (
    <CommonLayout dashboard drawerMenu={drawerMenu?.drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Reports;
