import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";
// import { setNewFieldsData } from "./api";
// import axios from "axios";
//import { members } from "../../../store/queries/members";
import { toast } from "react-toastify";
//import { useSelector } from "react-redux";

const useOrderColoum = ({ tableFields, moduleId, updateData, fixedFields, refetchData }) => {
  // const [message, setMessage] = useState("");

  let fieldsToChange = Object.entries(tableFields);
  let fixedFieldsToAdd = Object.entries(fixedFields);

  fieldsToChange = fieldsToChange.reduce(
    (a, v) => ({ ...a, [v[0]]: { ...v[1], key_id: v[0] } }),
    {}
  );

  fixedFieldsToAdd = fixedFieldsToAdd.reduce(
    (a, v) => ({ ...a, [v[0]]: { ...v[1], key_id: v[0] } }),
    {}
  );

  const [characters, setCharacters] = useState(Object.values(fieldsToChange));
  const [fixedFieldsData, setFixedFieldsData] = useState(
    Object.values(fixedFieldsToAdd)
  );

  const dispatch = useDispatch();
  // const membersState = useSelector((state) => state.members);
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setCharacters(items);
  }

  const handleCancel = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleSubmit = () => {
    let tempData = [];
    Object.values(characters).map((item, index) => {
      let data = {
        column_name: item.key_id,
        label: item.label,
        position: index,
        sortable: item.sortable,
        hidden: characters?.[index]?.hidden,
      };
      tempData.push(data);
    });
    
    let filedOrderData = {}; // eslint-disable-next-line
    tempData.forEach(i => { 
      const key = i.column_name;
      delete i['column_name'];
      filedOrderData[key] = i;
    });
    const obj = {
      listing_id: moduleId,
      field_settings: filedOrderData,
    };

    updateData(obj).then((response) => {
      if (response?.data?.statusCode === 200) {
        
        refetchData?.();
        dispatch(
          updateConfig((state) => {
            state.showEditModal = false;
          })
        );
        toast.success("Fields updated Successfully!");
      } else if (response?.statusCode === 400) {
        toast.error(response?.error?.data?.message);
        dispatch(
          updateConfig((state) => {
            state.showEditModal = false;
          })
        );
      }
      else {
        toast.error("Something went wrong!");
        dispatch(
          updateConfig((state) => {
            state.showEditModal = false;
          })
        );
      }
    });
  };

  const handleCheckbox = (Label) => {
    function getKeyByValue(object, Label) {
      return Object.keys(object).find((key) => object[key].label === Label);
    }

    let tempData = characters;
    
    let key = getKeyByValue(tempData, Label);
    tempData[key] = { ...tempData[key], hidden: !tempData[key].hidden };
    
    setCharacters(Object.values(tempData));
  };

  return {
    characters,
    // message,
    handleCancel,
    handleSubmit,
    handleOnDragEnd,
    handleCheckbox,
  };
};

export default useOrderColoum;
