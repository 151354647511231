import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  getSpecializationData,
  updateConfig,
} from "../../../store/slices/Subjects/Specialization/specializationSlice";
import {
  useGetSpecializationListDataQuery,
  useUpdateSpecializationStatusMutation,
  useUpdateSpecializationTableMutation,
} from "../../../store/queries/Subjects";
import { useNavigate, useParams } from "react-router-dom";
import { deleteSpecialization } from "../api";

const useSpecializationList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );

}, []);
  const { subjectID } = useParams();
  const storedData = JSON.parse(localStorage.getItem("subjectData"));

  const specializationState = useSelector((state) => state.specialization);
  const { selectedId } = useSelector((state) => state.specialization);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: SpecializationList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetSpecializationListDataQuery({
    page_size: specializationState?.currentPageSize,
    page: specializationState?.currentPage,
    sort_by: specializationState?.sortBy,
    sort_order: specializationState?.sortOrder,
    search: specializationState?.search,
    id: subjectID,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateSpecializationFields] = useUpdateSpecializationTableMutation();
  const [updateSpecializationStatus] = useUpdateSpecializationStatusMutation();

  useEffect(() => {
    if (specializationState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [specializationState.clearSelection]);

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.specializationData = [];
        state.autoCompleteData = [];
        state.imageName = "";
        state.imagePreview = "";
        state.selectedId = "";
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const hasCreatePermission = useMemo(() => {
    return SpecializationList?.data?.create_permission ?? false;
  }, [SpecializationList]);
  const hasEditPermission = useMemo(() => {
    return SpecializationList?.data?.edit_permission ?? false;
  }, [SpecializationList]);
  const hasDeletePermission = useMemo(() => {
    return SpecializationList?.data?.delete_permission ?? false;
  }, [SpecializationList]);

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (specializationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            specializationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      is_active: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.is_active}
              onChange={(e) => updateSpecializationStatus(data?._id)}
            />
          </div>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getSpecializationData(e?.[0]));
  };
  const handleDelete = async () => {
    const response = await deleteSpecialization(selectedId);
    if (response && response.status === 200) {
      toast.success(`Specialization deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  useEffect(() => {
    if (specializationState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [specializationState.clearSelection]);

  return {
    SpecializationList,
    specializationState,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    handleCreateClick,
    handleEditColumnsClick,
    handleEditAction,
    handleDeleteModal,
    handleDelete,
    handlePagination,
    updateSpecializationFields,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    storedData,
    subjectID,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  };
};

export default useSpecializationList;
