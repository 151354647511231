import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams(
            "search"
          )}page_no=${params?.page || 1}&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const cms = createApi({
  reducerPath: "cmsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["CMS", "Testimonials", "LocationSearchMeta"],
  endpoints: (builder) => ({
    // branches starts
    getTermsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/terms-and-conditions`,
      }),
      providesTags: ["CMS"],
    }),
    getPoliciesListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/policies`,
      }),
      providesTags: ["CMS"],
    }),
    getPrivacyListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/privacy-and-policy`,
      }),
      providesTags: ["CMS"],
    }),

    getMoreListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/app-settings`,
      }),
      providesTags: ["CMS"],
    }),

    getDynamicMetaListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/dynamic-meta`,
      }),
      providesTags: ["CMS"],
    }),

    getAboutUsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/about-us`,
      }),
      providesTags: ["CMS"],
    }),

    getContactUsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/contact-info`,
      }),
      providesTags: ["CMS"],
    }),
    // testimonial section Starts Here
    getTestimonialsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/testimonials`,
      }),
      providesTags: ["Testimonials"],
    }),

    updateTestimonialStatus: builder.mutation({
      query: (id) => ({
        method: "post",
        endpoint: `admin/api/testimonial-status/${id}`,
      }),
      // invalidatesTags: ["Student"],
    }),
    updateTestimonialsTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Testimonials"],
    }),

    getOurClientListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/clients`,
      }),
      providesTags: ["OurClient"],
    }),
    updateOurClientTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["OurClient"],
    }),

    updatePrivacyTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["privacy"],
    }),

    updateClientStatus: builder.mutation({
      query: (id) => ({
        method: "put",
        endpoint: `admin/api/clients/${id}/toggle-status`,
      }),
      // invalidatesTags: ["OurClient"],
    }),

    // testimonial section end Here
    updateTerms: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/terms-and-conditions`,
      }),
      invalidatesTags: ["CMS"],
    }),
    updatePolicies: builder.mutation({
      query: (body) => ({
        method: "put",
        body,
        endpoint: `admin/api/policies`,
      }),
      invalidatesTags: ["CMS"],
    }),
    updatePrivacy: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/privacy-and-policy`,
      }),
      invalidatesTags: ["CMS"],
    }),
    updatePrivacyStatus: builder.mutation({
      query: (body) => ({
        method: "put",
        endpoint: `admin/api/privacy-and-policy/${body.dataId}/content/${body.contentId}?status=${body.status}`,
      }),
      invalidatesTags: ["CMS"],
    }),

    updateMore: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/app-settings`,
      }),
      invalidatesTags: ["CMS"],
    }),

    updateAboutUs: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/about-us`,
      }),
      invalidatesTags: ["CMS"],
    }),

    updateContactUs: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/contact-info`,
      }),
      invalidatesTags: ["CMS"],
    }),

    updateAboutUsStatus: builder.mutation({
      query: (body) => ({
        method: "put",
        endpoint: `admin/api/about-us/${body.contentId}?status=${body.status}`,
      }),
      invalidatesTags: ["CMS"],
    }),
    //location search meta starts here
    getLocationSearchList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/location-search-meta`,
      }),
      providesTags: ["LocationSearchMeta"],
    }),
    updateMetaTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["LocationSearchMeta"],
    }),
    //location search meta ends here
  }),
});

export const {
  useGetTermsListDataQuery,
  useGetPrivacyListDataQuery,
  useGetContactUsListDataQuery,
  useGetTestimonialsListDataQuery,
  useUpdateTestimonialsTableListFieldsDataMutation,
  useUpdateTermsMutation,
  useUpdatePrivacyMutation,
  useUpdateContactUsMutation,
  useGetOurClientListDataQuery,
  useUpdateOurClientTableListFieldsDataMutation,
  useUpdateClientStatusMutation,
  useGetAboutUsListDataQuery,
  useGetMoreListDataQuery,
  useGetDynamicMetaListDataQuery,
  useUpdateAboutUsMutation,
  useUpdateMoreMutation,
  useUpdatePrivacyTableListFieldsDataMutation,
  useUpdatePrivacyStatusMutation,
  useUpdateAboutUsStatusMutation,
  useUpdateTestimonialStatusMutation,
  useGetPoliciesListDataQuery,
  useUpdatePoliciesMutation,
  useGetLocationSearchListQuery,
  useUpdateMetaTableListFieldsDataMutation
} = cms;
