import { useEffect } from "react";

export const useItemField = (
  fields,
  itmIndex,
  formik,
  basicData,
  tempIds,
  tempPIds,
  isEdit
) => {
  let caseText;

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.content?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("content", data);
  };

  return {
    handleRemoveFieldCS,
  };
};

export default useItemField;
