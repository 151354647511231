import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isFilter: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  payoutSettlementData: [],
  startDate:"",
  endDate:"",
  statusType:"",
  showFilterModal: false,
  // userType:"all",
  // subject:"",
  // specialization:""
};

export const ExportPayout = createAsyncThunk(
  "/admin/api/payouts",
  async (id, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/api/payouts/${id}/export`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const PayoutsSettlementSlice = createSlice({
  name: "payoutsSettlement",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = PayoutsSettlementSlice.actions;

export default PayoutsSettlementSlice.reducer;
