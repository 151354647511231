import { useFormik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Banners/bannersSlice";
// import { addBillingData, editBillingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useUpdateBannersMutation } from "../../../store/queries/Banners";


const useAddBanner = ({ refetch }) => {
	const dispatch = useDispatch();
	const { BannersData, isEdit } = useSelector((state) => state.banners);
	const [updateBannersData] = useUpdateBannersMutation();
	const [bannerID, setbannerID] = useState("");
	const [isChecked, setIsChecked] = useState(true);
	const [imagePreview, setMediaPreview] = useState(
		BannersData !== "" ? BannersData?.media_details?.url : ""
	);
	const options = [
		{ value: 'IMAGE', label: 'IMAGE' },
		{ value: 'VIDEO', label: 'VIDEO' },
	  ];

	  const [selectedOption, setSelectedOption] = useState(null);
	  

	useEffect(() => {
		if (BannersData && isEdit) {
			Object.keys(BannersData || {}).forEach((key) => {
				if(key === "type") {
					formik?.setFieldValue("type", BannersData?.[key]);
				}
				else if(key === "is_active") {
					setIsChecked(BannersData?.[key]);
					formik?.setFieldValue("is_active", BannersData?.[key]);
				}
				else if(key === "description") {
					formik?.setFieldValue("description", BannersData?.[key]);
				}
				else if(key === "link") {
					formik?.setFieldValue("link", BannersData?.[key]);
				}
				else if(key === "media_type") {
					const result = options.find(
						(value) => value?.value === BannersData?.[key]
					);
					setSelectedOption(result);
				}
				else if(key === "_id") {
					setbannerID(BannersData?.[key])
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BannersData]);

	// const validation = Yup.object({
	// 	name: Yup.string()
	// 	.min(3, "Name Must be atleast 3 Characters long")
	// 	.required("Enter Educational Level Name"),
	// });

	const formik = useFormik({
		initialValues: {
			type: "",
			description: "",
			link: "",
			media_type: "",
			is_active: null
		},

		onSubmit: (values) => {
			let data = {
				type: values.type,
				description: values?.description ? values?.description : "",
				link: values?.link ? values?.link : "",
				media_type: values?.media_type?.value ? values?.media_type?.value : "",
				is_active: isEdit ? values?.is_active : "",
			};
			if (values?.media) {
				data.media = values?.media
			}

			let formData = new FormData();

			Object.keys(data).forEach((key) => {
				return formData.append(key, data[key]);
			})

			if(isEdit) {
				updateBannersData({bannerID, formData}).then((response) => {
					if (response?.data?.statusCode === 200) {
						handleCloseModal();
							toast.success("Banner Details Updated");
						refetch();
						dispatch(
							updateConfig((state) => {
							  state.clearSelection = true;
							})
						  );
					} else if (response?.error?.data?.statusCode === 422) {
						// displays backend errors
						const errors = response?.error?.data?.errors[0]?.message;
						formik.setErrors(errors);
						toast.error(errors);
					} else {
						toast.error("Something went wrong");
					}
				});
			}
			
		}
	});

	const handleChange = (selectedOption) => {
		setSelectedOption(selectedOption);
		formik.setFieldValue("media_type",selectedOption)
	  };
	

	const getFieldError = (fieldName) => {
		if (formik.touched[fieldName] && formik.errors[fieldName]) {
			return formik.errors[fieldName];
		}
		return "";
	};

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showAddBannersModal = false;
			})
		);
	};

	const handleChecked = (e, id) => {
		const { checked } = e.target;
		setIsChecked(checked);
		formik.setFieldValue(id, checked);
	};

	const handleMedia = (e) => {
		const files = e.target.files;

		if (files?.[0]?.size / (1024 * 1024) <= 5) {
			formik.setFieldValue("media", e?.target?.files?.[0]);
			

			const file = e.target.files[0];
			const reader = new FileReader();

			reader.onloadend = () => {
				setMediaPreview(reader.result);
			};

			if (file) {
				reader.readAsDataURL(file);
			}
		} else if (files?.[0]?.size / (1024 * 1024) > 5) {
			toast.error("The media must be less than 5MB in size.");
			formik.setFieldError(
				"media",
				"The media must be less than 5MB in size."
			);
		}
	};
	return {
		formik,
		BannersData,
		// basicData: basicData?.data,
		serviceItems: BannersData?.serviceItems,
		getFieldError,
		handleCloseModal,
		handleChecked,
		isChecked,
		isEdit,
		selectedOption,
		options,
		handleChange,
		handleMedia,
		imagePreview
	};
};

export default useAddBanner;
