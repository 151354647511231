import { useSelector } from "react-redux";
import {
  useGetTutorsListDataQuery,
  useUpdateTableListFieldsDataMutation,
  useUpdateTutorStatusMutation,
} from "../../../store/queries/Tutor";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteData, updateConfig } from "../../../store/slices/Tutor/InactiveTutors/inactiveTutorsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Style from "./tutor.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import { tutor } from "../../../store/queries/Tutor";
import { current } from "@reduxjs/toolkit";
import { getIsSafeValue } from "./api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useInactiveTutors = ({ dashboard }) => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);
  const navigate = useNavigate();

  const { showEditModal } = useSelector((state) => state.global);
  const tutorsState = useSelector((state) => state.inactiveTutors);
  const [updateTutorStatus] = useUpdateTutorStatusMutation();
  const { isEdit, isFilter } = useSelector((state) => state.tutors);
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const {
    data: tutorsList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetTutorsListDataQuery({
    page_size: tutorsState.currentPageSize,
    page: tutorsState.currentPage,
    sort_by: tutorsState.sortBy,
    sort_order: tutorsState.sortOrder,
    search: tutorsState?.search,
    status_type: tutorsState?.statusType,
    zip_code: tutorsState?.tutorLocation,
    status: "inactive",
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    if (tutorsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [tutorsState.clearSelection]);
  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = !state.showAddTutorsModal;
        state.isEdit = false;
        state.selectedTutor = "";
        state.tutorsData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      is_active: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.is_active}
              // onChange={(e) => updateTutorStatus(data?._id)}
              onChange={(e) => handleChangeStatus(data?.is_active, data?._id)}
              disabled={dashboard ? true : false}
            />
          </div>
        );
      },
      name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?._id}`,
              });

              sessionStorage.setItem("active", `${data?._id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.image !== "" && data?.image
                    ? data?.image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },
      createdAt: (field, data) => {
        if (data?.createdAt) {
          const dateObject = new Date(data.createdAt);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      is_verified: (field, data) => {
        if (data?.is_verified) {
          return <p className="pro-mb-0">Active</p>;
        } else {
          return <p className="pro-mb-0">Inactive</p>;
        }
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleChangeStatus = (status, id) => {
    setObj({
      status: status,
      id: id,
    });
    getIsSafeValue(id).then((res) => {
      if (res?.data?.statusCode === 200) {
        setPopUpMessage(res?.data?.message);
        handleDeleteModal();
      }
    });
  };

  useEffect(() => {
    if (statusUpdate) {
      updateTutorStatus(obj?.id);
      dispatch(
        tutor.util.updateQueryData(
          "getTutorsListData",
          {
            page_size: tutorsState.currentPageSize,
            page: tutorsState.currentPage,
            sort_by: tutorsState.sortBy,
            sort_order: tutorsState.sortOrder,
            search: tutorsState?.search,
            status_type: tutorsState?.statusType,
            zip_code: tutorsState?.tutorLocation,
          },
          (cacheEntry) => {
            let currentCache = { ...current(cacheEntry) };
            const newResult = currentCache?.data?.tutor_list?.map((res) =>
              res._id === obj?.id
                ? {
                    ...res,
                    is_active: obj?.status === true ? false : true,
                  }
                : res
            );
            currentCache = {
              ...currentCache,
              data: {
                ...currentCache.data,
                tutor_list: newResult,
              },
            };
            return currentCache;
          }
        )
      );
      handleDeleteModal();
    }
    //eslint-disable-next-line
  }, [statusUpdate === true]);

  const handleDeleteModal = () => {
    setStatusUpdate(false);
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = true;
        state.selectedTutor = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (tutorsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = tutorsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedTutor = "";
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/tutors/tutor");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteData({id: selectedItemID[0]}))
        .unwrap()
        .then((result) => {
          if (result?.status_code === 200) {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    tutorsState,
    showEditModal,
    tutorsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: tutorsState.currentFilter,
    currentPage: tutorsState.currentPage,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
    isEdit,
    isFilter,
    handleDashboardRedirect,
    setStatusUpdate,
    popUpMessage,
    handleDelete,
    handleDeleteAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
  };
};

export default useInactiveTutors;
