import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddPremiumPlanModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedPremiumPlan: "",
  isEdit: false,
  premiumPlanData: [],
  clearSelection: false,
};

const premiumPlansSlice = createSlice({
  name: "premiumPlans",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = premiumPlansSlice.actions;

export default premiumPlansSlice.reducer;
