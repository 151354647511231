import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/FAQs/faqsSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useFaqListFilter = ({ handleFilterClick }) => {
	const dispatch = useDispatch();
	const { isFilter, currentFilter } = useSelector(
		(state) => state.faqs
	);

	const basicData = [
		{ value: "active", label: "ACTIVE" },
		{ value: "inactive", label: "INACTIVE" },
	  ];

	const formik = useFormik({
		initialValues: {
			currentFilter: "",
		},
		onSubmit: (values) => {
			let newObj = Object.entries(values);
			let subData = newObj
				.filter(
					(item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
				)
				.reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
			dispatch(
				updateConfig((state) => {
					Object.assign(state, subData);
				})
			);
			handleFilterClick();
		},
	});
	const clearFilter = () => {
		formik.handleReset();
		dispatch(
			updateConfig((state) => {
				Object.assign(state, formik.initialValues);
				state.isFilter = false;
			})
		);
		handleFilterClick();
	};

	let selectVal = basicData.find(
		(item) => item.value === formik.values.currentFilter
	  );

	useEffect(() => {
		if (isFilter) {
			formik.setValues({
				currentFilter: currentFilter || "",
			});
		}
	}, [isFilter]);
	return {
		formik,
		selectVal,
		basicData,
		clearFilter,
	};
};

export default useFaqListFilter;
