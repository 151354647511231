import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddOurClient from "./useAddOurClient";

const AddOurClient = ({ refetch, handleModalClick }) => {
  const {
    getFieldError,
    closeModal,
    formik,
    handleProfileImage,
    isEdit,
    imagePreview,
    imageName,
  } = useAddOurClient(handleModalClick, refetch);
  
  return (
    <div className={`row`}>
      
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />

      <Input
        label={"Position*"}
        type="text"
        id="position"
        name="position"
        className={`pro-input lg ${getFieldError("position") && " error"}`}
        {...formik.getFieldProps("position")}
        error={getFieldError("position")}
        errorMessage={getFieldError("position")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${
              formik.errors.image && formik.touched.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleProfileImage(e)}
          />

          <span className="input-drag-box">
            <IconText
              title={
                imageName !== "" ? imageName : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      {
        imagePreview && 
          <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={imagePreview}
                alt={`image - 01`}
              />
            </div>
          </div>
      }
      <span className="pro-font-sm pro-mb-3">Max upload file size 5MB</span>
      <div className="pro-check-box pro-py-2">
        <input
          type="checkbox"
          className="pro-check"
          id="default"
          name="pro-checkbox"
          checked={formik.values.status === true}
          onChange={(e) => {
            formik.setFieldValue(
              "status",
              e.target.checked ? true : false
            );
          }}
        />
        <label htmlFor="default" className="pro-check-label">
          Status
        </label>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleModalClick}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddOurClient;


