import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllRoles } from "../../../../store/slices/Settings/Role/roleSlice";

const useCreateRoleModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  useEffect(() => {
    if (roleState?.allRoles?.length === 0) {
      dispatch(getAllRoles());
    }
    // eslint-disable-next-line
  }, []);

  const handleRoleClick = (item) => {
    if (item) {
      navigate({
        pathname: "/permission",
        search: `role=${item._id}`,
      });
    } else {
      navigate("/permission");
    }
  };

  return {
    roleState,
    handleRoleClick,
  };
};

export default useCreateRoleModal;
