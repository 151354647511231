import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useCouponLists from "./useCouponLists";
import ReportedListFilter from "./CouponListFilter";
import AddCoupon from "./AddCoupon";
import CouponListFilter from "./CouponListFilter";

const CouponLists = () => {
  const {
    couponState,
    showEditModal,
    couponList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
    handleFilterClick,
    handleModalClick,
    handleEditAction,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  } = useCouponLists();
  return (
    <div>
      <HeadingGroup
        title={"Coupons"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ? "Add new" : ""}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: couponState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>

        <Table
          multiSelect={false}
          data={couponList?.data?.coupon_details || []}
          uniqueID={"_id"}
          fields={couponList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={couponState?.currentPageSize}
          assignable={false}
          deletable={hasDeletePermission}
          editable={hasEditPermission}
          showCheckBox={hasDeletePermission || hasEditPermission}
          handleEdit={handleEditAction}
          clear={couponState.clearSelection}
        />
        {couponList?.data?.coupon_details?.length > 0 && (

          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              couponList?.data?.total_count / couponState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(couponList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = couponList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={couponList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={couponState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <CouponListFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
            dropdown={couponList?.data?.coupon_types}
          />
        </OffCanvasLayout>

        <OffCanvasLayout
          show={couponState?.showCreateModal}
          handleClose={handleModalClick}
          title={couponState?.isEdit ? "Update Coupon" : "Add Coupon"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddCoupon refetch={refetch} handleModalClick={handleModalClick} dropdown={couponList?.data?.coupon_types}/>
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default CouponLists;
