import { useSelector } from "react-redux";
import {
  useGetPayoutListDataQuery,
  useUpdatePayoutListStatusMutation,
  useUpdatePayoutListTableFieldsDataMutation,
  useUpdateTutorStatusMutation,
} from "../../../store/queries/Tutor";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Tutor/PayoutList/payoutListSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getTutorPayoutDeytailsData } from "./api";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "./tutor.module.scss";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const usePayoutList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const navigate = useNavigate();

  const { showEditModal } = useSelector((state) => state.global);
  const payoutListState = useSelector((state) => state.payoutList);
  const [payoutDetailData, setPayoutDetailData] = useState("");
  const { isEdit, isFilter } = useSelector((state) => state.payoutList);
  const [payoutDetailModal, setPayoutDetailModal] = useState(false);
  const {
    data: payoutList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetPayoutListDataQuery({
    page_size: payoutListState.currentPageSize,
    page: payoutListState.currentPage,
    sort_by: payoutListState.sortBy,
    sort_order: payoutListState.sortOrder,
    search: payoutListState?.search,
    status_type: payoutListState?.statusType,
    start_date: payoutListState.startDate,
    end_date: payoutListState.endDate,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);
  
  const [updateTableFields] = useUpdatePayoutListTableFieldsDataMutation();

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = !state.showAddTutorsModal;
        state.isEdit = false;
        state.selectedTutor = "";
        state.tutorsData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };

  
  const getRow = (feild, data) => {
    const rows = {
      amount: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      date: (feild, data) => {
        // if (data?.date) {
        //   const dateObject = new Date(data?.date);

        //   // Format the date as 'YYYY-MM-DD'

        //   const formattedDate = dateObject.toISOString().split("T")[0];
        //   return <p className="pro-mb-0">{formattedDate}</p>;
        // }

        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      action: (feild, data) => (
        <button
          className={`pro-btn pro-btn-link pro-m-0 pro-ms-0 pro-me-0`}
          onClick={() => {
            setPayoutDetailModal(true);
            getTutorPayoutDeytailsData(data?.tutor_id).then((res) => {
              setPayoutDetailData(res?.data?.data);
            });
          }}
        >
          Show Details
        </button>
      ),
      tutor_name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedAssessmentSubmission = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = true;
        state.selectedTutor = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (payoutListState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            payoutListState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedTutor = "";
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/tutors/tutor");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const closePayoutDetailModal = () => {
    setPayoutDetailModal(false);
  };

  return {
    payoutListState,
    showEditModal,
    payoutList,
    isLoading,
    isFetching,
    paginationOptions,
    payoutDetailModal,
    closePayoutDetailModal,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: payoutListState.currentFilter,
    currentPage: payoutListState.currentPage,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
    isEdit,
    isFilter,
    payoutDetailData,
    handleDashboardRedirect,
  };
};

export default usePayoutList;
