import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import useValidations from "../../../../utils/hooks/useValidations";
import { changePassword } from "../../../Auth/api";

const useProfileEdit = ({ setProfileViewVisible }) => {
  const { validPasswords } = useValidations();
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const [showExistingPassword, setShowExistingPassword] = useState(true);

  var validationSchemaPasswordChange = Yup.object({
    confirm_password: Yup.string().required("Current password is required"),
    password: Yup.string().required("New password is required"),
    current_password: Yup.string().required("Current password is required")
  });


  const formikEditPassword = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      confirm_password: ""
    },

    validate: (values) => {
      let errors = {};
      const {
        uppercasePassword,
        lowercasePassword,
        digitsPassword,
        minLengthPassword,
        charsPassword,
      } = validPasswords(values.password);
      if (!values.password) {
        errors.password = "*Password required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "*Password confirmation required";
      }
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      if (!minLengthPassword) {
        errors.password = "*Password should be atleast 8 digits";
      }
      if (!charsPassword) {
        errors.password = "*Enter atleast 1 special character";
      }
      if (!digitsPassword) {
        errors.password = "*Enter atleast 1 digit";
      }
      if (!lowercasePassword) {
        errors.password = "*Enter atleast 1 lowercase letter";
      }

      if (!uppercasePassword) {
        errors.password = "*Enter atleast 1 uppercase letter";
      }

      return errors;
    },

    validationSchema: validationSchemaPasswordChange,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldError }) => {
      handleSubmitPasswordChange(values, resetForm, setFieldError);
    },
  });

  const handleSubmitPasswordChange = async (
    values,
    resetForm,
  ) => {
    try {
      try {
        let obj = {
          new_password_confirmation: values.confirm_password,
          new_password: values.password,
          current_password: values?.current_password
        };

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });

        changePassword(formData).then((data) => {
            if (data?.data?.statusCode === 200) {
              setProfileViewVisible(false);
              resetForm();
              toast.success("Your password has been updated successfully.");
            }
            else{
              toast.error(data?.message)
              setProfileViewVisible(false);
            }
          })
          .catch((err) => {
            if (err?.message !== "") {
              toast.error(err?.message);
            }
          });
      } catch (error) {}
    } catch (error) {
      toast.success("Failed to Update!");
    }
  };
  const handleShowExistingPassword = (e) => {
    e.preventDefault();
    setShowExistingPassword(!showExistingPassword);
    var x = document.getElementById("current_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault();
    setShowNewPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formikEditPassword,
    loading,
    showNewPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    showConfirmPassword,
    handleShowExistingPassword,
    showExistingPassword
  };
};

export default useProfileEdit;
