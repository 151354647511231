import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { updateConfig } from "../../../../store/slices/ReportedChat/reportedChatSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useReportedListsFilter = (handleFilterClick) => {
  const dispatch = useDispatch();
  const basicData = [
    { value: "resolved", label: "RESOLVED" },
    { value: "unresolved", label: "UNRESOLVED" },
  ];
  const { startDate, endDate, isFilter, statusType } = useSelector(
    (state) => state.reportedChat
  );
  const validationSchema = Yup.object({
    endDate: Yup.string().when(
      "startDate",
      (startDate, schema) =>
        startDate &&
        schema.test({
          test: function (value) {
            const { startDate } = this.parent;
            if (!startDate) {
              // If start date is not provided, no need for validation
              return true;
            }
            // Check if end date is provided
            return !!value;
          },
          message: "Select End Date",
        })
        .test({
          test: function (value) {
            const { startDate } = this.parent;
            if (!startDate) {
              // If start date is not provided, no need for validation
              return true;
            }
            // Check if end date is greater than or equal to start date
            const startDateObj = new Date(startDate);
            const endDateObj = new Date(value);
            return endDateObj >= startDateObj; // Changed operator to allow equality
          },
          message: "End date must be greater than or equal to start date", // Adjusted message
        })
    ),
});

  
  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      statusType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      dispatch(
        updateConfig((state) => {
          Object.assign(state, subData);
        })
      );
      handleFilterClick();
    },
  });
  const clearFilter = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        Object.assign(state, formik.initialValues);
        state.isFilter = false;
      })
    );
    handleFilterClick();
  };

  let selectVal = basicData.find(
    (item) => item.value === formik.values.statusType
  );
  useEffect(() => {
    if (isFilter) {
      formik.setValues({
        startDate: startDate || "",
        endDate: endDate || "",
        statusType: statusType || "",
      });
    }
  }, [isFilter]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  return {
    formik,
    selectVal,
    basicData,
    clearFilter,
    getFieldError,
  };
};

export default useReportedListsFilter;
