import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  refundData: [],
  startDate:"",
  endDate:"",
  statusType:"",
  userType:"",
  subject:"",
  specialization:""
};

export const CreateRefundSettle = createAsyncThunk(
  "/admin/api/refund-request",
  async (id, rejectWithValue ) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/api/refund-request/${id}/create-settlement`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const refundSlice = createSlice({
  name: "refund",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = refundSlice.actions;

export default refundSlice.reducer;
