import React from "react";
import { Input } from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../addPrivacy.module.scss";
import { Editor } from "react-draft-wysiwyg";
export const ItemField = ({
  formik,
  itmIndex,
  fields,
  isEdit,
  handleContentChange,
}) => {
  const {
    // fieldObj,
    // priorityU,
    // qcFieldData,
    // fontCaseObj,
    // priorityObj,
    handleRemoveFieldCS,
  } = useItemField(fields, itmIndex, formik, isEdit);
  return (
    <>
      <div className={`${Style.root}`}>
        {formik?.values?.content?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className="row gx-2">
          <div className="col-md-12">
            <Input
              type="text"
              id={`title`}
              name={`title`}
              label={`Title`}
              onBlur={formik.handleBlur(`content.${itmIndex}.title`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title &&
                " error"
              }`}
              {...formik.getFieldProps(`content.${itmIndex}.title`)}
              error={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
            />
          </div>
          <div className="col-md-12">
            {/* <Input
              type="text"
              id={`description`}
              name={`description`}
              label={`Description`}
              onBlur={formik.handleBlur(`content.${itmIndex}.description`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description &&
                " error"
              }`}
              {...formik.getFieldProps(`content.${itmIndex}.description`)}
              error={
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )
              }
            /> */}
            <div className={Style.editor_container}>
              <Editor
                wrapperClassName={Style.pro_editor_wrapper}
                editorClassName={Style.pro_editor_main}
                toolbarClassName={Style.pro_editor_toolbar}
                editorState={formik.values?.content?.[itmIndex]?.description}
                readOnly={false}
                onEditorStateChange={(editorState) =>
                  handleContentChange(editorState, itmIndex)
                }
                // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
                toolbar={{
                  options: [
                    "blockType",
                    "inline",
                    "list",
                    "textAlign",
                    "fontSize",
                    "fontFamily",
                  ],
                  blockType: {
                    inDropdown: true,
                    className: Style.dropdown_wrapper,
                    dropdownClassName: Style.dropdown_menu,
                  },
                }}
              />

              {formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className={Style.error_text}>
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )}
            </div>
          </div>
          <div className="col-12">
            <div className="pro-check-box pro-py-2">
              <input
                type="checkbox"
                className="pro-check"
                id={`default${itmIndex}`}
                name="pro-checkbox"
                checked={
                  formik?.values?.content?.[itmIndex]?.is_active === true
                }
                onChange={(e) => {
                  formik.setFieldValue(
                    `content.${itmIndex}.is_active`,
                    e.target.checked ? true : false
                  );
                }}
              />
              <label htmlFor={`default${itmIndex}`} className="pro-check-label">
                Status
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemField;
