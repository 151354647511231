import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateContact } from "../api";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useAddContactInfo = (refetch, handleModalClick) => {
  const { selectedData } = useSelector((state) => state.contact);
  const basicData = [
    { value: "contact_banner", label: "Contact Us" },
    { value: "support_section", label: "Learner Support" },
    { value: "contact_information", label: "Contact Information" },
    { value: "email_contacts", label: "Still not finding what you need?" },
  ];

  const foundObject = basicData.find(
    (item) => item.label === selectedData?.title
  );

  // Function to sanitize HTML and convert it to EditorState
  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Return empty editor state if no HTML

    const sanitizedHtml = DOMPurify.sanitize(html);
    const contentBlock = htmlToDraft(sanitizedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [initialData, setInitialData] = useState({
    title: selectedData?.title ?? "",
    type: foundObject?.value,
    description: selectedData?.description
      ? convertHtmlToEditorState(selectedData?.description)
      : "",
    description2: selectedData?.description2
      ? convertHtmlToEditorState(selectedData?.description2)
      : "",
    content:
      selectedData?.content?.map((item, index) => ({
        title: item?.title ?? "",
        description: item?.description
          ? convertHtmlToEditorState(item?.description)
          : "",
        icon: item?.icon ?? "",
        links:
          item?.links?.length !== 0
            ? item?.links?.map((link, i) => ({
                text: link?.text ?? "",
                url: link.url ?? "",
              }))
            : [],

        mail_text: item?.mail_text ?? "",
      })) || [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      // content: Yup.array().of(
      //   Yup.object().shape({
      //     title: Yup.string(),
      //     description: Yup.string(),
      //   })
      // ),
    }),

    onSubmit: (values) => {
      // created helper function to make the plaintext
      const getContentPlainText = (content) => {
        if (content.getCurrentContent) {
          const contentState = content.getCurrentContent();
          const contentRaw = convertToRaw(contentState);
          return draftToHtml(contentRaw);
        }
        return content;
      };

      let formData = new FormData();
      formData.append("title", values["title"]);
      formData.append("type", values["type"]);

      //assigning first plaintext into first description
      if (values?.description) {
        const plainText1 = getContentPlainText(values.description);
        formData.append("description", plainText1);
      }

      //assigning second plaintext value into description2
      if (values?.description2) {
        const plainText2 = getContentPlainText(values.description2);
        formData.append("description2", plainText2);
      }

      values?.content?.forEach((value, index) => {
        const plainText = getContentPlainText(value.description);
        if (selectedData?.content[index]?.mail_text) {
          formData.append(`content[${index}][mail_text]`, value?.mail_text);
        }
        if (selectedData?.content[index]?.icon) {
          formData.append(`content[${index}][icon]`, value?.icon);
        }
        formData.append(`content[${index}][title]`, value?.title);
        formData.append(`content[${index}][description]`, plainText);

        if (selectedData?.content[index]?.links) {
          values?.content?.[index]?.links?.forEach((value, i) => {
            formData.append(
              `content[${index}][links][${i}][text]`,
              value?.text
            );
            formData.append(`content[${index}][links][${i}][url]`, value?.url);
          });
        }
      });
      updateContact(formData, selectedData).then((response) => {
        if (response?.data?.statusCode === 200) {
          handleModalClick();
          refetch();
          toast.success(`${selectedData?.title} Updated`);
        } else if (response?.statusCode === 422) {
          const errors = response?.errors;
          errors.forEach((error) => {
            formik.setFieldError(error.field, error.message);
          });
        } else {
          toast.error("Something went wrong");
        }
      });
    },
  });
  const handleAddField = () => {
    let { content } = formik?.values;
    content = [
      ...content,
      {
        title: "",
        description: "",
        mail_text: "",
        links: [],
      },
    ];
    formik.setFieldValue("content", content);
  };

  const handleAddLink = (index) => {
    let { links } = formik?.values?.content?.[index];
    links = [
      ...links,
      {
        text: "",
        url: "",
      },
    ];
    formik.setFieldValue(`content.[${index}].links`, links);
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleContentChange = (editorState, index) => {
    const fieldName = `content[${index}].description`;
    formik?.setFieldValue(fieldName, editorState);
  };

  const handleContentChangeDescription = (editorState) => {
    formik.setFieldValue("description", editorState);
  };

  const handleContentChangeDescription2 = (editorState) => {
    formik.setFieldValue("description2", editorState);
  };

  return {
    basicData,
    formik,
    getFieldError,
    handleAddField,
    handleContentChange,
    handleContentChangeDescription,
    handleContentChangeDescription2,
    handleAddLink,
  };
};

export default useAddContactInfo;
