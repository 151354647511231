import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetTransactionListQuery,
  useUpdateTransactionTableMutation,
} from "../../../store/queries/Payment";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  getTransactionData,
  updateConfig,
} from "../../../store/slices/Payment/Transaction/TransactionSlice";

import { useEffect } from "react";

import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "../payment.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useTransactionList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const transactionState = useSelector((state) => state.transaction);

  const {
    data: transactionList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetTransactionListQuery({
    page_size: transactionState?.currentPageSize,
    page: transactionState?.currentPage,
    sort_by: transactionState?.sortBy,
    sort_order: transactionState?.sortOrder,
    search: transactionState?.search,
    start_date: transactionState.startDate,
    end_date: transactionState.endDate,
    subject_id: transactionState.subject,
    specialization_id: transactionState?.specialization,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTransactionTableMutation();

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isFilter = true;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      fee: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      tutor: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },
      paid_from: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/students-profile/${data?.paid_from_id}`,
              });

              sessionStorage.setItem("active", `${data?.paid_from_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.paid_from_image !== "" && data?.paid_from_image
                    ? data?.paid_from_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.paid_from}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.paid_from)}
            </span>
          </div>
        );
      },

      // transaction_id: (field, data) => {
      //   return (
      //     <div
      //       className={`pro-d-flex avatar-container ${Style.avatar_container}`}
      //       onClick={() => {
      //         dispatch(getTransactionData(data?._id)).then((res) => {
      //           if (res?.payload?.data?.course_purchase_details) {
      //             dispatch(
      //               updateConfig((state) => {
      //                 state.showDetailsModal = true;
      //               })
      //             );
      //           }
      //         });
      //       }}
      //     >
      //       <span
      //         className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
      //       >
      //         {data?.transaction_id}
      //       </span>
      //     </div>
      //   );
      // },
      action: (field, data) => {
        return (
          <button
            className={`pro-btn pro-btn-link`}
            disabled={false}
            onClick={() => {
              dispatch(getTransactionData(data?._id)).then((res) => {
                if (res?.payload?.data?.course_purchase_details) {
                  dispatch(
                    updateConfig((state) => {
                      state.showDetailsModal = true;
                    })
                  );
                }
              });
            }}
          >
            Show Details
          </button>
        );
      },
      date: (field, data) => {
        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  useEffect(() => {
    if (transactionState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [transactionState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (transactionState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            transactionState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailsModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  return {
    transactionState,
    showEditModal,
    tableFields,
    transactionList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: transactionState.currentFilter,
    currentPage: transactionState.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    closeModal,
  };
};

export default useTransactionList;
