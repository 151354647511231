import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddTutors from "./useAddTutors";

const AddTutors = ({ refetch, closeModal }) => {
  const {
    getFieldError,
    formik,
    handleProfileImage,
    isEdit,
    imagePreview,
    imageName,
    selectVal,
    basicData,
    useDataOptions,
    handleInputChange,
    userVal,
  } = useAddTutors(closeModal, refetch);
  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search for a Zip Code...";

  return (
    <div className={`row`}>
      <Input
        label={"First Name"}
        type="text"
        id="first_name"
        name="first_name"
        className={`pro-input lg ${getFieldError("first_name") && " error"}`}
        {...formik.getFieldProps("first_name")}
        error={getFieldError("first_name")}
        errorMessage={getFieldError("first_name")}
      />
      <Input
        label={"Last Name"}
        type="text"
        id="last_name"
        name="last_name"
        className={`pro-input lg ${getFieldError("last_name") && " error"}`}
        {...formik.getFieldProps("last_name")}
        error={getFieldError("last_name")}
        errorMessage={getFieldError("last_name")}
      />
      <Input
        label={"Email"}
        type="text"
        id="email"
        disabled={true}
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      <Input
        label={"Contact Number"}
        type="text"
        id="contact_number"
        disabled={true}
        name="contact_number"
        className={`pro-input lg ${
          getFieldError("contact_number") && " error"
        }`}
        {...formik.getFieldProps("contact_number")}
        error={getFieldError("contact_number")}
        errorMessage={getFieldError("contact_number")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Zip Code
        </label>
        <Select
          id="zip_code"
          name="zip_code"
          placeholder={"Select Zip Code"}
          className={`pro-input lg `}
          classNamePrefix="pro-input"
          noOptionsMessage={noOptionsMessage}
          isClearable={true}
          options={useDataOptions}
          value={
            userVal
          }
          onInputChange={(value) => handleInputChange(value)}
          onBlur={formik.handleBlur("zip_code")}
          onChange={(e) => formik.setFieldValue("zip_code", e?.value || null)}
          menuPlacement="auto"
        />
        {getFieldError("zip_code") && (
          <span className="error-text">{getFieldError("zip_code")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Gender
        </label>
        <Select
          id="gender"
          name="gender"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("gender") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData ?? []}
          value={selectVal}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          onBlur={formik.handleBlur("gender")}
          onChange={(e) => formik.setFieldValue("gender", e?.value)}
          menuPlacement="auto"
        />
        {getFieldError("gender") && (
          <span className="error-text">{getFieldError("gender")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${
              formik.errors.image && formik.touched.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleProfileImage(e)}
          />

          <span className="input-drag-box">
            <IconText
              title={
                imageName !== "" ? imageName : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={``}>
          <Image
            width={100}
            height={100}
            src={imagePreview}
            alt={`image - 01`}
          />
        </div>
      </div>
      <span className="pro-font-sm ">Max upload file size 5MB</span>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={closeModal}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddTutors;
