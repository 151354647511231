import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import {
  useGetStudentsBasicDataQuery,
  useUpdateStudentStatusMutation,
} from "../../../store/queries/Student";
import {
  getEditStudentData,
  getEducationLevelData,
  updateConfig,
} from "../../../store/slices/Student/Students/studentsSlice";
import { getIsSafeValue } from "../Students/api";

const useStudentDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const ProfileState = useSelector((state) => state.students);
  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});
  const [updateStudentStatus] = useUpdateStudentStatusMutation();

  const navigation = [
    {
      label: "Overview",
      title: "Overview",
      link: `/students-profile/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/students-profile/${activeProfile}`, true),
    },
    {
      label: "Classes",
      title: "Classes",
      link: `/students-profile/myclasses/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/myclasses/${activeProfile}`),
    },
    {
      label: `Course Purchases`,
      title: "Course Purchases",
      link: `/students-profile/transactions/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/transactions/${activeProfile}`),
    },
    {
      label: `Inquiry`,
      title: "Inquiry",
      link: `/students-profile/enquiry/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/enquiry/${activeProfile}`),
    },
    {
      label: "Report",
      title: "Report",
      link: `/students-profile/report/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/report/${activeProfile}`),
    },
  ];

  const navigate = useNavigate();

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetStudentsBasicDataQuery(activeProfile);

  // const hasUpdatePermission = useMemo(() => {
  //   let permission = profileData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [profileData]);

  useEffect(() => {
    if (profileData?.statusCode === 400 || profileData === "") {
        toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [profileData, activeProfile]);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const label = {
    gender: "Gender",
    email: "Email",
    education_level: "Education Level",
    location: "Location",
  };

  const value = {
    gender: `${profileData?.data?.student_detail?.gender ?? "Not specified"}`,
    email: `${profileData?.data?.student_detail?.email ?? "Not specified"}`,
    education_level: `${
      profileData?.data?.student_detail?.education_level ?? "Not specified"
    }`,
    location: `${
      profileData?.data?.student_detail?.location ?? "Not specified"
    }`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = true;
        state.isEdit = true;
        state.selectedID = profileData?.data?.student_detail?._id;
      })
    );
    dispatch(getEducationLevelData());
    dispatch(getEditStudentData(profileData?.data?.student_detail?._id));
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddModal = false;
        state.isEdit = false;
        state.selectedID = {};
        state.imagePreview = "";
        state.imageName = "";
        state.studentData = [];
        state.educationLevel = [];
      })
    );
  };

  const handleChangeStatus = (status, id) => {
    setObj({
      status: status,
      id: id,
    });
    getIsSafeValue(id).then((res) => {
      if (res?.data?.statusCode === 200) {
        setPopUpMessage(res?.data?.message);
        handleDeleteModal();
      }
    });
  };

  useEffect(() => {
    if (statusUpdate) {
      updateStudentStatus(obj?.id).then((res) => {
        if (res?.data?.statusCode === 200) {
          toast.success(res?.data?.message);
          refetch();
        } else {
          toast.error(res?.data?.message);
        }
      });
      handleDeleteModal();
    }
  }, [statusUpdate === true]);

  const handleDeleteModal = () => {
    setStatusUpdate(false);
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };
  return {
    activeProfile,
    isFetching,
    printRef,
    closeModal,
    profileData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    handleChangeStatus,
    setStatusUpdate,
    popUpMessage,
    handleDeleteModal,
  };
};

export default useStudentDetailsLayout;
