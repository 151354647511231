import React from "react";
import {
  Actions,
  Header,
  ModalLayout,
  NavGroup,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  OffCanvasLayout,
  ConfirmationBox,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useStudentDetailsLayout from "./useStudentDetailsLayout";
import profileStyle from "./studentsDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";

// import StudentsForm from "../StudentsListing/StudentsForm";
import Assets from "../../../assets/Assets";
import HeaderActions from "../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../pages/Layouts/CommonLayout/useCommonLayout";
// import AddStudents from "../Students/AddStudents";
// import CourseAssign from "../CourseAssign";

const StudentDetailsLayout = () => {
  const {
    activeProfile,
    isFetching,
    closeModal,
    profileData,
    basicDetails,
    ProfileState,
    // hasUpdatePermission,
    refetch,
    handleEditClick,
    navigation,
    handleChangeStatus,
    setStatusUpdate,
    popUpMessage,
    handleDeleteModal,
  } = useStudentDetailsLayout();

  const { navigations, globalState } = useCommonLayout();
  const createLabel = (label) => {
    return label?.split("_").map((word) => word?.charAt(0).toUpperCase() + word?.slice(1)).join(" ");
  };

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-4 pro-pt-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            profileData?.data?.student_detail?.image ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${
                          profileData?.data?.student_detail?.full_name ?? ""
                        }`}
                        designation={`${
                          profileData?.data?.student_detail?.stream?.name ?? ""
                        }`}
                        // uId={profileData?.data?.student_detail?._id ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${profileStyle.profile_name_wrap}`}
                      />
                    </div>

                    <div className="pro-d-flex pro-justify-between pro-w-100 pro-mb-2 ">
                      {profileData?.data?.student_detail?.is_active ? (
                        <Image
                          src={Assets.VERIFY}
                          width={69}
                          height={22}
                          alt={"active"}
                          propStyle={{ root: profileStyle.img_root }}
                        />
                      ) : (
                        <div className="pro-w-100 ">
                          <p className="pro-fw-medium pro-mb-0">
                            Disable Reason :
                            <span className="pro-fw-bolder">
                              {profileData?.data?.student_detail?.disable_reason
                                ? ` ${createLabel(
                                    profileData?.data?.tutorDetails
                                      ?.disable_reason
                                  )}`
                                : " Inactive"}
                            </span>
                          </p>
                        </div>
                      )}

                      <div className="form-check form-switch ">
                        <input
                          className="form-check-input cursor-pointer"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={profileData?.data?.student_detail?.is_active}
                          onChange={(e) =>
                            handleChangeStatus(
                              profileData?.data?.student_detail?.is_active,
                              profileData?.data?.student_detail?._id
                            )
                          }
                        />
                      </div>
                    </div>
                    {/* {profileData?.data?.student_detail?.is_profile_completed ? (
                      <p>Profile Verified</p>
                    ) : (
                      <p>Profile Not Verified</p>
                    )} */}
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                        editIcon={
                          profileData?.data?.permission?.edit_permission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={
                          profileData?.data?.permission?.edit_permission
                            ? handleEditClick
                            : null
                        }
                      />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          {/* <OffCanvasLayout
            show={ProfileState?.showAddModal}
            handleClose={closeModal}
            title={`${"Update Student Basic Details"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
          >
            <AddStudents
              isStickyFooter
              refetch={refetch}
              closeModal={closeModal}
            />
          </OffCanvasLayout> */}

          <ModalLayout
            show={ProfileState?.showDeleteModal}
            handleClose={() => handleDeleteModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={popUpMessage}
                cancelText={"No"}
                cancelAction={handleDeleteModal}
                submitText={"Yes"}
                submitAction={() => setStatusUpdate(true)}
                isRight={true}
              />
            </div>
          </ModalLayout>

          {/* main container */}

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default StudentDetailsLayout;
