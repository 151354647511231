import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddFAQModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedFAQ: "",
  isEdit: false,
  faqData: [],
  clearSelection: false,
  showFilterModal: false,
  isFilter: false
};

const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = faqsSlice.actions;

export default faqsSlice.reducer;
