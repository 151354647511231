import { useFormik } from "formik";
import { useDispatch,useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/EducationalLevel/educationalLevelSlice";
// import { addBillingData, editBillingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useUpdateEducationalMutation, useUpdateCreateEducationalMutation } from "../../../store/queries/EducationalLevel";


const useAddEducationalLevel = ({ refetch }) => {
	const dispatch = useDispatch();
	const { EducationalLevelData, isEdit } = useSelector((state) => state.educational);
	const [updateCreateEducationalLevelData] = useUpdateCreateEducationalMutation();
	const [updateEducationalLevelData] = useUpdateEducationalMutation();
	const [educationalId, setEducationalId] = useState("");
	const [isChecked, setIsChecked] = useState(true);

	useEffect(() => {
		if (EducationalLevelData && isEdit) {
			Object.keys(EducationalLevelData || {}).forEach((key) => {
				if(key === "name") {
					formik?.setFieldValue("name", EducationalLevelData?.[key]);
				}
				else if(key === "is_active") {
					setIsChecked(EducationalLevelData?.[key]);
					formik?.setFieldValue("is_active", EducationalLevelData?.[key]);
				}
				else if(key === "_id") {
					setEducationalId(EducationalLevelData?.[key])
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [EducationalLevelData]);

	const validation = Yup.object({
		name: Yup.string()
		.min(3, "Name Must be atleast 3 Characters long")
		.required("Enter Educational Level Name"),
	});

	const formik = useFormik({
		initialValues: {
			name: "",
			is_active: null
		},
		validationSchema: validation,

		onSubmit: (values) => {
			let data = {
				name: values.name,
				is_active: isEdit ? values?.is_active : "",
			};
			

			if(isEdit) {
				updateEducationalLevelData({educationalId, data}).then((response) => {
					if (response?.data?.statusCode === 200) {
						handleCloseModal();
							toast.success("Educational Level Details Updated");
						refetch();
						dispatch(
							updateConfig((state) => {
							  state.clearSelection = true;
							})
						  );
					} else if (response?.error?.data?.statusCode === 422) {
						// displays backend errors
						const errors = response?.error?.data?.errors[0]?.message;
						formik.setErrors(errors);
						toast.error(errors);
					} else {
						toast.error("Something went wrong");
					}
				});
			}
			else {
				updateCreateEducationalLevelData(data).then((response) => {
					if (response?.data?.statusCode === 200) {
						handleCloseModal();
							toast.success("New Educational Level Created");
						refetch();
					} else if (response?.error?.data?.statusCode === 422) {
						// displays backend errors
						const errors = response?.error?.data?.errors[0]?.message;
						formik.setErrors(errors);
						toast.error(errors);
					} else {
						toast.error("Something went wrong");
					}
				});
			}
			
		}
	});

	const getFieldError = (fieldName) => {
		if (formik.touched[fieldName] && formik.errors[fieldName]) {
			return formik.errors[fieldName];
		}
		return "";
	};

	const handleCloseModal = () => {
		dispatch(
			updateConfig((state) => {
				state.showAddEducationalLevelModal = false;
			})
		);
	};

	const handleChecked = (e, id) => {
		const { checked } = e.target;
		setIsChecked(checked);
		formik.setFieldValue(id, checked);
	};
	return {
		formik,
		EducationalLevelData,
		// basicData: basicData?.data,
		serviceItems: EducationalLevelData?.serviceItems,
		getFieldError,
		handleCloseModal,
		handleChecked,
		isChecked,
		isEdit
	};
};

export default useAddEducationalLevel;
