import React from "react";
import Style from "./avilabilityCalender.module.scss";

const useAvailabilityCalender = (data) => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const datas = data || {};
  const hours = [
    "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
    "12:00 AM",
  ];

  const dayHasNoAvailableSlots = (day) =>
    !datas[day] || datas[day].length === 0;

  const getTimeSlotsForDayAndHour = (day, hourIndex) => {
    return (
      datas[day]?.filter((slot) => slotContainsHour(slot, hourIndex)) || []
    );
  };

  const slotContainsHour = (slot, hourIndex) => {
    const startTime = parseTimeTo24H(slot.start);
    const endTime = parseTimeTo24H(slot.end);

    return (
      (hourIndex >= startTime && hourIndex < endTime) || startTime == endTime || (hourIndex >= startTime && endTime == 0)
    );
  };
  

  const parseTimeTo24H = (time) => {
    const [hour, minutes, meridian] = time
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    let hour24 = parseInt(hour);

    if (meridian === "PM" && hour24 !== 12) {
      hour24 += 12;
    }

    if (meridian === "AM" && hour24 === 12) {
      hour24 = 0;
    }

    return hour24;
  };

  const renderTimeSlots = (day, hourIndex) => {
    const slots = getTimeSlotsForDayAndHour(day, hourIndex);

    if (slots.length > 0) {
      let colSpan = 1;

      if (hourIndex > 0) {
        const prevSlots = getTimeSlotsForDayAndHour(day, hourIndex - 1);
        if (
          prevSlots.length > 0 &&
          JSON.stringify(prevSlots) === JSON.stringify(slots)
        ) {
          return null;
        }
      }

      return slots.map((slot, index) => {
        const startTime = parseTimeTo24H(slot.start);
        const endTime = parseTimeTo24H(slot.end);
        // colSpan = endTime - startTime;
        if(endTime == startTime){
          colSpan = 0;
        }
        else if(endTime == 0){
          colSpan = 24 - startTime;
        }
        else{
          colSpan = endTime - startTime;
        }

        return startTime === endTime ? (
          <td
            className={Style.available}
            colSpan={hours.length - 1}
            align="center"
          >
            <span>12:00 - 12:00</span>
          </td>
        ) : (
          <td key={hourIndex} colSpan={colSpan} className={Style.available}>
            {colSpan > 2 ? `${slot.start} - ${slot.end}` : `${colSpan}hr`}
            {index < slots.length - 1 && <br />}
          </td>
        );
      });
    } else {
      return <td key={hourIndex} className={Style.yellow}></td>;
    }
  };

  const renderDayRow = (day, dayIndex) => (
    <tr key={day}>
      <td className={Style.day}>{day}</td>
      {dayHasNoAvailableSlots(day) ? (
        <td colSpan={hours.length - 1} className={Style.not_available}>
          Unavailable
        </td>
      ) : (
        hours.map((hour, hourIndex) => renderTimeSlots(day, hourIndex))
      )}
    </tr>
  );

  const renderHourHeaderRow = () => (
    <tr>
      <th></th>
      {hours.map((hour, index) => (
        <td key={index} className={`pro-text-center ${Style.time}`}>
          <span>{hour}</span>
        </td>
      ))}
    </tr>
  );
  return {
    renderHourHeaderRow,
    dayHasNoAvailableSlots,
    renderDayRow,
    hours,
    days,

    getTimeSlotsForDayAndHour,
  };
};

export default useAvailabilityCalender;
