import { getAxiosInstance } from "../../../api";

export const getAutoCompleteUserData = async (param) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/api/testimonials/users-search?name=${param.name}&user_type=${param.type}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const createTestimonial = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`admin/api/testimonials`,params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateTestimonial = async (formData,id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put(`admin/api/testimonials/${id}`,formData);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const deleteTestimonial = async (id) => {
    const api = await getAxiosInstance();
  
    try {
      const response = await api.delete(`admin/api/testimonials/${id}`);
      return response;
    } catch (error) {
      return error?.response?.data;
    }
  };
