import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}filters=${params?.filter || ""}&${getParams("sort_by")}sort_order=${
            params?.sort_order || "desc"
          }&${getParams("place")}page_size=${
            params?.page_size || "10"
          }&${getParams("search")}page_no=${params?.page || 1}&status=${
            params?.status_type || ""
          }&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const student = createApi({
  reducerPath: "studentApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Student",
    "OverView",
    "MyClasses",
    "Enquiry",
    "Transaction",
    "Report",
  ],
  endpoints: (builder) => ({
    // branches starts
    getStudentsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/students`,
      }),
      providesTags: ["Student"],
    }),
    deleteStudent: builder.mutation({
      query: (id) => ({
        method: "delete",
        endpoint: `admin/api/students/${id}`,
      }),
      invalidatesTags: ["Student"],
    }),

    updateTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Student"],
    }),
    getStudentsBasicData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `admin/api/students/${id}`,
      }),
      providesTags: ["Student"],
    }),
    updateStudentStatus: builder.mutation({
      query: (id) => ({
        method: "put",
        endpoint: `admin/api/student-status/${id}`,
      }),
      invalidatesTags: ["Student"],
    }),

    // Over View Starts here
    getStudentOverViewData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/student-overview/${values.user_id}`,
      }),
      providesTags: ["OverView"],
    }),
    // Over view ends here

    // my classes Starts here
    getStudentsMyClassesData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/my-classes/${values.user_id}`,
      }),
      providesTags: ["MyClasses"],
    }),
    updateStudentMyClassTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MyClasses"],
    }),
    //my classes ends here
    // enquiry Starts here
    getEnquiryStudentData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/enquiry-list/${values.user_id}`,
      }),
      providesTags: ["Enquiry"],
    }),
    updateEnquiryStudentTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Enquiry"],
    }),
    //enquiry ends here
    // Transaction Starts here
    getTransactionStudentData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/student-transactions/${values.user_id}`,
      }),
      providesTags: ["Transaction"],
    }),
    updateTransactionStudentTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Transaction"],
    }),
    //Transaction ends here
    // Student report Starts here
    getReportStudentData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/student/reported-chats/${values.user_id}`,
      }),
      providesTags: ["Report"],
    }),
    updateReportStudentTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Report"],
    }),
    //Student report  ends here

    getBgVerificationList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/student/bg-verification`,
      }),
      providesTags: ["BgCheck"],
    }),
    updateBgVerificationTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["BgCheck"],
    }),
  }),

  // branches ends
});

export const {
  useGetStudentsListDataQuery,
  useDeleteStudentMutation,
  useGetStudentsBasicDataQuery,
  useUpdateTableListFieldsDataMutation,
  useGetStudentOverViewDataQuery,
  useGetStudentsMyClassesDataQuery,
  useUpdateStudentMyClassTableFieldsMutation,
  useGetEnquiryStudentDataQuery,
  useUpdateEnquiryStudentTableFieldsMutation,
  useGetTransactionStudentDataQuery,
  useUpdateTransactionStudentTableFieldsMutation,
  useGetReportStudentDataQuery,
  useUpdateReportStudentTableFieldsMutation,
  useUpdateStudentStatusMutation,
  useGetBgVerificationListQuery,
  useUpdateBgVerificationTableMutation,
} = student;
