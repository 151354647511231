import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api/index";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showAddTutorsModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedTutor: {},
  isEdit: false,
  tutorsData: [],
  isFilter: false,
  imagePreview: "",
  imageName: "",
  showFilterModal: false,
  tutorLocation: "",
  statusType: "",
  autoCompleteData: [],
  editLoading: false,
  editError: "",
  clearSelection: false
};

export const getEditTutorsData = createAsyncThunk(
  "/tutor/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/tutor/basic-details/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePremiumPlanById = createAsyncThunk(
  "/tutor/premium_plan_add",
  async ({body}, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.put(`admin/api/tutor/${body.tutor_id}/premium_plan_add/${body.id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteData = createAsyncThunk(
  "admin/api/tutor/delete",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`admin/api/tutor/delete/${params?.id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tutorsSlice = createSlice({
  name: "tutors",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditTutorsData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getEditTutorsData.fulfilled, (state, action) => {
        state.tutorsData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getEditTutorsData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError =
          "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = tutorsSlice.actions;

export default tutorsSlice.reducer;
