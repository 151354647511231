import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import Login from "../components/Auth/Login";
import ForgotPassword from "../components/Auth/ForgotPassword";
import ResetPassword from "../components/Auth/ResetPassword";
import ChangePassword from "../components/Auth/ChangePassword";
import Student from "../components/Student";
import Tutor from "../components/Tutor";
import Students from "../components/Student/Students";
import Premiumplans from "../components/PremiumPlans";
import Courses from "../components/Courses";
import FAQs from "../components/FAQs";
import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing";
import Tutors from "../components/Tutor/Tutors";
import Subjects from "../components/Subjects";
import EducationalLevel from "../components/EducationalLevel";
import Banners from "../components/Banners";
import AreaOfExpertise from "../components/AreaOfExpertise";
import CMS from "../components/CMS";
import TermsAndConditions from "../components/CMS/TermsAndConditions";
import Testimonials from "../components/CMS/Testimonials";
import PrivacyAndPolicy from "../components/CMS/PrivacyAndPolicy";
import ContactInfo from "../components/CMS/ContactInfo";
import StudentDetailsLayout from "../components/Student/StudentDetailsLayout";
import StudentDetails from "../components/Student/StudentDetails";
import Transactions from "../components/Student/Transactions";
import TutorDetails from "../components/Tutor/TutorDetails";
import TutorDetailsLayout from "../components/Tutor/TutorDetailsLayout";
import MyClasses from "../components/Tutor/MyClasses";
import MySubjects from "../components/Tutor/MySubjects";
import Payouts from "../components/Tutor/Payouts";
import TutorStudents from "../components/Tutor/Students";
import Reports from "../components/Reports";
import ReportedLists from "../components/Reports/ReportedLists";
import Payment from "../components/Payment";
import TransactionList from "../components/Payment/TransactionList/index";
import RefundList from "../components/Payment/RefundList/index";
import SubjectList from "../components/Subjects/SubjectList";
import SpecializationList from "../components/Subjects/SpecializationList";
import TutorReports from "../components/Tutor/Report";
import EnquiryList from "../components/Tutor/EnquiryList";
import Scheduled from "../components/Tutor/Scheduled";
import TutorDocuments from "../components/Tutor/TutorDocuments";
import StudentMyclasses from "../components/Student/StudentMyclasses";
import EnquiryStudent from "../components/Student/EnquiryStudent";
import StudentReport from "../components/Student/StudentReport";
import TutorBackgroundVerification from "../components/Tutor/TutorBackgroundVerification";
import TutorTaxForm from "../components/Tutor/TutorTaxForm";
import AllTutorDocuments from "../components/Tutor/AllTutorDocuments";
import PremiumPlanPurchase from "../components/Payment/PremiumPlanPurchase";
import Payout from "../components/Payment/Payouts";
import BgVerificationPayment from "../components/Payment/BgVerification";
import OurClients from "../components/CMS/OurClients";
import More from "../components/CMS/More";
import BgCheck from "../components/Student/BgCheck";
import AboutUs from "../components/CMS/AboutUs";
import Blog from "../components/CMS/Blog";
import Coupons from "../components/Coupon";
import CouponLists from "../components/Coupon/CouponLists";
import RefundSettlement from "../components/Payment/RefundSettlement";
import PayoutsSettlement from "../components/Payment/PayoutsSettlment";
import PayoutList from "../components/Tutor/PayoutList";
import CouponRedeemList from "../components/Coupon/CouponRedeemList";
import Revenue from "../components/Payment/Revenue";
import Policies from "../components/CMS/Policies";
import Role from "../components/Roles";
import Staffs from "../components/Roles/Staffs";
import Roles from "../components/Roles/Role";
import ManageRoles from "../components/Roles/Role/ManageRoles";
import PremiumPlan from "../components/PremiumPlan";
import PremiumPlanLists from "../components/PremiumPlan/PremiumPlanLists";
import InactiveStudents from "../components/Student/InactiveStudents";
import TutorReviews from "../components/TutorReviews";
import InactiveTutors from "../components/Tutor/InactiveTutors";
import OnboardingTutors from "../components/Tutor/OnboardingTutors";
import LocationSearchMeta from "../components/CMS/LocationSearchMeta";
import DeletedTutors from "../components/Tutor/DeletedTutors";
import DeletedStudents from "../components/Student/DeletedStudents";
import DynamicMeta from "../components/CMS/DynmaicMeta";

const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />}></Route>
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route path="forgot-password" element={<ForgotPassword />}></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
          <Route path="change-password" element={<ChangePassword />}></Route>
        </Route>
        {/* <Route path="/master" element={<Master />}>
          <Route path="branches" element={<Branches />}></Route>
        </Route> */}
        <Route path="/student" element={<Student />}>
          <Route path="" element={<Students />}></Route>
          <Route path="active-students" element={<Students />}></Route>
          <Route
            path="inactive-students"
            element={<InactiveStudents />}
          ></Route>
          <Route
            path="deleted-students"
            element={<DeletedStudents />}
          ></Route>
          <Route path="bg-check" element={<BgCheck />}></Route>
        </Route>
        <Route path="students-profile" element={<StudentDetailsLayout />}>
          <Route path="" element={<StudentDetails />}></Route>
          <Route path=":studentID" element={<StudentDetails />}></Route>
          <Route
            path="myclasses/:studentID"
            element={<StudentMyclasses />}
          ></Route>
          <Route
            path="transactions/:studentID"
            element={<Transactions />}
          ></Route>
          <Route path="enquiry/:studentID" element={<EnquiryStudent />}></Route>
          <Route path="report/:studentID" element={<StudentReport />}></Route>
        </Route>

        <Route path="/reported-chat" element={<Reports />}>
          <Route path="" element={<ReportedLists />} />
        </Route>
        <Route path="/role" element={<Role />}>
          <Route path="role-management" element={<Roles />} />
          <Route path="staffs" element={<Staffs />} />
        </Route>
        <Route path="permission" element={<ManageRoles />}></Route>
        <Route path="/coupon" element={<Coupons />}>
          <Route path="coupons" element={<CouponLists />} />
          <Route path="coupon-redeem" element={<CouponRedeemList />} />
        </Route>
        <Route path="/tutors" element={<Tutor />}>
          <Route path="active-tutors" element={<Tutors />}></Route>
          <Route path="inactive-tutors" element={<InactiveTutors />}></Route>
          <Route path="deleted-tutors" element={<DeletedTutors />}></Route>
          <Route path="tutor-reviews" element={<TutorReviews />}></Route>
          <Route
            path="onboarding-tutors"
            element={<OnboardingTutors />}
          ></Route>
          <Route
            path="bg-verification"
            element={<TutorBackgroundVerification />}
          ></Route>
          <Route path="tax-form" element={<TutorTaxForm />}></Route>
          <Route path="documents" element={<AllTutorDocuments />}></Route>
          <Route path="payouts-list" element={<PayoutList />}></Route>
        </Route>
        <Route path="payments" element={<Payment />}>
          <Route path="transaction-list" element={<TransactionList />}></Route>
          <Route path="refunds" element={<RefundList />}></Route>
          <Route path="revenue" element={<Revenue />}></Route>
          <Route path="premium-plan" element={<PremiumPlanLists />}>
            {/* <Route path="" element={<PremiumPlanLists />}></Route> */}
          </Route>
          <Route
            path="plans-purchase"
            element={<PremiumPlanPurchase />}
          ></Route>
          <Route path="payouts" element={<Payout />}></Route>
          <Route
            path="bg-verification"
            element={<BgVerificationPayment />}
          ></Route>
          <Route
            path="bg-verification"
            element={<BgVerificationPayment />}
          ></Route>
          <Route
            path="refund-settlement"
            element={<RefundSettlement />}
          ></Route>
          <Route
            path="payout-settlement"
            element={<PayoutsSettlement />}
          ></Route>
        </Route>
        <Route path="tutors-profile" element={<TutorDetailsLayout />}>
          <Route path="" element={<TutorDetails />}></Route>
          <Route path=":tutorID" element={<TutorDetails />}></Route>
          <Route path="my-classes/:tutorID" element={<MyClasses />}></Route>
          <Route path="enquiry/:tutorID" element={<EnquiryList />}></Route>
          <Route path="reports/:tutorID" element={<TutorReports />}></Route>
          <Route path="my-subjects/:tutorID" element={<MySubjects />}></Route>
          <Route path="students/:tutorID" element={<TutorStudents />}></Route>
          <Route path="schedules/:tutorID" element={<Scheduled />}></Route>
          <Route path="payouts/:tutorID" element={<Payouts />}></Route>
          <Route path="documents/:tutorID" element={<TutorDocuments />}></Route>
        </Route>

        <Route path="/subjects" element={<Subjects />}>
          <Route path="" element={<SubjectList />}></Route>
          <Route path=":subjectID" element={<SpecializationList />}></Route>
        </Route>
        <Route path="/courses" element={<Courses />}>
          {/* <Route path="dashboard" element={<Dashboard />}></Route> */}
          {/* <Route path="assessment-submission" element={<AssessmentSubmission />}></Route> */}
        </Route>

        {/* <Route path="/faqs" element={<FAQs />}></Route> */}

        <Route path="cms" element={<CMS />}>
          <Route path="about-us" element={<AboutUs />}></Route>
          <Route path="faqs" element={<FAQs />}></Route>
          <Route path="banners" element={<Banners />}></Route>
          <Route path="blog" element={<Blog />}></Route>
          <Route
            path="terms-and-conditions"
            element={<TermsAndConditions />}
          ></Route>
          <Route path="testimonials" element={<Testimonials />}></Route>
          <Route path="policies" element={<PrivacyAndPolicy />}></Route>
          <Route path="privacy-and-policy" element={<Policies />}></Route>
          <Route path="contactus" element={<ContactInfo />}></Route>
          <Route path="premium-plans" element={<Premiumplans />}></Route>
          <Route path="our-clients" element={<OurClients />}></Route>
          <Route path="app-settings" element={<More />}></Route>
          <Route path="dynamic-meta" element={<DynamicMeta />}></Route>
          <Route
            path="educational-level"
            element={<EducationalLevel />}
          ></Route>
          <Route path="location-search-meta" element={<LocationSearchMeta />} />
          <Route path="area-of-expertise" element={<AreaOfExpertise />}></Route>
        </Route>
        {/* <Route path="settings" element={<Settings />}>
          <Route path="roles" element={<Roles />}></Route>
        </Route> */}
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
