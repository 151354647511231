import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddEducationalLevelModal: false,
  showDeleteModal: false,
  selectedEducationalLevel: "",
  isEdit: false,
  EducationalLevelData: [],
  clearSelection: false,
};

const educationalLevelSlice = createSlice({
  name: "educational",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = educationalLevelSlice.actions;

export default educationalLevelSlice.reducer;
