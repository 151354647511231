import { useSelector } from "react-redux";
import { useGetContactUsListDataQuery } from "../../../store/queries/CMS" 
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/CMS/ContactInfo/contactInfoSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useContactUs = () => {


  const dispatch = useDispatch();

  const dataState = useSelector((state) => state.contact);
  const {
    data: dataList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetContactUsListDataQuery({
    page_size: dataState.currentPageSize,
    page: dataState.currentPage,
    sort_by: dataState.sortBy,
    sort_order: dataState.sortOrder,
    start: dataState.startDate,
    end: dataState.endDate,
    search: dataState.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = dataList?.data;
      })
    );
    // eslint-disable-next-line
  }, [dataList]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddDataModal = !state.showAddDataModal;
      })
    );
  };

  const handleEditClick = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddDataModal = true;
        state.selectedData = e;
      })
    );
  };
  return {
    
    isLoading,
    isFetching,
    handleModalClick,
    refetch,
    dataState,
    dataList,
    handleEditClick
  };
};

export default useContactUs;
