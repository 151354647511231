import { useSelector } from "react-redux";
import {
  useGetTaxFormDataQuery,
  useUpdateTaxFormStatusMutation,
  useUpdateTaxFormTableFieldsDataMutation,
} from "../../../store/queries/Tutor";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Tutor/TaxForm/taxFormSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Style from "./tutor.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import { toast } from "react-toastify";
import Select from "react-select";
import { useEffect } from "react";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useTutorTaxForm = ({ dashboard }) => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);
  const navigate = useNavigate();

  const { showEditModal } = useSelector((state) => state.global);
  const taxFormState = useSelector((state) => state.taxForm);
  const { isEdit, isFilter } = useSelector((state) => state.taxForm);

  const {
    data: taxFormList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetTaxFormDataQuery({
    page_size: dashboard ? "10" : taxFormState.currentPageSize,
    page: dashboard ? "1" : taxFormState.currentPage,
    sort_by: dashboard ? "" : taxFormState.sortBy,
    sort_order: dashboard ? "desc" : taxFormState.sortOrder,
    search: dashboard ? "" : taxFormState?.search,
    status_type: taxFormState?.statusType,
    start_date: taxFormState.startDate,
    end_date: taxFormState.endDate,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);
  const [updateTableFields] = useUpdateTaxFormTableFieldsDataMutation();
  const [updateStatus] = useUpdateTaxFormStatusMutation();

  const outputArray = taxFormList?.data?.tutor_form_status_values?.map(
    (item) => {
      return {
        value: item,
        label: item
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      };
    }
  );

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = !state.showAddTutorsModal;
        state.isEdit = false;
        state.selectedTutor = "";
        state.tutorsData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };


  const getRow = (feild, data) => {
    const rows = {
      revenue: (feild, data) => <p className="pro-mb-0">{"$" + data[feild]}</p>,
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      date: (field, data) => {
        // if (data?.date) {
        //   const dateObject = new Date(data?.date);

        //   // Format the date as 'YYYY-MM-DD'

        //   const formattedDate = dateObject.toISOString().split("T")[0];
        //   return <p className="pro-mb-0">{formattedDate}</p>;
        // }

        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      tutor_name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },
      status: (field, data) => {
        let selectVal = outputArray.find((item) => item.value === data[field]);
        return (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg`}
            classNamePrefix="pro-input"
            options={outputArray}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={selectVal}
            onChange={(selectedOption) => {
              const formData = {
                status: selectedOption?.value,
              };

              updateStatus({ body: formData, id: data?._id })
                .then((response) => {
                  if (response?.data?.success) {
                    toast.success("Status Updated Successfully!");
                    refetch?.();
                  } else if (!response?.data?.success) {
                    toast.error("Failed to Update Status!");
                  } else {
                    toast.error("Failed to Update Status!");
                  }
                })
                .catch(() => {
                  toast.error("Failed to Update Status!");
                });
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            isDisabled={
              data[field] === "SETTLED" ? true : dashboard ? true : false
            }
          />
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (taxFormState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = taxFormState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedTutor = "";
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/tutors/tax-form");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };
  return {
    taxFormState,
    showEditModal,
    taxFormList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: taxFormState.currentFilter,
    currentPage: taxFormState.currentPage,
    closeEditModal,
    updateTableFields,
    isEdit,
    isFilter,
    handleDashboardRedirect,
    handleFilterClick,
  };
};

export default useTutorTaxForm;
