import { getAxiosInstance } from "../../api";

export const getAutoCompleteData = async (searchVal) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/api/subjects/search/auto-complete?query=${searchVal}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};


export const deleteSpecialization = async (id) => {
  const api = await getAxiosInstance();

  try {
    const response = await api.delete(`admin/api/subjects/${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};