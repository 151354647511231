import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useReportedLists from "./useReportedLists";
import ReportedListFilter from "./ReportedListFilter";

const ReportedLists = () => {
  const {
    reportedState,
    showEditModal,
    reportedList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
    handleFilterClick,
  } = useReportedLists();
  return (
    <div>
      <HeadingGroup
        title={"Reported Users"}
        className={`pro-mb-4`}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: reportedState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>

        <Table
          multiSelect={false}
          data={reportedList?.data?.reported_chats || []}
          uniqueID={"_id"}
          fields={reportedList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={reportedState?.currentPageSize}
          assignable={false}
          deletable={reportedList?.data?.delete_permission}
          editable={reportedList?.data?.edit_permission}
          showCheckBox={false}
        />
        {reportedList?.data?.reported_chats?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              reportedList.data.filtered_count / reportedState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === reportedState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(reportedList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = reportedList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={reportedList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={reportedState?.showCreateModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <ReportedListFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
          />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default ReportedLists;
