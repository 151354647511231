import React from "react";
import usePayments from './usePayments';
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";

const Payment = () => {
  const { drawerMenu } = usePayments();
  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Payment;
