import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../../store/slices/Payment/Refund/RefundSlice";

const useRefundFilter = (handleFilterClick, activityFilter) => {
  const dispatch = useDispatch();
  const userData = [
    { value: "STUDENT", label: "STUDENT" },
    { value: "TUTOR", label: "TUTOR" },
  ];

  const [statusObjects, setStatusObjects] = useState([]);
  const {
    startDate,
    endDate,
    isFilter,
    statusType,
    subject,
    specialization,
    user_type,
  } = useSelector((state) => state.refund);
  const validationSchema = Yup.object({
    endDate: Yup.string().when(
      "startDate",
      (startDate, schema) =>
        startDate &&
        schema
          .test({
            test: function (value) {
              const { startDate } = this.parent;
              if (!startDate) {
                // If start date is not provided, no need for validation
                return true;
              }
              // Check if end date is provided
              return !!value;
            },
            message: "Select End Date",
          })
          .test({
            test: function (value) {
              const { startDate } = this.parent;
              if (!startDate) {
                // If start date is not provided, no need for validation
                return true;
              }
              // Check if end date is greater than or equal to start date
              const startDateObj = new Date(startDate);
              const endDateObj = new Date(value);
              return endDateObj >= startDateObj; // Changed operator to allow equality
            },
            message: "End date must be greater than or equal to start date", // Adjusted message
          })
    ),
  });

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      statusType: "",
      subject: "",
      specialization: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      dispatch(
        updateConfig((state) => {
          Object.assign(state, subData);
        })
      );
      handleFilterClick();
    },
  });
  const clearFilter = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        Object.assign(state, formik.initialValues);
        state.isFilter = false;
      })
    );
    handleFilterClick();
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  let selectVal = statusObjects.find(
    (item) => item.value === formik.values.statusType
  );
  let userVal = userData.find((item) => item.value === formik.values.user_type);
  useEffect(() => {
    if (isFilter) {
      formik.setValues({
        startDate: startDate || "",
        endDate: endDate || "",
        statusType: statusType || "",
        subject: subject || "",
        specialization: specialization || "",
      });
    }
  }, [isFilter]);

  useEffect(() => {
    if (activityFilter) {
      const newStatusObjects = activityFilter.map((status) => {
        return { value: status, label: status.replace(/_/g, " ") };
      });
      setStatusObjects(newStatusObjects);
    }
  }, [activityFilter]);
  return {
    formik,
    selectVal,
    statusObjects,
    getFieldError,
    clearFilter,
    userVal,
    userData,
  };
};

export default useRefundFilter;
