import { getAxiosInstance } from "../../api";

export const getCourseData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/api/scheduled-class/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getAutoCompleteData = async (searchVal) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/api/subjects/search/auto-complete?query=${searchVal}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
