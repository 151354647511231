import { useSelector } from "react-redux";
import { useGetPoliciesListDataQuery } from "../../../store/queries/CMS" 
import { updateConfig } from "../../../store/slices/CMS/Policies/policiesSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

const usePolicies = () => {

  const dispatch = useDispatch();

  const dataState = useSelector((state) => state.policies);
  const {
    data: dataList = {},
    refetch,
    isError
  } = useGetPoliciesListDataQuery({
    page_size: dataState.currentPageSize,
    page: dataState.currentPage,
    sort_by: dataState.sortBy,
    sort_order: dataState.sortOrder,
    start: dataState.startDate,
    end: dataState.endDate,
    search: dataState.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = true;
        state.is_edit = true;
        state.selectedItemsDetails = dataList?.data ?? "";
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = false;
        state.is_edit = false;
      })
    );
  }; 
  return {
    refetch,
    dataState,
    dataList,
    handleEditClick,
    closeModal
  };
};

export default usePolicies;
