import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useTutorBackgroundVerification from "./useTutorBackgroundVerification";
import TutorBgVerificationFilter from "./TutorBgVerificationFilter";
import BgVerificationDetails from "./BgVerificationDetails";
import Style from './tutorBgVerification.module.scss';

const TutorBackgroundVerification = () => {
  const {
    bgVerificationState,
    showEditModal,
    bgVerificationList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
    isEdit,
    isFilter,
    closeModal
  } = useTutorBackgroundVerification();
  return (
    <div>
      <HeadingGroup
        title={"Background Verification"}
        className={`pro-mb-4`}
        buttonTitle={bgVerificationList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: bgVerificationState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>

        <div className={`pro-pt-3 pro-pb-5 `}>
          {bgVerificationList?.data?.bg_verifications_list?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={bgVerificationList?.data?.bg_verifications_list || []}
              uniqueID={"_id"}
              fields={bgVerificationList?.data?.fields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={bgVerificationState?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              //   handleDelete={handleDeleteModal}
              showCheckBox={false}
              deletable={bgVerificationList?.data?.delete_permission}
              editable={bgVerificationList?.data?.edit_permission}
            />
          )}
        </div>
        {bgVerificationList?.data?.bg_verifications_list?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              bgVerificationList.data.filtered_count /
                bgVerificationState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === bgVerificationState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(
                bgVerificationList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] =
                  bgVerificationList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={bgVerificationList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={bgVerificationState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <TutorBgVerificationFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
            activityFilter={bgVerificationList?.data?.bg_verification_statuses}
          />
        </OffCanvasLayout>

        <ModalLayout
						centered={false}
						show={bgVerificationState.showDetailsModal}
						handleClose={() => closeModal()}
						title={"Background Verification Details"}
						closeIcon={<span className="material-symbols-outlined">close</span>}
						backdrop="static"
						propStyle={{ root: Style.modal_root }}
					>
						<BgVerificationDetails closeModal={() => closeModal()} refetch={refetch}/>
					</ModalLayout>
      </div>
    </div>
  );
};

export default TutorBackgroundVerification;
