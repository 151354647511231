import { getAxiosInstance } from "../../../api";

export const getAutoLocationData = async (searchVal) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/api/tutor-zipcode?query=${searchVal}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getIsSafeValue = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/api/student/${id}/is-safe-to-disable`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateStudentBasic = async (formData,id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`admin/api/students/${id}`,formData);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
