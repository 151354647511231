import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddModal: false,
  showDeleteModal: false,
  selectedData: "",
  isEdit: false,
  editTestimonialData: [],
  clearSelection: false,
  imagePreview: "",
  imageName: "",
  userData: [],
  selectedUserId: {},
  editLoading : false,
  editError:""

};

export const getEditTestimonialData = createAsyncThunk(
  "/testimonial/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/testimonials/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const testimonialsSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditTestimonialData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getEditTestimonialData.fulfilled, (state, action) => {
        state.editTestimonialData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getEditTestimonialData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = testimonialsSlice.actions;

export default testimonialsSlice.reducer;
