import React from "react";
import useContactUs from "./useContactInfo";
import { Link } from "react-router-dom";
import { OffCanvasLayout } from "@wac-ui-dashboard/wac_component_library";
import AddContactInfo from "./AddContactInfo";
import Style from "./contactUs.module.scss";
import parse from "html-react-parser";

function ContactInfo() {
  const { handleModalClick, refetch, dataState, dataList, handleEditClick } =
    useContactUs();

  return (
    <div className={Style.contact_wrapper}>
      <div className="pro-d-flex  pro-mb-3  pro-items-center">
        <h3 className="pro-ttl h3 pro-mb-0">{dataList?.data?.contactdetails?.contact_banner?.title}</h3>
        {dataList?.data?.edit_permission && (
        <button className={Style.btn_edit}  onClick={() =>
            handleEditClick(dataList?.data?.contactdetails?.contact_banner)
          }>
          <span
            className="material-symbols-outlined cursor-pointer x2"
          
          >
            edit_square
          </span>
        </button>
        )}
      </div>
      <p>{parse(dataList?.data?.contactdetails?.contact_banner?.description ? dataList?.data?.contactdetails?.contact_banner?.description : "")}</p>
      <div className="pro-d-flex  pro-mt-3 pro-mb-4  pro-items-center">
        <h5 className="pro-mb-0 pro-ttl h5">{dataList?.data?.contactdetails?.contact_information?.title}</h5>
        {dataList?.data?.edit_permission && (
          <button className={Style.btn_edit}  onClick={() =>
              handleEditClick(dataList?.data?.contactdetails?.contact_information)
            }>
          <span
            className="material-symbols-outlined cursor-pointer x2 x2"
            
          >
            edit_square
          </span>
        </button>
        )}
        
      </div>
      <div>
        {dataList?.data?.contactdetails?.contact_information?.content.map(
          (item, index) => (
            <p key={index}>
              {item.title} {parse(item?.description ? `: ${item?.description}` : "")}
              {item?.links?.length > 0 &&
                item?.links?.map((linkItem) => (
                  <div className="pro-mt-1">
                    <Link to={linkItem?.url}> {linkItem.text}</Link>
                  </div>
                ))}
            </p>
          )
        )}
      </div>
      <div className="pro-d-flex pro-mt-3 pro-mb-4  pro-items-center">
        <h5 className="pro-mb-0 pro-ttl h5">{dataList?.data?.contactdetails?.email_contacts?.title}</h5>
        {dataList?.data?.edit_permission && (
        <button className={Style.btn_edit}  onClick={() =>
              handleEditClick(dataList?.data?.contactdetails?.email_contacts)
            } >
          <span
            className="material-symbols-outlined cursor-pointer x2"
            
          >
            edit_square 
          </span>
        </button>
        )}
      </div>
      <ul>
        {dataList?.data?.contactdetails?.email_contacts?.content.map(
          (item, index) => (
            <li key={index}>
              {item.title} : <span>{item.mail_text}</span>,{" "}
              <span>{parse(item.description ? item.description : "")}</span>
            </li>
          )
        )}
      </ul>
      <div className="pro-d-flex pro-mt-3 pro-mb-4  pro-items-center">
        <h5 className="pro-mb-0 pro-ttl h5">{dataList?.data?.contactdetails?.support_section?.title}</h5>
        {dataList?.data?.edit_permission && (
        <button className={Style.btn_edit} onClick={() =>
              handleEditClick(dataList?.data?.contactdetails?.support_section)
            }>
          <span
            className="material-symbols-outlined cursor-pointer x2"
            
          >
            edit_square
          </span>
        </button>
        )}
      </div>
      <p>{parse(dataList?.data?.contactdetails?.support_section?.description ? dataList?.data?.contactdetails?.support_section?.description : "")}</p>
      <p>{parse(dataList?.data?.contactdetails?.support_section?.description2 ? dataList?.data?.contactdetails?.support_section?.description2 : "")}</p>

      <OffCanvasLayout
        show={dataState?.showAddDataModal}
        handleClose={handleModalClick}
        title={`Update ${dataState?.selectedData?.title}`}
        closeIcon={<span className="material-symbols-outlined cursor-pointer x2"> close </span>}
      >
        <AddContactInfo refetch={refetch} handleModalClick={handleModalClick} />
      </OffCanvasLayout>
    </div>
  );
}

export default ContactInfo;
