import React from "react";
import {
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../../locationSearch.module.scss";
import { Editor } from "react-draft-wysiwyg";
import FormStyle from "./addMetaForm.module.scss";

export const ItemField = ({ formik, itmIndex, fields, isEdit, handleContentChange }) => {
  const { handleRemoveFieldCS, handleImage, aboutUsState, handleDescriptionChange } = useItemField(
    fields,
    itmIndex,
    formik,
    isEdit,
  );

  return (
    <>
      <div className={Style.box_root}>
        {formik?.values?.content?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className="row gx-2">
          <div className="col-md-12">
            <Input
              type="text"
              id={`title`}
              name={`title`}
              label={`Title`}
              onBlur={formik.handleBlur(`content.${itmIndex}.title`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title &&
                " error"
              }`}
              {...formik.getFieldProps(`content.${itmIndex}.title`)}
              error={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
            />
          </div>
          {/* <div className="col-md-12">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
            <textarea
              type="text"
              id={`description`}
              name={`description`}
              label={`Description`}
              value={formik?.values?.content?.[itmIndex]?.description}
              onChange={(e) => {
                formik.setFieldTouched(`content.${itmIndex}.description`, true)
                formik.setFieldValue(`content.${itmIndex}.description`, e?.target?.value)
              }
              }
              onBlur={formik.handleBlur(`content.${itmIndex}.description`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description &&
                " error"
              }`}
              // {...formik.getFieldProps(`content.${itmIndex}.description`)}
            />
            {formik.touched?.content?.[itmIndex]?.description && formik.errors?.content?.[itmIndex]?.description && 
              (
                <span className="error-text">
                  {formik.errors?.content?.[itmIndex]?.description}
                </span>
              )}
          </div> */}
          <div className="col-md-12">
            <div className={FormStyle.editor_container}>
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
              <Editor
                wrapperClassName={FormStyle.pro_editor_wrapper}
                editorClassName={FormStyle.pro_editor_main}
                toolbarClassName={FormStyle.pro_editor_toolbar}
                editorState={formik.values?.content?.[itmIndex]?.description}
                readOnly={false}
                onEditorStateChange={(editorState) =>
                  handleContentChange(editorState, itmIndex)
                }
                // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
                toolbar={{
                  options: [
                    "blockType",
                    "inline",
                    "list",
                    "textAlign",
                    "fontSize",
                    "fontFamily",
                    "link"
                  ],
                  blockType: {
                    inDropdown: true,
                    className: FormStyle.dropdown_wrapper,
                    dropdownClassName: FormStyle.dropdown_menu,
                  },
                }}
              />

              {formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className={FormStyle.error_text}>
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemField;
