import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddDynamicMeta from "./useAddDynamicMeta";

const AddDynamicMeta = ({ refetch, handleModalClick }) => {
  const {
    formik,
    isEdit,
    editDynamicData
  } = useAddDynamicMeta(handleModalClick, refetch);
  
  return (
    <div className={`row`}>
      
      {editDynamicData?.map((setting) => (
            <div key={setting.serrvice_settings} className="input-wrap pro-mb-4">
              <label
                htmlFor={setting.serrvice_settings}
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                {setting.label}
              </label>
                  <textarea
                  rows={3}
                    id={setting.serrvice_settings}
                    type="text"
                    {...formik.getFieldProps(`${setting.serrvice_settings}`)}
                    className={`pro-input lg ${
                      formik?.touched[setting.serrvice_settings] &&
                      formik?.errors[setting.serrvice_settings] &&
                      "error"
                    }`}
                  />
                  {formik?.touched[setting.serrvice_settings] &&
                      formik?.errors[setting.serrvice_settings] && (
              <span className="error-text">{formik?.errors[setting.serrvice_settings]}</span>
            )}

            </div>
          ))}

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleModalClick}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default AddDynamicMeta;
