import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const usePremiumPlan = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Premium Plan",
      label: "Premium Plan",
      link: "/premium-plan",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">bar_chart_4_bars</span>
      ),
      active: checkIfActiveRoute("/premium-plan", true),
    },
  ];

  // const drawerMenuPermission = useMemo(() => {
  //   let menus = Object.values(
  //     globalState.dashboard_permissions?.permissions ?? []
  //   )?.flatMap?.((menu) =>
  //     menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
  //   );

  //   return drawerMenu
  //     ?.filter((menu) => {
  //       return menus?.includes?.(menu?.title);
  //     })
  //     ?.map((menu) => {
  //       return {
  //         ...menu,
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu};
  // return { drawerMenu };
};

export default usePremiumPlan;
