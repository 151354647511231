import { useDispatch, useSelector } from "react-redux";

const useBgVerificationDetails = ({ closeModal, refetch }) => {
  const { bgVerificationData } = useSelector(
    (state) => state.tutorBgVerification
  );
  const dispatch = useDispatch();

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      created_time: (field, data) => {
        const dateObject = new Date(data?.created_time);

        const options = {
          month: "short", // Short month name (e.g., Aug)
          day: "numeric",
          year: "numeric", // Day of the month (e.g., 18)
          hour: "numeric", // Hour (e.g., 5)
          minute: "numeric", // Minute (e.g., 30)
          hour12: true, // Use 12-hour format with AM/PM
        };

        const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
          dateObject
        );
        return <p className="pro-mb-0">{formattedDate}</p>;
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    bgVerificationData,
    getRow,
  };
};

export default useBgVerificationDetails;
