import { getAxiosInstance } from "../../../api";
import { createApi } from "@reduxjs/toolkit/query/react";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams(
            "search"
          )}page_no=${params?.page || 1}&status=${
            params?.status_type || ""
          }&timezone=${currentTimezone}`,
          body
        );

        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const reportedChat = createApi({
  reducerPath: "reportedChatApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ReportedChat"],
  endpoints: (builder) => ({
    getReportedList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/reported-chats`,
      }),
      providesTags: ["ReportedChat"],
    }),
    updateReportedListTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["ReportedChat"],
    }),
    updateReportStatus: builder.mutation({
      query: ({body, id}) => ({
        method: "post",
        body,
        endpoint: `admin/api/reported-chat/${id}`,
      }),

      invalidatesTags: ["ReportedChat"],
    }),
  }),
});

export const { useGetReportedListQuery, useUpdateReportedListTableMutation, useUpdateReportStatusMutation } =
  reportedChat;
