import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddContactInfo from "./useAddContactInfo";
import ItemField from "./ItemFieldClick";
import { Editor } from "react-draft-wysiwyg";
import Style from "../contactUs.module.scss";

const AddContactInfo = ({ refetch, handleModalClick }) => {
  const {
    formik,
    basicData,
    getFieldError,
    handleAddField,
    handleAddLink,
    handleContentChange,
    handleContentChangeDescription,
    handleContentChangeDescription2,
  } = useAddContactInfo(handleModalClick, refetch);

  return (
    <div className={`contact-form-wrapper`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Title
        </label>
        <Select
          id="title"
          name="title"
          placeholder={"Select Status"}
          className={`pro-input lg `}
          classNamePrefix="pro-input"
          options={basicData ?? []}
          value={basicData?.filter((p) => p?.label === formik?.values?.title)}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          onBlur={formik.handleBlur("title")}
          onChange={(e) => formik?.setFieldValue("title", e.label || null)}
          menuPlacement="auto"
        />
      </div>
      {formik?.values?.title !== "Contact Information" &&
        formik?.values?.title !== "Still not finding what you need?" && (
          <>
            <label className="pro-font-sm pro-mb-1 pro-fw-medium">Description 1</label>
            <Editor
              wrapperClassName={Style.pro_editor_wrapper}
              editorClassName={Style.pro_editor_main}
              toolbarClassName={Style.pro_editor_toolbar}
              editorState={formik.values?.description}
              readOnly={false}
              onEditorStateChange={handleContentChangeDescription}
              // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
              toolbar={{
                options: [
                  "blockType",
                  "inline",
                  "list",
                  "textAlign",
                  "fontSize",
                  "fontFamily",
                ],
                blockType: {
                  inDropdown: true,
                  className: Style.dropdown_wrapper,
                  dropdownClassName: Style.dropdown_menu,
                },
                fontSize: {
                  inDropdown: true,
                  className: Style.dropdown_wrapper,
                  dropdownClassName: Style.dropdown_menu,
                },
                fontFamily: {
                  inDropdown: true,
                  className: Style.dropdown_wrapper,
                  dropdownClassName: Style.dropdown_menu,
                },
              }}
            />
            {/* <Input
              label={"Description"}
              type="text"
              id="description"
              name="description"
              className={`pro-input lg `}
              {...formik.getFieldProps("description")}
            /> */}
          </>
        )}

      {formik?.values?.title === "Learner Support" && (
        <>
          <label className="pro-font-sm pro-mb-1 pro-fw-medium">Description 2</label>
          <Editor
            wrapperClassName={Style.pro_editor_wrapper}
            editorClassName={Style.pro_editor_main}
            toolbarClassName={Style.pro_editor_toolbar}
            editorState={formik.values?.description2}
            readOnly={false}
            onEditorStateChange={handleContentChangeDescription2}
            // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
            toolbar={{
              options: [
                "blockType",
                "inline",
                "list",
                "textAlign",
                "fontSize",
                "fontFamily",
              ],
              blockType: {
                inDropdown: true,
                className: Style.dropdown_wrapper,
                dropdownClassName: Style.dropdown_menu,
              },
            }}
          />
          {/* <Input
            label={"Description 2"}
            type="text"
            id="description2"
            name="description2"
            className={`pro-input lg `}
            {...formik.getFieldProps("description2")}
          /> */}
        </>
      )}
      {(formik?.values?.title === "Contact Information" ||
        formik?.values?.title === "Still not finding what you need?") && (
        <div className="col-12">
          {formik?.values?.content?.map((fields, index) => (
            <ItemField
              key={index}
              itmIndex={index}
              fields={fields}
              formik={formik}
              handleContentChange={handleContentChange}
              getFieldError={getFieldError}
              handleAddLink={handleAddLink}
            />
          ))}
          <Button
            className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
            // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
          >
            {` Add More`}
          </Button>
        </div>
      )}

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default AddContactInfo;
