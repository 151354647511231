import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  clearSelection: false,
  showCourseCreateModal: false,
  activeFilter: 1,
  queryData: {},
  courseSortBy: "",
  paymentSortBy: "",
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 5,
  showAddModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedStudent: "",
  isEdit: false,
  studentData: [],
};
export const courseAssign = createAsyncThunk(
  "v1/student/assign-course/",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/student/assign-course/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const studentsProfileSlice = createSlice({
  name: "studentsProfile",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = studentsProfileSlice.actions;

export default studentsProfileSlice.reducer;
