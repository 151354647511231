import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showAddModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedStudent: "",
  isEdit: false,
  editData: [],
  selectedID: {},
  isFilter: false,
  imagePreview: "",
  imageName: "",
  showFilterModal: false,
  studentLocation: "",
  statusType: "",
  autoCompleteData: [],
  educationLevel: [],
  editLoading: false,
  editError: "",
  clearSelection: false,
  selectedItemDetails: []
};

export const getAutoCompleteData = async (searchVal, type) => {
  const api = await getAxiosInstance();
  let fetchApi = "";
  if(type === "CITY") {
    fetchApi = `admin/api/location-search-meta/city/auto-complete?query=${searchVal}`;
  }
  else if(type === "STATE") {
    fetchApi = `admin/api/location-search-meta/state/auto-complete?query=${searchVal}`
  }
  else {
    fetchApi = `admin/api/location-search-meta/subject/auto-complete?query=${searchVal}`
  }
  try {
    const response = await api.get(fetchApi);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getEditMetaData = createAsyncThunk(
  "/meta/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/location-search-meta/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const locationSearchSlice = createSlice({
  name: "locationSearch",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditMetaData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getEditMetaData.fulfilled, (state, action) => {
        state.selectedItemDetails = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getEditMetaData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = locationSearchSlice.actions;

export default locationSearchSlice.reducer;
