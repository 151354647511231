import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetMySubjectsDataQuery,
  useUpdateMySubjectsTableFieldsMutation,
} from "../../../store/queries/Tutor";

import Style from "../Tutors/tutor.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Tutor/MySubject/mySubjectSlice";
import { toast } from "react-toastify";

const useMySubjects = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { tutorID } = useParams();
  const [updateTableFields] = useUpdateMySubjectsTableFieldsMutation();
  const activeProfile = tutorID ?? sessionStorage.getItem("active");
  const mySubjectsState = useSelector((state) => state.mySubject);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: mySubjectsList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetMySubjectsDataQuery({
    user_id: activeProfile,
    page_size: mySubjectsState?.currentPageSize,
    page: mySubjectsState?.currentPage,
    sort_by: mySubjectsState?.sortBy,
    sort_order: mySubjectsState?.sortOrder,
    search: mySubjectsState?.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (mySubjectsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            mySubjectsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      online_rate: (field, data) => {
        if (data.online_rate !== null && !isNaN(data.online_rate)) {
          return <>{`$${data.online_rate}`}</>;
        }
        return <>{data.online_rate}</>;
      },
      in_person_rate: (field, data) => {
        if (data.in_person_rate !== null && !isNaN(data.in_person_rate)) {
          return <>{`$${data.in_person_rate}`}</>;
        }
        return <>{data.in_person_rate}</>;
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    mySubjectsList,
    paginationOptions,
    mySubjectsState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    refetch,
    getRow,
    updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
  };
};
export default useMySubjects;
