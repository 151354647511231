import { useFormik } from "formik";
import { useEffect } from "react";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Subjects/Specialization/specializationSlice";
import {
  useUpdateCreateSubjectDataMutation,
  useUpdateSubjectsDataMutation,
} from "../../../../store/queries/Subjects";
import { getAutoCompleteData } from "../../api";
import { useMemo } from "react";
const useAddSpecialization = ( refetch, handleClose, parendtId) => {
  const dispatch = useDispatch();
  const {
    specializationData,
    isEdit,
    autoCompleteData,
    imageName,
    imagePreview,
    selectedId,
  } = useSelector((state) => state.specialization);

  const [createSpecialization] = useUpdateSubjectsDataMutation();
  const [updateSpecialization] = useUpdateCreateSubjectDataMutation();
  const storedData = JSON.parse(localStorage.getItem("subjectData"));

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        is_specialization: specializationData?.is_specialization,
        name: specializationData?.specialization || "",
        parent_id: specializationData?.parent_id || "",
      });

      dispatch(
        updateConfig((state) => {
          state.imagePreview = specializationData?.image || "";
          state.imageName = specializationData?.image_name?.split("/").pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, specializationData]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Specialization"),
    image: isEdit ? Yup.string() : Yup.string().required('Please upload an image'),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      is_specialization: "true",
      parent_id: parendtId,
      image: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {


      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });

      if (isEdit) {
        updateSpecialization({ selectedSubject: selectedId, formData }).then((response) => {
          if (response?.data?.statusCode === 200) {
            resetForm();
            handleClose();
            toast.success("Specialization Details Updated");
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors[0]?.message;
            formik.setErrors(errors);
            toast.error(errors);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        createSpecialization(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            resetForm();
            handleClose();
            toast.success("New Specialization Created");
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            Object.keys(errors).forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = file.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleInputChange = (item) => {
    if (item?.length >= 1) {
      getAutoCompleteData(item).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.autoCompleteData = response?.data?.data;
          })
        );
      });
    }
  };

  // useEffect(() => {
  //   handleInputChange(storedData.name)
  // }, [isEdit])

  const handleParentId = (val) => {
    formik.setFieldValue("parent_id", val?.value);
  };

  const useDataOptions = useMemo(
    () =>
      autoCompleteData?.map((opt) => {
        return { value: opt?._id, label: opt?.name, id: opt?._id };
      }),
    // eslint-disable-next-line
    [autoCompleteData]
  );
  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.parent_id
  );

  return {
    formik,
    specializationData,
    getFieldError,
    handleClose,
    handleProfileImage,
    imagePreview,
    imageName,
    isEdit,
    userVal,
    handleInputChange,
    handleParentId,
    useDataOptions,
    storedData,
  };
};

export default useAddSpecialization;
