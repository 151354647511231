import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import {
  updateConfig,
  createNewEntry,
  updateEntry,
} from "../../../../store/slices/Blog/blogSlice.js";

import { useGetBasicDataQuery } from "../../../../store/queries/blog";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useBlogForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const BASE_URL = process.env.REACT_APP_PRODUCTION === "FALSE" ? process.env.REACT_APP_APIURL : process.env.REACT_APP_QAURL;

  const profilefileInputRef = useRef(null);

  const { is_edit, selectedId, selectedItemsDetails } = useSelector(
    (state) => state.blog
  );

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image : ""
  );
  
  const { data: CategoryData = {}, isSuccess: isOptionDatas } =
    useGetBasicDataQuery({});

  const handleContentChange = (editorsState) => {
    formik.setFieldValue("content", editorsState);
  };

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.image ?? "");
    }
  }, [selectedItemsDetails]);

  const validation = Yup.object({
    title: Yup.string()
      .min(2, "The title must be at least 2 characters")
      .required("*Required"),
    category: Yup.string().required("*Required"),
    slug: Yup.string().required("*Required"),
    author: Yup.string().required("*Required"),
    image: is_edit ? Yup.string() : Yup.string().required("*Required"),
    meta_title: Yup.string().required("*Required"),
    meta_description: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      slug: selectedItemsDetails !== "" ? selectedItemsDetails?.slug : "",
      meta_title: selectedItemsDetails !== "" ? selectedItemsDetails?.meta_title : "",
      meta_description: selectedItemsDetails !== "" ? selectedItemsDetails?.meta_description : "",
      author: selectedItemsDetails !== "" ? selectedItemsDetails?.author : "",
      title: selectedItemsDetails !== "" ? selectedItemsDetails?.title : "",
      image_alt: selectedItemsDetails !== "" ? selectedItemsDetails?.image_alt : "",
      category:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.category?._id !== null
            ? selectedItemsDetails?.category?._id
            : ""
          : "",
      is_published:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.is_published !== null
            ? selectedItemsDetails?.is_published
            : 0
          : 0,
      image: "",
    },

    validationSchema: validation,
    enableReinitialize: true,

    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text
      const plainText = values.content.getCurrentContent().getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText.trim()) {
        errors.content = "*Description cannot be empty";
      }

      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      const contentState = values.content.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);

      let obj = {
        title: values.title,
        slug: values.slug ? values.slug : "",
        meta_title: values.meta_title ? values.meta_title : "",
        meta_description: values.meta_description ? values.meta_description : "",
        author: values.author ? values.author : "",
        meta_description: values.meta_description
          ? values.meta_description
          : "",
        category: values.category ? values.category : "",
        is_published: +values.is_published,
        content: plainText,
      };

      if (is_edit) {
        obj.id = selectedId;
      }

      if (values.image) {
        obj.image = values.image;
      }
      if(values?.image_alt) {
        obj.image_alt = values.image_alt
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (is_edit) {
        dispatch(updateEntry({ data: formData, id: selectedId })).then(
          (response) => {
            if (response?.payload?.success) {
              resetForm();
              refetch();
              closeModal?.();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              if (is_edit) {
                dispatch(
                  updateConfig((state) => {
                    state.clearSelection = true;
                  })
                );
              }
              toast.success(response?.payload?.message);
            } else if (!response?.payload?.success) {
              formik.setErrors(response?.payload?.message);
            } else toast.error(response?.payload?.message);
          }
        );
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            if (is_edit) {
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
            }
            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("image", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageCoverPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "image",
          "The image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  //   const defaultHtmlContent = selectedItemsDetails?.content;

  //   const sanitizedHtml = DOMPurify.sanitize(defaultHtmlContent);
  //   const contentState = ContentState.createFromBlockArray(
  // 	  convertFromHTML(sanitizedHtml)
  //   );
  //   const editorStates = EditorState.createWithContent(contentState);

  //   useEffect(() => {
  // 	  if (!formik.values.content) {
  // 		  formik.setFieldValue("content", editorStates);
  // 	  }
  //   }, [editorStates]);

  useEffect(() => {
    setTimeout(() => {
      const htmlContent = selectedItemsDetails?.content?.replace(/\\/g, "");
      const sanitizedHtml = DOMPurify.sanitize(htmlContent);

      // Convert HTML to ContentState using htmlToDraft
      const contentBlock = htmlToDraft(sanitizedHtml);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      // Create an EditorState with the converted ContentState
      const editorState = EditorState.createWithContent(contentState);
      formik.setFieldValue("content", editorState);
    }, 2000);
  }, [selectedItemsDetails?.content]);

  function uploadImageCallback(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        // "https://admin.logos-learning.webc.in/admin/api/get-blog-image-url"
        `${BASE_URL}admin/api/get-blog-image-url`
      );
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${localStorage.getItem("USER_ACCESS_TOKEN")}`
      );
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        setTimeout(() => {
          const uploadedImage = {
            data: {
              link: `${response?.data?.image_url}`,
            },
          };
          resolve(uploadedImage);
        }, 2000);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  return {
    formik,
    imageCoverPreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleCoverImage,
    handleCloseModal,
    CategoryData,
    handleContentChange,
    uploadImageCallback,
    // editorStates
  };
};

export default useBlogForm;
