import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modifier, EditorState } from 'draft-js';
import TableConfigModal from './TableConfigModal';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class CustomTableOption extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    editorState: PropTypes.object.isRequired,
  };

  state = {
    showModal: false,
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  addTable = ({ columns, rows }) => {
    const { editorState, onChange } = this.props;
    let tableHTML = '<table style="width:100%;border:1px solid black;">';
    for (let i = 0; i < rows; i++) {
      tableHTML += '<tr>';
      for (let j = 0; j < columns; j++) {
        tableHTML += i === 0 ? '<th>Header</th>' : '<td>Cell</td>';
      }
      tableHTML += '</tr>';
    }
    tableHTML += '</table>';

    const contentState = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      tableHTML,
      editorState.getCurrentInlineStyle()
    );

    const newEditorState = EditorState.push(
      editorState,
      contentState,
      'insert-characters'
    );

    onChange(newEditorState);
  };
  


  render() {
    return (
      <>
        <div onClick={this.openModal} style={{ cursor: 'pointer', padding: '8px' }}>
          Table
        </div>
        <TableConfigModal
          show={this.state.showModal}
          onHide={this.closeModal}
          onSubmit={this.addTable}
        />
      </>
    );
  }
}

export default CustomTableOption;
