import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Student/InactiveStudents/inactiveStudentsSlice";
import { useDispatch } from "react-redux";
import Style from "./students.module.scss";
import {
  useGetStudentsListDataQuery,
  useUpdateStudentStatusMutation,
  useUpdateTableListFieldsDataMutation,
} from "../../../store/queries/Student";
import { useNavigate } from "react-router-dom";
import Assets from "../../../assets/Assets";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { student } from "../../../store/queries/Student";
import { current } from "@reduxjs/toolkit";
import { getIsSafeValue } from "./api";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useDeletedStudents = ({ dashboard }) => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);

  const { showEditModal } = useSelector((state) => state.global);
  const studentsState = useSelector((state) => state.deletedStudents);
  const navigate = useNavigate();
  const [updateStudentStatus] = useUpdateStudentStatusMutation();
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});

  const {
    data: studentsList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetStudentsListDataQuery({
    page_size: dashboard ? "10" : studentsState.currentPageSize,
    page: dashboard ? "1" : studentsState.currentPage,
    sort_by: dashboard ? "" : studentsState.sortBy,
    sort_order: dashboard ? "desc" : studentsState.sortOrder,
    filter: "deleted",
    search: dashboard ? "" : studentsState?.search,
    // status_type: studentsState?.statusType,
    place: studentsState?.studentLocation,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,

      full_name: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.image !== "" && data?.image
                    ? data?.image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.full_name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },
      is_active: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.is_active}
              // onChange={(e) => handleChangeStatus(data?.is_active, data?._id)}
              disabled={true}
            />
          </div>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddStudentModal = true;
        state.selectedStudent = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.currentFilter = null;
        state.statusType = "";
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (studentsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = studentsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedStudent = "";
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/student/active-students");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  return {
    studentsState,
    showEditModal,
    studentsList,
    isLoading,
    isFetching,
    currentPage: studentsState.currentPage,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    closeEditModal,
    updateTableFields,
    // handleFilterClick,
    handleDashboardRedirect,
    setStatusUpdate,
    popUpMessage,
  };
};

export default useDeletedStudents;
