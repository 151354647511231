import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  useCreateRefundSettlementDataMutation,
} from "../../../../store/queries/Payment";

const useAddRefundSettlement = ({ refetch ,handleModalClick}) => {
  const dispatch = useDispatch();
  const { couponData, isEdit, selectedCoupon } =
    useSelector((state) => state.refundSettlement);
  const [updateCreateData] = useCreateRefundSettlementDataMutation();

  const validation = Yup.object({
    from_date: Yup.string().required("Required"),
    to_date: Yup.string().required("Required"),
  });


  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formik = useFormik({
    initialValues: {
      from_date: "",
      to_date: "",
      timeZone: currentTimezone
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {

      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });

        updateCreateData(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            toast.success(response?.data?.message);
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            Object.keys(errors).forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
    },
  });

  useEffect(() => {
    if (isEdit) {

      if(couponData?.from_date) {
        const dateObject = new Date(couponData?.to_date);

					// Format the date as 'YYYY-MM-DD'

					const formattedDate = dateObject.toISOString().split('T')[0];

          formik.setFieldValue("from_date", formattedDate)
					
      }
      if(couponData?.to_date) {
        const dateObject = new Date(couponData?.to_date);

					// Format the date as 'YYYY-MM-DD'

					const formattedDate = dateObject.toISOString().split('T')[0];

          formik.setFieldValue("to_date",formattedDate)	
      }
    }
    // eslint-disable-next-line
  }, [isEdit, couponData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  // let selectVal = outputArray.find(
  //   (item) => item.value === formik.values.type
  // );

  return {
    formik,
    couponData,
    getFieldError,
    isEdit,
    // outputArray,
    // selectVal
  };
};

export default useAddRefundSettlement;
