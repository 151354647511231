import React from "react";
import {
  Actions,
  Header,
  ModalLayout,
  NavGroup,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  OffCanvasLayout,
  ConfirmationBox,
  Image,
} from "@wac-ui-dashboard/wac_component_library";
import { ProfileCard } from "../../Global/ProfileCard";
import { Link, Outlet } from "react-router-dom";
import Style from "../../../pages/Layouts/CommonLayout/commonLayout.module.scss";
import useTutorDetailsLayout from "./useTutorDetailsLayout";
import profileStyle from "./tutorsDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import ProtectRoute from "../../../utils/components/ProtectRoute/ProtectRoute";

import Assets from "../../../assets/Assets";
import { SimpleImageSlider } from "../../Global/SimpleImageSlider";
import HeaderActions from "../../../pages/Layouts/CommonLayout/HeaderActions";
import useCommonLayout from "../../../pages/Layouts/CommonLayout/useCommonLayout";
// import CourseAssign from "../CourseAssign";
import { formatCustomFDate } from "../../../utils/functions/table";
import AddTutors from "../Tutors/AddTutors";
import ReactSelect from "react-select";

const TutorDetailsLayout = () => {
  const {
    tutorsState,
    activeProfile,
    closeModal,
    isFetching,
    profileData,
    basicDetails,
    handleEditClick,
    refetch,
    navigation,
    basicData,
    updatePremiumPlan,
    selectVal,
    handleChangeStatus,
    setStatusUpdate,
    popUpMessage,
    handleDeleteModal,
    onboardDetails
  } = useTutorDetailsLayout();

  const { navigations, globalState } = useCommonLayout();

  const createLabel = (label) => {
    return label?.split("_").map((word) => word?.charAt(0).toUpperCase() + word?.slice(1)).join(" ");
  };

  return (
    <ProtectRoute>
      <Header
        Link={Link}
        navigations={navigations}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.HEADERLOGO
            : Assets.HEADERLOGODARK
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper} pro-ps-0`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-4 pro-pt-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            profileData?.data?.tutorDetails?.image ??
                            Assets.GENDER_IMAGE
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${profileData?.data?.tutorDetails?.name ?? ""}`}
                        designation={`${
                          profileData?.data?.tutorDetails?.designation ?? ""
                        }`}
                        // uId={profileData?.data?.tutorDetails?._id ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${profileStyle.profile_name_wrap}`}
                      />
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    >
                      {/* {profileData?.data?.tutorDetails?.is_verified ? (
                        <p className="pro-mb-2">Profile Verified</p>
                      ) : (
                        <p className="pro-mb-2">Profile Not Verified</p>
                      )} */}
                      {profileData?.data?.tutorDetails?.highest_rated && (
                        <p className="pro-mb-1">Highest Rated</p>
                      )}
                      <div className="pro-w-100 pro-d-flex pro-justify-between pro-items-center pro-mb-2 ">
                        {profileData?.data?.tutorDetails?.is_active && (
                          <Image
                            src={Assets.VERIFY}
                            width={69}
                            height={22}
                            alt={"active"}
                            propStyle={{ root: profileStyle.img_root }}
                          />
                        )}
                        <div className="form-check form-switch ">
                          <input
                            className="form-check-input cursor-pointer"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={profileData?.data?.tutorDetails?.is_active}
                            // onChange={(e) => updateTutorStatus(profileData?.data?.tutorDetails?._id)}
                            onChange={(e) =>
                              handleChangeStatus(
                                profileData?.data?.tutorDetails?.is_active,
                                profileData?.data?.tutorDetails?._id
                              )
                            }
                          />
                        </div>
                      </div>
                      {profileData?.data?.tutorDetails?.is_active === false && (
                        <div className="pro-w-100 pro-mb-2 ">
                          <p className="pro-fw-medium pro-mb-0">
                            Disable Reason :
                            <span className="pro-fw-bolder">
                              {profileData?.data?.tutorDetails?.disable_reason
                                ? ` ${createLabel(
                                    profileData?.data?.tutorDetails
                                      ?.disable_reason
                                  )}`
                                : " Inactive"}
                            </span>
                          </p>
                        </div>
                      )}
                      <div className="pro-d-flex pro-gap-1 pro-mb-5 pro-pb-1">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.90039 11.1H6.23372C5.48228 11.1 4.76161 11.3985 4.23025 11.9298C3.6989 12.4612 3.40039 13.1819 3.40039 13.9333V15.35"
                            stroke="#1E1926"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0705 11.9298C14.6019 12.4612 14.9004 13.1818 14.9004 13.9333V15.3499"
                            stroke="#1E1926"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9.06673 8.26664C10.6315 8.26664 11.9001 6.99812 11.9001 5.43331C11.9001 3.8685 10.6315 2.59998 9.06673 2.59998C7.50192 2.59998 6.2334 3.8685 6.2334 5.43331C6.2334 6.99812 7.50192 8.26664 9.06673 8.26664Z"
                            stroke="#1E1926"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M9 10.3947L10.6576 11.9211L14.5 8"
                            stroke="#1E1926"
                            stroke-linecap="square"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <p className="pro-fw-medium pro-mb-0">
                          Background Check :
                          <span className="pro-fw-bolder">
                            {profileData?.data?.tutorDetails
                              ?.bg_verification_process_status
                              ? ` ${createLabel(
                                  profileData?.data?.tutorDetails
                                    ?.bg_verification_process_status
                                )}`
                              : " Not Completed"}
                          </span>
                        </p>
                      </div>
                      <div className="input-wrap pro-mb-4">
                        <label
                          htmlFor=""
                          className="pro-font-sm pro-mb-1 pro-fw-medium"
                        >
                          Selected Premium Plan
                        </label>
                        <ReactSelect
                          id="premium_plan"
                          name="premium_plan"
                          placeholder={"Select Premium Plan"}
                          className={`pro-input lg `}
                          classNamePrefix="pro-input"
                          options={basicData?.data?.premium_plans ?? []}
                          value={selectVal}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?._id}
                          onChange={(e) => updatePremiumPlan(e._id)}
                          menuPlacement="auto"
                        />
                      </div>
                      <div
                        className={`${profileStyle.hightlight_text} pro-mb-5`}
                      >
                        <p className="pro-mb-0 ">
                          {`Profile Last Updated - `}
                          <span className="pro-fw-bold">
                            {formatCustomFDate(
                              profileData?.data?.tutorDetails?.last_updated
                            )}
                          </span>
                        </p>

                        <p
                          className={`pro-mb-0 text-truncate ${profileStyle.mail}`}
                        >
                          {profileData?.data?.tutorDetails?.email}
                        </p>
                      </div>

                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                        editIcon={
                          profileData?.data?.permission?.edit_permission ? (
                            <span className="material-symbols-outlined">
                              edit_square
                            </span>
                          ) : null
                        }
                        handleEdit={
                          profileData?.data?.permission?.edit_permission
                            ? handleEditClick
                            : null
                        }
                      />
                      <MultiColumnTable
                        title={"Onboard Details"}
                        data={onboardDetails}
                        extraClassNames={`pro-mb-5`}
                        // editIcon={
                        //   profileData?.data?.permission?.edit_permission ? (
                        //     <span className="material-symbols-outlined">
                        //       edit_square
                        //     </span>
                        //   ) : null
                        // }
                        // handleEdit={
                        //   profileData?.data?.permission?.edit_permission
                        //     ? handleEditClick
                        //     : null
                        // }
                      />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>

          <ModalLayout
            show={tutorsState?.showDeleteModal}
            handleClose={() => handleDeleteModal}
          >
            <div className="pro-m-5">
              <ConfirmationBox
                title={popUpMessage}
                cancelText={"No"}
                cancelAction={handleDeleteModal}
                submitText={"Yes"}
                submitAction={() => setStatusUpdate(true)}
                isRight={true}
              />
            </div>
          </ModalLayout>

          <OffCanvasLayout
            show={tutorsState?.showAddTutorsModal}
            handleClose={closeModal}
            title={`${"Update Tutor Basic Details"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
          >
            <AddTutors
              isStickyFooter
              refetch={refetch}
              closeModal={closeModal}
            />
          </OffCanvasLayout>

          {/* main container */}

          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <NavGroup navigations={navigation} type={"type-2"} Link={Link} />
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default TutorDetailsLayout;
