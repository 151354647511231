export const useItemField = (
  formik,
) => {

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.content?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("content", data);
  };

  const handleRemoveFieldLink = (index, i) => {
    const data = formik?.values?.content?.[index]?.links?.filter(
      (item, itemIndex) => itemIndex !== i
    );
    formik.setFieldValue(`content.[${index}].links`, data);
  };

  

  return {
    handleRemoveFieldCS,
    handleRemoveFieldLink
  };
};

export default useItemField;
