import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAutoCompleteData,
  updateConfig,
} from "../../../../store/slices/CMS/LocationSearchMeta/LocationSearchSlice";
import { toast } from "react-toastify";
import { createMeta, updateMeta } from "../api";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useAddMeta = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const {
    isEdit,
    selectedData,
    editData,
    selectedItemDetails,
    autoCompleteData,
  } = useSelector((state) => state.locationSearch);

  const [useDataOptions, setUseDataOptions] = useState([]);

  const profilefileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemDetails !== "" ? selectedItemDetails?.og_image : ""
  );

  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Return empty editor state if no HTML

    const sanitizedHtml = DOMPurify.sanitize(html);
    const contentBlock = htmlToDraft(sanitizedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.autoCompleteData = [];
      })
    );
    if (selectedItemDetails?.slug) {
      formik.setFieldValue("slug", selectedItemDetails?.slug);
      handleInputChange(selectedItemDetails?.name, selectedItemDetails?.type);
    }
    setInitialData({
      title: selectedItemDetails?.title ?? "",
      og_image_alt: selectedItemDetails?.og_image_alt ?? "",
      og_type: selectedItemDetails?.og_type ?? "",
      summary: selectedItemDetails?.summary ?? "",
      canonical_tag: selectedItemDetails?.canonical_tag ?? "",
      meta_title: selectedItemDetails?.meta_title ?? "",
      slug: selectedItemDetails?.slug ?? "",
      search_slug: selectedItemDetails?.search_slug ?? "",
      keywords: selectedItemDetails?.keywords ?? "",
      description: selectedItemDetails?.description ?? "",
      type: selectedItemDetails?.type ?? "",
      is_active: selectedItemDetails?.is_active ?? "",
      image: "",
      content: selectedItemDetails?.content?.map((item, index) => ({
        title: item?.title ?? "",
        description: item?.description
          ? convertHtmlToEditorState(item?.description)
          : "",
        // description: item?.description ?? "",
      })) || [
        {
          title: "",
          description: "",
        },
      ],
    });
  }, [selectedItemDetails]);

  let typeList = [
    {
      label: "CITY",
      value: "CITY",
    },
    {
      label: "SUBJECT",
      value: "SUBJECT",
    },
    {
      label: "STATE",
      value: "STATE",
    },
  ];

  const [initialData, setInitialData] = useState({
    title: selectedItemDetails?.title ?? "",
    og_image_alt: selectedItemDetails?.og_image_alt ?? "",
    og_type: selectedItemDetails?.og_type ?? "",
    summary: selectedItemDetails?.summary ?? "",
    canonical_tag: selectedItemDetails?.canonical_tag ?? "",
    meta_title: selectedItemDetails?.meta_title ?? "",
    search_slug: selectedItemDetails?.search_slug ?? "",
    slug: selectedItemDetails?.slug ?? "",
    keywords: selectedItemDetails?.keywords ?? "",
    description: selectedItemDetails?.description ?? "",
    type: selectedItemDetails?.type ?? "",
    is_active: selectedItemDetails?.is_active ?? "",
    image: "",
    content: selectedItemDetails?.content?.map((item, index) => ({
      title: item?.title ?? "",
      description: item?.description
          ? convertHtmlToEditorState(item?.description)
          : "",
    })) || [
      {
        title: "",
        description: "",
      },
    ],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,

    validationSchema: Yup.object({
      type: Yup.string().required("Type is required"),
      title: Yup.string().required("Title is required"),
      // og_image_alt: Yup.string().required("Og Image Alt is required"),
      // og_type: Yup.string().required("Og type is required"),
      // summary: Yup.string().required("Summary is required"),
      // canonical_tag: Yup.string().required("Canonical Tag is required"),
      meta_title: Yup.string().required("Meta Title is required"),
      slug: Yup.string().required("Select a value"),
      keywords: Yup.string().required("Keywords is required"),
      description: Yup.string().required("Description is required"),
      image: !isEdit && Yup.string().required("Image is required"),
      content: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("*Required"),
          // description: Yup.string().required("*Required"),
        })
      ),
    }),

    validate: (values) => {
      let errors = {};

      if(values?.type === "SUBJECT") {
        errors.search_slug = ""
        if(values?.search_slug === "") {
          errors.search_slug = "*Slug is Required"
        }
        if (errors.search_slug === "") {
          delete errors.search_slug;
        }
      }

      if (values.content && values.content.length > 0) {
        errors.content = [];
        values?.content?.forEach((item, index) => {
          let descriptionErrors = {};
          let titleErrors = {};

          if (!item?.title) {
            titleErrors.title = "*Required";
          }
          if (Object.keys(titleErrors).length > 0) {
            errors.content[index] = titleErrors;
          }
          // Assuming `description` is an EditorState object from Draft.js or similar library
          const plainText = item.description
            ? item.description.getCurrentContent().getPlainText()
            : "";

          // Check if the content is empty or only contains whitespace
          if (!plainText.trim()) {
            // Assign the error message to the description field of the current item
            descriptionErrors.description = "*Required";
          }
          else if (plainText.trim()) {
            const wordCount = plainText.trim()?.split(/\s+/)?.length;
            if (wordCount > 150) {
              descriptionErrors.description =
                "Description must be 150 words or less.";
            }
          }

          if (Object.keys(descriptionErrors).length > 0) {
            errors.content[index] = descriptionErrors;
          }
        });
        if (errors.content.length === 0) {
          delete errors.content;
        }

      }
      return errors;
    },

    onSubmit: (values, resetForm) => {
      let formData = new FormData();
      formData.append("type", values["type"]);
      formData.append("title", values["title"]);
      formData.append("og_image_alt", values["og_image_alt"]);
      formData.append("og_type", values["og_type"]);
      formData.append("summary", values["summary"]);
      formData.append("canonical_tag", values["canonical_tag"]);
      formData.append("meta_title", values["meta_title"]);
      formData.append("slug", values["slug"]);
      formData.append("keywords", values["keywords"]);
      formData.append("description", values["description"]);
      formData.append("is_active", values["is_active"]);
      if(values?.search_slug) {
        formData.append(`search_slug`, values["search_slug"]);
      }
      if (values?.image) {
        formData.append(`image`, values["image"]);
      }

      values?.content?.forEach((value, index) => {

        let contentState;
        if (value.description.getCurrentContent) {
          contentState = value.description.getCurrentContent();
        } else {
          // Assuming value.description might directly be a string or another format in some cases
          contentState = value.description; // Or handle this case based on your actual data structure
        }
        const contentRaw = convertToRaw(contentState);
        const plainText = draftToHtml(contentRaw);

        // Checking whether the new item is added to the list
        //  checking whether the new item is added to the list,if new item added
        //  then selectedItemDetails[0]?.content[index]?._id will be undefined
        // if (selectedItemDetails[0]?.content[index]?._id) {
        //   formData.append(
        //     `content[${index}][_id]`,
        //     selectedItemDetails[0]?.content[index]?._id
        //   );
        // }
        formData.append(`content[${index}][title]`, value?.title);
        formData.append(`content[${index}][description]`, plainText);
      });

      if (!isEdit) {
        createMeta(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            dispatch(
              updateConfig((state) => {
                state.isEdit = false;
                state.selectedItemDetails = "";
                state.autoCompleteData = [];
              })
            );
            setUseDataOptions([]);
            handleModalClick();
            refetch();
            toast.success("Location search meta created");
          } else if (response?.statusCode === 422) {
            toast.error(response?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        updateMeta(formData, selectedData).then((response) => {
          if (response?.data?.statusCode === 200) {
            dispatch(
              updateConfig((state) => {
                state.isEdit = false;
                state.selectedItemDetails = "";
                state.autoCompleteData = [];
              })
            );
            setUseDataOptions([]);
            handleModalClick();
            refetch();
            toast.success("Location search meta updated");
          } else if (response?.statusCode === 422) {
            toast.error(response?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (autoCompleteData) {
      setUseDataOptions(
        autoCompleteData.map((opt) => ({ value: opt?.slug, label: opt?.name }))
      );
    } else {
      setUseDataOptions([]); // Clear useDataOptions when autoCompleteData is null
    }
  }, [autoCompleteData]);

  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.slug
  );

  const handleAddField = () => {
    let { content } = formik?.values;
    content = [
      ...content,
      {
        title: "",
        description: "",
        // status: true,
        // image: "",
      },
    ];
    formik.setFieldValue("content", content);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      const allowedExtensions = ["jpeg", "jpg", "png"];
      const file = e.target.files[0];
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        formik.setFieldValue("image", e?.target?.files?.[0]);

        const reader = new FileReader();

        reader.onloadend = () => {
          setImageCoverPreview(reader.result);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      } else {
        formik.setFieldError(
          "image",
          "The image must be a .jpeg, .jpg, or .png file."
        );
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleInputChange = (item, type) => {
    if (item?.length >= 1) {
      if (type !== "") {
        getAutoCompleteData(item, type).then((response) => {
          dispatch(
            updateConfig((state) => {
              state.autoCompleteData = response?.data?.data;
            })
          );
        });
      }
      // else {
      //   getAutoCompleteData(item, formik?.values?.type).then((response) => {
      //     dispatch(
      //       updateConfig((state) => {
      //         state.autoCompleteData = response?.data?.data;
      //       })
      //     );
      //   });
      // }
    }
  };

  const handleContentChange = (editorState, index) => {
    const fieldName = `content[${index}].description`;
    formik?.setFieldValue(fieldName, editorState);
  };

  return {
    formik,
    imagePreview,
    imageName,
    isEdit,
    getFieldError,
    closeModal,
    handleAddField,
    setImageName,
    setImagePreview,
    imageCoverPreview,
    handleCoverImage,
    profilefileInputRef,
    selectedItemDetails,
    typeList,
    useDataOptions,
    userVal,
    handleInputChange,
    setUseDataOptions,
    handleContentChange
  };
};

export default useAddMeta;
