import React from "react";
import useAddPlan from "./useAddPlan";
import {
  Button,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import ItemField from "./ItemFieldClick";

const AddPlan = ({ refetch, handleModalClick }) => {
  const {
    formik,
    getFieldError,
    isEdit,
    handleAddField
  } = useAddPlan({ refetch, handleModalClick });

  return (
    <div className={`row`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Amount*"}
        type="text"
        id="amount"
        name="amount"
        className={`pro-input lg ${getFieldError("amount") && " error"}`}
        {...formik.getFieldProps("amount")}
        error={getFieldError("amount")}
        errorMessage={getFieldError("amount")}
      />
      <Input
        label={"Expiry in Days*"}
        type="number"
        id="expiry_in_days"
        name="expiry_in_days"
        className={`pro-input lg ${getFieldError("expiry_in_days") && " error"}`}
        {...formik.getFieldProps("expiry_in_days")}
        error={getFieldError("expiry_in_days")}
        errorMessage={getFieldError("expiry_in_days")}
      />

<div className="col-12">
        {formik?.values?.feature?.map((fields, index) => (
          <ItemField
            key={index}
            itmIndex={index}
            fields={fields}
            formik={formik}
            getFieldError={getFieldError}
            isEdit={isEdit}
          />
        ))}
        <Button
          className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
          onClick={handleAddField}
          // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
        >
          {` Add More`}
        </Button>
      </div>

      <Input
        label={"Price Label*"}
        type="text"
        id="price_label"
        name="price_label"
        className={`pro-input lg ${getFieldError("price_label") && " error"}`}
        {...formik.getFieldProps("price_label")}
        error={getFieldError("price_label")}
        errorMessage={getFieldError("price_label")}
      />

      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="is_active"
            name="is_active"
            type="checkbox"
            checked={Boolean(formik.values?.is_active) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "is_active",
                !Boolean(formik.values?.is_active) ?? 0
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddPlan;
