import React from "react";
import Style from "./addStaffForm.module.scss";

const AddFormFields = ({
	formikEditPassword,
	showNewPassword,
	handleShowNewPassword,
	handleShowConfirmPassword,
	showConfirmPassword,
	handleShowExistingPassword,
	showExistingPassword
}) => {

	return (
		<div className={Style.root}>

			<div className={Style.item}>
				<h5>Change Password</h5>

				<div className="row">
					<div className="col-12">
						<div className="input-wrap p-re">
							<label
								htmlFor="password"
								className="pro-font-sm pro-mb-1 pro-fw-medium"
							>
								Current password
							</label>
							<input
								type="password"
								className={`pro-input lg ${formikEditPassword.errors.current_password &&
									formikEditPassword.touched.current_password &&
									"error"
									}`}
								id="current_password"
								name="current_password"
								{...formikEditPassword.getFieldProps("current_password")}
								autoComplete="new-current-password"
							/>
							{showExistingPassword ? (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowExistingPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility_off{" "}
									</span>
								</button>
							) : (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowExistingPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility{" "}
									</span>
								</button>
							)}
							{formikEditPassword.touched.current_password &&
								formikEditPassword.errors.current_password && (
									<span className="error-text">
										{formikEditPassword.errors.current_password}
									</span>
								)}
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="input-wrap p-re">
							<label
								htmlFor="password"
								className="pro-font-sm pro-mb-1 pro-fw-medium"
							>
								New password
							</label>
							<input
								type="password"
								className={`pro-input lg ${formikEditPassword.errors.password &&
									formikEditPassword.touched.password &&
									"error"
									}`}
								id="password"
								name="password"
								{...formikEditPassword.getFieldProps("password")}
								autoComplete="new-password"
							/>
							{showNewPassword ? (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowNewPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility_off{" "}
									</span>
								</button>
							) : (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowNewPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility{" "}
									</span>
								</button>
							)}
							{formikEditPassword.touched.password &&
								formikEditPassword.errors.password && (
									<span className="error-text">
										{formikEditPassword.errors.password}
									</span>
								)}
						</div>
					</div>
				</div>
				<div className="input-wrap pro-mb-5 p-re">
					<label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
						Re-enter password
					</label>
					<input
						type="password"
						className={`pro-input lg ${formikEditPassword.errors.confirm_password &&
							formikEditPassword.touched.confirm_password &&
							"error"
							}`}
						id="confirm_password"
						name="confirm_password"
						{...formikEditPassword.getFieldProps("confirm_password")}
						autoComplete="confirm-password"
					/>
					
					{showConfirmPassword ? (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowConfirmPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility_off{" "}
									</span>
								</button>
							) : (
								<button
									className={`hide-icon`}
									onClick={(e) => handleShowConfirmPassword(e)}
								>
									<span className="material-symbols-outlined">
										{" "}
										visibility{" "}
									</span>
								</button>
							)}
					{formikEditPassword.touched.confirm_password &&
								formikEditPassword.errors.confirm_password && (
									<span className="error-text">
										{formikEditPassword.errors.confirm_password}
									</span>
								)}
				</div>

				
			</div>
		</div>
	);
	//  : isDataLoading === "loading" ? (
	//   <div>Loading...</div>
	// ) : (
	//   ""
	// );
};

export default AddFormFields;
