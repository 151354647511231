import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/FAQs/faqsSlice";
// import { addBillingData, editBillingData } from "../api";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import {
  useUpdateCreateFAQMutation,
  useUpdateFaqMutation,
} from "../../../store/queries/FAQs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useAddFAQ = ({ refetch }) => {
  const dispatch = useDispatch();
  const { faqData, isEdit } = useSelector((state) => state.faqs);
  const [updateCreateFAQData] = useUpdateFaqMutation();
  const [updateFAQData] = useUpdateCreateFAQMutation();
  const [faqId, setFaqd] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isFaq, setIsFaq] = useState(true);
  const [isHomepage, setIsHomepage] = useState(true);

  useEffect(() => {
    if (faqData && isEdit) {
      Object.keys(faqData || {}).forEach((key) => {
        if (key === "question") {
          formik?.setFieldValue("question", faqData?.[key]);
        } else if (key === "answer") {
          const htmlContent = faqData?.[key]?.replace(/\\/g, "");
          const sanitizedHtml = DOMPurify.sanitize(htmlContent);

          // Convert HTML to ContentState using htmlToDraft
          const contentBlock = htmlToDraft(sanitizedHtml);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );

          // Create an EditorState with the converted ContentState
          const editorState = EditorState.createWithContent(contentState);
          formik.setFieldValue("answer", editorState);
          // formik?.setFieldValue("answer", faqData?.[key]);
        } else if (key === "_id") {
          setFaqd(faqData?.[key]);
        } else if (key === "is_active") {
          formik?.setFieldValue("is_active", faqData?.[key]);
          setIsChecked(faqData?.[key]);
        } else if (key === "is_faq") {
          formik?.setFieldValue("is_faq", faqData?.[key]);
          setIsFaq(faqData?.[key]);
        } else if (key === "is_homepage") {
          formik?.setFieldValue("is_homepage", faqData?.[key]);
          setIsHomepage(faqData?.[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqData]);

  const validation = Yup.object({
    question: Yup.string().required("Enter Question"),
    // answer: Yup.string().required("Enter Answer"),
  });

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
      is_active: "",
      is_faq: isFaq,
      is_homepage: isHomepage,
    },
    validationSchema: validation,
    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text

      const plainText =
        values?.answer && values.answer.getCurrentContent().getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText.trim()) {
        errors.answer = "*Answer cannot be empty";
      }

      return errors;
    },
    onSubmit: (values) => {
      const contentState = values?.answer?.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);
      let data = {
        question: values.question,
        answer: plainText,
        is_active: isEdit ? values?.is_active : "",
        is_faq: values?.is_faq,
        is_homepage: values?.is_homepage,
      };

      if (isEdit) {
        updateCreateFAQData({ faqId, data }).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleCloseModal();
            toast.success("FAQ Details Updated");
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors[0]?.message;
            formik.setErrors(errors);
            toast.error(errors);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        updateFAQData(data).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleCloseModal();
            toast.success("New Question Created");
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors[0]?.message;
            formik.setErrors(errors);
            toast.error(errors);
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddFAQModal = false;
      })
    );
  };

  const handleChecked = (e, id) => {
    const { checked } = e.target;
    setIsChecked(checked);
    formik.setFieldValue(id, checked);
  };

  const handleCheckedFaq = (e) => {
    const { checked } = e.target;
    setIsFaq(checked);
    formik?.setFieldValue("is_faq", checked);
  };

  const handleCheckedHomePage = (e) => {
    const { checked } = e.target;
    setIsHomepage(checked);
    formik?.setFieldValue("is_homepage", checked);
  };

  const handleContentChange = (editorState) => {
    formik?.setFieldValue("answer", editorState);
  };

  return {
    formik,
    faqData,
    // basicData: basicData?.data,
    serviceItems: faqData?.serviceItems,
    handleContentChange,
    getFieldError,
    handleCloseModal,
    handleChecked,
    handleCheckedFaq,
    handleCheckedHomePage,
    isChecked,
    isFaq,
    isHomepage,
    isEdit,
  };
};

export default useAddFAQ;
