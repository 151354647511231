import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddDataModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedData: "",
  isEdit: false,
  itemData: [],
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = contactSlice.actions;

export default contactSlice.reducer;
