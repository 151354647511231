import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isFilter: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  revenueData: [],
  source: "",
  revenue_type: "",
  showFilterModal: false,
  showDetailsModal: false,
  activeValue: "",
  date_from: "",
  date_to: "",
  // userType:"all",
  // subject:"",
  // specialization:""
};

const RevenueSlice = createSlice({
  name: "Revenue",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = RevenueSlice.actions;

export default RevenueSlice.reducer;
