import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filters=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams("search")}page_no=${
            params?.page || 1
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };


export const banners = createApi({
    reducerPath: "bannersApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: [
      "Banners"
    ],
    endpoints: (builder) => ({
      // branches starts
      getBannersListData: builder.query({
        query: (values) => ({
          method: "get",
          params: values,
          endpoint: `admin/api/banners`,
        }),
        providesTags: ["Banners"],
      }),

      updateBanners: builder.mutation({
        query(data) {
          const body = data.formData;
          const id = data.bannerID
          return {
            endpoint: `admin/api/banners/${id}`,
            method: 'put',
            body,
          }
        },
        invalidatesTags: ["Banners"],
      }),

      updateTableListFieldsData: builder.mutation({
        query(body) {
          return {
            endpoint: `admin-panel/api/panel-listings`,
            method: 'post',
            body,
          }
        },
        invalidatesTags: ["Banners"],
      }),
  
    })
      
  });

  export const { useGetBannersListDataQuery, useUpdateBannersMutation, useUpdateTableListFieldsDataMutation } = banners;