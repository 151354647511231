import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useRevenue from "./useRevenue";
import RevenueFilter from "./RevenueFilter";
import Style from "./revenue.module.scss";
import EmptyData from "../../Global/EmptyData";
import TransactionDetails from "../TransactionList/TransactionDetails";
import PremiumPlanDetails from "../PremiumPlanPurchase/PremiumPlanDetails";

const Revenue = ({ dashboard = false }) => {
  const {
    revenueState,
    showEditModal,
    revenueList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilterClick,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
    handleDashboardRedirect,
    closeModal,
  } = useRevenue({ dashboard });

  return (
    <div>
      <HeadingGroup title={"Revenue"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {!dashboard ? (
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: revenueState?.search }}
                handleActionClick={handleEditColumnsClick}
              />
            </div>
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
                onClick={handleFilterClick}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={`pro-pt-3 pro-pb-5 `}>
          {revenueList?.data?.revenue_list?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                multiSelect={false}
                data={revenueList?.data?.revenue_list || []}
                uniqueID={"_id"}
                fields={revenueList?.data?.fields || []}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={revenueState?.currentPageSize}
                assignable={false}
                deletable={revenueList?.data?.delete_permission}
                editable={revenueList?.data?.edit_permission}
                showCheckBox={false}
              />
            </div>
          )}
        </div>
        {revenueList?.data?.revenue_list?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              revenueList.data.filtered_count / revenueState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === revenueState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(revenueList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = revenueList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={revenueList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={revenueState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <RevenueFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
            activityFilter={revenueList?.data?.revenue_types_values}
            sources={revenueList?.data?.payment_gateway_values}
          />
        </OffCanvasLayout>

        <ModalLayout
          centered={false}
          show={revenueState.showDetailsModal}
          handleClose={() => closeModal()}
          title={"Transaction Details"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          propStyle={{ root: Style.modal_root }}
        >
          {revenueState?.activeValue === 1 && (
            <TransactionDetails
              closeModal={() => closeModal()}
              refetch={refetch}
            />
          )}
          {revenueState?.activeValue === 2 && (
            <PremiumPlanDetails
              closeModal={() => closeModal()}
              refetch={refetch}
            />
          )}
          {revenueState?.activeValue === 3 && (
            <TransactionDetails
              closeModal={() => closeModal()}
              refetch={refetch}
            />
          )}
        </ModalLayout>
      </div>
    </div>
  );
};

export default Revenue;
