import {
  Button,
  HeadingGroup,
  Image,
  Input,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useBgDetails from "./useBgDetails";
import Select from "react-select";
// import { Editor } from "react-draft-wysiwyg";
import Style from "./editPolicy.module.scss";
import { useEffect } from "react";
import Assets from "../../../../assets/Assets";
import { getFormatedDate } from "../../../../utils/functions/table";

const BgDetails = ({ closeModal, refetch }) => {
  const { bgData, getRow } = useBgDetails({ closeModal, refetch });

  const dateObject = new Date(bgData?.transaction_details?.date);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Define the options for formatting the date
  const options = {
    timeZone: userTimezone,
  	weekday: 'short', // Short weekday name (e.g., Fri)
  	month: 'short',   // Short month name (e.g., Oct)
  	day: 'numeric',    // Day of the month (e.g., 13)
  	year: 'numeric',   // Year (e.g., 2023)
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(dateObject);

  // const dateStartObject = new Date(bgData?.transaction_details?.start_time);
  // const dateEndObject = new Date(bgData?.transaction_details?.end_time);

  // // Format the time in 12-hour format with AM/PM
  // const formattedStartTime = dateStartObject.toLocaleTimeString('en-US', {
  // 	hour: 'numeric',
  // 	minute: '2-digit',
  // 	hour12: true,
  // });

  // const formattedEndTime = dateEndObject.toLocaleTimeString('en-US', {
  // 	hour: 'numeric',
  // 	minute: '2-digit',
  // 	hour12: true,
  // });

  return (
    <>
      <div className="pro-p-5">
        <div className={`pro-p-2 ${Style.header_root}`}>
          <div className=" row gx-4 row-cols-4">
            <div className={`pro-d-flex`}>
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={
                    bgData?.transaction_details?.submitted_image !== "" &&
                    bgData?.transaction_details?.submitted_image
                      ? bgData?.transaction_details?.submitted_image
                      : Assets.GENDER_IMAGE
                  }
                  width={10}
                  height={10}
                  alt={bgData?.transaction_details?.submitted}
                />
              </div>
              <div className="pro-ps-2">
                <h6 className="pro-ttl h6 pro-mb-0">
                  {bgData?.transaction_details?.submitted}
                </h6>
                <p
                  className={`pro-mt-1 pro-fw-medium pro-mb-0 ${Style.alt_text}`}
                >
                  #{bgData?.transaction_details?.transaction_id}
                </p>
              </div>
            </div>
            <div>
              <h6 className="pro-ttl h6 pro-mb-0">{"Tutor"}</h6>
              <p className="pro-mt-1 pro-fw-medium pro-mb-0">
                {bgData?.transaction_details?.tutor}
              </p>
            </div>
            <div>
              <h6 className="pro-ttl h6 pro-mb-0">{"Amount"}</h6>
              <p className="pro-mt-1 pro-fw-medium pro-mb-0">
                {"$" + bgData?.transaction_details?.amount}
              </p>
            </div>
            <div>
              <h6 className="pro-ttl h6 pro-mb-0">{"Date"}</h6>
              <p className="pro-mt-1 pro-fw-medium pro-mb-0">
                {formattedDate}
              </p>
            </div>
          </div>
        </div>

        {/* <div>
						<span>  # {bgData?.transaction_details?.transaction_id}</span>
						<span> {formattedDate} </span>
						<span>
							{formattedStartTime} - {formattedEndTime}
						</span>
						<span> {bgData?.transaction_details?.activity} </span>
					</div> */}

        {/* <div className={`${Style.align_prof_img}`}>
						<div
							className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
						>
							<Image
								src={
									bgData?.transaction_details?.tutor_image !== "" && bgData?.transaction_details?.tutor_image
										? bgData?.transaction_details?.tutor_image
										: Assets.GENDER_IMAGE
								}
								width={10}
								height={10}
								alt={bgData?.transaction_details?.tutor}
							/>
						</div>
					</div> */}

        {/* <div>
						<div>{bgData?.transaction_details?.tutor}</div>
						<div>
							{bgData?.transaction_details?.subject} - {bgData?.transaction_details?.specialization}
						</div>
						<div>
							Amount = {bgData?.transaction_details?.amount}
						</div>
					</div> */}

        {/* <div>
						<p>Meet Provider: {bgData?.transaction_details?.meet_provider}</p>
						<p>Meet Id: {bgData?.transaction_details?.meet_id}</p>
					</div> */}

        {/* <div>
						<p>
							Hours: {bgData?.transaction_details?.hours} hrs ={' '}
							<span>
								Session start time : {formattedStartTime} Session end time : {formattedEndTime}
							</span>
						</p>
						<p>Meet Id: {bgData?.transaction_details?.meet_id}</p>
					</div> */}
        <HeadingGroup title={"Activity"} extraClassName={`pro-mt-5`} />
        <div className="col-12">
          <Table
            multiSelect={false}
            data={bgData?.transaction_details?.events || []}
            uniqueID={"_id"}
            fields={bgData?.fields}
            getRow={getRow}
            showCheckBox={false}
          />
        </div>
      </div>
    </>
  );
};

export default BgDetails;
