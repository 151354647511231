import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Coupon/couponSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useCouponListFilter = ({ handleFilterClick, dropdown }) => {
	const dispatch = useDispatch();
	const { isFilter, coupon_type } = useSelector(
		(state) => state.coupon
	);

	const outputArray = dropdown?.map(item => {
		return { value: item, label: item.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') };
	});

	const formik = useFormik({
		initialValues: {
			coupon_type: "",
		},
		onSubmit: (values) => {
			let newObj = Object.entries(values);
			let subData = newObj
				.filter(
					(item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
				)
				.reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
			dispatch(
				updateConfig((state) => {
					Object.assign(state, subData);
				})
			);
			handleFilterClick();
		},
	});
	const clearFilter = () => {
		formik.handleReset();
		dispatch(
			updateConfig((state) => {
				Object.assign(state, formik.initialValues);
				state.isFilter = false;
			})
		);
		handleFilterClick();
	};

	let selectVal = outputArray.find(
		(item) => item.value === formik.values.coupon_type
	);

	useEffect(() => {
		if (isFilter) {
			formik.setValues({
				coupon_type: coupon_type || "",
			});
		}
	}, [isFilter]);
	return {
		formik,
		selectVal,
		outputArray,
		clearFilter,
	};
};

export default useCouponListFilter;
