import { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

import { getImage } from "../../../utils/functions/getCroppedImage";
// import { useUpdateProfileDetailsMutation } from "../../../store/queries/Profile";

const useProfileCard = ({
  data,
  userId,
  profileData,
  setShowDeleteImageModal,
  activeImageIndex,
  setActiveImageIndex,
  setShow,
  refetch,
  handleClose,
}) => {

  const [showCrop, setShowCrop] = useState(false);
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [image, setImage] = useState(null);

  // const [updateProfile] = useUpdateProfileDetailsMutation();

  const imgRef = useRef();
  const activeIndex = useRef(0);


  const handleThumbClick = (index) => {
    setActiveImageIndex(index);
    activeIndex.current = index;
  };

  const handleCropClick = () => {
    setShowCrop(true);
  };

  const handleCropCancel = () => {
    setShowCrop(false);
    if (image) {
      setImage(null);
      setActiveImageIndex((prev) => prev - 1);

      activeIndex.current = activeIndex.current - 1;
    }
  };

  let obj = {
    user_id: userId,
    first_name: profileData?.data?.first_name,
    last_name: profileData?.data?.last_name,
    country_code: profileData?.data?.country_code,
    phone_number: profileData?.data?.phone_number,
    gender: profileData?.data?.gender?.id,
    dob: profileData?.data?.dob,
    community_id: profileData?.data?.community_id?._id,
    diocese_id: profileData?.data?.diocese_id?._id,
  };

  const handlePrimary = async () => {
    let formData = new FormData();

    Object.keys(obj).forEach((key) => {
      return formData.append(key, obj[key]);
    });
    formData.append("_method", "PUT");

    data?.forEach((img, i) => {
      if (img?.isPrimary) {
        formData.append(
          `profile_images[${i}][is_primary]`,
          Number(Boolean(!data[i]?.isPrimary)) ?? ""
        );
        formData.append(`profile_images[${i}][_id]`, data[i]?.id ?? "");
      }
    });

    formData.append(
      `profile_images[${activeImageIndex}][is_primary]`,
      Number(Boolean(!data[activeImageIndex]?.isPrimary)) ?? ""
    );
    formData.append(
      `profile_images[${activeImageIndex}][_id]`,
      data[activeImageIndex]?.id ?? ""
    );

    // updateProfile(formData)
    //   .then((response) => {
    //     if (response?.data?.status_code === 200) {
    //       refetch();
    //       handleClose?.();

    //       toast.success("Image set to primary!");
    //     } else {
    //       handleClose?.();
    //       toast.error("Couldn't set image to primary!");
    //     }
    //   })
    //   .catch(() => {
    //     toast.error("Couldn't set image to primary!");
    //   });
  };

  const handleNewImage = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
    activeIndex.current = data.length;
    setActiveImageIndex(data.length);
    setShowCrop(true);
  };

  const handleUpload = useCallback(async () => {
    try {
      let reqImage = await getImage(imgRef.current, crop);

      const formData = new FormData();


      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (activeImageIndex <= data.length - 1) {
        formData.append("_method", "PUT");
        formData.append(
          `profile_images[${activeImageIndex}][is_primary]`,
          Number(Boolean(data[activeImageIndex]?.isPrimary)) ?? ""
        );
        formData.append(
          `profile_images[${activeImageIndex}][_id]`,
          data[activeImageIndex]?.id ?? ""
        );
        formData.append(`profile_images[${activeImageIndex}][file]`, reqImage);
      } else {
        formData.append("_method", "PUT");
        formData.append(`profile_images[${activeImageIndex}][is_primary]`, 0);
        formData.append(`profile_images[${activeImageIndex}][file]`, reqImage);
      }

      // updateProfile(formData)
      //   .then((response) => {
      //     if (response?.data?.status_code === 200) {
      //       toast.success("Image Uploaded Successfully!");
      //       setImage(null);
      //       refetch();
      //       handleClose();
      //     } else {
      //       toast.error("Failed to upload image!");
      //     }
      //   })
      //   .catch(() => {
      //     toast.error("Failed to upload image!");
      //   });
    } catch (e) {
      toast.error("Failed to Crop Image!");
    }
    // eslint-disable-next-line
  }, [crop, activeImageIndex]);

  const handleImageLimit = () => {
    toast.error("Image limit exceeded, maximum is 7");
  };

  return {
    activeImageIndex,
    showCrop,
    crop,
    image,
    imgRef,
    handleImageLimit,
    setCrop,
    handleThumbClick,
    handleCropClick,
    handleCropCancel,
    handlePrimary,
    handleUpload,
    handleNewImage,
  };
};

export default useProfileCard;
