import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { updateConfig } from "../../../../store/slices/Tutor/InactiveTutors/inactiveTutorsSlice";
import { getAutoLocationData } from "../api";
import { useMemo } from "react";

const useTutorFilter = (handleFilterClick) => {
  const dispatch = useDispatch();

  const { isFilter, statusType, tutorLocation, autoCompleteData } = useSelector(
    (state) => state.inactiveTutors
  );
  const formik = useFormik({
    initialValues: {
      tutorLocation: "",
    },
    onSubmit: (values) => {
      let newObj = Object.entries(values);
      let subData = newObj
        .filter((item) => {
          return item[1] !== undefined || item[1] !== "" || item[1] !== null;
        })
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      dispatch(
        updateConfig((state) => {
          Object.assign(state, subData);
        })
      );
      handleFilterClick();
    },
  });
  const clearFilter = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        Object.assign(state, formik.initialValues);
        // state.isFilter = false;
        state.showFilterModal = false;
      })
    );
  };

  useEffect(() => {
    if (isFilter) {
      formik.setValues({
        statusType: statusType || "",
        tutorLocation: tutorLocation || "",
      });
    }
  }, [isFilter]);

  const handleInputChange = (item) => {
    if (item?.length >= 1) {
      getAutoLocationData(item).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.autoCompleteData = response?.data.data.zip_code_list;
          })
        );
      });
    }
  };

  const useDataOptions = useMemo(
    () =>
      autoCompleteData?.map((opt) => {
        return { value: opt, label: opt };
      }),
    // eslint-disable-next-line
    [autoCompleteData]
  );
  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.tutorLocation
  );
  return {
    formik,
    clearFilter,
    useDataOptions,
    handleInputChange,
    userVal,
  };
};

export default useTutorFilter;
