import { useSelector } from "react-redux";
import { useGetFAQsListDataQuery, useUpdateTableListFieldsDataMutation } from "../../store/queries/FAQs"; 
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { updateConfig } from "../../store/slices/FAQs/faqsSlice";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getFAQData } from "./api";
import { useNavigate } from "react-router-dom";

const useFAQs = () => {
  
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const faqsState = useSelector((state) => state.faqs);
  const {
    data: faqsList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetFAQsListDataQuery({
    page_size: faqsState.currentPageSize,
    page: faqsState.currentPage,
    sort_by: faqsState.sortBy,
    sort_order: faqsState.sortOrder,
    filters: faqsState.currentFilter,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

//   const [deleteAssessmentSubmission] = useDeleteBranchMutation();
//   const [updateBranchFields] = useUpdateBranchTableHeadDataMutation();

  // useEffect(() => {
  //   dispatch(
  //     globalUpdateConfig((state) => {
  //       state.tableFields = faqsList?.data?.fields;
  //     })
  //   );
  //   // eslint-disable-next-line
  // }, [faqsList]);

  useEffect(() => {
    if (faqsState?.selectedFAQ && faqsState?.isEdit) {
      fillFAQData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqsState?.isEdit, faqsState?.showAddFAQModal]);

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddFAQModal = !state.showAddFAQModal;
        state.isEdit = false;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      is_active: (field, data) => {
        if(data[feild]) {
          return (
            <p className="pro-mb-0">
              Active
            </p>
          );
        }
        else {
          return (
            <p className="pro-mb-0">
              Inactive
            </p>
          );
        }
      }
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

//   const handleDelete = () => {
//     deleteAssessmentSubmission(faqsState?.selectedFAQ).then((response) => {
//       if (response?.data?.status_code === 200) {
//         toast.success("Assessment Submission deleted successfully");
//         handleDeleteModal();
//         refetch();
//       } else {
//         toast.error("Something went wrong");
//         handleDeleteModal();
//       }
//     });
//   };

const fillFAQData = () => {
  getFAQData(faqsState?.selectedFAQ).then((response) => {
    if (response?.data?.statusCode === 200) {
      dispatch(
        updateConfig((state) => (state.faqData = response?.data?.data))
      );
    } else {
      toast.error("Something went wrong to fetch brach data");
    }
  });
};

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedFAQ = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddFAQModal = true;
        state.selectedFAQ = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (faqsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = faqsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  useEffect(() => {
    if (faqsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [faqsState.clearSelection]);

  const filters = useMemo(() => {
    if (faqsList?.data?.filters) {
      const buttonGroups = faqsList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, faqsList]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedFAQ = "";
      })
    );
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = true;
      })
    );
  };

  return {
    // drawerMenu,
    faqsState,
    showEditModal,
    tableFields,
    faqsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilter,
    activeFilter: faqsState.currentFilter,
    currentPage: faqsState.currentPage,
    filters,
    closeEditModal,
		updateTableFields,
    handleFilterClick
  };
};

export default useFAQs;
