import React from "react";
import {
  payment,
  useGetRefundListQuery,
  useUpdateRefundTableMutation,
} from "../../../store/queries/Payment";
import { capitalizeOnFirst, getFormatedDate } from "../../../utils/functions/table";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateRefundSettle,
  updateConfig,
} from "../../../store/slices/Payment/Refund/RefundSlice";
import { useMemo } from "react";
import { useEffect } from "react";
import { subDays } from "date-fns";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useNavigate } from "react-router-dom";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "../payment.module.scss";
import { toast } from "react-toastify";

const useRefundList = ({ dashboard }) => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const refundState = useSelector((state) => state.refund);

  const {
    data: refundList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetRefundListQuery({
    page_size: refundState?.currentPageSize,
    page: refundState?.currentPage,
    sort_by: refundState?.sortBy,
    sort_order: refundState?.sortOrder,
    filter: refundState?.currentFilter,
    search: refundState?.search,
    start_date: refundState.startDate,
    end_date: refundState.endDate,
    status_type: refundState?.statusType,
    user_type: refundState.user_type,
    subject_id: refundState.subject,
    specialization_id: refundState?.specialization,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateRefundTableMutation();

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isFilter = true;
      })
    );
  };

  const createLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const getRow = (field, data) => {
    const rows = {
      fee: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      tutor: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },
      paid_from: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/students-profile/${data?.paid_from_id}`,
              });

              sessionStorage.setItem("active", `${data?.paid_from_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.paid_from_image !== "" && data?.paid_from_image
                    ? data?.paid_from_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.paid_from}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.paid_from)}
            </span>
          </div>
        );
      },
      date: (field, data) => {
        // if (data?.date) {
        // 	const dateObject = new Date(data?.date);

        // 	// Format the date as 'YYYY-MM-DD'

        // 	const formattedDate = dateObject.toISOString().split('T')[0];
        // 	return (
        // 		<p className="pro-mb-0">
        // 			{formattedDate}
        // 		</p>
        // 	);
        // }

        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      status: (field, data) => {
        return <p className="pro-mb-0">{createLabel(data[field])}</p>;
      },
      action: (field, data) => {
        return !dashboard ? (
          <button
            className="pro-btn pro-btn-primary"
            onClick={() => handleSettleClick(field, data)}
            disabled={
              data["status"] === "REFUND_SETTLEMENT_CREATED" ||
              data["status"] === "COMPLETED"
                ? true
                : false
            }
          >
            Create Settlement
          </button>
        ) : (
          <button className="pro-btn pro-btn-primary" disabled={true}>
            Create Settlement
          </button>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSettleClick = (field, data) => {
    dispatch(CreateRefundSettle(data?._id)).then((res) => {
      if (res?.payload?.data?.length !== 0) {
        toast.success(res?.payload?.message);
        dispatch(payment.util.invalidateTags(["Refund-settlement"]));
        refetch();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  useEffect(() => {
    if (refundState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [refundState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (refundState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = refundState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/payments/refunds");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  return {
    refundState,
    showEditModal,
    tableFields,
    refundList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: refundState.currentFilter,
    currentPage: refundState.currentPage,
    updateTableFields,
    handleEditColumnsClick,
    handleFilterClick,
    handleDashboardRedirect,
  };
};

export default useRefundList;
