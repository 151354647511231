import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

export const Dashboard = ({ data, onClick }) => {
  if (!data?.dashboard) {
    return "";
  }
  return <IconText icon={<img src={data?.dashboard ?? ""} alt={"img"} />} />;
};

export const StudentsRow = ({ data, onClick }) => {
  if (!data?.students) {
    return "";
  }
  return <IconText icon={<img src={data?.students ?? ""} alt={"img"} />} />;
};

export const PremiumPlansIcon = ({ data, onClick }) => {
  if (!data?.premium_plans) {
    return "";
  }
  return <IconText icon={<img src={data?.premium_plans ?? ""} alt={"img"} />} />;
};

export const JobPostingsIcon = ({ data, onClick }) => {
  if (!data?.job_postings) {
    return "";
  }
  return <IconText icon={<img src={data?.job_postings ?? ""} alt={"img"} />} />;
};

export const FAQIcon = ({ data, onClick }) => {
  if (!data?.faq) {
    return "";
  }
  return <IconText icon={<img src={data?.faq ?? ""} alt={"img"} />} />;
};