import {
  Button,
  HeadingGroup,
  Image,
  Input,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useBgCheckDetails from "./useBgCheckDetails";
import Select from "react-select";
// import { Editor } from "react-draft-wysiwyg";
import Style from "./bgCheckDetails.module.scss";
import { useEffect } from "react";
import Assets from "../../../../assets/Assets";

const BgCheckDetails = ({ closeModal, refetch }) => {
  const { bgCheckData, getRow } = useBgCheckDetails({ closeModal, refetch });

  const dateObject = new Date(bgCheckData?.bg_verifications_list?.[0]?.date);

  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Define the options for formatting the date
  const options = {
    timeZone: currentTimezone,
    weekday: "short", // Short weekday name (e.g., Fri)
    month: "short", // Short month name (e.g., Oct)
    day: "numeric", // Day of the month (e.g., 13)
    year: "numeric", // Year (e.g., 2023)
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObject
  );

  const dateStartObject = new Date(
    bgCheckData?.bg_verifications_list?.[0]?.date
  );

  // Format the time in 12-hour format with AM/PM
  const formattedStartTime = dateStartObject.toLocaleTimeString("en-US", {
    timeZone: currentTimezone,
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <>
      <div className="pro-p-5">
        <div className={`${Style.align_stud_img} pro-text-center`}>
          <div
            className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
          >
            <Image
              src={
                bgCheckData?.bg_verifications_list?.[0]?.submitted_image !==
                  "" && bgCheckData?.bg_verifications_list?.[0]?.submitted_image
                  ? bgCheckData?.bg_verifications_list?.[0]?.submitted_image
                  : Assets.GENDER_IMAGE
              }
              width={10}
              height={10}
              alt={bgCheckData?.bg_verifications_list?.[0]?.submitted}
            />
          </div>
          <h5 className="pro-ttl h5  pro-mb-0 pro-mt-1">
            {bgCheckData?.bg_verifications_list?.[0]?.submitted}
          </h5>
        </div>

        <div className={`${Style.event_box} pro-my-2`}>
          <div> {bgCheckData?.bg_verifications_list?.[0]?.status} </div>
          <div className="pro-ms-3"> {formattedDate}</div>
        </div>

        <div className={`${Style.content_wrap} pro-p-5`}>
          <div className={`${Style.align_prof_img} pro-d-flex pro-mb-4`}>
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img}`}
            >
              <Image
                src={
                  bgCheckData?.bg_verifications_list?.[0]?.check_image !== "" &&
                  bgCheckData?.bg_verifications_list?.[0]?.check_image
                    ? bgCheckData?.bg_verifications_list?.[0]?.check_image
                    : Assets.GENDER_IMAGE
                }
                width={10}
                height={10}
                alt={bgCheckData?.bg_verifications_list?.[0]?.check}
              />
            </div>
            <div className="pro-ps-2">
              <h6 className="pro-mb-1 pro-text-capitalize">
                {bgCheckData?.bg_verifications_list?.[0]?.check}
              </h6>
            </div>
          </div>

          <div>
            <p className="pro-mb-1">
              Paid Amount :{" "}
              <span className="pro-ms-2">
                {"$" + bgCheckData?.bg_verifications_list?.[0]?.paid_amount}
              </span>
            </p>
          </div>
        </div>

        <div className="pro-w-100 pro-pt-5">
          <h6 className="pro-ttl h6 pro-mb-0">Activity</h6>
          <Table
            multiSelect={false}
            data={bgCheckData?.bg_verifications_list?.[0]?.events || []}
            uniqueID={"_id"}
            fields={bgCheckData?.fields}
            getRow={getRow}
            showCheckBox={false}
          />
        </div>
      </div>
    </>
  );
};

export default BgCheckDetails;
