import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useEffect } from "react";
import { updateConfig } from "../../../../store/slices/Tutor/Tutors/tutorsSlice";
import { getAutoLocationData, updateTutorBasic } from "../api";
import { tutor } from "../../../../store/queries/Tutor";
import { useMemo } from "react";

const useAddTutors = (closeModal, refetch) => {
  const dispatch = useDispatch();
  const basicData = [
    { value: "MALE", label: "Male", _id: 1 },
    { value: "FEMALE", label: "Female", _id: 2 },
  ];
  const {
    isEdit,
    imageName,
    imagePreview,
    autoCompleteData,
    selectedTutor,
    tutorsData,
  } = useSelector((state) => state.tutors);

  const validation = Yup.object({
    first_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter First Name")
      .max(50, "Enter valid Name"),
    last_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter Last Name")
      .max(50, "Enter valid Name"),
    image: !isEdit && Yup.string().required("Enter User Type"),

    gender: Yup.string().required("Enter User Gender"),
    zip_code: Yup.string()
      .required("Enter Zip Code")
      .notOneOf([""], "Zip Code cannot be empty"), // Ensure it is not an empty string
  });
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      image: "",
      gender: "",
      zip_code: "",
      contact_number:""
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });
      if (isEdit) {
        updateTutorBasic(formData, selectedTutor).then((response) => {
          if (response?.data?.statusCode === 200) {
            closeModal();
            refetch();
            dispatch(tutor.util.invalidateTags("Tutor"));
            toast.success(" Tutor Basic Details updated");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };
  const handleInputChange = (item) => {
    if (item?.length >= 1) {
      getAutoLocationData(item).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.autoCompleteData = response?.data.data.zip_code_list;
          })
        );
      });
    }
  };

  const useDataOptions = useMemo(
    () =>
      autoCompleteData?.map((opt) => {
        return { value: opt, label: opt };
      }),
    // eslint-disable-next-line
    [autoCompleteData]
  );
  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.zip_code
  );

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        first_name: tutorsData?.tutorDetails?.basic_details?.first_name || "",
        last_name: tutorsData?.tutorDetails?.basic_details?.last_name || "",
        email: tutorsData?.tutorDetails?.email || "",
        gender: tutorsData?.tutorDetails?.basic_details?.gender,
        zip_code:
          tutorsData?.tutorDetails?.basic_details?.zip_code === "nil"
            ? ""
            : tutorsData?.tutorDetails?.basic_details?.zip_code,
        contact_number: tutorsData?.tutorDetails?.basic_details?.contact_number,
      });

      dispatch(
        updateConfig((state) => {
          state.imagePreview = tutorsData?.tutorDetails?.image || "";
          state.imageName = tutorsData?.tutorDetails?.image?.split("/").pop();
        })
      );
      if (tutorsData?.tutorDetails?.basic_details?.zip_code !== "nil") {
        handleInputChange(tutorsData?.tutorDetails?.basic_details?.zip_code);
      }
    }
    // eslint-disable-next-line
  }, [isEdit, tutorsData]);
  let selectVal = basicData.find(
    (item) => item.value === formik?.values?.gender
  );

  return {
    getFieldError,
    formik,
    handleProfileImage,
    isEdit,
    imagePreview,
    imageName,
    selectVal,
    basicData,
    useDataOptions,
    handleInputChange,
    userVal
  };
};

export default useAddTutors;
