import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/OurClients/ourClientsSlice";
import { toast } from "react-toastify";
import { useMemo } from "react";
import {
  createClient,
  getAutoCompleteUserData,
  updateClient,
} from "../api";
import { useEffect } from "react";

const useAddOurClient = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const {
    isEdit,
    imageName,
    imagePreview,
    selectedData,
    editOurClientsData,
  } = useSelector((state) => state.ourClients);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name"),
    position: Yup.string().required("Enter Position"),
    image: !isEdit && Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      image: "",
      position: "",
      status: true,
      name: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });
      if (!isEdit) {
        createClient(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            toast.success("New Client Created");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        updateClient(formData, selectedData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            toast.success(" Our Clients updated");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        position: editOurClientsData?.client_details?.position || "",
        status: editOurClientsData?.client_details?.status,
        name: editOurClientsData?.client_details?.name || "",
      });

      dispatch(
        updateConfig((state) => {
          state.imagePreview = editOurClientsData?.client_details?.image|| "";
          state.imageName = editOurClientsData?.client_details?.image
            ?.split("/")
            .pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, editOurClientsData]);

  // let userVal = useDataOptions.find(
  //   (item) => item.value === formik?.values?.client_id
  // );

  return {
    getFieldError,
    closeModal,
    formik,
    handleProfileImage,
    isEdit,
    imagePreview,
    imageName,
  };
};

export default useAddOurClient;
