import { Table, HeadingGroup } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useStudentDetails from "./useStudentDetails";
import EmptyData from "../../Global/EmptyData";

import Style from "./studentDetails.module.scss";

const StudentDetails = () => {
  const {
    profileState,
    getClassesRow,
    getEnquiryRow,
    getTransactionsRow,
    profileData,
    getReportRow,
    isFetching,
  } = useStudentDetails();

  return (
    <>
      <div className={`pro-px-5 pro-pt-4`}>
        <HeadingGroup
          title={"Classes"}
        ></HeadingGroup>
        <div className={`pro-pt-3 pro-pb-5 `}>
          {profileData?.data?.my_classList?.my_classes?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={profileData?.data?.my_classList?.my_classes || []}
              uniqueID={"_id"}
              fields={profileData?.data?.my_classList?.fields}
              showCheckBox={false}
              // SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              // handleSort={handleSort}
              getRow={getClassesRow}
              loading={isFetching}
              perpage={profileState?.currentPageSize}
              assignable={false}
            />
          )}
        </div>
      </div>

      <div className={`pro-mx-5`}>
        <HeadingGroup
          title={"Inquiry"}
          extraClassName={`pro-pt-4`}
        ></HeadingGroup>
        <div className={`pro-pt-3 pro-pb-5 `}>
          {profileData?.data?.enquiryList?.enquiry?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={profileData?.data?.enquiryList?.enquiry || []}
              uniqueID={"_id"}
              fields={profileData?.data?.enquiryList?.fields}
              showCheckBox={false}
              // SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              // handleSort={handleSort}
              getRow={getEnquiryRow}
              loading={isFetching}
              perpage={profileState?.currentPageSize}
              assignable={false}
            />
          )}
        </div>
      </div>

      <div className={`pro-mx-5`}>
        <HeadingGroup
          title={"Course Purchases"}
          extraClassName={`pro-pt-4`}
        ></HeadingGroup>
        <div className={`pro-pt-3 pro-pb-5 `}>
          {profileData?.data?.transactionList?.transactions?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={profileData?.data?.transactionList?.transactions || []}
              uniqueID={"_id"}
              fields={profileData?.data?.transactionList?.fields}
              showCheckBox={false}
              // SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              // handleSort={handleSort}
              getRow={getTransactionsRow}
              loading={isFetching}
              perpage={profileState?.currentPageSize}
              assignable={false}
            />
          )}
        </div>
      </div>
      <div className={`pro-mx-5`}>
        <HeadingGroup
          title={"Report"}
          extraClassName={`pro-pt-4`}
        ></HeadingGroup>
        <div className={`pro-pt-3 pro-pb-5 `}>
          {profileData?.data?.reportChatList?.reported_chats?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={profileData?.data?.reportChatList?.reported_chats || []}
              uniqueID={"_id"}
              fields={profileData?.data?.reportChatList?.fields}
              showCheckBox={false}
              // SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              // handleSort={handleSort}
              getRow={getReportRow}
              loading={isFetching}
              perpage={profileState?.currentPageSize}
              assignable={false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
