import React from "react";
import DashboardCard from "../../Dashboard/DashboardCard";
import { DataContainer } from "@wac-ui-dashboard/wac_component_library";
import usePayouts from "./usePayouts";
import DashboardCardShimmer from "../../Global/Shimmers/DashboardCardShimmer";
import OverViewChart from "../../Global/OverviewChart";
import DashboardHero from "../../Dashboard/DashboardHero";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TransactionList from "../../Payment/TransactionList";
import { Table, Pagination } from "@wac-ui-dashboard/wac_component_library";
import Style from "./payouts.module.scss";

function Payouts({ shimmerCount = 8 }) {
  const iconMonth = (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.11768 6.54879C7.11768 6.8641 7.33672 7.13642 7.65389 7.2641L1.56073 17.9723C-0.235523 21.1291 2.50232 24.7805 6.66556 24.7805H17.0011C21.1643 24.7805 23.9022 21.1291 22.1059 17.9723L16.0127 7.26401C16.3298 7.13629 16.5487 6.86403 16.5487 6.54879M7.11768 6.54879C7.11768 6.111 7.53992 5.7561 8.06078 5.7561H8.30268L6.31808 2.21281C5.92548 1.68485 6.37707 1 7.11782 1H16.549C17.2719 1 17.7194 1.65231 17.3756 2.17447C17.3585 2.20037 17.3419 2.22683 17.3283 2.2547L15.6218 5.75622C16.1352 5.76349 16.5487 6.11555 16.5487 6.54879M7.11768 6.54879H16.5487"
        stroke="#4B4751"
        strokeWidth="2"
      />
      <path
        d="M11.2901 20V12H11.7963V20H11.2901ZM12.6327 14.6406C12.608 14.3885 12.5021 14.1927 12.3148 14.0531C12.1276 13.9135 11.8735 13.8437 11.5525 13.8437C11.3344 13.8437 11.1502 13.875 11 13.9375C10.8498 13.9979 10.7346 14.0823 10.6543 14.1906C10.5761 14.299 10.537 14.4219 10.537 14.5594C10.5329 14.674 10.5566 14.774 10.608 14.8594C10.6615 14.9448 10.7346 15.0187 10.8272 15.0812C10.9198 15.1417 11.0267 15.1948 11.1481 15.2406C11.2695 15.2844 11.3992 15.3219 11.537 15.3531L12.1049 15.4906C12.3807 15.5531 12.6337 15.6365 12.8642 15.7406C13.0947 15.8448 13.2942 15.9729 13.463 16.125C13.6317 16.2771 13.7623 16.4563 13.8549 16.6625C13.9496 16.8688 13.9979 17.1052 14 17.3719C13.9979 17.7635 13.8992 18.1031 13.7037 18.3906C13.5103 18.676 13.2305 18.8979 12.8642 19.0562C12.5 19.2125 12.0607 19.2906 11.5463 19.2906C11.036 19.2906 10.5916 19.2115 10.213 19.0531C9.83642 18.8948 9.54218 18.6604 9.33025 18.35C9.12037 18.0375 9.01029 17.651 9 17.1906H10.2932C10.3076 17.4052 10.3683 17.5844 10.4753 17.7281C10.5844 17.8698 10.7294 17.9771 10.9105 18.05C11.0936 18.1208 11.3004 18.1562 11.5309 18.1562C11.7572 18.1562 11.9537 18.1229 12.1204 18.0562C12.2891 17.9896 12.4198 17.8969 12.5123 17.7781C12.6049 17.6594 12.6512 17.5229 12.6512 17.3687C12.6512 17.225 12.6091 17.1042 12.5247 17.0062C12.4424 16.9083 12.321 16.825 12.1605 16.7562C12.0021 16.6875 11.8076 16.625 11.5772 16.5687L10.8889 16.3937C10.356 16.2625 9.93519 16.0573 9.62654 15.7781C9.3179 15.499 9.16461 15.1229 9.16667 14.65C9.16461 14.2625 9.26646 13.924 9.47222 13.6344C9.68004 13.3448 9.96502 13.1188 10.3272 12.9563C10.6893 12.7938 11.1008 12.7125 11.5617 12.7125C12.0309 12.7125 12.4403 12.7938 12.7901 12.9563C13.142 13.1188 13.4156 13.3448 13.6111 13.6344C13.8066 13.924 13.9074 14.2594 13.9136 14.6406H12.6327Z"
        fill="#4B4751"
      />
    </svg>
  );
  const iconCredit = (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2705_162029" fill="white">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 7C6.84315 7 5.5 8.34314 5.5 10V25.23C5.5 26.8869 6.84314 28.23 8.5 28.23H27.576C29.2329 28.23 30.576 26.8869 30.576 25.23V23H31.5H33.5V21V15V13H31.5H30.576V10C30.576 8.34315 29.2329 7 27.576 7H8.5ZM28.576 13V10C28.576 9.44771 28.1283 9 27.576 9H8.5C7.94771 9 7.5 9.44771 7.5 10V25.23C7.5 25.7823 7.94771 26.23 8.5 26.23H27.576C28.1283 26.23 28.576 25.7823 28.576 25.23V23H26.5C23.7386 23 21.5 20.7614 21.5 18C21.5 15.2386 23.7386 13 26.5 13H28.576ZM26.5 15H31.5V21H26.5C24.8431 21 23.5 19.6569 23.5 18C23.5 16.3431 24.8431 15 26.5 15Z"
        />
      </mask>
      <path
        d="M30.576 23V22H29.576V23H30.576ZM33.5 23V24H34.5V23H33.5ZM33.5 13H34.5V12H33.5V13ZM30.576 13H29.576V14H30.576V13ZM28.576 13V14H29.576V13H28.576ZM28.576 23H29.576V22H28.576V23ZM31.5 15H32.5V14H31.5V15ZM31.5 21V22H32.5V21H31.5ZM6.5 10C6.5 8.89543 7.39543 8 8.5 8V6C6.29086 6 4.5 7.79085 4.5 10H6.5ZM6.5 25.23V10H4.5V25.23H6.5ZM8.5 27.23C7.39543 27.23 6.5 26.3346 6.5 25.23H4.5C4.5 27.4392 6.29085 29.23 8.5 29.23V27.23ZM27.576 27.23H8.5V29.23H27.576V27.23ZM29.576 25.23C29.576 26.3346 28.6806 27.23 27.576 27.23V29.23C29.7852 29.23 31.576 27.4392 31.576 25.23H29.576ZM29.576 23V25.23H31.576V23H29.576ZM30.576 24H31.5V22H30.576V24ZM31.5 24H33.5V22H31.5V24ZM34.5 23V21H32.5V23H34.5ZM34.5 21V15H32.5V21H34.5ZM34.5 15V13H32.5V15H34.5ZM33.5 12H31.5V14H33.5V12ZM31.5 12H30.576V14H31.5V12ZM29.576 10V13H31.576V10H29.576ZM27.576 8C28.6806 8 29.576 8.89543 29.576 10H31.576C31.576 7.79086 29.7852 6 27.576 6V8ZM8.5 8H27.576V6H8.5V8ZM29.576 13V10H27.576V13H29.576ZM29.576 10C29.576 8.89543 28.6806 8 27.576 8V10H27.576H29.576ZM27.576 8H8.5V10H27.576V8ZM8.5 8C7.39543 8 6.5 8.89543 6.5 10H8.5V10V8ZM6.5 10V25.23H8.5V10H6.5ZM6.5 25.23C6.5 26.3346 7.39543 27.23 8.5 27.23V25.23H8.5H6.5ZM8.5 27.23H27.576V25.23H8.5V27.23ZM27.576 27.23C28.6806 27.23 29.576 26.3346 29.576 25.23H27.576V27.23ZM29.576 25.23V23H27.576V25.23H29.576ZM26.5 24H28.576V22H26.5V24ZM20.5 18C20.5 21.3137 23.1863 24 26.5 24V22C24.2909 22 22.5 20.2091 22.5 18H20.5ZM26.5 12C23.1863 12 20.5 14.6863 20.5 18H22.5C22.5 15.7909 24.2909 14 26.5 14V12ZM28.576 12H26.5V14H28.576V12ZM31.5 14H26.5V16H31.5V14ZM32.5 21V15H30.5V21H32.5ZM26.5 22H31.5V20H26.5V22ZM22.5 18C22.5 20.2091 24.2909 22 26.5 22V20C25.3954 20 24.5 19.1046 24.5 18H22.5ZM26.5 14C24.2909 14 22.5 15.7909 22.5 18H24.5C24.5 16.8954 25.3954 16 26.5 16V14Z"
        fill="#4B4751"
        mask="url(#path-1-inside-1_2705_162029)"
      />
      <circle cx="26.25" cy="18.05" r="1.25" fill="#4B4751" />
    </svg>
  );
  const iconDue = (
    <svg
      width="26"
      height="18"
      viewBox="0 0 26 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25 2V17H2" stroke="#4B4751" stroke-width="2" />
      <rect
        x="1"
        y="1"
        width="20"
        height="12"
        stroke="#4B4751"
        stroke-width="2"
      />
      <circle cx="11.5" cy="7.5" r="1.5" fill="#4B4751" />
    </svg>
  );

  const icons = [iconMonth, iconCredit, iconDue];
  const {
    handlePagination,
    handlePageSize,
    handleDateChange,
    getRow,
    TutorDetailPayoutData,
    selectedDate,
    payoutState,
    paginationOptions,
    isFetching,
  } = usePayouts();

  return (
    <div className="pro-m-5">
      <div className="row">
        <div className={`pro-w-100 pro-my-5 pro-pb-5 ${Style.main_wrap}`}>
          <div className="pro-py-4">
            <div className="row pro-items-end g-4">
              <div className="col-lg-8">
                <div className="pro-d-flex pro-flex-column">
                  <p
                    className={`pro-mb-0 pro-font-sm pro-fw-bold pro-text-uppercase ${Style.amount_title}`}
                  >
                    Total Amount
                  </p>
                  <h3 className="h3 pro-ttl  pro-mb-0">
                    {TutorDetailPayoutData?.data?.data?.total_amount}
                  </h3>
                </div>
              </div>
              <div className="col-lg-4">
                <div className={Style.calender_root}>
                  <DatePicker
                    placeholderText="Click to select a date"
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="LLLL yyyy"
                    showMonthYearPicker
                    className={Style.input_box}
                    wrapperClassName={Style.calender_wrap}
                    popperClassName="popup"
                    showIcon
                    icon={
                      <>
                        <span className={`calender_icon`}>
                          <span class="material-symbols-outlined">
                            {" "}
                            calendar_today{" "}
                          </span>
                        </span>
                        <span class="material-symbols-outlined x4 dropdown_icon">
                          arrow_drop_down{" "}
                        </span>
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pro-py-2">
            <div className="row g-4">
              <div className="col-lg-8">
                <div className="row g-4">
                  {TutorDetailPayoutData?.data?.data?.contents?.map(
                    (item, index) => (
                      <div key={index} className="col-lg-6">
                        <div className={`${Style.card}`}>
                          <div
                            className={`${Style.icon} pro-d-flex pro-items-center pro-justify-center`}
                          >
                            <span>{icons[index]}</span>
                          </div>
                          <p
                            className={`${Style.title} pro-fw-medium pro-mb-0 `}
                          >
                            {item?.title}
                          </p>
                          <h5
                            className={`${Style.number} pro-ttl pro-mb-0 h5 `}
                          >
                            {item?.amount}
                          </h5>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                <div className={`${Style.graph_card} pro-p-4 pro-pb-3`}>
                  <h6 className="pro-ttl h6">Overview</h6>
                  <OverViewChart props={TutorDetailPayoutData?.data?.data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-mt-5">
        <h4>Transaction History</h4>
        <Table
          multiSelect={false}
          data={TutorDetailPayoutData?.data?.payout_lists || []}
          uniqueID={"_id"}
          fields={TutorDetailPayoutData?.data?.fields || []}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          showCheckBox={false}
        />
      </div>

      {TutorDetailPayoutData?.data?.payout_lists?.length > 0 && (
        <Pagination
          currentPage={payoutState?.currentPage}
          totalPageCount={Math.ceil(
            TutorDetailPayoutData.data.total_count / payoutState.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
    </div>
  );
}

export default Payouts;
