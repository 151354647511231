import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import usePayouts from "./usePayouts";
import PayoutFilter from "./PayoutFilter";

const Payout = () => {
  const {
    payoutsState,
    showEditModal,
    payoutList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilterClick,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
  } = usePayouts();
  return (
    <div>
      <HeadingGroup title={"Payouts"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: payoutsState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={payoutList?.data?.payout_list || []}
          uniqueID={"_id"}
          fields={payoutList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={payoutsState?.currentPageSize}
          assignable={false}
          deletable={payoutList?.data?.delete_permission}
          editable={payoutList?.data?.edit_permission}
          showCheckBox={false}
        />
        {payoutList?.data?.payout_list?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              payoutList.data.filtered_count /
                payoutsState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === payoutsState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(
                payoutList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = payoutList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={payoutList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={payoutsState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <PayoutFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
            activityFilter={payoutList?.data?.tutorPayoutStatusesValues}
          />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default Payout;
