import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddBannersModal: false,
  showDeleteModal: false,
  selectedBanner: "",
  isEdit: false,
  BannersData: [],
  clearSelection: false,
};

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = bannersSlice.actions;

export default bannersSlice.reducer;
