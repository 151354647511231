import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useTutorTaxForm from "./useTutorTaxForm";
import EmptyData from "../../Global/EmptyData";
import Style from "./tutor.module.scss";
import TaxFormFilter from "./TaxFormFilter";

function TutorTaxForm({ dashboard = false }) {
  const {
    taxFormState,
    showEditModal,
    taxFormList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    closeEditModal,
    updateTableFields,
    handleDashboardRedirect,
    handleFilterClick
  } = useTutorTaxForm({dashboard});

  return (
    <div>
      <HeadingGroup
        title={"Tax Form"}
        className={`pro-mb-4`}
        // buttonTitle={taxFormList?.data?.create_permission && "Add new"}
        // handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
      {!dashboard ? (
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: taxFormState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
      ) : (<></>)}

        <div className={`pro-pt-3 pro-pb-5 `}>
          {taxFormList?.data?.tutorTaxfrom?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={
                dashboard ? () => handleDashboardRedirect() : () => { }
              }
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
            <Table
              multiSelect={false}
              data={taxFormList?.data?.tutorTaxfrom || []}
              uniqueID={"_id"}
              fields={taxFormList?.data?.fields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={taxFormState?.currentPageSize}
              assignable={false}
              //   handleDelete={handleDeleteModal}
              showCheckBox={false}
              deletable={taxFormList?.data?.delete_permission}
              editable={taxFormList?.data?.edit_permission}
            />
            </div>
          )}
        </div>
        {taxFormList?.data?.tutorTaxfrom?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              taxFormList.data.filtered_count / taxFormState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === taxFormState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(taxFormList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = taxFormList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={taxFormList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={taxFormState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <TaxFormFilter isStickyFooter handleFilterClick={handleFilterClick} activityFilter={taxFormList?.data?.tutor_form_status_values}/>
        </OffCanvasLayout>
      </div>
    </div>
  );
}

export default TutorTaxForm;
