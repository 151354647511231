import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/CMS/AboutUs/aboutUsSlice";

export const useItemField = (
  fields,
  itmIndex,
  formik,
  basicData,
  tempIds,
  tempPIds,
  isEdit
) => {
  const dispatch = useDispatch();
  const aboutUsState = useSelector((state) => state.aboutUs);

  const handleRemoveFieldCS = (index) => {
    const data = formik?.values?.content?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("content", data);
  };

  const handleImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue(`content.${itmIndex}.image`, e?.target?.files?.[0]);
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const newFileReader = reader.result;
        const newImageName = file?.name;
        dispatch(
          updateConfig((state) => {
            state.imagePreview = [...state.imagePreview, newFileReader];
            state.imageName = [...state.imageName, newImageName];
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        `content.${itmIndex}.image`,
        "The image must be less than 5MB in size."
      );
    }
  };

  return {
    handleRemoveFieldCS,
    handleImage,
    aboutUsState,
  };
};

export default useItemField;
