import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetRoleListDataQuery } from "../../../store/queries/Roles";
import { deleteRole, updateConfig } from "../../../store/slices/Settings/Role/roleSlice";
import {
  StudentsRow,
  PremiumPlansIcon,
  JobPostingsIcon,
  FAQIcon,
  Dashboard,
} from "./Rows";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { toast } from "react-toastify";

const useRoles = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rolesState = useSelector((state) => state.role);

  const { data: roles = {}, isFetching, refetch, isError } = useGetRoleListDataQuery({});

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const hasCreatePermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [roles]);
  const hasEditPermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [roles]);
  const hasUpdatePermission = useMemo(() => {
    let permission = roles?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
  }, [roles]);

  const handleSort = (label) => {
    if (rolesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = rolesState.sortOrder === "asc" ? "asc" : "desc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCreateClick = () => {
    setShowModal(true);
  };

  const handleEdit = (item) => {
    navigate({
      pathname: "/permission",
      search: `role=${item[0]}&update=true`,
    });
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      dashboard: (_, data) => <Dashboard data={data} />,
      students: (_, data) => <StudentsRow data={data} />,
      premium_plans: (_, data) => <PremiumPlansIcon data={data} />,
      job_postings: (_, data) => <JobPostingsIcon data={data} />,
      faq: (_, data) => <FAQIcon data={data} />,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleDelete = async () => {
    const response = await deleteRole(rolesState?.selectedId);
    if (response && response.status === 200) {
      toast.success(`Role deleted successfully.`);
      refetch();
      dispatch(
        updateConfig((state) => {
          state.clearSelection = true;
        })
      );
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal(); 
  };

  useEffect(() => {
    if (rolesState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [rolesState.clearSelection]);

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  return {
    roles,
    rolesState,
    isFetching,
    showModal,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
    handleDelete,
    handleDeleteModal
  };
};

export default useRoles;
