import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/More/moreSlice";
import { toast } from "react-toastify";
import { useMemo } from "react";
import {
  createClient,
  getAutoCompleteUserData,
  updateClient,
} from "../api";
import { useEffect } from "react";

const useAddMore = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const {
    isEdit,
    imageName,
    imagePreview,
    selectedData,
    editMoreData,
  } = useSelector((state) => state.more);

  const validation = Yup.object({
		label: Yup.string()
			.required("*Required"),
    labelValue: Yup.string()
			.required("*Required"),

	});


  const formik = useFormik({
    initialValues: {
      label: editMoreData?.app_settings_details !== ""
      ? editMoreData?.app_settings_details?.label
      : "",
      labelValue: editMoreData?.app_settings_details !== ""
      ? editMoreData?.app_settings_details?.value
      : null,
      serrvice_settings: editMoreData?.app_settings_details !== ""
      ? editMoreData?.app_settings_details?.app_setting
      : "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      let obj = {
				label: values.label,
				value: values.labelValue ? values.labelValue : "",
				serrvice_settings: values.serrvice_settings ? values.serrvice_settings : "",
				
			};
      let formData = new FormData();
      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      
        updateClient(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            resetForm();
            toast.success("More updated");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  // useEffect(() => {
  //   if (isEdit) {
  //     formik.setValues({
  //       position: editMoreData?.client_details?.position || "",
  //       status: editMoreData?.client_details?.status,
  //       name: editMoreData?.client_details?.name || "",
  //     });

  //     dispatch(
  //       updateConfig((state) => {
  //         state.imagePreview = editMoreData?.client_details?.image|| "";
  //         state.imageName = editMoreData?.client_details?.image
  //           ?.split("/")
  //           .pop();
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEdit, editMoreData]);

  // let userVal = useDataOptions.find(
  //   (item) => item.value === formik?.values?.client_id
  // );

  return {
    getFieldError,
    closeModal,
    formik,
    handleProfileImage,
    isEdit,
    imagePreview,
    imageName,
  };
};

export default useAddMore;
