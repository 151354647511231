import LOGO from "./images/logo.svg";
import USER from "./images/header/avatar.png";
import PROFILE from "./images/header/profile.png";
import HEADERLOGO from "./images/header/logo.svg";
import IMAGE_APPEARANCE from "./images/image-appearance.png";

import THEME_BLACK from "./images/black.png";
import THEME_LIGHT from "./images/light.png";
import THEME_CLOSED from "./images/closed.png";
import THEME_COMPACT from "./images/compact.png";
import THEME_DARK from "./images/dark.png";
import THEME_DETAILED from "./images/detailed.png";
import THEME_EXPANDED from "./images/expanded.png";
import NO_DATA from "./images/no_data.svg";
import GENDER_IMAGE from "./images/gender_image.png";
import VERIFY from "./images/verify.svg";
import PDFLOGO from './images/pdfLogo.png';

const Assets = {
  LOGO,
  USER,
  HEADERLOGO,
  IMAGE_APPEARANCE,
  THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  THEME_COMPACT,
  THEME_DARK,
  THEME_DETAILED,
  THEME_EXPANDED,
  NO_DATA,
  GENDER_IMAGE,
  VERIFY,
  PDFLOGO,
  PROFILE
};

export default Assets;
