import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import { ImageCard } from "../ImageCard";
import useSubjectList from "./useSubjectList";
import AddSubject from "./AddSubject";
import EmptyData from '../../Global/EmptyData/index';

function SubjectList() {
  const {
    subjectsState,
    showEditModal,
    subjectsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    // handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    filters,
    handleFilter,
    activeFilter,
    currentPage,
    showImageModal,
    closeModal,
    imageData,
    closeEditModal,
    updateTableFields,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  } = useSubjectList();

  return (
    <div>
      <HeadingGroup
        title={"All Subjects"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ? "Add new" : ""}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          data={filters}
          searchable={true}
          activeFilter={activeFilter}
          handleButtonGroupChange={handleFilter}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: subjectsState?.search }}
        />
        <div className={`pro-pt-3 pro-pb-5 `}>
          {subjectsList?.data?.subjects?.length === 0 ? (
            <EmptyData/>
          ) : (
            <Table
              multiSelect={false}
              data={subjectsList?.data?.subjects || []}
              uniqueID={"_id"}
              fields={subjectsList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={subjectsState?.currentPageSize}
              clear={subjectsState.clearSelection}
              assignable={false}
              handleEdit={handleEditAction}
              // handleDelete={handleDeleteModal}
              deletable={false}
              editable={hasEditPermission}
              showCheckBox={hasEditPermission}
            />
          )}
        </div>

        {subjectsList?.data?.subjects?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              subjectsList?.data?.filtered_count / subjectsState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        {/* <ModalLayout
          show={subjectsState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected subject ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout> */}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(subjectsList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = subjectsList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={subjectsList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showImageModal} handleClose={closeModal}>
          <ImageCard data={imageData} handleClose={closeModal} />
        </ModalLayout>
        <OffCanvasLayout
          show={subjectsState?.showAddSubjectsModal}
          handleClose={handleModalClick}
          title={subjectsState?.isEdit ? "Update Subject" : "Add Subject"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddSubject refetch={refetch} handleModalClick={handleModalClick} />
        </OffCanvasLayout>
      </div>
    </div>
  );
}

export default SubjectList;
