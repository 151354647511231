import { Navigate } from "react-router-dom";
import useProtectRoute from "./useProtectRoute";
import { useSelector } from "react-redux";

const ProtectRoute = ({ children }) => {
  const { state } = useProtectRoute();
  const globalState = useSelector((state) => state.global);
  let auth = localStorage.getItem("USER_ACCESS_TOKEN");

  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const delay = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 100);

  //   return () => clearTimeout(delay);
  // }, []);

  if (!auth) {
    return <Navigate to={"/login"} />;
  } else if (
    // isLoading ||
    (state.dashboard_permissions_status !== "fulfilled" &&
      globalState.isLogged !== "idle")
  ) {
    return <div className="app-loading fill"></div>;
  }

  return children;
};

export default ProtectRoute;
