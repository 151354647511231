import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showAddModal: false,
  showPassword: false,
  showDeleteModal: false,
  selectedStudent: "",
  isEdit: false,
  studentData: [],
  selectedID: {},
  isFilter: false,
  imagePreview: "",
  imageName: "",
  showFilterModal: false,
  studentLocation: "",
  statusType: "",
  autoCompleteData: [],
  educationLevel: [],
  editLoading: false,
  editError: "",
};

export const getEditStudentData = createAsyncThunk(
  "/student/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/students/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getEducationLevelData = createAsyncThunk(
  "/education-level/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/education-levels`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deletedStudentsSlice = createSlice({
  name: "deletedStudents",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEditStudentData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getEditStudentData.fulfilled, (state, action) => {
        state.studentData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getEditStudentData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError =
          "Something went Wrong ! , please try After Some times";
      });
    builder
      .addCase(getEducationLevelData.pending, (state, action) => {})
      .addCase(getEducationLevelData.fulfilled, (state, action) => {
        state.educationLevel = action.payload?.data?.education_levels;
      })
      .addCase(getEducationLevelData.rejected, (state, action) => {});
  },
});

export const { updateConfig } = deletedStudentsSlice.actions;

export default deletedStudentsSlice.reducer;
