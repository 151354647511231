import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import { useDispatch } from "react-redux";
import Students from "../../Student/Students";
import Tutors from "../../Tutor/Tutors";
import TutorTaxForm from "../../Tutor/TutorTaxForm";
import RefundList from "../../Payment/RefundList";
import BgCheck from "../../Student/BgCheck";
import Courses from "../../Courses";
import Revenue from "../../Payment/Revenue";
import { updateConfig } from "../../../store/slices/Courses/coursesSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BgVerificationPayment from "../../Payment/BgVerification";
import { useSelector } from "react-redux";
import useRoutes from "../../../routes/useRoutes";

const useDashboardListing = () => {
  const dispatch = useDispatch();
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
    isError
  } = useGetDashboardDataQuery({});


  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [listingType, setListingType] = useState();

  // useEffect(() => {
  //   if (isSuccess) {
  //     setListingType(Object.keys(dashboardData?.data || {})?.[2] ?? "");
  //   }

  //   // handleTotalStudents();
  //   //eslint-disable-next-line
  // }, [isSuccess]);

  const handleTotalStudents = () => {
    setListingType("student");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };
  const handleTotalTutors = () => {
    setListingType("tutor");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };
  const handleTutorVerification = () => {
    setListingType("tutor_verification");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };
  const handleTaxForm = () => {
    setListingType("tax_form_1099");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };
  const handleCompletedClass = () => {
    setListingType("completed_class");
    dispatch(
      updateConfig((state) => {
        state.activity = "COMPLETED";
      })
    );
  };
  const handleTotalBooked = () => {
    setListingType("total_booked");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };
  const handleTotalRevenue = () => {
    setListingType("total_revenue");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };
  const handleRefundPending = () => {
    setListingType("refund_pending");
    // let tempdate = new Date(currentDate.getFullYear(), 0, 1);
    // dispatch(
    //   updateConfig((state) => {
    //     // state.currentPageSize = dashboardData?.data?.TotalOrders?.total_order;
    //     state.startDate = tempdate;
    //     state.endDate = currentDate;
    //   })
    // );
  };

  let object = {
    student: handleTotalStudents,
    tutor: handleTotalTutors,
    tutor_verification: handleTutorVerification,
    tax_form_1099: handleTaxForm,
    completed_class: handleCompletedClass,
    total_booked: handleTotalBooked,
    total_revenue: handleTotalRevenue,
    refund_pending: handleRefundPending,
  };

  const handleClick = (label) => {
    object[label]();
  };

  

  let dashboardItems = {
    student: {
      label: "student",
      title: "Total Students",
      icon: "Groups",
      handleClick,
      component: <Students dashboard={true} />,
    },
    tutor: {
      label: "tutor",
      title: "Total Tutors",
      icon: "groups_3",
      handleClick,
      component: <Tutors dashboard={true} />,
    },
    tutor_verification: {
      label: "tutor_verification",
      title: "Tutor Bg Verification",
      icon: "how_to_reg",
      handleClick,
      component: <BgVerificationPayment dashboard={true} />,
    },
    tax_form_1099: {
      label: "tax_form_1099",
      title: "1099 Tax Form",
      icon: "ballot",
      handleClick,
      component: <TutorTaxForm dashboard={true} />,
    },
    completed_class: {
      label: "completed_class",
      title: "Completed Scheduled Session",
      icon: "fact_check",
      handleClick,
      component: <Courses dashboard={true} />,
    },
    total_booked: {
      label: "total_booked",
      title: "Total Booked",
      icon: "receipt_long",
      handleClick,
      component: <Courses dashboard={true} />,
    },
    total_revenue: {
      label: "total_revenue",
      title: "Total Revenue",
      icon: "monetization_on",
      handleClick,
      component: <Revenue dashboard={true} />,
    },
    refund_pending: {
      label: "refund_pending",
      title: "Refund Pending",
      icon: "pending_actions",
      handleClick,
      component: <RefundList dashboard={true} />,
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
  };
};

export default useDashboardListing;
