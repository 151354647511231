import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/DynamicMeta/dynamicMetaSlice";
import { toast } from "react-toastify";
import {
  updateDynamicMeta,
} from "../api";

const useAddDynamicMeta = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const {
    editDynamicData,
  } = useSelector((state) => state.dynamicMeta);

  // Initial values for Formik
const initialValues = editDynamicData?.reduce((acc, setting) => {
  acc[setting?.serrvice_settings] = setting.value;
  return acc;
}, {});

// Validation schema for Formik
const validationSchema = Yup.object().shape(
  editDynamicData?.reduce((acc, setting) => {
    if (
      setting?.serrvice_settings === "tutor_page_meta_title" ||
      setting?.serrvice_settings === "tutor_page_meta_description" ||
      setting?.serrvice_settings === "home_page_meta_title" ||
      setting?.serrvice_settings === "home_page_meta_description"
    ) {
      acc[setting?.serrvice_settings] = Yup.string().required("Required");
    } else if (
      setting?.serrvice_settings === "tutor_page_canonical_link" ||
      setting?.serrvice_settings === "home_page_canonical_link"
    ) {
      acc[setting?.serrvice_settings] = Yup.string();
    }
    return acc;
  }, {})
);



  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      updateDynamicMeta(values).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            resetForm();
            toast.success(response?.data?.message);
          } else if (response?.statusCode === 422) {
            toast.success(response?.data?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
    },
  });
  

  return {
    formik,
    editDynamicData
  };
};

export default useAddDynamicMeta;
