import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Subjects/subjectSlice";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  useUpdateSubjectsDataMutation,
  useUpdateCreateSubjectDataMutation,
} from "../../../../store/queries/Subjects";

const useAddSubject = ({ refetch ,handleModalClick}) => {
  const dispatch = useDispatch();
  const { subjectData, isEdit, imageName, imagePreview, selectedSubject } =
    useSelector((state) => state.subjects);

  const [updateSubjectsData] = useUpdateSubjectsDataMutation();
  const [updateCreateSubjectsData] = useUpdateCreateSubjectDataMutation();
  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        is_specialization: subjectData?.is_specialization,
        // description: subjectData?.description || "",
        name: subjectData?.specialization || "",
      });

      dispatch(
        updateConfig((state) => {
          state.imagePreview = subjectData?.image || "";
          state.imageName = subjectData?.image_name?.split("/").pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, subjectData]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Subject name"),
    // description: Yup.string().required("Enter Description"),
    image: !isEdit && Yup.string().required("Select Image"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      is_specialization: "false",
      // description: "",
      image:""
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {

      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });

      if (isEdit) {
        updateCreateSubjectsData({ selectedSubject, formData }).then(
          (response) => {
            if (response?.data?.statusCode === 200) {
              handleModalClick();
              toast.success("Subject Details Updated");
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
            } else if (response?.error?.data?.statusCode === 422) {
              // displays backend errors
              const errors = response?.error?.data?.errors[0]?.message;
              formik.setErrors(errors);
              toast.error(errors);
            } else {
              toast.error("Something went wrong");
            }
          }
        );
      } else {
        updateSubjectsData(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            toast.success("New Subject Created");
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            Object.keys(errors).forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

 

  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = file.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  return {
    formik,
    subjectData,
    getFieldError,
    handleProfileImage,
    imagePreview,
    imageName,
    isEdit,
  };
};

export default useAddSubject;
