import React from 'react';
import {
	ConfirmationBox,
	HeadingGroup,
	ModalLayout,
	OffCanvasLayout,
	Pagination,
	SearchFilters,
	Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../components/Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useEducationalLevel from './useEducationalLevel';
import CommonLayout from '../../pages/Layouts/CommonLayout';
import AddEducationalLevel from './AddEducationalLevel';
function EducationalLevel() {

	const {
		drawerMenu,
		educationalState,
		showEditModal,
		tableFields,
		educationalList,
		isLoading,
		isFetching,
		paginationOptions,
		getRow,
		handleEditColumnsClick,
		handleSort,
		handlePagination,
		handleModalClick,
		// handleDelete,
		// handleDeleteModal,
		handleEditAction,
		handleSearch,
		// updateBranchFields,
		handleClearClick,
		handlePageSize,
		refetch,
		currentPage,
		filters,
        handleFilter,
        activeFilter,
		closeEditModal,
		updateTableFields
	} = useEducationalLevel();

	const propertiesToInclude = ["name", "status"];
	
	return (
		<div>
			{/* <CommonLayout drawerMenu={drawerMenu}> */}
			<HeadingGroup
				title={"All Educational Levels"}
				className={`pro-mb-4`}
				buttonTitle={"Add new"}
				handleClick={handleModalClick}
			/>
			<div className="col-auto pro-pt-3 pro-pb-6">
				<SearchFilters
				data={filters}
				searchable={false}
				activeFilter={activeFilter}
				handleButtonGroupChange={handleFilter}
					onSearchInput={handleSearch}
					showActions={true}
					handleActionClick={handleEditColumnsClick}
					loading={isLoading}
					SearchIcon={
						<span className="material-symbols-outlined"> search </span>
					}
					showClearFilters
					handleClear={handleClearClick}
					searchInputProps={{ value: educationalState?.search }}
				/>
				<Table
					multiSelect={false}
					data={educationalList?.data?.education_level || []}
					uniqueID={"_id"}
					fields={tableFields}
					SortIcon={<FaSort />}
					editIcon={<span className="material-symbols-outlined">edit</span>}
					handleSort={handleSort}
					getRow={getRow}
					loading={isFetching}
					perpage={educationalState?.currentPageSize}
					clear={educationalState.clearSelection}
					assignable={false}
					handleEdit={handleEditAction}
					// handleDelete={handleDeleteModal}
					deletable={false}
					showCheckBox={true}
				/>
				{educationalList?.data?.education_level?.length > 0 && (
					<Pagination
						currentPage={currentPage}
						totalPageCount={Math.ceil(
							educationalList.data.filtered_count / educationalState.currentPageSize
						)}
						onPageChange={handlePagination}
						options={paginationOptions}
						onActionChange={handlePageSize}
						center
					/>
				)}
				{/* <ModalLayout
					show={educationalState?.showDeleteModal}
					handleClose={handleDeleteModal}
				>
					<div className="pro-m-5">
						<ConfirmationBox
							title={"Are you sure delete the selected Premium Plan ?"}
							subTitle={"This action can't be undo "}
							cancelText={"No"}
							cancelAction={handleDeleteModal}
							submitText={"Yes"}
							submitAction={handleDelete}
							isRight={true}
						/>
					</div>
				</ModalLayout> */}

<ModalLayout show={showEditModal} handleClose={closeEditModal}>
						<div className="pro-m-5">
							<OrderColumn
								title={"Choose which columns you see"}
								refetch={refetch}
								tableFields={Object.keys(educationalList?.data?.fields ?? {})
									.reduce((filteredObj, property) => {
										filteredObj[property] = educationalList?.data?.fields[property];
										return filteredObj;
									}, {})}
								moduleId={educationalList?.data?.module_id}
								updateData={updateTableFields}
							/>
						</div>
					</ModalLayout>
				<OffCanvasLayout
					show={educationalState?.showAddEducationalLevelModal}
					handleClose={handleModalClick}
					title={educationalState?.isEdit ? "Update Educational Level" : "Add Educational Level"}
					closeIcon={<span className="material-symbols-outlined"> close </span>}
				>
					<AddEducationalLevel refetch={refetch} />
				</OffCanvasLayout>
			</div>
			{/* </CommonLayout> */}
		</div>
	);
}

export default EducationalLevel
