import React from 'react';
import useTermsAndConditions from './useTermsAndConditions';
import { HeadingGroup, ModalLayout } from '@wac-ui-dashboard/wac_component_library';
import Style from './termsAndCondition.module.scss';
import EditPolicy from "./EditPolicy";
function TermsAndConditions() {

	const {
    refetch,
    dataState,
    dataList,
    handleEditClick,
    closeModal
	} = useTermsAndConditions();

    const content = dataList?.data?.terms_and_conditions ?? "";

    const parse = require("html-react-parser");
	
	return (
        <>
        <HeadingGroup
        title={"Terms And Condition"}
        extraClassName={`pro-mb-5`}
      >
        {dataList?.data?.edit_permission && (
          <button className="pro-btn pro-btn-primary pro-items-center" onClick={handleEditClick}>
          <span class="material-symbols-outlined">edit_square</span>
          <span>Edit</span>
        </button>
        )}
        
      </HeadingGroup>
        <div className={`pro-pb-5 ${Style.admin_content_area}`}>
        {parse(content)}
      </div >
      <ModalLayout
        centered={false}
        show={dataState.showEditModal}
        handleClose={() => closeModal()}
        title={dataState?.is_edit ? "Update Terms And Condition" : ""}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle ={{root: Style.modal_root}}
      >
        <EditPolicy closeModal={() => closeModal()} refetch={refetch} />
      </ModalLayout>
        </>
        
	);
}

export default TermsAndConditions;
