import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Style from "./OverviewChart.module.scss";

const OverviewChart = ({ props }) => {

  return (
    <div className={Style.chart}>
      <div className={Style.amount_wrap}>
        <div className={Style.amount}>
          <h6 className={`pro-ttl h6 pro-mb-0`}>
            {props?.overview_amount}
          </h6>
        </div>
      </div>
      <div>
        <div className={Style.question}>
          <div className={Style.question_container}>
          <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={props?.overview_graph}
                margin={{
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3" />

                <XAxis dataKey="week" />
                <YAxis
                // dataKey="percentage"
                // type="number"
                // domain={["auto", "auto"]}
                // tickFormatter={(tick) => {
                //   return `${tick}%`;
                // }}
                />
                <Area dataKey="percentage" stroke="#8884d8" fill="#8884d8" />
              </AreaChart>
            </ResponsiveContainer>

          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewChart;
