import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import {
  getMenuPermissions,
  getProfilePermissions,
  updateConfig,
} from "../../../store/slices/Global";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);

  const {
    allowedDashboardComponent,
    allowedStudentsComponent,
    allowedReportedChatComponent,
    allowedPremiumPlansComponent,
    allowedTutorComponent,
    allowedPaymentComponent,
    allowedSubjectsComponent,
    allowedCmsComponent,
    allowedCourseComponent,
    allowedRoleComponent,
    allowedCouponComponent,
    allowedPlanComponent,
    allowedTutorReviewComponent,
  } = useRoutes();

  useEffect(() => {
    if (
      !globalState.isLogged &&
      location.pathname === "/login"
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
    }

    //eslint-disable-next-line
  }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
      dispatch(getProfilePermissions());
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedStudentsComponent?.length > 0 || allowedCmsComponent?.length > 0)
    ) {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedStudentsComponent?.length > 0) {
        navigate(
          `/student/${allowedStudentsComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPlanComponent?.length > 0) {
        navigate(`/${allowedPlanComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedReportedChatComponent?.length > 0) {
        navigate(
          `${allowedReportedChatComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedCourseComponent?.length > 0) {
        navigate(`${allowedCourseComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedCmsComponent?.length > 0) {
        navigate(`/cms/${allowedCmsComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedRoleComponent?.length > 0) {
        navigate(
          `/role/${allowedRoleComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPremiumPlansComponent?.length > 0) {
        navigate(
          `${allowedPremiumPlansComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedTutorComponent?.length > 0) {
        navigate(
          `/tutors/${allowedTutorComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedCouponComponent?.length > 0) {
        navigate(
          `/coupon/${allowedCouponComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedPaymentComponent?.length > 0) {
        navigate(
          `/payments/${allowedPaymentComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedSubjectsComponent?.length > 0) {
        navigate(`${allowedSubjectsComponent?.map((item) => item?.path)?.[0]}`);
      }
    }

    //eslint-disable-next-line
  }, [
    allowedDashboardComponent,
    allowedStudentsComponent,
    allowedReportedChatComponent,
    allowedTutorComponent,
    allowedPaymentComponent,
    allowedSubjectsComponent,
    allowedRoleComponent,
    allowedCmsComponent,
    allowedCourseComponent,
    allowedCouponComponent,
    allowedPlanComponent,
    allowedTutorReviewComponent,
    globalState.isLogged
  ]);

  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${
        allowedDashboardComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Student",
      link: `/student/${
        allowedStudentsComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute(`/student`),
    },
    {
      label: "Tutor",
      link: `/tutors${allowedTutorComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/tutors"),
    },
    {
      label: "Tutor Reviews",
      link: `/tutor-reviews`,
      isButton: false,
      active: checkIfActiveRoute(`/tutor-reviews`),
    },
    {
      label: "Payments",
      link: `/payments/${
        allowedPaymentComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/payments"),
    },
    {
      label: "Subject",
      link: `/subjects`,
      isButton: false,
      active: checkIfActiveRoute("/subjects"),
    },
    {
      label: "Scheduled Classes",
      link: `/courses`,
      isButton: false,
      active: checkIfActiveRoute(`/courses`),
    },
    {
      label: "Premium plan",
      link: `/premium-plan`,
      isButton: false,
      active: checkIfActiveRoute(`/premium-plan`),
    },
    {
      label: "Reported Users",
      link: `/reported-chat`,
      isButton: false,
      active: checkIfActiveRoute(`/reported-chat`),
    },
    {
      label: "Coupon",
      link: `/coupon/${allowedCouponComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/coupon`),
    },
    {
      label: "CMS",
      link: `/cms/${allowedCmsComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/cms"),
    },
    {
      label: "Users",
      link: `/role/${allowedRoleComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/role"),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions.permissions?.flatMap?.(
      (menu) => [menu.name]
    );
    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;
