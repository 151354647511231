import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
};

const StudentReportSlice = createSlice({
  name: "StudentReport",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = StudentReportSlice.actions;
export default StudentReportSlice.reducer;
