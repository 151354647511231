import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { Component } from "react";
import Style from "./blogForm.module.scss";
import useBlogForm from "./useBlogForm";
import { limitStrLength } from "../../../../utils/functions/table";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PropTypes from "prop-types";
import CustomTableOption from "./Table/CustomTableOption";


const BlogForm = ({ refetch, closeModal }) => {
  const {
    formik,
    imageCoverPreview,
    selectedItemsDetails,
    profilefileInputRef,
    CategoryData,
    handleCoverImage,
    handleContentChange,
    uploadImageCallback,
  } = useBlogForm({ refetch, closeModal });

  return (
    <div className={`pro-w-100 pro-h-100 pro-d-flex pro-flex-column`}>
      <div className="row flex-fill gx-0">
        <div className="col-md-9">
          <div className={Style.editor_container}>
            <div className="editor">
              <Editor
                wrapperClassName={Style.pro_editor_wrapper}
                editorClassName={Style.pro_editor_main}
                toolbarClassName={Style.pro_editor_toolbar}
                editorState={formik.values.content}
                onEditorStateChange={handleContentChange}
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                  image: {
                    uploadCallback: uploadImageCallback,
                    alt: { present: true, mandatory: false },
                    previewImage: true,
                  },
                }}
                toolbarCustomButtons={[<CustomTableOption />]}
              ></Editor>
            </div>
            {formik.touched.content && formik.errors.content && (
              <span className={Style.error_text}>{formik.errors.content}</span>
            )}
          </div>
        </div>
        <div className={`col-md-3 pro-p-5 ${Style.right_form}`}>
          <Input
            label={"Title*"}
            type="text"
            id="title"
            name="title"
            className={`pro-input lg ${
              formik.errors.title && formik.touched.title && "error"
            }`}
            {...formik.getFieldProps("title")}
            onChange={(event) =>
              formik.setFieldValue("title", event?.target?.value)
            }
            error={formik.errors.title && formik.touched.title}
            errorMessage={formik.errors.title}
          />

          <div className="input-wrap pro-mb-4">
            <label htmlFor="category" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Category*
            </label>
            <Select
              id="category"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg ${
                formik.touched.category && formik.errors.category && "error"
              }`}
              classNamePrefix="pro-input"
              options={CategoryData?.data?.blog_category}
              getOptionValue={(option) => option?._id}
              getOptionLabel={(option) => option?.category}
              value={CategoryData?.data?.blog_category?.filter(
                (m) => formik.values.category === m?._id
              )}
              onBlur={formik.handleBlur("category")}
              onChange={(value) => {
                formik.setFieldValue("category", value?._id || null);
              }}
            />
            {formik.touched.category && formik.errors.category && (
              <span className="error-text">{formik.errors.category}</span>
            )}
          </div>

          <Input
            label={"Author*"}
            type="text"
            id="author"
            name="author"
            className={`pro-input lg ${
              formik.errors.author && formik.touched.author && "error"
            }`}
            {...formik.getFieldProps("author")}
            onChange={(event) =>
              formik.setFieldValue("author", event?.target?.value)
            }
            error={formik.errors.author && formik.touched.author}
            errorMessage={formik.errors.author}
          />

          <Input
            label={"Slug*"}
            type="text"
            id="slug"
            name="slug"
            className={`pro-input lg ${
              formik.errors.slug && formik.touched.slug && "error"
            }`}
            {...formik.getFieldProps("slug")}
            onChange={(event) =>
              formik.setFieldValue("slug", event?.target?.value)
            }
            error={formik.errors.slug && formik.touched.slug}
            errorMessage={formik.errors.slug}
          />

          <Input
            label={"Meta Title*"}
            type="text"
            id="meta_title"
            name="meta_title"
            className={`pro-input lg ${
              formik.errors.meta_title && formik.touched.meta_title && "error"
            }`}
            {...formik.getFieldProps("meta_title")}
            onChange={(event) =>
              formik.setFieldValue("meta_title", event?.target?.value)
            }
            error={formik.errors.meta_title && formik.touched.meta_title}
            errorMessage={formik.errors.meta_title}
          />

          <div className="input-wrap pro-mb-4">
            <label
              htmlFor="meta_description"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Meta Description*
            </label>
            <textarea
              id="meta_description"
              rows={4}
              name="meta_description"
              className={`pro-input lg ${
                formik.touched.meta_description &&
                formik.errors.meta_description &&
                "error"
              }`}
              {...formik.getFieldProps("meta_description")}
            ></textarea>
            {formik.touched.meta_description &&
              formik.errors.meta_description && (
                <span className="error-text">
                  {formik.errors.meta_description}
                </span>
              )}
          </div>

          <Input
            label={"Image Alt Text"}
            type="text"
            id="image_alt"
            name="image_alt"
            className={`pro-input lg ${
              formik.errors.image_alt && formik.touched.image_alt && "error"
            }`}
            {...formik.getFieldProps("image_alt")}
            onChange={(event) =>
              formik.setFieldValue("image_alt", event?.target?.value)
            }
            error={formik.errors.image_alt && formik.touched.image_alt}
            errorMessage={formik.errors.image_alt}
          />

          <div className="input-wrap pro-mb-4">
            <label htmlFor="image" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Image*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept=".jpeg, .jpg , .png"
                ref={profilefileInputRef}
                className={`pro-input ${
                  formik.touched.image && formik.errors.image && "error"
                }`}
                id="image"
                name="image"
                onBlur={formik.handleBlur("image")}
                onChange={(e) => handleCoverImage(e)}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    formik?.values?.image?.name !== undefined
                      ? limitStrLength(formik?.values?.image?.name, 30)
                      : selectedItemsDetails !== "" &&
                        selectedItemsDetails?.image
                      ? selectedItemsDetails?.image
                      : `Drop files to attach or browse`
                  }
                />
              </span>
              {formik.touched.image && formik.errors.image && (
                <span className="error-text">{formik.errors.image}</span>
              )}
            </div>
          </div>

          <div className={`col-2 pro-my-2`}>
            <div className={`${Style.root_image_inner}`}>
              <Image
                width={100}
                height={100}
                src={imageCoverPreview}
                alt={`branch - 01`}
              />
            </div>
          </div>

          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              id="is_published"
              name="is_published"
              role="switch"
              checked={Boolean(formik.values?.is_published) ?? false}
              onChange={() => {
                formik.setFieldValue(
                  "is_published",
                  !Boolean(formik.values?.is_published) ?? 0
                );
              }}
            />
            <label className="pro-font-sm pro-fw-medium" htmlFor="is_published">
              Publish*
            </label>
          </div>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-py-4 pro-px-5 ${Style.footer_btn_wrap}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={() => closeModal()}>
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

BlogForm.propTypes = {
  refetch: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default BlogForm;
