import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { verifyTutorDocuments } from "./api";
import { updateConfig } from "../../../../store/slices/Tutor/AllTutorDocuments/AllTutorDocumentsSlice";
import { toast } from "react-toastify";

const useDocumentForm = ({ closeModal, refetch }) => {
  const dispatch = useDispatch();
  const { selectedItemDetails } = useSelector(
    (state) => state.allTutorDocument
  );

  const formik = useFormik({
    initialValues: {
      name: selectedItemDetails[0]?.name,
      documents: (selectedItemDetails[0]?.documents).map((item, index) => ({
        index,
        document_name: item?.title,
        document_id: item?._id,
        document_status: item?.verified_status,
      })),
    },

    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const dataToSend = {
        documents: values?.documents?.map((item) => ({
          document_id: item?.document_id,
          verification_status: item?.document_status,
        })),
      };


      verifyTutorDocuments(dataToSend).then((response) => {
        if (response?.data?.statusCode === 200) {
          resetForm();
          closeModal?.();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
              state.clearSelection = true;
            })
          );
          toast.success(response?.data?.message);
        } else if (!response?.data?.statusCode === 200) {
          formik.setErrors(response?.data?.data);
          toast.error(response?.data?.message);
        }
      });
    },
  });

  return { selectedItemDetails, formik };
};

export default useDocumentForm;
