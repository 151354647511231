import React from 'react';
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useStudent from './useStudent';

function Student() {
    const { drawerMenu } = useStudent();
    return (
      <CommonLayout drawerMenu={drawerMenu}>
        <Outlet />
      </CommonLayout>
    );
}

export default Student
