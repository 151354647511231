import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Style from "./tutorBgVerification.module.scss";
import {
  useGetTutorBgVerificationDataQuery,
  useUpdateTutorBgVerificationTableFieldsMutation,
} from "../../../store/queries/Tutor";
import Assets from "../../../assets/Assets";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  getBgData,
  updateConfig,
} from "../../../store/slices/Tutor/TutorBackgroundVerification/TutorBackgroundVerificationSlice";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useTutorBackgroundVerification = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
    //eslint-disable-next-line
  }, []);
  const navigate = useNavigate();

  const { showEditModal } = useSelector((state) => state.global);
  const bgVerificationState = useSelector((state) => state.tutorBgVerification);
  const { isEdit, isFilter } = useSelector(
    (state) => state.tutorBgVerification
  );
  const {
    data: bgVerificationList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetTutorBgVerificationDataQuery({
    page_size: bgVerificationState.currentPageSize,
    page: bgVerificationState.currentPage,
    sort_by: bgVerificationState.sortBy,
    sort_order: bgVerificationState.sortOrder,
    search: bgVerificationState?.search,
    status_type: bgVerificationState?.statusType,
    start_date: bgVerificationState.startDate,
    status: bgVerificationState?.statusType,
    end_date: bgVerificationState.endDate,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);
  
  const [updateTableFields] = useUpdateTutorBgVerificationTableFieldsMutation();
  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.selectedId = {};
        state.clearSelection = true;
      })
    );
  };


  const getRow = (feild, data) => {
    const rows = {
      paid_amount: (feild, data) => (
        <p className="pro-mb-0">{"$" + data[feild]}</p>
      ),
      tutor: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },
      initiator: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              const profilePath =
                data?.initiated_by === "TUTOR"
                  ? `/tutors-profile/${data?.initiator_id}`
                  : `/students-profile/${data?.initiator_id}`;

              navigate({
                pathname: profilePath,
              });

              sessionStorage.setItem("active", `${data?.initiator_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.initiator_image !== "" && data?.initiator_image
                    ? data?.initiator_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.initiator}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.initiator)}
            </span>
          </div>
        );
      },

      action: (field, data) => {
        return (
          <button
            className={`pro-btn pro-btn-link`}
            onClick={() => {
              dispatch(getBgData(data?._id)).then((res) => {
                if (res?.payload?.data?.bg_verifications_details) {
                  dispatch(
                    updateConfig((state) => {
                      state.showDetailsModal = true;
                    })
                  );
                }
              });
            }}
          >
            Show Details
          </button>
        );
      },
      date: (field, data) => {
        //       const dateObject = new Date(data?.date);

        // // Format the date as 'YYYY-MM-DD'
        // const formattedDate = dateObject.toISOString().split('T')[0];
        //       return (
        //         <p className="pro-mb-0">
        //             {formattedDate}
        //           </p>
        //       );
        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (bgVerificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            bgVerificationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedTutor = "";
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailsModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };
  return {
    bgVerificationState,
    showEditModal,
    bgVerificationList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: bgVerificationState.currentFilter,
    currentPage: bgVerificationState.currentPage,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
    isEdit,
    isFilter,
    closeModal,
  };
};
export default useTutorBackgroundVerification;
