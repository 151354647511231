import { useNavigate, useParams } from "react-router-dom";
import { useGetTutorDocumentDataQuery } from "../../../store/queries/Tutor";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "../Tutors/tutor.module.scss";
import { updateConfig } from "../../../store/slices/Tutor/TutorDocuments/TutorDocumentSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";

const useTutorDocuments = () => {
  const { tutorID } = useParams();
  const dispatch = useDispatch();
  const tutorDocumentsState = useSelector((state) => state.tutorDocument);

  const { data: documentData = {}, isFetching , isError} = useGetTutorDocumentDataQuery({
    tutor_id: tutorID ?? "",
    page_size: tutorDocumentsState?.currentPageSize,
    page: tutorDocumentsState?.currentPage,
    sort_by: tutorDocumentsState?.sortBy,
    sort_order: tutorDocumentsState?.sortOrder,
    //     search: tutorDocumentsState?.search,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      title: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => handleImageDownloadClick(data?.url,data?.title)}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={Assets.PDFLOGO}
                width={15}
                height={15}
                alt={data?.title}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary `}
            >
              {data?.title}
            </span>
          </div>
        );
      },
      verified_status :(field, data)=>{
        return(
          data.verified_status === "CONTENT_APPROVED" ? <span>Content Approved</span> :
          data?.verified_status === "PENDING" ? <span className="pro-text-warning ">Pending</span> :
          data?.verified_status === "CONTENT_DENIED" ? <span className="pro-text-danger">Content Denied</span> : "" 
        )
      },
      createdAt: (field, data) => {

				// if (data?.createdAt) {
				// 	const dateObject = new Date(data?.createdAt);

				// 	// Format the date as 'YYYY-MM-DD'

				// 	const formattedDate = dateObject.toISOString().split('T')[0];
				// 	return (
				// 		<p className="pro-mb-0">
				// 			{formattedDate}
				// 		</p>
				// 	);
				// }
        if (data?.createdAt) {
          const dateObject = new Date(data.createdAt);
        
          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: userTimezone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split('/');

  // Reformat to dd/mm/yyyy
  const formattedDateDMY = `${day}/${month}/${year}`;
        
          return (
            <p className="pro-mb-0">
              {formattedDateDMY}
            </p>
          );
        }

			},
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (tutorDocumentsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            tutorDocumentsState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleImageDownloadClick = (imageUrl, imageName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a Blob URL for the image blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element
        const downloadLink = document.createElement("a");

        // Set the href attribute to the Blob URL
        downloadLink.href = blobUrl;

        // Set the download attribute with the desired filename
        downloadLink.download = imageName || "downloaded_image";

        // Append the anchor element to the document
        document.body.appendChild(downloadLink);

        // Trigger a click on the anchor element to start the download
        downloadLink.click();

        // Remove the anchor element from the document
        document.body.removeChild(downloadLink);

        // Revoke the Blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        toast.error("Error downloading image:", error);
      });
  };

  return {
    paginationOptions,
    documentData,
    tutorDocumentsState,
    isFetching,
    handleSort,
    handlePagination,
    getRow,
    handlePageSize,
  };
};

export default useTutorDocuments;
