import React from "react";
import Style from "./addSpecialization.module.scss";
import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import useAddSpecialization from "./useAddSpecialization";
import Select from "react-select";

const AddSpecialization = ({refetch, handleClose, parendtId}) => {
  const {
    formik,
    getFieldError,
    handleProfileImage,
    imagePreview,
    imageName,
    isEdit,
    useDataOptions,
    handleInputChange,
    handleParentId,
    userVal,
    storedData,
  } = useAddSpecialization(refetch, handleClose, parendtId);

  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search for a subject...";


  return (
    <div className={`row`}>
      <Input
        label={"Specialization*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Subject
        </label>
        <Select
          id="parent_id"
          placeholder="Search for a subject..."
          name="parent_id"
          noOptionsMessage={noOptionsMessage}
          isClearable={false}
          options={useDataOptions}
          value={
            userVal || {
              value: storedData?._id,
              label: storedData?.name,
              id: storedData?._id,
            }
          }
          onInputChange={(value) => handleInputChange(value)}
          className={`pro-input multi-select lg ${
            formik.errors.parent_id && formik.touched.parent_id && " error"
          }`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("parent_id")}
          onChange={(value) => handleParentId(value)}
          isDisabled = {true}
        ></Select>

        {getFieldError("parent_id") && (
          <span className="error-text">{getFieldError("parent_id")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${
              formik.errors.image && formik.touched.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleProfileImage(e)}
          />

          <span className="input-drag-box">
            <IconText title={imageName || "Drop files to attach or browse"} />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imagePreview}
            alt={`image - 01`}
          />
        </div>
      </div>
      <span className="pro-font-sm ">Max upload file size 5MB</span>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleClose}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddSpecialization;
