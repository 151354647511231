import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filters=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams("search")}page_no=${
            params?.page || 1
          }&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };


export const roles = createApi({
    reducerPath: "rolesApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: [
      "Roles",
      "Staffs"
    ],
    endpoints: (builder) => ({
        getRoleListData: builder.query({
            query: (values) => ({
              method: "get",
              params: values,
              endpoint: `admin/api/roles`,
            }),
            providesTags: ["Roles"],
          }),
          getStaffList: builder.query({
            query: (values) => ({
              method: "get",
              params: values,
              endpoint: `admin/api/admin-users`,
            }),
            providesTags: ["Staffs"],
          }),
          updateStaffListTable: builder.mutation({
            query(body) {
              return {
                endpoint: `admin-panel/api/panel-listings`,
                method: "post",
                body,
              };
            },
            invalidatesTags: ["Staffs"],
          }),

          updateCreateStaffs: builder.mutation({
            query: (body) => ({
              method: "post",
              body,
              endpoint: `admin/api/admin-users`,
            }),
            invalidatesTags: ["Staffs"],
          }),
          updateCreateStaffData: builder.mutation({
            query(data) {
              const body = data.formData;
              const id = data.selectedStaff;
              return {
                endpoint: `admin/api/admin-users/${id}`,
                method: "put",
                body,
              };
            },
            invalidatesTags: ["Staffs"],
          }),

          updateStaffStatus: builder.mutation({
            query: (id) => ({
              method: "put",
              endpoint: `admin/api/admin-user/${id}/update-status`,
            }),
          }),
      
    })
      
  });

  export const { useGetRoleListDataQuery, useGetStaffListQuery, useUpdateStaffListTableMutation, useUpdateCreateStaffsMutation, useUpdateCreateStaffDataMutation, useUpdateStaffStatusMutation } = roles;
