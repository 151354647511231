import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isFilter: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  planData: [],
  startDate: "",
  endDate: "",
  featuredPlan: "",
  showDetailsModal: false,
};

export const getPlanData = createAsyncThunk(
  "/premium-plan-purchase/",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/premium-plan-purchase/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const premiumPlanPurchaseSlice = createSlice({
  name: "premiumPlanPurchase",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlanData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getPlanData.fulfilled, (state, action) => {
        state.planData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getPlanData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = premiumPlanPurchaseSlice.actions;

export default premiumPlanPurchaseSlice.reducer;
