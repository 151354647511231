import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddFAQ from "./useAddFAQ";
import Style from "./faqForm.module.scss";
import { Editor } from "react-draft-wysiwyg";

const AddFAQ = (refetch) => {
  const {
    formik,
    isEdit,
    isChecked,
    isFaq,
    isHomepage,
    getFieldError,
    handleCloseModal,
    handleChecked,
    handleContentChange,
    handleCheckedFaq,
    handleCheckedHomePage,
  } = useAddFAQ(refetch);

  return (
    <div className={`row`}>
      <Input
        label={"Question"}
        type="text"
        id="question"
        name="question"
        className={`pro-input lg ${getFieldError("question") && " error"}`}
        {...formik.getFieldProps("question")}
        error={getFieldError("question")}
        errorMessage={getFieldError("question")}
      />

      <div className={Style.editor_container}>
        <Editor
          wrapperClassName={Style.pro_editor_wrapper}
          editorClassName={Style.pro_editor_main}
          toolbarClassName={Style.pro_editor_toolbar}
          editorState={formik.values.answer}
          readOnly={false}
          onEditorStateChange={handleContentChange}
          // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
          toolbar={{
            options: [
              "blockType",
              "inline",
              "list",
              "textAlign",
              "fontSize",
              "fontFamily",
            ],
            blockType: {
              inDropdown: true,
              className: Style.dropdown_wrapper,
              dropdownClassName: Style.dropdown_menu,
            },
          }}
        />
        {formik.touched.answer && formik.errors.answer && (
          <span className={Style.error_text}>{formik.errors.answer}</span>
        )}
      </div>

      <div className="pro-check-box row">
        <div className="col-md-3 pro-mt-4">
          <input
            type="checkbox"
            className="pro-check"
            id="is_faq"
            name="pro-checkbox"
            onChange={(e) => handleCheckedFaq(e)}
            checked={isFaq}
            disabled={false}
          />
          <label htmlFor="is_active" className="pro-check-label">
            isFaq
          </label>
        </div>
        <div className="col-md-4 pro-mt-4">
          <input
            type="checkbox"
            className="pro-check"
            id="is_faq"
            name="pro-checkbox"
            onChange={(e) => handleCheckedHomePage(e)}
            checked={isHomepage}
            disabled={false}
          />
          <label htmlFor="is_active" className="pro-check-label">
            isHomepage
          </label>
        </div>
        {isEdit && (
          // <div className="pro-check-box row">
          <div className="col-md-4 pro-mt-4">
            <input
              type="checkbox"
              className="pro-check"
              id="is_active"
              name="pro-checkbox"
              onChange={(e) => handleChecked(e, "is_active")}
              checked={isChecked}
              disabled={false}
            />
            <label htmlFor="is_active" className="pro-check-label">
              Is Active
            </label>
            {/* </div> */}
          </div>
        )}
      </div>

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddFAQ;
