import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import Style from "./tutor.module.scss";
import useOnboardingTutors from "./useOnboardingTutors";

function OnboardingTutors({ dashboard = false }) {
  const {
    tutorsState,
    showEditModal,
    tutorsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
    handleDashboardRedirect,
    setStatusUpdate,
    popUpMessage,
  } = useOnboardingTutors({ dashboard });

  return (
    <div>
      <HeadingGroup
        title={"Onboarding Tutors"}
        className={`pro-mb-4`}
        buttonTitle={tutorsList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {!dashboard ? (
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: tutorsState?.search }}
                handleActionClick={handleEditColumnsClick}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={`pro-pt-3 pro-pb-5 `}>
          {tutorsList?.data?.tutor_list?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                multiSelect={false}
                data={
                  tutorsList?.data?.tutor_list?.filter((item) => item?.name) ||
                  []
                }
                uniqueID={"_id"}
                fields={tutorsList?.data?.fields}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={tutorsState?.currentPageSize}
                assignable={false}
                handleEdit={handleEditAction}
                //   handleDelete={handleDeleteModal}
                showCheckBox={false}
                deletable={tutorsList?.data?.delete_permission}
                editable={tutorsList?.data?.edit_permission}
              />
            </div>
          )}
        </div>
        {tutorsList?.data?.tutor_list?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              tutorsList.data.filtered_count / tutorsState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === tutorsState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={tutorsState?.showDeleteModal}
          handleClose={() => handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={popUpMessage}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={() => setStatusUpdate(true)}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(tutorsList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = tutorsList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={tutorsList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        
      </div>
    </div>
  );
}

export default OnboardingTutors;
