import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filters=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams("search")}page_no=${
            params?.page || 1
          }&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };


export const educationalLevel = createApi({
    reducerPath: "educationalApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: [
      "Educational"
    ],
    endpoints: (builder) => ({
      // branches starts
      getEducationalListData: builder.query({
        query: (values) => ({
          method: "get",
          params: values,
          endpoint: `admin/api/education-level`,
        }),
        providesTags: ["Educational"],
      }),

      updateCreateEducational: builder.mutation({
        query: (body) => ({
          method: "post",
          body,
          endpoint: `admin/api/education-level`,
        }),
        invalidatesTags: ["Educational"],
      }),

      updateEducational: builder.mutation({
        query(data) {
          const body = data.data;
          const id = data.educationalId
          return {
            endpoint: `admin/api/education-level/${id}`,
            method: 'put',
            body,
          }
        },
        invalidatesTags: ["Educational"],
      }),
      updateTableListFieldsData: builder.mutation({
        query(body) {
          return {
            endpoint: `admin-panel/api/panel-listings`,
            method: 'post',
            body,
          }
        },
        invalidatesTags: ["Educational"],
      }),
  
      
      // getPremiumPlanData: builder.query({
      //   query: () => ({
      //     method: "get",
      //     endpoint: `admin/api/premium-plans/${id}`,
      //   }),
      //   invalidatesTags: ["premiumPlans"],
      // }),
    })
  
      // branches ends
      
  });

  export const { useGetEducationalListDataQuery,useUpdateCreateEducationalMutation, useUpdateEducationalMutation, useUpdateTableListFieldsDataMutation } = educationalLevel;