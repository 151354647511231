import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getStaffData,
  updateConfig,
} from "../../../store/slices/Staffs/staffsSlice.js";
import { useEffect } from "react";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useNavigate } from "react-router-dom";
import { useGetRoleListDataQuery, useGetStaffListQuery, useUpdateStaffListTableMutation, useUpdateStaffStatusMutation, roles } from "../../../store/queries/Roles/index.js";
import Style from "./staffs.module.scss";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const useStaffs = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );

}, []);
  const navigate = useNavigate();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const staffState = useSelector((state) => state.staffs);
  const { data: Roles } = useGetRoleListDataQuery()

  const [updateStaffStatus] = useUpdateStaffStatusMutation();

  const {
    data: staffList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetStaffListQuery({
    page_size: staffState.currentPageSize,
    page: staffState.currentPage,
    sort_by: staffState.sortBy,
    sort_order: staffState.sortOrder,
    search: staffState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);
  const [updateTableFields] = useUpdateStaffListTableMutation();

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = true;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      is_active: (field, data) => {
        return (
          <div>
              <div className={Style.inner_row}>
                <div className="form-check form-switch ">
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    checked={data[field]}
                    onChange={(e) => handleChangeStatus(data?.is_active, data?._id)}
                  />
                </div>
              </div>
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleChangeStatus = (status, id) => {
    updateStaffStatus(id);
    dispatch(
      roles.util.updateQueryData(
        "getStaffList",
        {
          page_size: staffState.currentPageSize,
    page: staffState.currentPage,
    sort_by: staffState.sortBy,
    sort_order: staffState.sortOrder,
    search: staffState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache?.data?.admin_users_list?.map((res) =>
            res._id === id
              ? {
                  ...res,
                  is_active: status === true ? false : true,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              admin_users_list: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };

  

  useEffect(() => {
    if (staffState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [staffState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (staffState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = staffState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.selectedSubject = "";
        state.subjectData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedStaff = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getStaffData(e?.[0]));
  };
  return {
    staffState,
    showEditModal,
    tableFields,
    staffList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: staffState.currentFilter,
    currentPage: staffState.currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    handleModalClick,
    handleEditAction,
    Roles
  };
};

export default useStaffs;
