import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: {},
  isLoading: false,
  showPassword: false,
  isFilter: false,
  showFilterModal: false,
  statusType: "",
  startDate: "",
  endDate: "",
  showDetailsModal: false,
  bgVerificationData: [],
};

export const getBgData = createAsyncThunk(
  "/tutors/bg-verification/",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/tutors/bg-verification/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tutorBgVerificationSlice = createSlice({
  name: "tutorBgVerification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBgData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getBgData.fulfilled, (state, action) => {
        state.bgVerificationData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getBgData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError =
          "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = tutorBgVerificationSlice.actions;
export default tutorBgVerificationSlice.reducer;
