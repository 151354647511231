import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../../components/Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import usePayoutList from "./usePayoutList";
import EmptyData from "../../Global/EmptyData";
import Style from "./tutor.module.scss";
import PayoutListFilter from "./PayoutListFilter";
import PayoutDetail from "./PayoutDetail";

function PayoutList({ dashboard = false }) {
  const {
    payoutListState,
    showEditModal,
    payoutList,
    isLoading,
    isFetching,
    paginationOptions,
    payoutDetailModal,
    closePayoutDetailModal,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,

    currentPage,
    payoutDetailData,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
    handleDashboardRedirect,
  } = usePayoutList();

  return (
    <div>
      <HeadingGroup
        title={"Settled Payouts"}
        className={`pro-mb-4`}
        buttonTitle={payoutList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {!dashboard ? (
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: payoutListState?.search }}
                handleActionClick={handleEditColumnsClick}
              />
            </div>
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
                onClick={handleFilterClick}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={`pro-pt-3 pro-pb-5 `}>
          {payoutList?.data?.payout_list?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                multiSelect={false}
                data={payoutList?.data?.payout_lists || []}
                uniqueID={"_id"}
                fields={payoutList?.data?.fields}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={payoutListState?.currentPageSize}
                assignable={false}
                handleEdit={handleEditAction}
                //   handleDelete={handleDeleteModal}
                showCheckBox={false}
                deletable={payoutList?.data?.delete_permission}
                editable={payoutList?.data?.edit_permission}
              />
            </div>
          )}
        </div>
        {payoutList?.data?.payout_list?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              payoutList.data.filtered_count / payoutListState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === payoutListState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(payoutList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = payoutList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={payoutList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={payoutListState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <PayoutListFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
          />
        </OffCanvasLayout>

        <ModalLayout
          show={payoutDetailModal}
          handleClose={closePayoutDetailModal}
          title={"Payout ID"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          className={Style.modal_root}
          backdrop={"static"}
        >
          <PayoutDetail payoutDetailData={payoutDetailData} />
        </ModalLayout>
      </div>
    </div>
  );
}

export default PayoutList;
