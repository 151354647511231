import React, { useEffect, useState } from "react";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { useNavigate } from "react-router-dom";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  tutorReviews,
  useGetTutorReviewDataQuery,
} from "../../store/queries/TutorReviews";
import { updateConfig } from "../../store/slices/TutorReviews/TutorReviewSlice";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { useUpdateTableListFieldsDataMutation } from "../../store/queries/TutorReviews";
import Assets from "../../assets/Assets";
import Style from "./tutorReviews.module.scss";
import { useUpdateTutorReviewStatusMutation } from "../../store/queries/TutorReviews";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../utils/functions/table";

const useTutorReviews = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showEditModal } = useSelector((state) => state.global);
  const [isExpanded, setIsExpanded] = useState(null);
  const reviewState = useSelector((state) => state.tutorReviews);
  const { checkIfActiveRoute } = useRouteUtils();
  const [updateTutorReviewStatus] = useUpdateTutorReviewStatusMutation();
  const drawerMenu = [
    {
      title: "Tutor Reviews",
      label: "Tutor Reviews",
      link: "/tutor-reviews",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">rate_review</span>,
      active: checkIfActiveRoute("/tutor-reviews", true),
    },
  ];

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: tutorReviewList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetTutorReviewDataQuery({
    page_size: reviewState.currentPageSize,
    page: reviewState.currentPage,
    sort_by: reviewState.sortBy,
    sort_order: reviewState.sortOrder,
    search: reviewState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (reviewState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = reviewState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleReadMore = (data) => {
    let id = data?._id;
    setIsExpanded((state) => (state === id ? null : id));
  };


  const getRow = (feild, data) => {
    const rows = {
      student: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/students-profile/${data?.student_id}`,
              });

              sessionStorage.setItem("active", `${data?.student_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.student_image !== "" && data?.student_image
                    ? data?.student_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.full_name}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },
      tutor: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.[feild])}
            </span>
          </div>
        );
      },
      createdAt: (field, data) => {
        if (data?.createdAt) {
          const dateObject = new Date(data.createdAt);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      is_active: (field, data) => {
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={data?.is_active}
              // onChange={(e) => updateTutorStatus(data?._id)}
              onChange={(e) => handleChangeStatus(data?.is_active, data?._id)}
              disabled={false}
            />
          </div>
        );
      },
      review: (_, data) => {
        if (data?.review?.length > 90) {
          return (
            <>
              <p className="pro-mb-0">
                {isExpanded === data?._id
                  ? data.review
                  : `${data.review.slice(0, 90)}...`}
              </p>

              <span
                className={`${Style.readMoreBtn} pro-pnt`}
                onClick={() => handleReadMore(data)}
              >
                {isExpanded === data._id ? "Read Less" : "Read More"}
              </span>
            </>
          );
        } else {
          return <p className="pro-mb-0">{data?.review}</p>;
        }
      },
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleChangeStatus = (status, id) => {
    updateTutorReviewStatus(id);
    dispatch(
      tutorReviews.util.updateQueryData(
        "getTutorReviewData",
        {
          page_size: reviewState.currentPageSize,
          page: reviewState.currentPage,
          sort_by: reviewState.sortBy,
          sort_order: reviewState.sortOrder,
          search: reviewState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache?.data?.reviews?.map((res) =>
            res._id === id
              ? {
                  ...res,
                  is_active: status === true ? false : true,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              reviews: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    drawerMenu,
    reviewState,
    tutorReviewList,
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    currentPage: reviewState.currentPage,
    handlePageSize,
    handlePagination,
    updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
    refetch,
    getRow,
    handleSort,
    handleClearClick,
    handleSearch,
  };
};

export default useTutorReviews;
