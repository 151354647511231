import { useGetTutorDetailPayoutDataQuery } from "../../../store/queries/Tutor";
import { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Tutor/PayoutList/payoutListSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const usePayouts = () => {
  const { tutorID } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateFormat, setDateFormat] = useState("");
  const payoutState = useSelector((state) => state.payoutList);
  const dispatch = useDispatch();

  useEffect(() => {
    const localTime = moment(selectedDate).local();
    const formattedLocalTime = localTime.format("MM-YYYY");
    setDateFormat(formattedLocalTime);
    dispatch(
      updateConfig((state) => {
        state.month = formattedLocalTime;
      })
    );
    //eslint-disable-next-line
  }, [selectedDate]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: TutorDetailPayoutData = {},
    isLoading,
    isFetching,
    isError
  } = useGetTutorDetailPayoutDataQuery({
    tutor_id: tutorID ?? "",
    month: payoutState?.month,
    page_size: payoutState?.currentPageSize,
    page: payoutState?.currentPage,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const lineData = useMemo(() => {
    return {
      labels: TutorDetailPayoutData?.data?.data?.overview_graph?.map(
        (item) => item.week
      ),
      datasets:
        TutorDetailPayoutData?.data?.data?.overview_graph?.map(
          (item, index) => ({
            labels: item?.labels,
            data: item.percentage,
            // You can add more properties here as needed
            pointRadius: 0,
            borderColor: "#19703B",
          })
        ) || [],
    };
    //eslint-disable-next-line
  }, [TutorDetailPayoutData?.data?.data?.overview_graph]);

  const lineDataOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };

  const getRow = (field, data) => {
    const rows = {
      amount: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      date: (field, data) => {
        // if (data?.date) {
        // 	const dateObject = new Date(data?.date);

        // 	// Format the date as 'YYYY-MM-DD'

        // 	const formattedDate = dateObject.toISOString().split('T')[0];
        // 	return (
        // 		<p className="pro-mb-0">
        // 			{formattedDate}
        // 		</p>
        // 	);
        // }

        if (data?.date) {
          const dateObject = new Date(data.date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    handleDateChange,
    handlePagination,
    handlePageSize,
    getRow,
    TutorDetailPayoutData,
    payoutState,
    isLoading,
    paginationOptions,
    selectedDate,
    lineData,
    lineDataOptions,
    isFetching,
  };
};

export default usePayouts;
