import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { udateEntry, updateConfig } from "../../../../store/slices/CMS/TermsAndConditions/termsAndConditionsSlice";

const useEditPolicy = ({ closeModal, refetch }) => {
  

  const { selectedItemsDetails } = useSelector((state) => state.terms);
  const dispatch = useDispatch();

  const handleContentChange = (editorsState) => {
    formik.setFieldValue("terms_and_conditions", editorsState);
  };

  const formik = useFormik({
    initialValues: {
      type: 1
    },
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text
      const plainText = values?.terms_and_conditions?.getCurrentContent().getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText.trim()) {
        errors.terms_and_conditions = "*Description cannot be empty";
      }

      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const contentState = values?.terms_and_conditions?.getCurrentContent();
      const contentRaw = convertToRaw(contentState);
      const plainText = draftToHtml(contentRaw);

      
      let obj = {
        terms_and_conditions: plainText,
      };
        dispatch(udateEntry(obj)).then(
          (response) => {
            if (response?.payload?.statusCode === 200) {
              resetForm();
              closeModal?.();
              refetch?.();
              dispatch(
                updateConfig((state) => {
                  state.showEditModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );

              toast.success(response?.payload?.message);
            } else if (response?.payload?.statusCode === 400) {
              formik.setErrors(response?.payload?.message);
            } else toast.error(response?.payload?.message);
          }
        );
    },
  });

  useEffect(() => {
    if(selectedItemsDetails?.terms_and_conditions !== undefined) {
      setTimeout(() => {
        const htmlContent = selectedItemsDetails?.terms_and_conditions?.replace(/\\/g, "");
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        // Convert HTML to ContentState using htmlToDraft
        const contentBlock = htmlToDraft(sanitizedHtml);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
  
        // Create an EditorState with the converted ContentState
        const editorState = EditorState.createWithContent(contentState);
        formik.setFieldValue("terms_and_conditions", editorState);
      }, 2000);
    }
    
  }, [selectedItemsDetails?.terms_and_conditions]);

  return {
    formik,
    selectedItemsDetails,
    handleContentChange,
  };
};

export default useEditPolicy;
