import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useRefundSettlement from "./useRefundSettlement";
import AddRefundSettlement from "./AddRefundSettlement";

const RefundSettlement = () => {
  const {
    refundState,
    showEditModal,
    refundList,
    isLoading,
    isFetching,
    paginationOptions,
    confirmSelect,
    handleConfirmChangeStatus,
    getRow,
    handleSort,
    handlePagination,
    handleCancelConfirm,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    handleModalClick,
  } = useRefundSettlement();
  return (
    <div>
      <HeadingGroup
        title={"Refund Settlement"}
        className={`pro-mb-4`}
        buttonTitle={refundList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              loading={isLoading}
              searchable={false}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          {/* <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div> */}
        </div>
        <Table
          multiSelect={false}
          data={refundList?.data?.refund_settlemet_list || []}
          uniqueID={"_id"}
          fields={refundList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={refundState?.currentPageSize}
          assignable={false}
          deletable={refundList?.data?.delete_permission}
          editable={refundList?.data?.edit_permission}
          showCheckBox={false}
        />
        {refundList?.data?.refund_settlemet_list?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              refundList.data.filtered_count / refundState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === refundState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(refundList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = refundList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={refundList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={confirmSelect} handleClose={handleCancelConfirm}>
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to change the status ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleCancelConfirm}
              submitText={"Yes"}
              submitAction={handleConfirmChangeStatus}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={refundState?.showCreateModal}
          handleClose={handleModalClick}
          title={refundList?.isEdit ? "Update Settlement" : "Add Settlement"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddRefundSettlement
            refetch={refetch}
            handleModalClick={handleModalClick}
            dropdown={refundList?.data?.coupon_types}
          />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default RefundSettlement;
