import { getAxiosInstance } from "../../../../api";

export const verifyTutorDocuments = async (data) => {
     const api = await getAxiosInstance();
     try {
       const response = await api.post(`admin/api/tutor/document-verification`,data);
       return response;
     } catch (error) {
       return error?.response?.data;
     }
   };