import React from "react";
import useAboutUs from "./useAboutUs";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import { ImageCard } from "../../Global/ImageCard";
import AddAboutUs from "./AddAboutUs";
function AboutUs() {
  const {
    dataState,
    showEditModal,
    tableFields,
    dataList,
    isLoading,
    isFetching,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handleModalClick,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    handleClearClick,
    refetch,
    editLoading,
    handleDelete,
    updateTableFields,
    closeEditModal,
    showImageModal,
    imageData,
    closeImageModal,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  } = useAboutUs();

  return (
    <div>
      <HeadingGroup
        title={"About Us"}
        className={`pro-mb-4`}
        // buttonTitle={"Add new"}
        // handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          searchable={true}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: dataState?.search }}
        />
        <Table
          multiSelect={false}
          data={dataList?.data?.about_us || []}
          uniqueID={"_id"}
          fields={tableFields?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={dataState?.currentPageSize}
          clear={dataState.clearSelection}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={hasDeletePermission}
          editable={hasEditPermission}
          showCheckBox={hasDeletePermission || hasEditPermission}
          extraClasssName={"align-top"}
        />
        <ModalLayout
          show={dataState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure you want to delete?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(dataList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = dataList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={dataList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>

        <OffCanvasLayout
          show={dataState?.showAddModal && !editLoading}
          handleClose={handleModalClick}
          title={dataState?.isEdit ? "Update About Us" : "Add About Us"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddAboutUs refetch={refetch} handleModalClick={handleModalClick} />
        </OffCanvasLayout>
      </div>
    </div>
  );
}

export default AboutUs;
