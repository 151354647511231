import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import CreateRoleModal from "./CreateRoleModal";
import useRoles from "./useRoles";

const Roles = () => {
  const {
    showModal,
    roles,
    rolesState,
    isFetching,
    hasCreatePermission,
    hasEditPermission,
    hasUpdatePermission,
    handleCreateClick,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
    handleDelete,
    handleDeleteModal,
  } = useRoles();

  return (
    <>
      <HeadingGroup
        title={"Role"}
        className={`pro-mb-4`}
        buttonTitle={"Create"}
        handleClick={handleCreateClick}
      />

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <Table
          data={roles?.data?.role_details_list || []}
          deletable={true}
          uniqueID={"_id"}
          fields={roles?.data?.fields}
          showCheckBox={true}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          handleEdit={handleEdit}
          handleDelete={handleDeleteModal}
          loading={isFetching}
          perpage={rolesState?.currentPageSize}
          assignable={false}
          multiSelect={false}
          clear={rolesState.clearSelection}
        />

        <ModalLayout
          show={rolesState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Role ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showModal}
          handleClose={closeModal}
          title={"Create Role"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <CreateRoleModal />
        </ModalLayout>
      </div>
    </>
  );
};

export default Roles;
