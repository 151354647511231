import { Auth } from "@wac-ui-dashboard/wac_component_library";
import { useOutletContext } from "react-router-dom";
import useChangePassword from "./useChangePassword";

const ChangePassword = () => {
  const { emailRef } = useOutletContext();

  const { formik, emailStatus, handleLoginClick } = useChangePassword(emailRef);

  return (
    <Auth
      title={"Forgot Password"}
      buttonText={"Submit"}
      handleClick={formik.handleSubmit}
      actionText={"Back to login"}
      handleActionClick={handleLoginClick}
      loading={emailStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Email
        </label>
        <input
          name="email"
          value={formik?.values?.email}
          onChange={formik?.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          className={`pro-input lg ${
            formik.errors.email &&
            (formik.touched.email || formik.values.email) &&
            "error"
          }`}
        />
        {formik.errors.email &&
          (formik.touched.email || formik.values.email) && (
            <span className="error-text">{formik?.errors?.email}</span>
          )}
      </div>
    </Auth>
  );
};

export default ChangePassword;
