import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import appReducer from "./store";
import { student } from "./store/queries/Student";
import { tutor } from "./store/queries/Tutor";
import { premiumPlans } from "./store/queries/PremiumPlans";
import { courses } from "./store/queries/Courses";
import { faqs } from "./store/queries/FAQs";
import { dashboard } from "./store/queries/Dashboard";
import { subjects } from "./store/queries/Subjects";
import { roles } from "./store/queries/Roles";
import { educationalLevel } from "./store/queries/EducationalLevel";
import { banners } from "./store/queries/Banners";
import { areaOfExpertise } from "./store/queries/AreaOfExpertise";
import { cms } from "./store/queries/CMS";
import { settings } from "./store/queries/Settings";
import { reportedChat } from "./store/queries/ReportedChat";
import { payment } from "./store/queries/Payment";
import { blog } from "./store/queries/blog";
import { coupon } from "./store/queries/Coupon";
import { couponRedeem } from "./store/queries/CouponRedeem";
import { premiumPlan } from "./store/queries/PremiumPlan";
import { profile } from "./store/queries/profile";
import { tutorReviews } from "./store/queries/TutorReviews";

// import 'bootstrap/dist/css/bootstrap.min.css';

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      student.middleware,
      tutor.middleware,
      premiumPlans.middleware,
      courses.middleware,
      faqs.middleware,
      dashboard.middleware,
      subjects.middleware,
      roles.middleware,
      educationalLevel.middleware,
      banners.middleware,
      areaOfExpertise.middleware,
      cms.middleware,
      reportedChat.middleware,
      payment.middleware,
      blog.middleware,
      coupon.middleware,
      couponRedeem.middleware,
      premiumPlan.middleware,
      profile.middleware,
      tutorReviews.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
