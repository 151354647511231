import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/PremiumPlan/premiumPlanSlice";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useCreatePlanDataMutation, useUpdateCreatePlanDataMutation } from "../../../../store/queries/PremiumPlan";

const useAddPlan = ({ refetch ,handleModalClick}) => {
  const dispatch = useDispatch();
  const { planData, isEdit, selectedPlan } =
    useSelector((state) => state.premiumPlan);

  const [updatePlanData] = useUpdateCreatePlanDataMutation();
  const [updateCreatePlanData] = useCreatePlanDataMutation();

  const validation = Yup.object({
    name: Yup.string().required("Enter name"),
    price_label: Yup.string()
    .min(2, "Price label must be atleast 2 Characters long")
    .max(30, "Price label not exceed 30 characters.")
    .required("Price label Required"),
    expiry_in_days: Yup.number()
    .typeError('Expiry in days must be a number')
    .required("Expiry in days Required"),
    amount: Yup.number()
    .typeError('Amount must be a number')
    .required("Amount Required"),
    feature:
        Yup.array().of(
          Yup.object().shape({
            feature: Yup.string().required("Feature Required")
          })
        ),
  });


  const [initialData, setInitialData] = useState({
    name: "",
      amount: "",
      expiry_in_days: "",
      price_label: "",
      is_active: true,
    feature: [
      {
        feature: "",
      },
    ],
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {

      let formData = new FormData();
      formData.append("name", values["name"]);
      formData.append("amount", values["amount"]);
      formData.append("expiry_in_days", values["expiry_in_days"]);
      formData.append("price_label", values["price_label"]);
      formData.append("is_active", values["is_active"]);

      values?.feature?.forEach((value, index) => {
        //  checking whether the new item is added to the list,if new item added 
        //  then selectedItemDetails?.content[index]?._id will be undefined
        // if (planData?.features[index]?._id) {
        //   formData.append(
        //     `feature[${index}][_id]`,
        //     planData?.features[index]?._id
        //   );
        // }
        formData.append(`feature[${index}]`, value?.feature);
      })

      if (isEdit) {
        updatePlanData({id: selectedPlan, formData: formData}).then(
          (response) => {
            
            if (response?.data?.statusCode === 200) {
              handleModalClick();
              toast.success("Premium Plan Details Updated");
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
            } else if (response?.error?.data?.statusCode === 422) {
              // displays backend errors
              const errors = response?.error?.data?.errors[0]?.message;
              formik.setErrors(errors);
              toast.error(errors);
            } else {
              toast.error("Something went wrong");
            }
          }
        );
      } else {
        updateCreatePlanData(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            toast.success("New Premium Plan Created");
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            toast.error(response?.error?.data?.errors[0]?.message);
            Object.keys(errors).forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      setInitialData({
        name: planData?.name,
        is_active: planData?.is_active,
        price_label: planData?.price_label,
        expiry_in_days: planData?.expiry_in_days,
        amount: planData?.amount,
        feature: planData?.features?.map((item, index) => ({
          feature: item ?? "",
        })),
      });
      
    }
    // eslint-disable-next-line
  }, [isEdit, planData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleAddField = () => {
    let { feature } = formik?.values;
    feature = [
      ...feature,
      {
        feature: "",
      },
    ];
    formik.setFieldValue("feature", feature);
  };


  return {
    formik,
    planData,
    getFieldError,
    isEdit,
    handleAddField
  };
};

export default useAddPlan;
