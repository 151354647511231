import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useBlog from "./useBlog";
import BlogForm from "./BlogForm";
import EmptyData from "../../Global/EmptyData";
import { ImageCard } from "../../Global/ImageCard";
import Style from './blog.module.scss';

const Blog = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleClearClick,
    updateTableFields,
    showCreateModal,
    closeModal,
    handleCreateClick,
    handleEditAction,
    showImageModal,
    closeImageModal,
    imageData,
  } = useBlog();

  return (
    <>
      <HeadingGroup
        title={"Blog"}
        className={`pro-mb-4`}
        buttonTitle={mainData?.data?.create_permission ? "Add new" : ""}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={mainData?.data?.filters}
              onDateChange={handleDateChange}
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: menuState?.search }}
            />
          </div>
        </div>

        {mainData?.data?.blogs?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.blogs || []}
            uniqueID={"_id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            showCheckBox={mainData?.data?.edit_permission || mainData?.data?.delete_permission}
            //fields={adons?.data?.fields}
            deletable={mainData?.data?.delete_permission}
            editable={mainData?.data?.edit_permission}
            handleDelete={handleDelete}
            handleEdit={handleEditAction}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
          />
        )}

        {mainData?.data?.blogs?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              // dataList?.data?.pagination?.total_count / dataState.currentPageSize
              mainData?.data?.total_count / menuState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
          <ModalLayout
        centered={false}
        show={showCreateModal}
        handleClose={() => closeModal()}
        title={menuState?.is_edit ? "Update Blog" : "Add Blog"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
        propStyle ={{
          root: Style.modal_root,
          root_body : Style.modal_body
        }}
      >
        <BlogForm closeModal={() => closeModal()} refetch={refetch}/>
      </ModalLayout>
          

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showImageModal} handleClose={closeImageModal} backdrop="static">
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Blog;
