import {
  OffCanvasLayout,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useMyClasses from "./useMyClasses";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import MyClassesFilter from "./myClassesFilter";

const MyClasses = () => {
  const {
    isLoading,
    isFetching,
    myClassesList,
    paginationOptions,
    myClassesState,
    handleFilterClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
    updateTableFields,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditColumnsClick,
  } = useMyClasses();

  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: myClassesState?.search }}
              showActions={true}
              handleActionClick={handleEditColumnsClick}
            />
          </div>

          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>

        <div className={`pro-pt-3 pro-pb-5 `}>
          {myClassesList?.data?.scheduledClassList?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={myClassesList?.data?.scheduledClassList || []}
              uniqueID={"id"}
              fields={myClassesList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={myClassesState?.clearSelection}
              perpage={myClassesState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
        </div>
        {myClassesList?.data?.scheduledClassList?.length > 0 && (
          <Pagination
            currentPage={myClassesState?.currentPage}
            totalPageCount={Math.ceil(
              myClassesList.data.total_count / myClassesState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={myClassesState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <MyClassesFilter
            isStickyFooter
            activityFilter={myClassesList?.data?.sessionStatusValues}
            handleFilterClick={handleFilterClick}
          />
        </OffCanvasLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(
                myClassesList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] = myClassesList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={myClassesList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default MyClasses;
