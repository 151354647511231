import React from "react";
import useDocumentForm from "./useDocumentForm";
import { Input, Image, Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import Assets from "../../../../assets/Assets";
import Style from '../../Tutors/tutor.module.scss';

const DocumentForm = ({ closeModal, refetch,DocumentStatuses }) => {
  const { formik } = useDocumentForm({ closeModal, refetch });
  return (
    <>
      <div className={`pro-w-100`}>
        <Input
          label={"Tutor"}
          type="text"
          id="name"
          name="name"
          disabled={true}
          className={`pro-input lg ${
            formik.errors.name && formik.touched.name && "error"
          }`}
          {...formik.getFieldProps("name")}
          error={formik.errors.name && formik.touched.name}
          errorMessage={formik.errors.name}
        />

        {formik?.values?.documents?.map((item, index) => (
          <div key={item.index} className={`pro-mb-4 ${Style.document_item}`}>
            {/* <p>{item?.document_name}</p> */}
            <div className="row gx-4">
              <div className="col">
                <label>{`Document ${index+1}`}</label>
                <div className={Style.img_box}>
                  <Image
                    src={Assets.PDFLOGO}
                    width="48px"
                    height="48px"
                    alt={"pdf"}
                    propStyle={{root: Style.image}}
                  />
                </div>
              </div>
              <div className="col">
              <label>{`Status`}</label>
              <div className="input-wrap">
                <Select
                  id={item.index}
                  name={`documents[${item.index}].document_status`}
                  placeholder={"Select"}
                  className={`pro-input lg  ${
                    formik?.errors?.documents?.[item.index]?.document_status &&
                    formik.touched?.documents?.[item.index]?.document_status &&
                    " error"
                  }`}
                  classNamePrefix="pro-input"
                  options={DocumentStatuses}
                  getOptionLabel={(option) => option?.value}
                  getOptionValue={(option) => option?.value}
                  value={DocumentStatuses?.filter(
                    (m) =>
                      formik?.values?.documents[item.index].document_status ===
                      m?.value
                  )}
                  onChange={(value) =>
                    formik?.setFieldValue(
                      `documents[${item.index}].document_status`,
                      value.value || null
                    )
                  }
                  menuPlacement="auto"
                />
                {formik?.errors?.documents?.[item.index]?.document_status &&
                  formik.touched?.documents?.[item.index]?.document_status && (
                    <span className="error-text">
                      {formik?.errors?.documents?.[item.index]?.document_status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className={`col-12 pro-d-flex pro-justify-end`} >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            // disabled={hasUpdatePermission === true ? false : true}
            onClick={formik.handleSubmit}
          >
            {"Update"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default DocumentForm;
