import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import usePremiumPlanPurchase from "./usePremiumPlanPurchase";
import PremiumPlanPurchaseFilter from "./PremiumPlanPurchaseFilter";
import PremiumPlanDetails from "./PremiumPlanDetails";
import Style from "./plan.module.scss";

const PremiumPlanPurchase = () => {
  const {
    premiumPlanPurchaseState,
    showEditModal,
    premiumPlanPurchaseList,
    isLoading,
    isFetching,
    paginationOptions,
    currentPage,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilterClick,
    updateTableFields,
    handleEditColumnsClick,
    closeModal,
  } = usePremiumPlanPurchase();
  return (
    <div>
      <HeadingGroup title={"Premium Plan Purchase"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: premiumPlanPurchaseState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={premiumPlanPurchaseList?.data?.premium_plan_purchase_list || []}
          uniqueID={"_id"}
          fields={premiumPlanPurchaseList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={premiumPlanPurchaseState?.currentPageSize}
          assignable={false}
          deletable={premiumPlanPurchaseList?.data?.delete_permission}
          editable={premiumPlanPurchaseList?.data?.edit_permission}
          showCheckBox={false}
        />
        {premiumPlanPurchaseList?.data?.premium_plan_purchase_list?.length >
          0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              premiumPlanPurchaseList.data.filtered_count /
                premiumPlanPurchaseState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === premiumPlanPurchaseState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(
                premiumPlanPurchaseList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] =
                  premiumPlanPurchaseList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={premiumPlanPurchaseList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={premiumPlanPurchaseState?.showCreateModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <PremiumPlanPurchaseFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
          />
        </OffCanvasLayout>

        <ModalLayout
          centered={false}
          show={premiumPlanPurchaseState.showDetailsModal}
          handleClose={() => closeModal()}
          title={"Premium Plan Details"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          propStyle={{ root: Style.modal_root }}
        >
          <PremiumPlanDetails
            closeModal={() => closeModal()}
            refetch={refetch}
          />
        </ModalLayout>
      </div>
    </div>
  );
};

export default PremiumPlanPurchase;
