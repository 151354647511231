import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import usePremiumPlanLists from "./usePremiumPlanLists";
import AddPlan from "./AddPlan";

const PremiumPlanLists = () => {
  const {
    planState,
    showEditModal,
    planList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
    handleFilterClick,
    handleModalClick,
    handleEditAction,
    handleDelete,
    handleDeleteModal,
    hasDeletePermission
  } = usePremiumPlanLists();
  return (
    <div>
      <HeadingGroup
        title={"Premium Plan"}
        className={`pro-mb-4`}
        buttonTitle={planList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters
              handleClear={handleClearClick}
              searchInputProps={{ value: planState?.search }}
              handleActionClick={handleEditColumnsClick}
            />
          </div>
        </div>

        <Table
          multiSelect={false}
          data={planList?.data?.premium_plans || []}
          uniqueID={"_id"}
          fields={planList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={planState?.currentPageSize}
          assignable={false}
          deletable={hasDeletePermission}
          editable={planList?.data?.edit_permission}
          showCheckBox={hasDeletePermission}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          clear={planState.clearSelection}
        />
        {planList?.data?.premium_plans?.length > 0 && (

          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              planList?.data?.total_count / planState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

<ModalLayout
          show={planState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Plan ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(planList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = planList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={planList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={planState?.showCreateModal}
          handleClose={handleModalClick}
          title={planState?.isEdit ? "Update Premium Plan" : "Add Premium Plan"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddPlan refetch={refetch} handleModalClick={handleModalClick} />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default PremiumPlanLists;
