import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useRefundList from "./useRefundList";
import RefundFilter from "./RefundFilter";
import EmptyData from "../../Global/EmptyData";
import Style from "./refundList.module.scss";

const RefundList = ({ dashboard = false }) => {
  const {
    refundState,
    showEditModal,
    refundList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    updateTableFields,
    handleFilterClick,
    handleEditColumnsClick,
    handleDashboardRedirect,
  } = useRefundList({ dashboard });
  return (
    <div>
      <HeadingGroup title={"Refunds"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {!dashboard && (
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: refundState?.search }}
                handleActionClick={handleEditColumnsClick}
              />
            </div>
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
                onClick={handleFilterClick}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          </div>
        )}

        <div className={`pro-pt-3 pro-pb-5 `}>
          {refundList?.data?.refundRequestList?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                multiSelect={false}
                data={refundList?.data?.refundRequestList || []}
                uniqueID={"_id"}
                fields={refundList?.data?.fields || []}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={refundState?.currentPageSize}
                assignable={false}
                deletable={refundList?.data?.delete_permission}
                editable={refundList?.data?.edit_permission}
                showCheckBox={false}
              />
            </div>
          )}
        </div>
        {refundList?.data?.refundRequestList?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              refundList.data.filtered_count / refundState.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === refundState.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(refundList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = refundList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={refundList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={refundState?.showCreateModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <RefundFilter 
          isStickyFooter 
          handleFilterClick={handleFilterClick}
          activityFilter={refundList?.data?.refundStatusesValues}
           />
        </OffCanvasLayout>
      </div>
    </div>
  );
};

export default RefundList;
