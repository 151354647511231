import { getAxiosInstance } from "../../../api";

export const getAutoCompleteUserData = async (param) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/api/testimonials/users-search?name=${param.name}&user_type=${param.type}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const createPolicy = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`admin/api/privacy-and-policy`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateAboutUs = async (formData, id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`admin/api/about-us/${id}`, formData);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteClient = async (id) => {
  const api = await getAxiosInstance();

  try {
    const response = await api.delete(`admin/api/clients/${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
