import React from "react";
import useAddCoupon from "./useAddCoupon";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const AddCoupon = ({ refetch, handleModalClick, dropdown }) => {
  const { formik, getFieldError, isEdit, outputArray, selectVal } =
    useAddCoupon({ refetch, handleModalClick, dropdown });

  return (
    <div className={`row`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Code*"}
        type="text"
        id="code"
        name="code"
        className={`pro-input lg ${getFieldError("code") && " error"}`}
        {...formik.getFieldProps("code")}
        error={getFieldError("code")}
        errorMessage={getFieldError("code")}
      />
      <Input
        label={"User Count*"}
        type="text"
        id="users_count"
        name="users_count"
        className={`pro-input lg ${getFieldError("users_count") && " error"}`}
        {...formik.getFieldProps("users_count")}
        error={getFieldError("users_count")}
        errorMessage={getFieldError("users_count")}
      />

      <Input
        label={"From Date*"}
        type="date"
        id="date_from"
        value={formik?.values?.date_from}
        name="date_from"
        className={`pro-input lg ${getFieldError("date_from") && " error"}`}
        onChange={(e) => formik.setFieldValue("date_from", e.target.value)}
        error={getFieldError("date_from")}
        errorMessage={getFieldError("date_from")}
      />
      <Input
        label={"To Date*"}
        type="date"
        id="date_to"
        value={formik.values.date_to}
        name="date_to"
        min={formik.values.date_from}
        onChange={(e) => formik.setFieldValue("date_to", e.target.value)}
        disabled={!formik.values.date_from}
        className={`pro-input lg ${getFieldError("date_to") && " error"}`}
        error={getFieldError("date_to")}
        errorMessage={getFieldError("date_to")}
      />

      <Input
        label={"Discount Amount*"}
        type="number"
        id="discount_amount"
        name="discount_amount"
        className={`pro-input lg ${
          getFieldError("discount_amount") && " error"
        }`}
        {...formik.getFieldProps("discount_amount")}
        error={getFieldError("discount_amount")}
        errorMessage={getFieldError("discount_amount")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type*
        </label>
        <Select
          id="type"
          placeholder="Select type"
          name="type"
          isClearable={true}
          options={outputArray}
          value={selectVal}
          className={`pro-input multi-select lg ${
            formik.errors.type && formik.touched.type && " error"
          }`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("type")}
          onChange={(e) => formik?.setFieldValue("type", e?.value || null)}
        />
        {formik.touched.type && formik.errors.type && (
          <span className="error-text">{formik.errors.type}</span>
        )}{" "}
      </div>

      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="is_active"
            name="is_active"
            type="checkbox"
            checked={Boolean(formik.values?.is_active) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "is_active",
                !Boolean(formik.values?.is_active) ?? 0
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddCoupon;
