import React from "react";
import {
  useGetRefundSettlementListQuery,
  useUpdateRefundSettlementTableMutation,
  useUpdateStatusMutation,
} from "../../../store/queries/Payment";
import { useDispatch, useSelector } from "react-redux";
import {
  ExportRefund,
  updateConfig,
} from "../../../store/slices/Payment/RefundSettlement/RefundSettlementSlice";
import { useEffect, useState } from "react";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

const useRefundSettlement = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );

    //eslint-disable-next-line
  }, []);

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const refundState = useSelector((state) => state.refundSettlement);
  const [confirmSelect, setConfirmSelect] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const {
    data: refundList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetRefundSettlementListQuery({
    page_size: refundState?.currentPageSize,
    page: refundState?.currentPage,
    sort_by: refundState?.sortBy,
    sort_order: refundState?.sortOrder,
    filter: refundState?.currentFilter,
    search: refundState?.search,
    start_date: refundState.startDate,
    end_date: refundState.endDate,
    status_type: refundState?.statusType,
    userType: refundState.userType,
    subject_id: refundState.subject,
    specialization_id: refundState?.specialization,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateRefundSettlementTableMutation();
  const [updateStatus] = useUpdateStatusMutation();

  const outputArray = refundList?.data?.refundStatusesValues?.map((item) => {
    return {
      value: item,
      label: item
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
    };
  });

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isFilter = true;
      })
    );
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.selectedSubject = "";
        state.refundSettlementData = [];
        state.imagePreview = "";
        state.imageName = "";
        state.clearSelection = true;
      })
    );
  };

  // const hasCreatePermission = useMemo(() => {
  //   let permission = roles?.data?.permission?.filter((p) =>
  //     Object.keys(p).includes("create_permission")
  //   );
  //   return permission?.[0]?.create_permission ?? 0;
  // }, [refundList]);
  // const hasEditPermission = useMemo(() => {
  //   let permission = refundList?.data?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [refundList]);
  // const hasUpdatePermission = useMemo(() => {
  //   let permission = refundList?.data?.permission?.filter((p) =>
  //     Object.keys(p).includes("can_update")
  //   );
  //   return permission?.[0]?.can_update ?? 0;
  // }, [refundList]);

  const getRow = (field, data) => {
    const rows = {
      total_amount: (field, data) => (
        <p className="pro-mb-0">{"$" + data[field]}</p>
      ),
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,

      to_date: (field, data) => {
        if (data?.to_date) {
          const dateObject = new Date(data.to_date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      from_date: (field, data) => {
        if (data?.from_date) {
          const dateObject = new Date(data.from_date);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      status: (field, data) => {
        let selectVal = outputArray.find((item) => item.value === data[field]);

        return (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg`}
            classNamePrefix="pro-input"
            options={outputArray}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={selectVal}
            onChange={(selectedOption) => {
              const formData = {
                status: selectedOption?.value,
              };
              setSelectedId(data?._id);
              setSelectedStatus(selectedOption?.value);
              if (selectedOption?.value !== "COMPLETED") {
                updateStatus({ body: formData, id: data?._id })
                  .then((response) => {
                    if (response?.data?.success) {
                      toast.success("Status Updated Successfully!");
                      refetch?.();
                    } else if (!response?.data?.success) {
                      toast.error("Failed to Update Status!");
                    } else {
                      toast.error("Failed to Update Status!");
                    }
                  })
                  .catch(() => {
                    toast.error("Failed to Update Status!");
                  });
              } else {
                setConfirmSelect(true);
              }
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            isDisabled={data[field] === "COMPLETED" ? true : false}
          />
        );
      },

      action: (feild, data) => (
        <button
          className="pro-btn pro-btn-primary"
          onClick={() => handleExportClick(feild, data)}
        >
          Export
        </button>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleExportClick = (field, data) => {
    dispatch(ExportRefund(data?._id)).then((res) => {
      if (res?.payload) {
        handleXlDownloadClick(res?.payload?.data?.export_link, data?._id);
      }
    });
  };

  const handleXlDownloadClick = (imageUrl, imageName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a Blob URL for the image blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element
        const downloadLink = document.createElement("a");

        // Set the href attribute to the Blob URL
        downloadLink.href = blobUrl;

        // Set the download attribute with the desired filename
        downloadLink.download = imageName || "downloaded_image";

        // Append the anchor element to the document
        document.body.appendChild(downloadLink);

        // Trigger a click on the anchor element to start the download
        downloadLink.click();

        // Remove the anchor element from the document
        document.body.removeChild(downloadLink);

        // Revoke the Blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        toast.error("Error downloading image:", error);
      });
  };

  useEffect(() => {
    if (refundState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [refundState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (refundState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = refundState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleCancelConfirm = () => {
    setConfirmSelect(false);
  };

  const handleConfirmChangeStatus = () => {
    const formData = {
      status: selectedStatus,
    };
    updateStatus({ body: formData, id: selectedId })
      .then((response) => {
        if (response?.data?.success) {
          toast.success("Status Updated Successfully!");
          setConfirmSelect(false);
          refetch?.();
        } else if (!response?.data?.success) {
          toast.error("Failed to Update Status!");
        } else {
          toast.error("Failed to Update Status!");
        }
      })
      .catch(() => {
        toast.error("Failed to Update Status!");
      });
  };

  return {
    refundState,
    showEditModal,
    tableFields,
    refundList,
    isLoading,
    isFetching,
    paginationOptions,
    confirmSelect,
    handleConfirmChangeStatus,
    handleCancelConfirm,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    activeFilter: refundState.currentFilter,
    currentPage: refundState.currentPage,
    updateTableFields,
    handleEditColumnsClick,
    handleFilterClick,
    handleModalClick,
  };
};

export default useRefundSettlement;
