import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useReports = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "Reports",
      label: "Report",
      link: "/reported-chat",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">bar_chart_4_bars</span>,
      active: checkIfActiveRoute("/reported-chat", true),
    },
  ];
  return { drawerMenu };
};

export default useReports;
