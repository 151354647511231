import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  filter: "",
  showCreateModal: false,
  showDeleteModal: false,
  showFilterModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  isFilter: false,
};

const myClassesSlice = createSlice({
  name: "myclasses",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = myClassesSlice.actions;
export default myClassesSlice.reducer;
