import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}filters=${params?.filter || ""}&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&${getParams("zip_code")}page_size=${
            params?.page_size || "10"
          }&${getParams("search")}page_no=${params?.page || 1}&status=${
            params?.status || ""
          }&timezone=${currentTimezone}&month=${params?.month || ""}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const tutor = createApi({
  reducerPath: "tutorApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Tutor",
    "OverView",
    "MyClasses",
    "Reports",
    "Enquiry",
    "MySubjects",
    "Schedules",
    "Students",
    "BgVerification",
    "TaxForm",
    "TutorDetail-Payouts",
    "Payouts List",
    "TutorDocuments",
    "AllTutorDocuments",
    "Payout List Detail",
    "Onboarding-Tutors",
  ],
  endpoints: (builder) => ({
    // branches starts
    getAssessmentSubmissionListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/assessment-submissions`,
      }),
      providesTags: ["Tutor"],
    }),
    getTutorsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutors`,
      }),
      providesTags: ["Tutor"],
    }),
    updateTableListFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Tutor"],
    }),
    getTutorBasicData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `admin/api/tutor/basic-details/${id}`,
      }),
      providesTags: ["Tutor"],
    }),
    updateTutorStatus: builder.mutation({
      query: (id) => ({
        method: "put",
        endpoint: `admin/api/tutor-status/${id}`,
      }),
      invalidatesTags: ["Tutor"],
    }),
    //onboarding tutors starts here
    getOnboardingTutorsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/onboarding-tutors`,
      }),
      providesTags: ["Onboarding-Tutors"],
    }),
    //onboarding tutors ends here
    // Over View Starts here
    getOverViewData: builder.query({
      query: (id) => ({
        method: "get",
        endpoint: `admin/api/tutor/overview/${id}`,
      }),
      providesTags: ["OverView"],
    }),
    // Over view ends here
    // MyClasses Starts here
    getMyClassesData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/my-classes/${values.user_id}`,
      }),
      providesTags: ["MyClasses"],
    }),
    updateMyClassesDataTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MyClasses"],
    }),
    // MyClasses ends here
    // reports Starts here
    getReportsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/reported-chats/${values.user_id}`,
      }),
      providesTags: ["Reports"],
    }),
    updateReportsTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Reports"],
    }),
    //reports ends here
    // enquiry Starts here
    getEnquiryData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/enquiry-list/${values.user_id}`,
      }),
      providesTags: ["Enquiry"],
    }),
    updateEnquiryTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Enquiry"],
    }),
    //enquiry ends here
    // MySubjects Starts here
    getMySubjectsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/${values.user_id}/subjects`,
      }),
      providesTags: ["MySubjects"],
    }),
    updateMySubjectsTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["MySubjects"],
    }),
    //MySubjects ends here
    // Scheduled Starts here
    getScheduledData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/${values.user_id}/schedules`,
      }),
      providesTags: ["Schedules"],
    }),
    updateSchedulesTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Schedules"],
    }),
    //Scheduled ends here
    // TutorStudents Starts here
    getTutorStudentsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/${values.user_id}/students`,
      }),
      providesTags: ["Students"],
    }),
    updateTutorStudentsTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Students"],
    }),
    //TutorStudents ends here
    // Tutor BG Verification Starts here
    getTutorBgVerificationData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutors/bg-verification`,
      }),
      providesTags: ["BgVerification"],
    }),
    updateTutorBgVerificationTableFields: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["BgVerification"],
    }),
    //Tutor BG Verification ends here
    //Tutor Documents starts here
    getTutorDocumentData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/${values?.tutor_id}/document-list`,
      }),
      providesTags: ["TutorDocuments"],
    }),
    //Tutor Documents ends here
    //ALL Tutor Documents starts here
    getAllTutorDocumentData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutor/documents`,
      }),
      providesTags: ["AllTutorDocuments"],
    }),
    //ALL Tutor Documents ends here

    //Tutor Side Panel Payots starts here
    getPayoutListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/payouts/payments`,
      }),
      providesTags: ["Payout List"],
    }),
    updatePayoutListTableFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["Payout List"],
    }),

    // updatePayoutListStatus: builder.mutation({
    //   query: ({ body, id }) => ({
    //     method: "put",
    //     body,
    //     endpoint: `admin/api/payouts/${id}`,
    //   }),

    //   invalidatesTags: ["Payouts List"],
    // }),

    //Tutor Side Panel Payouts ends here

    //Tutor Tax Form Starts Here
    getTaxFormData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tax-form`,
      }),
      providesTags: ["TaxForm"],
    }),
    updateTaxFormTableFieldsData: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["TaxForm"],
    }),
    updateTaxFormStatus: builder.mutation({
      query: ({ body, id }) => ({
        method: "put",
        body,
        endpoint: `admin/api/tax-form-status/${id}`,
      }),

      invalidatesTags: ["TaxForm"],
    }),
    //Tutor Tax Form Ends Here
    //Tutor Detail payouts starts here
    getTutorDetailPayoutData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/tutors/${values?.tutor_id}/payouts`,
      }),
      providesTags: ["TutorDetail-Payouts"],
    }),
    //Tutor Detail payouts ends here

    getPremiumPlanData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/premium-plan-list`,
      }),
      providesTags: ["Tutor"],
    }),
  }),
  // branches ends
});

export const {
  useGetAssessmentSubmissionListDataQuery,
  useGetTutorsListDataQuery,
  useGetOnboardingTutorsListDataQuery,
  useUpdateTableListFieldsDataMutation,
  useGetTutorBasicDataQuery,
  useGetOverViewDataQuery,
  useGetMyClassesDataQuery,
  useGetReportsDataQuery,
  useGetEnquiryDataQuery,
  useGetMySubjectsDataQuery,
  useGetScheduledDataQuery,
  useUpdateMyClassesDataTableFieldsMutation,
  useUpdateEnquiryTableFieldsMutation,
  useUpdateReportsTableFieldsMutation,
  useUpdateMySubjectsTableFieldsMutation,
  useUpdateSchedulesTableFieldsMutation,
  useUpdateTutorStatusMutation,
  useGetTutorStudentsDataQuery,
  useUpdateTutorStudentsTableFieldsMutation,
  useGetTutorBgVerificationDataQuery,
  useUpdateTutorBgVerificationTableFieldsMutation,
  useGetTutorDocumentDataQuery,
  useGetAllTutorDocumentDataQuery,
  useGetPayoutListDataQuery,
  useGetTutorPayoutsDetailListQuery,
  useUpdatePayoutListTableFieldsDataMutation,
  useUpdatePayoutListStatusMutation,
  useGetTaxFormDataQuery,
  useUpdateTaxFormTableFieldsDataMutation,
  useGetTutorDetailPayoutDataQuery,
  useUpdateTaxFormStatusMutation,
  useGetPremiumPlanDataQuery,
} = tutor;
