import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "desc",
  allRoles: [],
  allRolesStatus: "idle",
  allPermissions: [],
  allPermissionsStatus: "idle",
  rolePermission: {},
  rolePermissionStatus: "idle",
  clearSelection: false,
  showDeleteModal: false,
  selectedId: ''
};

export const getAllRoles = createAsyncThunk(
  "roles/getAllRoles",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/api/roles`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/api/get-all-permisiions`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPermission = createAsyncThunk(
  "roles/getPermission",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/admin/api/role-based-menu-permissions/${params.id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRole = async (id) => {
  const api = await getAxiosInstance();

  try {
    const response = await api.delete(`admin/api/roles/${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: {
    [getAllRoles.pending]: (state) => {
      state.allRolesStatus = "pending";
    },
    [getAllRoles.fulfilled]: (state, action) => {
      state.allRolesStatus = "success";
      state.allRoles = action.payload?.data?.data?.role_details_list;
    },
    [getAllRoles.rejected]: (state) => {
      state.allRolesStatus = "failed";
    },
    [getAllPermissions.pending]: (state) => {
      state.allPermissionsStatus = "pending";
    },
    [getAllPermissions.fulfilled]: (state, action) => {
      state.allPermissionsStatus = "success";
      state.allPermissions = action.payload?.data?.data;
    },
    [getAllPermissions.rejected]: (state) => {
      state.allPermissionsStatus = "failed";
    },
    [getPermission.pending]: (state) => {
      state.rolePermissionStatus = "pending";
    },
    [getPermission.fulfilled]: (state, action) => {
      state.rolePermission[action.meta.arg.id] = action.payload?.data?.data;
      state.rolePermissionStatus = "fulfilled";
    },
    [getPermission.rejected]: (state) => {
      state.rolePermissionStatus = "rejected";
    },
  },
});

export const { updateConfig } = roleSlice.actions;

export default roleSlice.reducer;
