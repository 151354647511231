import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllTutorDocumentDataQuery,
  useUpdateTableListFieldsDataMutation,
} from "../../../store/queries/Tutor";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "../Tutors/tutor.module.scss";
import { updateConfig } from "../../../store/slices/Tutor/AllTutorDocuments/AllTutorDocumentsSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { addDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {useNavigate} from "react-router-dom";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useAllTutorDocuments = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.status = "";
      })
    );

}, []);
  const navigate = useNavigate(); 
  const AllTutorDocumentState = useSelector((state) => state.allTutorDocument);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: AllDocumentData = {},
    isFetching,
    isLoading,
    refetch,
    isError
  } = useGetAllTutorDocumentDataQuery({
    page_size: AllTutorDocumentState?.currentPageSize,
    page: AllTutorDocumentState?.currentPage,
    sort_by: AllTutorDocumentState?.sortBy,
    sort_order: AllTutorDocumentState?.sortOrder,
    search: AllTutorDocumentState?.search,
    status: AllTutorDocumentState?.status?.value,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const hasEditPermission = useMemo(() => {
    return AllDocumentData?.data?.edit_permission ?? false;
  }, [AllDocumentData]);

  useEffect(() => {
    if (AllTutorDocumentState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [AllTutorDocumentState.clearSelection]);

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const DocumentStatuses = [
    { id: 1, value: "PENDING" },
    { id: 2, value: "CONTENT_APPROVED" },
    { id: 3, value: "CONTENT_DENIED" },
  ];

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      tutor: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.["name"])}
            </span>
          </div>
        );
      },
      url: (field, data) =>
        data["documents"]?.map((item) => (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container} ${Style.inner_row}`}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={Assets.PDFLOGO}
                width={15}
                height={15}
                alt={data?.title}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary `}
              onClick={() => handleImageDownloadClick(item.url, item.title)}
            >
              {item?.title}
            </span>
          </div>
        )),
      verified_status: (field, data) =>
        data["documents"]?.map((item) => (
          <div key={item?._id} className={`${Style.inner_row} `}>
            {item.verified_status === "CONTENT_APPROVED" ? (
              <span className="pro-badge sm badge-approved">
                Content Approved
              </span>
            ) : item?.verified_status === "PENDING" ? (
              <span className="pro-badge sm badge-pending ">Pending</span>
            ) : item?.verified_status === "CONTENT_DENIED" ? (
              <span className="pro-badge sm badge-denied">Content Denied</span>
            ) : (
              ""
            )}
          </div>
        )),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (AllTutorDocumentState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            AllTutorDocumentState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  // const handleActionChange = (item) => {
  //   if (item?.value === 0) {
  //     dispatch(
  //       globalUpdateConfig((state) => {
  //         state.showEditModal = true;
  //       })
  //     );
  //   }
  // };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const handleEditAction = (data) => {
    const tempData = AllDocumentData?.data?.TutorDocuments.filter(
      (item) => item?.tutor_id === data?.[0]
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
        state.selectedItemDetails = tempData;
      })
    );
  };

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleImageDownloadClick = (imageUrl, imageName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a Blob URL for the image blob
        const blobUrl = URL.createObjectURL(blob);

        // Create an anchor element
        const downloadLink = document.createElement("a");

        // Set the href attribute to the Blob URL
        downloadLink.href = blobUrl;

        // Set the download attribute with the desired filename
        downloadLink.download = imageName || "downloaded_image";

        // Append the anchor element to the document
        document.body.appendChild(downloadLink);

        // Trigger a click on the anchor element to start the download
        downloadLink.click();

        // Remove the anchor element from the document
        document.body.removeChild(downloadLink);

        // Revoke the Blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => {
        toast.error("Error downloading image:", error);
      });
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedTutor = "";
      })
    );
  };

  const handleSelectStatusFilter = (data) => {
    dispatch(
      updateConfig((state) => {
        state.status = data;
      })
    );
  };

  return {
    AllDocumentData,
    isFetching,
    AllTutorDocumentState,
    isLoading,
    actionOptions,
    date,
    paginationOptions,
    showEditModal,
    DocumentStatuses,
    handleSelectStatusFilter,
    updateTableFields,
    closeEditModal,
    handlePageSize,
    handlePagination,
    refetch,
    handleClose,
    handleEditAction,
    // handleEditClick,
    handleClearClick,
    // handleActionChange,
    handleSearch,
    handleSort,
    getRow,
    handleEditColumnsClick,
    hasEditPermission
  };
};

export default useAllTutorDocuments;
