import React from "react";
import useAddStaff from "./useAddStaff";
import {
  Button,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";

const AddStaff = ({ refetch, handleModalClick, dropdown }) => {
  const {
    formik,
    getFieldError,
    isEdit,
    outputArray,
    selectVal,
    showPassword,
    handleShowPassword
  } = useAddStaff({ refetch, handleModalClick, dropdown });

  return (
    <div className={`row`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <Input
        label={"Email*"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />
      {!isEdit && (

<div className="input-wrap pro-mb-5 p-re">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Password
        </label>
        <input
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="password"
          autoComplete="on"
          className={`pro-input lg ${
            formik.errors.password && formik.touched.password && "error"
          } hide-icon-input`}
        />

        {showPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowPassword(e)}
          >
            <span className="material-symbols-outlined">visibility</span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowPassword(e)}
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        )}
        {formik.errors.password && formik.touched.password && (
          <span className="error-text">{formik?.errors?.password}</span>
        )}
      </div>
      )}
      

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Role
        </label>
        <Select
          id="role_id"
          placeholder="Select Role"
          name="role_id"
          isClearable={true}
          options={outputArray}
          value={selectVal}
          className={`pro-input multi-select lg ${formik.errors.role_id && formik.touched.role_id && " error"
            }`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("role_id")}
          onChange={(e) => formik?.setFieldValue("role_id", e?.value || null)}
        />
        {formik.touched.role_id &&
          formik.errors.role_id && (
            <span className="error-text">
              {formik.errors.role_id}
            </span>
          )}{" "}
      </div>

      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="is_active"
            name="is_active"
            type="checkbox"
            checked={Boolean(formik.values?.is_active) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "is_active",
                !Boolean(formik.values?.is_active) ?? 0
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddStaff;
