import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useCoupons = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Coupons",
      label: "Coupons",
      link: "/coupon/coupons",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">more</span>
      ),
      active: checkIfActiveRoute("/coupon/coupons", true),
    },
    {
      title: "Coupon Redeem",
      label: "Coupon Redeem",
      link: "/coupon/coupon-redeem",
      iconAsset: "DrawerIcon1",
      icon: (
        <span className="material-symbols-outlined x4">redeem</span>
      ),
      active: checkIfActiveRoute("/coupon/coupon-redeem", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenuPermission };
  // return { drawerMenu };
};

export default useCoupons;
