import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { getGlobalSearchData } from "./api";
import { updateConfig } from "../../../../store/slices/Global";
import { logout } from "./api";
import { toast } from "react-toastify";

const useHeaderActions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isProfileViewVisible, setProfileViewVisible] = useState(false);
  const searchInputRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const globalState = useSelector((state) => state.global);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setIsModalOpen(true);
        focusSearchInput();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(
      updateConfig((state) => {
        state.searchKey = "";
        state.searchData = {};
      })
    );
  };

  // useEffect(() => {
  //   if (isProfileViewVisible)
  //   dispatch(
  //     updateConfig((state) => {
  //       state.isProfileViewVisible = false;
  //       setProfileViewVisible(false);
  //     })
  //   );
  // }, [isProfileViewVisible]);

  const handleViewProfile = () => {
    setProfileViewVisible(true);
    // toast.warning("coming soon");
  };

  const handleCloseProfileView = () => {
    setProfileViewVisible(false);
  };

  const handleLogout = () => {
    // logout().then((response) => {
    //   if (response?.data?.status_code === 200) {
    //     toast.success("Logout Success");
        localStorage.removeItem("USER_ACCESS_TOKEN");
        localStorage.removeItem("dashboard_permissions");
        // globalState.dashboard_permissions.permissions = [];
        dispatch(
          updateConfig((state) => {
            state.dashboard_permissions = {};
          })
        )
        localStorage.clear();
        navigate("/");
    //   } else {
    //     toast.error("Something went wrong");
    //   }
    // });
  };

  const handleSettings = () => {
    // navigate("/settings/appearance");
    toast.warning("coming soon");
  };

  return {
    isModalOpen,
    searchInputRef,
    globalState,
    setIsModalOpen,
    closeModal,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleCloseProfileView,
    isProfileViewVisible,
    setProfileViewVisible,
  };
};

export default useHeaderActions;
