import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useBgCheck from "./useBgCheck";
import BgCheckFilter from "./BgCheckFilter";
import Style from "./bgCheck.module.scss";
import BgCheckDetails from "./BgCheckDetails";
import EmptyData from "../../Global/EmptyData";

const BgCheck = ({ dashboard = false }) => {
  const {
    bgVerficationState,
    showEditModal,
    bgVerficationList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleSort,
    handlePagination,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    handleFilterClick,
    currentPage,
    updateTableFields,
    handleEditColumnsClick,
    closeModal,
    handleDashboardRedirect,
  } = useBgCheck({dashboard});
  return (
    <div>
      <HeadingGroup title={"Background Check"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {!dashboard ? (
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
              handleClear={handleClearClick}
                searchInputProps={{ value: bgVerficationState?.search }}
                handleActionClick={handleEditColumnsClick}
              />
            </div>
            <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
          </div>
        ) : (
          <></>
        )}

        <div className={`pro-pt-3 pro-pb-5 `}>
          {bgVerficationList?.data?.bg_verifications_list?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                multiSelect={false}
                data={bgVerficationList?.data?.bg_verifications_list || []}
                uniqueID={"_id"}
                fields={bgVerficationList?.data?.fields || []}
                SortIcon={<FaSort />}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={bgVerficationState?.currentPageSize}
                assignable={false}
                deletable={bgVerficationList?.data?.delete_permission}
                editable={bgVerficationList?.data?.edit_permission}
                showCheckBox={false}
              />
            </div>
          )}
        </div>
        {bgVerficationList?.data?.bg_verifications_list?.length > 0 &&
          !dashboard && (
            <Pagination
              currentPage={currentPage}
              totalPageCount={Math.ceil(
                bgVerficationList.data.filtered_count /
                  bgVerficationState.currentPageSize
              )}
              onPageChange={handlePagination}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === bgVerficationState.currentPageSize
              )}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        <ModalLayout show={showEditModal} handleClose={handleEditColumnsClick}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(
                bgVerficationList?.data?.fields ?? {}
              ).reduce((filteredObj, property) => {
                filteredObj[property] =
                  bgVerficationList?.data?.fields[property];
                return filteredObj;
              }, {})}
              moduleId={bgVerficationList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={bgVerficationState?.showCreateModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <BgCheckFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
            activityFilter={bgVerficationList?.data?.bg_verification_statuses}
          />
        </OffCanvasLayout>

        <ModalLayout
          centered={false}
          show={bgVerficationState.showDetailsModal}
          handleClose={() => closeModal()}
          title={"Background Check Details"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          propStyle={{ root: Style.modal_root }}
        >
          <BgCheckDetails closeModal={() => closeModal()} refetch={refetch} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default BgCheck;
