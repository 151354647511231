import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddMeta from "./useAddMeta";
import ItemField from "./ItemFieldClick";
import { limitStrLength } from "../../../../utils/functions/table";
import Style from "./addMetaForm.module.scss";
import Select from "react-select";

const AddMeta = ({ refetch, handleModalClick }) => {
  const {
    getFieldError,
    formik,
    isEdit,
    handleAddField,
    imagePreview,
    setImageName,
    imageCoverPreview,
    handleCoverImage,
    profilefileInputRef,
    selectedItemDetails,
    typeList,
    useDataOptions,
    userVal,
    handleInputChange,
    setUseDataOptions,
    handleContentChange
  } = useAddMeta(handleModalClick, refetch);


  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search here...";

  return (
    <div className={`row`}>
      <div className="col pro-mb-4">
        <div className="input-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Search Meta Type *
          </label>

          <Select
            id="type"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.type && formik.errors.type && " error"
            }`}
            classNamePrefix="pro-input"
            options={typeList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={typeList?.filter((m) => formik.values.type === m?.value)}
            onBlur={formik.handleBlur("type")}
            onChange={(selectedOption) =>
              {
                formik.setFieldValue("type", selectedOption?.value)
                formik.setFieldValue("slug", "")
                setUseDataOptions([])
              }
            }
            isDisabled={isEdit}
          />
          {formik.touched?.type && formik.errors?.type && (
            <div className="error-text">{formik.errors?.type}</div>
          )}
        </div>
      </div>
      {formik.values.type === "CITY" ? (
        <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          City
        </label>
        <Select
          id="slug"
          placeholder="Search for a city..."
          name="slug"
          noOptionsMessage={noOptionsMessage}
          isClearable={false}
          options={useDataOptions}
          value={userVal}
          onInputChange={(value) => 
            handleInputChange(value, "CITY")
          }
          className={`pro-input multi-select lg ${
            formik.errors.slug && formik.touched.slug && " error"
          }`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("slug")}
          onChange={(value) => formik.setFieldValue("slug", value?.value)}
        ></Select>
        {formik.touched?.slug && formik.errors.slug && (
          <span className="error-text">{formik.errors.slug}</span>
        )}{" "}
      </div>
      ) : formik.values.type === "STATE" ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            State
          </label>
          <Select
            id="slug"
            placeholder="Search for a state..."
            name="slug"
            noOptionsMessage={noOptionsMessage}
            isClearable={false}
            options={useDataOptions}
            value={userVal}
            onInputChange={(value) => 
              handleInputChange(value, "STATE")}
            className={`pro-input multi-select lg ${
              formik.errors.slug && formik.touched.slug && " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("slug")}
            onChange={(value) => formik.setFieldValue("slug", value?.value)}
          ></Select>
          {formik.touched?.slug && formik.errors.slug && (
            <span className="error-text">{formik.errors.slug}</span>
          )}{" "}
        </div>
      ) : (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Subject
          </label>
          <Select
            id="slug"
            placeholder="Search for a subject..."
            name="slug"
            noOptionsMessage={noOptionsMessage}
            isClearable={false}
            options={useDataOptions}
            value={userVal}
            onInputChange={(value) => handleInputChange(value, "SUBJECT")}
            className={`pro-input multi-select lg ${
              formik.errors.slug && formik.touched.slug && " error"
            }`}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("slug")}
            onChange={(value) => formik.setFieldValue("slug", value?.value)}
          ></Select>
          {formik.touched?.slug && formik.errors.slug && (
            <span className="error-text">{formik.errors.slug}</span>
          )}{" "}
        </div>
      )}

      {formik.values.type === "SUBJECT" && (
        <Input
        label={"Slug*"}
        type="text"
        id="search_slug"
        name="search_slug"
        className={`pro-input lg ${getFieldError("search_slug") && "error"}`}
        {...formik.getFieldProps("search_slug")}
        error={getFieldError("search_slug")}
        errorMessage={getFieldError("search_slug")}
      />
      )}



      <Input
        label={"Title*"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
      />

<Input
        label={"Meta Title*"}
        type="text"
        id="meta_title"
        name="meta_title"
        className={`pro-input lg ${getFieldError("meta_title") && " error"}`}
        {...formik.getFieldProps("meta_title")}
        error={getFieldError("meta_title")}
        errorMessage={getFieldError("meta_title")}
      />

<Input
        label={"Canonical Tag"}
        type="text"
        id="canonical_tag"
        name="canonical_tag"
        className={`pro-input lg ${getFieldError("canonical_tag") && " error"}`}
        {...formik.getFieldProps("canonical_tag")}
        error={getFieldError("canonical_tag")}
        errorMessage={getFieldError("canonical_tag")}
      />

<Input
        label={"Og Type"}
        type="text"
        id="og_type"
        name="og_type"
        className={`pro-input lg ${getFieldError("og_type") && " error"}`}
        {...formik.getFieldProps("og_type")}
        error={getFieldError("og_type")}
        errorMessage={getFieldError("og_type")}
      />
      <Input
        label={"Og Image Alt"}
        type="text"
        id="og_image_alt"
        name="og_image_alt"
        className={`pro-input lg ${getFieldError("og_image_alt") && " error"}`}
        {...formik.getFieldProps("og_image_alt")}
        error={getFieldError("og_image_alt")}
        errorMessage={getFieldError("og_image_alt")}
      />

      <Input
        label={"Keywords*"}
        type="text"
        id="keywords"
        name="keywords"
        className={`pro-input lg ${getFieldError("keywords") && " error"}`}
        {...formik.getFieldProps("keywords")}
        error={getFieldError("keywords")}
        errorMessage={getFieldError("keywords")}
      />
      <Input
        label={"Description*"}
        type="text"
        id="description"
        name="description"
        className={`pro-input lg ${getFieldError("description") && " error"}`}
        {...formik.getFieldProps("description")}
        error={getFieldError("description")}
        errorMessage={getFieldError("description")}
      />

<div className="col-md-12">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Summary
            </label>
            <textarea
              type="text"
              id={`summary`}
              name={`summary`}
              label={`summary`}
              value={formik?.values?.summary}
              onChange={(e) => {
                formik.setFieldTouched(`summary`, true)
                formik.setFieldValue(`summary`, e?.target?.value)
              }
              }
              onBlur={formik.handleBlur(`summary`)}
              className={`pro-input lg ${
                formik.touched?.summary &&
                formik.errors?.summary &&
                " error"
              }`}
              // {...formik.getFieldProps(`content.${itmIndex}.summary`)}
            />
            {formik.touched?.summary && formik.errors?.summary && 
              (
                <span className="error-text">
                  {formik.errors?.summary}
                </span>
              )}
          </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : selectedItemDetails !== "" && selectedItemDetails?.image
                  ? selectedItemDetails?.image
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`branch - 01`}
          />
        </div>
      </div>

      {isEdit && (
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="is_active"
            name="is_active"
            type="checkbox"
            checked={Boolean(formik.values?.is_active) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "is_active",
                !Boolean(formik.values?.is_active) ?? false
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      )}

      <div className="col-12">
      <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Content
          </label>
        {formik?.values?.content?.map((fields, index) => (
          <ItemField
            key={index}
            itmIndex={index}
            fields={fields}
            formik={formik}
            getFieldError={getFieldError}
            isEdit={isEdit}
            imagePreview={imagePreview}
            setImageName={setImageName}
            handleContentChange={handleContentChange}
          />
        ))}
        <Button
          className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
          onClick={handleAddField}
          disabled={formik?.values?.content?.length >= 2 ? true : false}
        >
          {` Add More`}
        </Button>
      </div>

      <div
        className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddMeta;
