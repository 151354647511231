import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddMore from "./useAddMore";

const AddMore = ({ refetch, handleModalClick }) => {
  const {
    getFieldError,
    formik,
    isEdit,
  } = useAddMore(handleModalClick, refetch);
  
  return (
    <div className={`row`}>
      
      <Input
        label={"Label*"}
        type="text"
        id="label"
        name="label"
        className={`pro-input lg ${getFieldError("label") && " error"}`}
        {...formik.getFieldProps("label")}
        error={getFieldError("label")}
        errorMessage={getFieldError("label")}
      />

      <Input
        label={"Value*"}
        type="text"
        id="labelValue"
        name="labelValue"
        className={`pro-input lg ${getFieldError("labelValue") && " error"}`}
        {...formik.getFieldProps("labelValue")}
        error={getFieldError("labelValue")}
        errorMessage={getFieldError("labelValue")}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleModalClick}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddMore;
