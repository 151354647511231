import React from 'react';
import CommonLayout from "../../pages/Layouts/CommonLayout";
import { Outlet } from "react-router-dom";
import useTutor from './useTutor';

function Tutor() {
    const { drawerMenu } = useTutor();
    return (
      <CommonLayout  drawerMenu={drawerMenu}>
        <Outlet />
      </CommonLayout>
    );
}

export default Tutor
