import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetMyClassesDataQuery,
  useUpdateMyClassesDataTableFieldsMutation,
} from "../../../store/queries/Tutor";

import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../assets/Assets";
import Style from "../Tutors/tutor.module.scss";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Tutor/MyClasses/myClassesSlice";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";

const useMyclasses = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { tutorID } = useParams();
  const navigate = useNavigate();
  const [updateTableFields] = useUpdateMyClassesDataTableFieldsMutation();
  const activeProfile = tutorID ?? sessionStorage.getItem("active");
  const myClassesState = useSelector((state) => state.myClasses);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: myClassesList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetMyClassesDataQuery({
    user_id: activeProfile,
    page_size: myClassesState?.currentPageSize,
    page: myClassesState?.currentPage,
    sort_by: myClassesState?.sortBy,
    sort_order: myClassesState?.sortOrder,
    search: myClassesState?.search,
    status: myClassesState?.filter,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);
  
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (myClassesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = myClassesState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      student: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/students-profile/${data?.student_id}`,
              });

              sessionStorage.setItem("active", `${data?.student_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.image !== "" && data?.image
                    ? data?.image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.student}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.student)}
            </span>
          </div>
        );
      },

      start_time: (field, data) => {
        // if (data?.start_time) {
        // 	const dateObject = new Date(data?.start_time);

        // 	// Format the date as 'YYYY-MM-DD'

        // 	const formattedDate = dateObject.toISOString().split('T')[0];
        // 	return (
        // 		<p className="pro-mb-0">
        // 			{formattedDate}
        // 		</p>
        // 	);
        // }
        if (data?.start_time) {
          const dateObject = new Date(data.start_time);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      mode_of_class: (field, data) => {
        return (
          <>
            {data.mode_of_class === "ONLINE" ? (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-success pro-fw-medium`}
              >
                Online
              </span>
            ) : (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-warning pro-fw-medium`}
              >
                In Person
              </span>
            )}
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    myClassesList,
    paginationOptions,
    myClassesState,
    handleFilterClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
  };
};
export default useMyclasses;
