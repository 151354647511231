import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/PrivacyAndPolicy/privacyAndPolicySlice";
import { toast } from "react-toastify";
import { useState } from "react";
import { createPolicy, updatePolicy } from "../api";
import DOMPurify from "dompurify";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useAddPrivacy = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const { isEdit, selectedData, editData } = useSelector(
    (state) => state.privacy
  );

  // Function to sanitize HTML and convert it to EditorState
  const convertHtmlToEditorState = (html) => {
    if (!html) return EditorState.createEmpty(); // Return empty editor state if no HTML

    const sanitizedHtml = DOMPurify.sanitize(html);
    const contentBlock = htmlToDraft(sanitizedHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  };

  const [initialData, setInitialData] = useState({
    header: editData?.privacyandPolicy?.header ?? "",
    content:
      editData?.privacyandPolicy?.content?.map((item, index) => ({
        title: item?.title ?? "",
        description: item?.description
          ? convertHtmlToEditorState(item?.description)
          : "",
        is_active: item?.is_active ?? true,
      })) || [],
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: Yup.object({
      header: Yup.string().required("Enter Header"),
      content: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required("Enter Title"),
          // description: Yup.string().required("Enter description"),
        })
      ),
    }),
    validate: (values) => {
      let errors = {};
      // Check if content is not undefined and has length
      if (values.content && values.content.length > 0) {
        // Initialize content array in errors object
        errors.content = [];

        values.content.forEach((value, index) => {
          // Initialize an empty object for holding errors of this item
          let contentErrors = {};

          // Assuming `description` is an EditorState object from Draft.js or similar library
          const plainText = value.description
            ? value.description.getCurrentContent().getPlainText()
            : "";

          // Check if the content is empty or only contains whitespace
          if (!plainText.trim()) {
            // Assign the error message to the description field of the current item
            contentErrors.description = "*Description is required";
          }

          // If there are any errors for the current item, push them to the errors.content array
          if (Object.keys(contentErrors).length > 0) {
            errors.content[index] = contentErrors;
          }
        });
        // If after processing all content items there are no errors, remove the content key from errors
        if (errors.content.length === 0) {
          delete errors.content;
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      let formData = new FormData();
      // Object.keys(values).forEach((key) => {
      //   return formData.append(key, values[key]);
      // });
      formData.append("header", values["header"]);

      values?.content?.map((value, index) => {
        let contentState;
        if (value.description.getCurrentContent) {
          contentState = value.description.getCurrentContent();
        } else {
          // Assuming value.description might directly be a string or another format in some cases
          contentState = value.description; // Or handle this case based on your actual data structure
        }
        const contentRaw = convertToRaw(contentState);
        const plainText = draftToHtml(contentRaw);
        return (
          formData.append(`content[${index}][title]`, value?.title),
          formData.append(`content[${index}][description]`, plainText),
          formData.append(`content[${index}][is_active]`, value?.is_active)
        );
      });
      if (!isEdit) {
        createPolicy(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            toast.success("New Policy Created");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        updatePolicy(formData, selectedData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            toast.success("Policy updated");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  const handleAddField = () => {
    let { content } = formik?.values;
    content = [
      ...content,
      {
        title: "",
        description: "",
        is_active: true,
      },
    ];
    formik.setFieldValue("content", content);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  // useEffect(() => {
  //   if (editData && isEdit) {
  //     setInitialData({
  //       header: editData?.privacyandPolicy?.header,
  //       content: editData?.privacyandPolicy?.content,
  //     });
  //   }
  // }, [editData, isEdit]);

  const handleContentChange = (editorState, index) => {
    const fieldName = `content[${index}].description`;
    formik?.setFieldValue(fieldName, editorState);
  };

  return {
    getFieldError,
    closeModal,
    handleAddField,
    handleContentChange,
    formik,
    isEdit,
  };
};

export default useAddPrivacy;
