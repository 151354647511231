import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddPremiumPlans from "./useAddPremiumPlans";

const AddPremiumPlans = (refetch) => {
	const {
		formik,
		isEdit,
		getFieldError,
		handleCloseModal,
	} = useAddPremiumPlans(refetch);

	return (
		<div className={`row`}>

			<Input
				label={"Name"}
				type="text"
				id="name"
				name="name"
				className={`pro-input lg ${getFieldError("name") && " error"
					}`}
				{...formik.getFieldProps("name")}
				error={getFieldError("name")}
				errorMessage={getFieldError("name")}
			/>

<Input
				label={"Amount"}
				type="text"
				id="amount"
				name="amount"
				className={`pro-input lg ${getFieldError("amount") && " error"
					}`}
				{...formik.getFieldProps("amount")}
				error={getFieldError("amount")}
				errorMessage={getFieldError("amount")}
			/>

<Input
				label={"Expiry in Days"}
				type="text"
				id="expiry_in_days"
				name="expiry_in_days"
				className={`pro-input lg ${getFieldError("expiry_in_days") && " error"
					}`}
				{...formik.getFieldProps("expiry_in_days")}
				error={getFieldError("expiry_in_days")}
				errorMessage={getFieldError("expiry_in_days")}
			/>
			

			<div
				className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
			>
				<Button
					className={"pro-btn-link lg pro-px-5"}
					onClick={handleCloseModal}
				>
					Cancel
				</Button>

				<Button
					className={"pro-btn-primary lg pro-ms-3"}
					type="submit"
					onClick={formik.handleSubmit}
				>
					{isEdit ? "Update" : "Create"}
				</Button>
			</div>
		</div>
	);
};

export default AddPremiumPlans;
