import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isFilter: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  bgCheckData: [],
  startDate:"",
  endDate:"",
  statusType:"",
  showDetailsModal: false,
  // userType:"all",
  // subject:"",
  // specialization:""
};

export const getBgData = createAsyncThunk(
  "/student/bg-verification/",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/student/bg-verification/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const BgCheckSlice = createSlice({
  name: "BgCheck",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBgData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getBgData.fulfilled, (state, action) => {
        state.bgCheckData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getBgData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = BgCheckSlice.actions;

export default BgCheckSlice.reducer;
