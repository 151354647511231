import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useStudent = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Active Students",
      label: "Active Students",
      link: "/student/active-students",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">toggle_on</span>,
      active:
        checkIfActiveRoute("/active-students", true) ||
        checkIfActiveRoute("/student/active-students", true),
    },
    {
      title: "Inactive Students",
      label: "Inactive Students",
      link: "/student/inactive-students",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">toggle_off</span>,
      active:
        checkIfActiveRoute("/inactive-students  ", true) ||
        checkIfActiveRoute("/student/inactive-students", true),
    },
    {
      title: "Deleted Students",
      label: "Deleted Students",
      link: "/student/deleted-students",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">delete</span>,
      active:
        checkIfActiveRoute("/deleted-students  ", true) ||
        checkIfActiveRoute("/student/deleted-students", true),
    },
    {
      title: "Background Check",
      label: "Background Check",
      link: "/student/bg-check",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">3p</span>,
      active:
        checkIfActiveRoute("/student", true) ||
        checkIfActiveRoute("/student/bg-check", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useStudent;
