import {
  Button,
  HeadingGroup,
  Image,
  Input,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import useTransactionDetails from "./useTransactionDetails";
import Select from "react-select";
// import { Editor } from "react-draft-wysiwyg";
import Style from "./editPolicy.module.scss";
import { useEffect } from "react";
import Assets from "../../../../assets/Assets";

const TransactionDetails = ({ closeModal, refetch }) => {
  const { transactionData, getRow } = useTransactionDetails({
    closeModal,
    refetch,
  });
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateObject = new Date(transactionData?.course_purchase_details?.date);

  // Define the options for formatting the date
  const options = {
    timeZone: userTimezone,
    weekday: "short", // Short weekday name (e.g., Fri)
    month: "short", // Short month name (e.g., Oct)
    day: "numeric", // Day of the month (e.g., 13)
    year: "numeric", // Year (e.g., 2023)
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObject
  );

  // const dateStartObject = new Date(transactionData?.course_purchase_details?.start_time);
  // const dateEndObject = new Date(transactionData?.course_purchase_details?.end_time);

  // // Format the time in 12-hour format with AM/PM
  // const formattedStartTime = dateStartObject.toLocaleTimeString('en-US', {
  // 	hour: 'numeric',
  // 	minute: '2-digit',
  // 	hour12: true,
  // });

  // const formattedEndTime = dateEndObject.toLocaleTimeString('en-US', {
  // 	hour: 'numeric',
  // 	minute: '2-digit',
  // 	hour12: true,
  // });

  return (
    <>
      <div className="pro-p-5">
        <div className={`${Style.header_root}  pro-p-2`}>
          <div className="row row-cols-4 gx-4">
            <div className="pro-d-flex">
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={
                    transactionData?.course_purchase_details
                      ?.paid_from_image !== "" &&
                    transactionData?.course_purchase_details?.paid_from_image
                      ? transactionData?.course_purchase_details
                          ?.paid_from_image
                      : Assets.GENDER_IMAGE
                  }
                  width={10}
                  height={10}
                  alt={transactionData?.course_purchase_details?.paid_from}
                />
              </div>
              <div className="pro-ps-2">
                <h6 className="pro-ttl h6 pro-mb-0">
                  {transactionData?.course_purchase_details?.paid_from}
                </h6>
                <p
                  className={`pro-mb-0 pro-mt-1 pro-fw-medium ${Style.alt_text}`}
                >
                  #{transactionData?.course_purchase_details?.transaction_id}
                </p>
              </div>
            </div>
            <div>
              <h6 className="pro-mb-0 h6 pro-ttl"> {"Amount"} </h6>
              <p className="pro-mb-0 pro-mt-1 pro-fw-medium">
                {" "}
                ${transactionData?.course_purchase_details?.fee}{" "}
              </p>
            </div>
            <div>
              <h6 className="pro-mb-0 h6 pro-ttl"> {"Subject"} </h6>
              <p className="pro-mb-0 pro-mt-1 pro-fw-medium">
                {" "}
                {transactionData?.course_purchase_details?.subject} -{" "}
                {transactionData?.course_purchase_details?.specialization}{" "}
              </p>
            </div>
            <div className="">
              <h6 className="pro-mb-0 h6 pro-ttl"> {"Date"} </h6>
              <p className="pro-mb-0 pro-mt-1 pro-fw-medium">
                {" "}
                {formattedDate}{" "}
              </p>
              {/* <h6 className="pro-mb-0 pro-font-sm"> {transactionData?.course_purchase_details?.activity} </h6> */}
            </div>
          </div>
        </div>

        {/* <div className={`${Style.align_prof_img} pro-pt-5`}>
						<div
							className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
						>
							<Image
								src={
									transactionData?.course_purchase_details?.tutor_image !== "" && transactionData?.course_purchase_details?.tutor_image
										? transactionData?.course_purchase_details?.tutor_image
										: Assets.GENDER_IMAGE
								}
								width={10}
								height={10}
								alt={transactionData?.course_purchase_details?.tutor}
							/>
						</div>
					</div> */}
        {/* 
					<div className="pro-pt-2">
						<p className="pro-mb-1">{transactionData?.course_purchase_details?.tutor}</p>
						<p className="pro-mb-1">
							{transactionData?.course_purchase_details?.subject} - {transactionData?.course_purchase_details?.specialization}
						</p>
						<p className="pro-mb-1">
							Fee = {transactionData?.course_purchase_details?.fee}
						</p>
					</div> */}

        {/* <div>
						<p>Meet Provider: {transactionData?.course_purchase_details?.meet_provider}</p>
						<p>Meet Id: {transactionData?.course_purchase_details?.meet_id}</p>
					</div> */}

        {/* <div>
						<p>
							Hours: {transactionData?.course_purchase_details?.hours} hrs ={' '}
							<span>
								Session start time : {formattedStartTime} Session end time : {formattedEndTime}
							</span>
						</p>
						<p>Meet Id: {transactionData?.course_purchase_details?.meet_id}</p>
					</div> */}
        <HeadingGroup title={"Activity"} extraClassName={`pro-mt-5`} />
        <div className="col-12">
          <Table
            multiSelect={false}
            data={transactionData?.course_purchase_details?.events || []}
            uniqueID={"_id"}
            fields={transactionData?.fields}
            getRow={getRow}
            showCheckBox={false}
          />
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
