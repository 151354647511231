import {
  HeadingGroup,
  Permissions,
} from "@wac-ui-dashboard/wac_component_library";
import useManageRoles from "./useManageRoles";
import Style from "./manageRoles.module.scss";
import CommonLayout from "../../../../pages/Layouts/CommonLayout";
const ManageRoles = () => {
  const {
    state,
    role,
    update,
    name,
    error,
    handleNameChange,
    handlePermissionChange,
    handleSave,
  } = useManageRoles();
  return (
    <>
    <CommonLayout>
      <HeadingGroup
        title={"Role Management"}
        buttonTitle={"Save"}
        handleClick={handleSave}
        extraClassName={`pro-justify-between pro-pb-4`}
      />
      <div className={`row`}>
        <div className={`col-4`}>
          <div className="input-wrap pro-mb-5">
            <label
              htmlFor="role_name_input"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Name
            </label>
            <input
              id="role_name_input"
              type="text"
              value={name}
              disabled={update === "true" ? true : ""}
              onChange={handleNameChange}
              className={`pro-input lg ${error && "error"}`}
            />
            {error && <span className="error-text">{error}</span>}
          </div>
        </div>
      </div>
      <div className="pro-pb-6">
        {(role ? state.rolePermission?.[role] : true) &&
          state?.allPermissions?.map?.((permission, index) => (
            <div className={Style.root}>
              <Permissions
                title={permission?.menu_name}
                permissions={permission?.sub_menu_permissions}
                defaultCheckedList={
                  state.rolePermission?.[role]?.permissions?.filter?.(
                    (m) => m._id === permission._id
                  )?.[0]?.sub_menu_permissions || []
                }
                key={index}
                uniqueID={"id"}
                handleChange={(item) =>
                  handlePermissionChange(item, permission._id)
                }
              />
            </div>
          ))}
      </div>
      </CommonLayout>
    </>
  );
};

export default ManageRoles;
