import { Image } from "@wac-ui-dashboard/wac_component_library";

import Style from "../Students/students.module.scss";
import Assets from "../../../assets/Assets";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useGetTransactionStudentDataQuery, useUpdateTransactionStudentTableFieldsMutation } from "../../../store/queries/Student";
import { updateConfig } from "../../../store/slices/Student/StudentTransaction/studentTransactionSlice";
import { toast } from "react-toastify";
import { capitalizeOnFirst } from "../../../utils/functions/table";


const useTransactions = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { studentID } = useParams();
  const navigate = useNavigate();
  const [updateTableFields] = useUpdateTransactionStudentTableFieldsMutation();
  const activeProfile = studentID ?? sessionStorage.getItem("active");
  const TransactionState = useSelector((state) => state.studentTransaction);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: TransactionList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetTransactionStudentDataQuery({
    user_id: activeProfile,
    page_size: TransactionState?.currentPageSize,
    page: TransactionState?.currentPage,
    sort_by: TransactionState?.sortBy,
    sort_order: TransactionState?.sortOrder,
    search: TransactionState?.search,
  });

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (TransactionState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = TransactionState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      tutor: (field, data) => {
        return (
          <div
          className={`pro-d-flex avatar-container ${Style.avatar_container}`}
          onClick={() => {
            navigate({
              pathname: `/tutors-profile/${data?.tutor_id}`,
            });

            sessionStorage.setItem("active", `${data?.tutor_id}`);
          }}
        >
          <div
            className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
          >
          <Image
              src={
                data?.tutor_image !== "" && data?.tutor_image
                  ? data?.tutor_image
                  : Assets.GENDER_IMAGE
              }
              width={16}
              height={16}
              alt={data?.tutor}
            />
          </div>
          <span
            className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
          >
            {capitalizeOnFirst(data?.tutor)}
          </span>
        </div>
        );
      },
      date: (field, data) => {

				// if (data?.date) {
				// 	const dateObject = new Date(data?.date);

				// 	// Format the date as 'YYYY-MM-DD'

				// 	const formattedDate = dateObject.toISOString().split('T')[0];
				// 	return (
				// 		<p className="pro-mb-0">
				// 			{formattedDate}
				// 		</p>
				// 	);
				// }
        if (data?.date) {
          const dateObject = new Date(data.date);
        
          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: userTimezone,
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });

          // Extract day, month, and year from the formatted date
  const [month, day, year] = formattedDate.split('/');

  // Reformat to dd/mm/yyyy
  const formattedDateDMY = `${day}/${month}/${year}`;
        
          return (
            <p className="pro-mb-0">
              {formattedDateDMY}
            </p>
          );
        }

			},
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedData = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    TransactionList,
    paginationOptions,
    TransactionState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
  };
};

export default useTransactions;
