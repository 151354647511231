import React from "react";
import useAddPayoutSettlement from "./useAddPayoutSettlement";
import {
  Button,
  Input,
} from "@wac-ui-dashboard/wac_component_library";

const AddPayoutSettlement = ({ refetch, handleModalClick }) => {
  const {
    formik,
    getFieldError,
    isEdit,
    outputArray,
    selectVal
  } = useAddPayoutSettlement({ refetch, handleModalClick });

  return (
    <div className={`row`}>

      <Input
        label={"From Date*"}
        type="date"
        id="from_date"
        value={formik?.values?.from_date}
        name="from_date"
        className={`pro-input lg ${getFieldError("from_date") && " error"}`}
        onChange={(e) => formik.setFieldValue("from_date", e.target.value)}
        error={getFieldError("from_date")}
        errorMessage={getFieldError("from_date")}

      />
      <Input
        label={"To Date*"}
        type="date"
        id="to_date"
        value={formik.values.to_date}
        name="to_date"
        min={formik.values.from_date}
        onChange={(e) => formik.setFieldValue("to_date", e.target.value)}
        disabled={!formik.values.from_date}
        className={`pro-input lg ${getFieldError("to_date") && " error"}`}

        error={getFieldError("to_date")}
        errorMessage={getFieldError("to_date")}
      />

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddPayoutSettlement;
