import React from "react";
import useTutorDocuments from "./useTutorDocuments";
import { Table, Pagination } from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";

function TutorDocuments() {
  const {
    paginationOptions,
    documentData,
    tutorDocumentsState,
    isFetching,
    handleSort,
    handlePagination,
    getRow,
    handlePageSize,
  } = useTutorDocuments();

  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <div className={`pro-pt-3 pro-pb-5 `}>
          {documentData?.data?.tutor_documents?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={documentData?.data?.tutor_documents || []}
              uniqueID={"_id"}
              fields={documentData?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={tutorDocumentsState?.clearSelection}
              perpage={tutorDocumentsState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
        </div>
        {documentData?.data?.tutor_documents?.length > 0 && (
          <Pagination
            currentPage={tutorDocumentsState?.currentPage}
            totalPageCount={Math.ceil(
              documentData.data.total_count /
                tutorDocumentsState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
    </>
  );
}

export default TutorDocuments;
