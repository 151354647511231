import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useTutorStudents from "./useTutorStudents";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData/index";

function TutorStudents() {
  const {
    isLoading,
    isFetching,
    tutorStudentstList,
    paginationOptions,
    tutorStudentsState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
    updateTableFields,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditColumnsClick,
  } = useTutorStudents();
  return (
    <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
      <SearchFilters
        onSearchInput={handleSearch}
        loading={isLoading}
        SearchIcon={<span className="material-symbols-outlined"> search </span>}
        showClearFilters
        handleClear={handleClearClick}
        searchInputProps={{ value: tutorStudentsState?.search }}
        showActions={true}
        handleActionClick={handleEditColumnsClick}
      />

      <div className={`pro-pt-3 pro-pb-5 `}>
        {tutorStudentstList?.data?.students?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={tutorStudentstList?.data?.students || []}
            uniqueID={"id"}
            fields={tutorStudentstList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={tutorStudentsState?.clearSelection}
            perpage={tutorStudentsState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}
      </div>
      {tutorStudentstList?.data?.students?.length > 0 && (
        <Pagination
          currentPage={tutorStudentsState?.currentPage}
          totalPageCount={Math.ceil(
            tutorStudentstList.data.total_count /
              tutorStudentsState.currentPageSize
          )}
          onPageChange={handlePagination}
          options={paginationOptions}
          onActionChange={handlePageSize}
          center
        />
      )}
      <ModalLayout show={showEditModal} handleClose={closeEditModal}>
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetchData={refetch}
            tableFields={Object.keys(
              tutorStudentstList?.data?.fields ?? {}
            ).reduce((filteredObj, property) => {
              filteredObj[property] =
                tutorStudentstList?.data?.fields[property];
              return filteredObj;
            }, {})}
            moduleId={tutorStudentstList?.data?.module_id}
            updateData={updateTableFields}
          />
        </div>
      </ModalLayout>
    </div>
  );
}

export default TutorStudents;
