import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useEnquiryStudent from "./useEnquiryStudent";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";

const EnquiryStudent = () => {
  const {
    isLoading,
    isFetching,
    enquiryList,
    paginationOptions,
    enquiryState,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    getRow,
    updateTableFields,
    closeEditModal,
    showEditModal,
    refetch,
    handleEditColumnsClick,
  } = useEnquiryStudent();
  return (
    <>
      <div className="col-auto pro-pt-4 pro-ps-5 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: enquiryState?.search }}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
        />
        <div className={`pro-pb-5 `}>
          {enquiryList?.data?.enquiryList?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={enquiryList?.data?.enquiryList || []}
              uniqueID={"id"}
              fields={enquiryList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              clear={enquiryState?.clearSelection}
              perpage={enquiryState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={false}
              showCheckBox={false}
            />
          )}
        </div>

        {enquiryList?.data?.enquiryList?.length > 0 && (
          <Pagination
            currentPage={enquiryState?.currentPage}
            totalPageCount={Math.ceil(
              enquiryList.data.total_count / enquiryState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={Object.keys(enquiryList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = enquiryList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={enquiryList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default EnquiryStudent;
