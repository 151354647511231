import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../../store/slices/Tutor/MyClasses/myClassesSlice";

const useMyClassesFilter = (handleFilterClick, activityFilter) => {
  const dispatch = useDispatch();

  const [statusObjects, setStatusObjects] = useState([]);
  const { isFilter, filter } = useSelector(
    (state) => state.myClasses
  );

  const formik = useFormik({
    initialValues: {
      status: "",
    },
    onSubmit: (values) => {
      dispatch(
        updateConfig((state) => {
          state.filter = values?.status;
        })
      );
      handleFilterClick();
    },
  });
  const clearFilter = () => {
    formik.handleReset();
    dispatch(
      updateConfig((state) => {
        Object.assign(state, formik.initialValues);
        // state.isFilter = false;
        state.filter = "";
      })
    );
    handleFilterClick();
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  useEffect(() => {
    if (activityFilter) {
      const newStatusObjects = activityFilter.map((status) => {
        return { value: status, label: status.replace(/_/g, " ") };
      });
      setStatusObjects(newStatusObjects);
    }
  }, [activityFilter]);

  useEffect(() => {
    
    if (isFilter) {
      formik.setValues({
        status: filter || "",
      });
    }
  }, [isFilter]);

  let selectActivity = statusObjects.find(
    (item) => item.value === formik.values.status
  );

  return { formik, getFieldError, clearFilter, statusObjects, selectActivity };
};

export default useMyClassesFilter;
