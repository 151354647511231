import React from "react";
import {
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
// import Style from "../addReport.module.scss";
import useItemField from "./useItemField";
import Style from "../../ourClients.module.scss";
import { Editor } from "react-draft-wysiwyg";
import FormStyle from "./aboutUsForm.module.scss";

export const ItemField = ({
  formik,
  itmIndex,
  fields,
  isEdit,
  handleContentChange,
}) => {
  const { handleRemoveFieldCS, handleImage, aboutUsState } = useItemField(
    fields,
    itmIndex,
    formik,
    isEdit
  );
  return (
    <>
      <div className={Style.box_root}>
        {formik?.values?.content?.length !== 1 && (
          <button
            className={`${Style.btn_close} btn-close`}
            onClick={() => handleRemoveFieldCS(itmIndex)}
          ></button>
        )}
        <div className="row gx-2">
          <div className="col-md-12">
            <Input
              type="text"
              id={`title`}
              name={`title`}
              label={`Title`}
              onBlur={formik.handleBlur(`content.${itmIndex}.title`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title &&
                " error"
              }`}
              {...formik.getFieldProps(`content.${itmIndex}.title`)}
              error={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.content?.[itmIndex]?.title &&
                formik.errors?.content?.[itmIndex]?.title && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.title}
                  </span>
                )
              }
            />
          </div>
          <div className="col-md-12">
            {/* <Input
              type="text"
              id={`description`}
              name={`description`}
              label={`Description`}
              onBlur={formik.handleBlur(`content.${itmIndex}.description`)}
              className={`pro-input lg ${
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description &&
                " error"
              }`}
              {...formik.getFieldProps(`content.${itmIndex}.description`)}
              error={
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )
              }
              errorMessage={
                formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )
              }
            /> */}
            <div className={FormStyle.editor_container}>
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
              <Editor
                wrapperClassName={FormStyle.pro_editor_wrapper}
                editorClassName={FormStyle.pro_editor_main}
                toolbarClassName={FormStyle.pro_editor_toolbar}
                editorState={formik.values?.content?.[itmIndex]?.description}
                readOnly={false}
                onEditorStateChange={(editorState) =>
                  handleContentChange(editorState, itmIndex)
                }
                // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
                toolbar={{
                  options: [
                    "blockType",
                    "inline",
                    "list",
                    "textAlign",
                    "fontSize",
                    "fontFamily",
                  ],
                  blockType: {
                    inDropdown: true,
                    className: FormStyle.dropdown_wrapper,
                    dropdownClassName: FormStyle.dropdown_menu,
                  },
                }}
              />

              {formik.touched?.content?.[itmIndex]?.description &&
                formik.errors?.content?.[itmIndex]?.description && (
                  <span className={FormStyle.error_text}>
                    {formik.errors?.content?.[itmIndex]?.description}
                  </span>
                )}
            </div>
          </div>
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Photo*
            </label>
            <div className="input-drag">
              <input
                type="file"
                placeholder="placeholder"
                accept=".jpeg, .jpg , .png"
                className={`pro-input ${
                  formik.errors?.content?.[itmIndex]?.image &&
                  formik.touched?.content?.[itmIndex]?.image &&
                  " error"
                }`}
                id="image"
                name="image"
                onBlur={formik.handleBlur(`content.${itmIndex}.image`)}
                onChange={(e) => handleImage(e)}
              />

              <span className="input-drag-box">
                <IconText
                  title={
                    aboutUsState?.imageName[itmIndex] ??
                    formik?.values?.content[itmIndex]?.image_path !== ""
                      ? aboutUsState?.imageName[itmIndex] ??
                        formik?.values?.content[itmIndex]?.image_path
                      : `Drop files to attach or browse`
                  }
                />
              </span>
              {formik.errors?.content?.[itmIndex]?.image &&
                formik.touched?.content?.[itmIndex]?.image && (
                  <span className="error-text">
                    {formik.errors?.content?.[itmIndex]?.image}
                  </span>
                )}
            </div>
          </div>
          <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={
                  aboutUsState?.imagePreview[itmIndex] ??
                  formik?.values?.content[itmIndex]?.image
                }
                alt={`image - 01`}
              />
            </div>
          </div>
          <span className="pro-font-sm pro-mt-1 pro-mb-1 ">
            **Max upload file size 5MB
          </span>
          <div className="pro-check-box pro-py-2">
            <input
              type="checkbox"
              className="pro-check"
              id="default"
              name="pro-checkbox"
              checked={formik?.values?.content?.[itmIndex]?.status === true}
              onChange={(e) => {
                formik.setFieldValue(
                  `content.${itmIndex}.status`,
                  e.target.checked ? true : false
                );
              }}
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemField;
