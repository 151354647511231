import { combineReducers } from "@reduxjs/toolkit";
import globalSlice from "./slices/Global";
import studentsSlice from "./slices/Student/Students/studentsSlice";
import premiumPlansSlice from "./slices/PremiumPlans/premiumPlansSlice";
import coursesSlice from "./slices/Courses/coursesSlice";
import { student } from "./queries/Student";
import faqsSlice from "./slices/FAQs/faqsSlice";
import { dashboard } from "./queries/Dashboard";
import { premiumPlans } from "./queries/PremiumPlans";
import { tutor } from "./queries/Tutor";
import tutorsSlice from "./slices/Tutor/Tutors/tutorsSlice";
import { subjects } from "./queries/Subjects";
import subjectSlice from "./slices/Subjects/subjectSlice";
import { roles } from "./queries/Roles";
import roleSlice from "./slices/Settings/Role/roleSlice";
import { faqs } from "./queries/FAQs";
import { educationalLevel } from "./queries/EducationalLevel";
import educationalLevelSlice from "./slices/EducationalLevel/educationalLevelSlice";
import { banners } from "./queries/Banners";
import bannersSlice from "./slices/Banners/bannersSlice";
import { areaOfExpertise } from "./queries/AreaOfExpertise";
import areaOfExpertiseSlice from "./slices/AreaOfExpertise/areaOfExpertiseSlice";
import { cms } from "./queries/CMS";
import contactInfoSlice from "./slices/CMS/ContactInfo/contactInfoSlice";
import privacyAndPolicySlice from "./slices/CMS/PrivacyAndPolicy/privacyAndPolicySlice";
import termsAndConditionsSlice from "./slices/CMS/TermsAndConditions/termsAndConditionsSlice";
import testimonialsSlice from "./slices/CMS/Testimonials/testimonialsSlice";
import { courses } from "./queries/Courses";
import studentsProfileSlice from "./slices/Student/studentsProfileSlice";
import { reportedChat } from "./queries/ReportedChat";
import reportedChatSlice from "./slices/ReportedChat/reportedChatSlice";
import TransactionSlice from "./slices/Payment/Transaction/TransactionSlice";
import RefundSlice from "./slices/Payment/Refund/RefundSlice";
import { payment } from "./queries/Payment";
import specializationSlice from "./slices/Subjects/Specialization/specializationSlice";
import TutorReportsSlice from "./slices/Tutor/TutorReports/TutorReportsSlice";
import TutorDocumentSlice from "./slices/Tutor/TutorDocuments/TutorDocumentSlice";
import TutorPayoutsSlice from "./slices/Tutor/TutorPayouts/TutorPayoutsSlice";
import AllTutorDocumentsSlice from "./slices/Tutor/AllTutorDocuments/AllTutorDocumentsSlice";
import EnquiryListSlice from "./slices/Tutor/EnquiryList/EnquiryListSlice";
import mySubjectSlice from "./slices/Tutor/MySubject/mySubjectSlice";
import ScheduledSlice from "./slices/Tutor/Scheduled/ScheduledSlice";
import TutorStudentsSlice from "./slices/Tutor/TutorStudents/TutorStudentsSlice";
import myClassesSlice from "./slices/Tutor/MyClasses/myClassesSlice";
import studentMyClassesSlice from "./slices/Student/studentMyClasses/studentMyClassesSlice";
import StudentEnquirySlice from "./slices/Student/studentEnquiry/StudentEnquirySlice";
import studentTransactionSlice from "./slices/Student/StudentTransaction/studentTransactionSlice";
import StudentReportSlice from "./slices/Student/StudentReport/StudentReportSlice";
import TutorBackgroundVerificationSlice from "./slices/Tutor/TutorBackgroundVerification/TutorBackgroundVerificationSlice";
import PremiumPlanPurchaseSlice from "./slices/Payment/PremiumPlanPurchase/PremiumPlanPurchaseSlice";
import PayoutsSlice from "./slices/Payment/Payouts/PayoutsSlice";
import BgVerificationPaymentSlice from "./slices/Payment/BgVerification/BgVerificationPaymentSlice";
import ourClientsSlice from "./slices/CMS/OurClients/ourClientsSlice";
import BgCheckSlice from "./slices/Student/BgCheck/BgCheckSlice";
import aboutUsSlice from "./slices/CMS/AboutUs/aboutUsSlice";
import moreSlice from "./slices/CMS/More/moreSlice";
import { blog } from "./queries/blog";
import blogSlice from "./slices/Blog/blogSlice";
import { coupon } from "./queries/Coupon";
import couponSlice from "./slices/Coupon/couponSlice";
import PayoutsSettlementSlice from "./slices/Payment/PayoutsSettlement/PayoutsSettlementSlice";
import RefundSettlementSlice from "./slices/Payment/RefundSettlement/RefundSettlementSlice";
import payoutListSlice from "./slices/Tutor/PayoutList/payoutListSlice";
import taxFormSlice from "./slices/Tutor/TaxForm/taxFormSlice";
import { couponRedeem } from "./queries/CouponRedeem";
import couponRedeemSlice from "./slices/CouponRedeem/couponRedeemSlice";
import RevenueSlice from "./slices/Payment/Revenue/RevenueSlice";
import policiesSlice from "./slices/CMS/Policies/policiesSlice";
import staffsSlice from "./slices/Staffs/staffsSlice";
import { premiumPlan } from "./queries/PremiumPlan";
import premiumPlanSlice from "./slices/PremiumPlan/premiumPlanSlice";
import { profile } from "./queries/profile";
import profileSlice from "./slices/Profile/profileSlice";
import tutorReviewSlice from "./slices/TutorReviews/TutorReviewSlice";
import { tutorReviews } from "./queries/TutorReviews";
import inactiveTutorsSlice from "./slices/Tutor/InactiveTutors/inactiveTutorsSlice";
import onboardingTutorsSlice from "./slices/Tutor/OnboardingTutors/onboardingTutorsSlice";
import inactiveStudentsSlice from "./slices/Student/InactiveStudents/inactiveStudentsSlice";
import LocationSearchSlice from "./slices/CMS/LocationSearchMeta/LocationSearchSlice";
import deletedTutorsSlice from "./slices/Tutor/DeletedTutors/deletedTutorsSlice";
import deletedStudentsSlice from "./slices/Student/DeletedStudents/deletedStudentsSlice";
import dynamicMetaSlice from "./slices/CMS/DynamicMeta/dynamicMetaSlice";

const appReducer = combineReducers({
  [student.reducerPath]: student.reducer,
  [premiumPlans.reducerPath]: premiumPlans.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [tutor.reducerPath]: tutor.reducer,
  [reportedChat.reducerPath]: reportedChat.reducer,
  [subjects.reducerPath]: subjects.reducer,
  [roles.reducerPath]: roles.reducer,
  [faqs.reducerPath]: faqs.reducer,
  [educationalLevel.reducerPath]: educationalLevel.reducer,
  [banners.reducerPath]: banners.reducer,
  [areaOfExpertise.reducerPath]: areaOfExpertise.reducer,
  [cms.reducerPath]: cms.reducer,
  [courses.reducerPath]: courses.reducer,
  [payment.reducerPath]: payment.reducer,
  [blog.reducerPath]: blog.reducer,
  [coupon.reducerPath]: coupon.reducer,
  [couponRedeem.reducerPath]: couponRedeem.reducer,
  [premiumPlan.reducerPath]: premiumPlan.reducer,
  [profile.reducerPath]: profile.reducer,
  [tutorReviews.reducerPath]: tutorReviews.reducer,

  global: globalSlice,
  coupon: couponSlice,
  premiumPlan: premiumPlanSlice,
  couponRedeem: couponRedeemSlice,
  blog: blogSlice,
  students: studentsSlice,
  premiumPlans: premiumPlansSlice,
  courses: coursesSlice,
  faqs: faqsSlice,
  ourClients: ourClientsSlice,
  tutors: tutorsSlice,
  reportedChat: reportedChatSlice,
  subjects: subjectSlice,
  role: roleSlice,
  staffs: staffsSlice,
  educational: educationalLevelSlice,
  banners: bannersSlice,
  areaOfExpertise: areaOfExpertiseSlice,
  contact: contactInfoSlice,
  privacy: privacyAndPolicySlice,
  terms: termsAndConditionsSlice,
  policies: policiesSlice,
  testimonials: testimonialsSlice,
  studentsProfile: studentsProfileSlice,
  transaction: TransactionSlice,
  payouts: PayoutsSlice,
  Revenue: RevenueSlice,
  BgVerificationPayment: BgVerificationPaymentSlice,
  refund: RefundSlice,
  specialization: specializationSlice,
  myClasses: myClassesSlice,
  tutorReport: TutorReportsSlice,
  tutorDocument: TutorDocumentSlice,
  tutorPayouts: TutorPayoutsSlice,
  allTutorDocument: AllTutorDocumentsSlice,
  enquiryList: EnquiryListSlice,
  mySubject: mySubjectSlice,
  scheduled: ScheduledSlice,
  tutorStudents: TutorStudentsSlice,
  studentMyClasses: studentMyClassesSlice,
  studentEnquiry: StudentEnquirySlice,
  studentTransaction: studentTransactionSlice,
  StudentReport: StudentReportSlice,
  tutorBgVerification: TutorBackgroundVerificationSlice,
  premiumPlanPurchase: PremiumPlanPurchaseSlice,
  BgCheck: BgCheckSlice,
  aboutUs: aboutUsSlice,
  more: moreSlice,
  dynamicMeta: dynamicMetaSlice,
  payoutsSettlement: PayoutsSettlementSlice,
  refundSettlement: RefundSettlementSlice,
  payoutList: payoutListSlice,
  taxForm: taxFormSlice,
  profile: profileSlice,
  tutorReviews: tutorReviewSlice,
  inactiveTutors: inactiveTutorsSlice,
  onboardingTutors: onboardingTutorsSlice,
  inactiveStudents: inactiveStudentsSlice,
  deletedTutors: deletedTutorsSlice,
  deletedStudents: deletedStudentsSlice,
  locationSearch: LocationSearchSlice,
});

export default appReducer;
