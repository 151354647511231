import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../../api";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  specializationData: [],
  autoCompleteData: [],

  imagePreview: "",
  imageName: "",
};

export const getSpecializationData = createAsyncThunk(
  "/specialization/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/subjects/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const specializationSlice = createSlice({
  name: "specialization",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpecializationData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getSpecializationData.fulfilled, (state, action) => {
        state.specializationData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getSpecializationData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export default specializationSlice.reducer;
export const { updateConfig } = specializationSlice.actions;
