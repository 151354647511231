import { Image, Table } from "@wac-ui-dashboard/wac_component_library";
import useBgVerificationDetails from "./useBgVerificationDetails";
import Style from "./bgCheckDetails.module.scss";
import Assets from "../../../../assets/Assets";

const BgVerificationDetails = ({ closeModal, refetch }) => {
  const { bgVerificationData, getRow } = useBgVerificationDetails({
    closeModal,
    refetch,
  });

  const dateObject = new Date(
    bgVerificationData?.bg_verifications_details?.[0]?.date
  );
  const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Define the options for formatting the date
  const options = {
    timeZone: currentTimezone,
    weekday: "short", // Short weekday name (e.g., Fri)
    month: "short", // Short month name (e.g., Oct)
    day: "numeric", // Day of the month (e.g., 13)
    year: "numeric", // Year (e.g., 2023)
  };

  // Format the date using the options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObject
  );

  return (
    <>
      <div className="pro-p-5">
        <div className={`${Style.align_stud_img} pro-text-center`}>
          <div
            className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
          >
            <Image
              src={
                bgVerificationData?.bg_verifications_details?.[0]
                  ?.tutor_image !== "" &&
                bgVerificationData?.bg_verifications_details?.[0]?.tutor_image
                  ? bgVerificationData?.bg_verifications_details?.[0]
                      ?.tutor_image
                  : Assets.GENDER_IMAGE
              }
              width={10}
              height={10}
              alt={bgVerificationData?.bg_verifications_details?.[0]?.tutor}
            />
          </div>
          <h5 className="pro-ttl h5  pro-mb-0 pro-mt-1">
            {bgVerificationData?.bg_verifications_details?.[0]?.tutor}
          </h5>
        </div>

        <div className={`${Style.event_box} pro-my-2`}>
          <div>
            {" "}
            {bgVerificationData?.bg_verifications_details?.[0]?.status}{" "}
          </div>
          <div className="pro-ms-3"> {formattedDate}</div>
        </div>

        <div className={`${Style.content_wrap} pro-p-5`}>
          <div className={`${Style.align_prof_img} pro-d-flex pro-mb-4`}>
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img}`}
            >
              <Image
                src={
                  bgVerificationData?.bg_verifications_details?.[0]
                    ?.initiator_image !== "" &&
                  bgVerificationData?.bg_verifications_details?.[0]
                    ?.initiator_image
                    ? bgVerificationData?.bg_verifications_details?.[0]
                        ?.initiator_image
                    : Assets.GENDER_IMAGE
                }
                width={10}
                height={10}
                alt={
                  bgVerificationData?.bg_verifications_details?.[0]?.initiator
                }
              />
            </div>
            <div className="pro-ps-2">
              <h6 className="pro-mb-1 pro-text-capitalize">
                {bgVerificationData?.bg_verifications_details?.[0]?.initiator}
              </h6>
            </div>
          </div>

          <div>
            <p className="pro-mb-1">
              Paid Amount : {"$"}
              <span>
                {bgVerificationData?.bg_verifications_details?.[0]?.paid_amount}
              </span>
            </p>
          </div>
        </div>

        <div className="pro-w-100 pro-pt-5">
          <h6 className="pro-ttl h6 pro-mb-0">Activity</h6>
          <Table
            multiSelect={false}
            data={
              bgVerificationData?.bg_verifications_details?.[0]?.events || []
            }
            uniqueID={"_id"}
            fields={bgVerificationData?.fields}
            getRow={getRow}
            showCheckBox={false}
          />
        </div>
      </div>
    </>
  );
};

export default BgVerificationDetails;
