import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  sortBy: "",
  sortOrder: "",
  search: "",
  showAddCourseModal: false,
  showDeleteModal: false,
  selectedCourse: "",
  isEdit: false,
  CourseData: [],
  startDate:"",
  endDate:"",
  showFilterModal: false,
  mode_of_class: "",
  specialization: "",
  activity: "",
  showDetailsModal: false,
};

export const getClassData = createAsyncThunk(
  "/scheduled-class/",
  async (id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/api/scheduled-class/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClassData.pending, (state, action) => {
        state.editLoading = true;
      })
      .addCase(getClassData.fulfilled, (state, action) => {
        state.CourseData = action.payload?.data;
        state.editLoading = false;
      })
      .addCase(getClassData.rejected, (state, action) => {
        state.editLoading = false;
        state.editError = "Something went Wrong ! , please try After Some times";
      });
  },
});

export const { updateConfig } = coursesSlice.actions;

export default coursesSlice.reducer;
