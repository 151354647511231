import React from "react";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useInactiveStudents from "./useInactiveStudents";
import EmptyData from "../../Global/EmptyData/index";
import Style from "./students.module.scss";

function InactiveStudents({ dashboard = false }) {
  const {
    studentsState,
    showEditModal,
    studentsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    refetch,
    currentPage,
    closeEditModal,
    updateTableFields,
    handleDashboardRedirect,
    handleDeleteModal,
    setStatusUpdate,
    popUpMessage,
    handleDelete,
    handleDeleteAction,
    showDeleteConfirm,
    setShowDeleteConfirm,
  } = useInactiveStudents({ dashboard });
  return (
    <div>
      <HeadingGroup title={"Inactive Students"} className={`pro-mb-4`} />
      <div className="col-auto pro-pt-3 pro-pb-6">
        {!dashboard ? (
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                onSearchInput={handleSearch}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: studentsState?.search }}
                handleActionClick={handleEditColumnsClick}
              />
            </div>
            {/* <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
                onClick={handleFilterClick}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div> */}
          </div>
        ) : (
          <></>
        )}
        <div className={`pro-pt-3 pro-pb-5 `}>
          {studentsList?.data?.students?.length === 0 ? (
            <EmptyData />
          ) : (
            <div
              className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
            >
              <Table
                multiSelect={false}
                data={studentsList?.data?.students || []}
                uniqueID={"_id"}
                fields={studentsList?.data?.fields}
                SortIcon={<FaSort />}
                // editIcon={<span className="material-symbols-outlined">edit</span>}
                deleteIcon={
                  <span className="material-symbols-outlined">delete</span>
                }
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={studentsState?.currentPageSize}
                assignable={false}
                handleEdit={handleEditAction}
                handleDelete={handleDelete}
                showCheckBox={true}
                deletable={studentsList?.data?.delete_permission}
                editable={studentsList?.data?.edit_permission}
                clear={studentsState.clearSelection}
              />
            </div>
          )}
        </div>

        {studentsList?.data?.students?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              studentsList.data.filtered_count / studentsState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={studentsState?.showDeleteModal}
          handleClose={() => handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={popUpMessage}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={() => setStatusUpdate(true)}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(studentsList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = studentsList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={studentsList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        {/* <OffCanvasLayout
          show={tutorsState?.showAddTutorsModal}
          handleClose={handleModalClick}
          title={tutorsState?.isEdit ? "Update Tutor" : "Add Tutor"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddTutors isStickyFooter />
        </OffCanvasLayout> */}

        {/* <OffCanvasLayout
          show={studentsState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <StudentsFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
          />
        </OffCanvasLayout> */}
        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={() => handleDeleteAction()}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </div>
  );
}

export default InactiveStudents;
