import { useSelector } from "react-redux";
import {
  useGetCoursesListDataQuery,
  useUpdateTableListFieldsDataMutation,
} from "../../store/queries/Courses";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import {
  getClassData,
  updateConfig,
} from "../../store/slices/Courses/coursesSlice";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getCourseData } from "./api";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./course.module.scss";
import Assets from "../../assets/Assets";
import { useNavigate } from "react-router-dom";
import { capitalizeOnFirst } from "../../utils/functions/table";

const useCourses = ({ dashboard }) => {
  const { checkIfActiveRoute } = useRouteUtils();
  const drawerMenu = [
    {
      title: "Course Schedules",
      label: "Course Schedules",
      link: "/courses",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">school</span>,
      active: checkIfActiveRoute("/courses", true),
    },
  ];

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.filter = "";
      })
    );
  }, []);

  const navigate = useNavigate();

  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const coursesState = useSelector((state) => state.courses);
  const [skip, setSkip] = useState(true);
  const {
    data: coursesList = {},
    isLoading,
    isFetching,
    refetch,
    isError
  } = useGetCoursesListDataQuery({
    page_size: coursesState.currentPageSize,
    page: coursesState.currentPage,
    sort_by: coursesState.sortBy,
    sort_order: coursesState.sortOrder,
    start_date: coursesState?.startDate,
    end_date: coursesState?.endDate,
    mode_of_class: coursesState?.mode_of_class,
    specialization: coursesState?.specialization,
    activity: coursesState?.activity,
    search: coursesState?.search,
  });
  
  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  const [updateTableFields] = useUpdateTableListFieldsDataMutation();

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = coursesList?.data?.fields;
      })
    );
    // eslint-disable-next-line
  }, [coursesList]);

  const createLabel = (label) => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleModalClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddCoursesModal = !state.showAddCoursesModal;
        state.isEdit = false;
      })
    );
  };


  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      tutor: (field, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/tutors-profile/${data?.tutor_id}`,
              });

              sessionStorage.setItem("active", `${data?.tutor_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.tutor_image !== "" && data?.tutor_image
                    ? data?.tutor_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.tutor}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.tutor)}
            </span>
          </div>
        );
      },
      student: (feild, data) => {
        return (
          <div
            className={`pro-d-flex avatar-container ${Style.avatar_container}`}
            onClick={() => {
              navigate({
                pathname: `/students-profile/${data?.student_id}`,
              });

              sessionStorage.setItem("active", `${data?.student_id}`);
            }}
          >
            <div
              className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
            >
              <Image
                src={
                  data?.student_image !== "" && data?.student_image
                    ? data?.student_image
                    : Assets.GENDER_IMAGE
                }
                width={16}
                height={16}
                alt={data?.student}
              />
            </div>
            <span
              className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
            >
              {capitalizeOnFirst(data?.student)}
            </span>
          </div>
        );
      },
      action: (field, data) => {
        return (
          <button
            className={`pro-btn pro-btn-link`}
            disabled={dashboard ? true : false}
            onClick={() => {
              dispatch(getClassData(data?._id)).then((res) => {
                if (res?.payload?.data?.scheduledClass) {
                  dispatch(
                    updateConfig((state) => {
                      state.showDetailsModal = true;
                    })
                  );
                }
              });
            }}
          >
            Show Details
          </button>
        );
      },
      activity: (field, data) => {
        return <p className="pro-mb-0">{createLabel(data[field])}</p>;
      },
      mode_of_class: (field, data) => {
        return (
          <>
            {data.mode_of_class === "ONLINE" ? (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-success pro-fw-medium`}
              >
                Online
              </span>
            ) : (
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-warning pro-fw-medium`}
              >
                In Person
              </span>
            )}
          </>
        );
      },
      start_time: (field, data) => {
        //       const dateObject = new Date(data?.start_time);

        //       const formattedStartTime = dateObject.toLocaleTimeString("en-US", {
        //         hour: "numeric",
        //         minute: "2-digit",
        //         hour12: true,
        //       });

        // // Format the date as 'YYYY-MM-DD'
        // const formattedDate = dateObject.toISOString().split('T')[0];
        //       return (
        //         <p className="pro-mb-0">
        //             {formattedDate} {formattedStartTime}
        //           </p>
        //       );

        if (data?.start_time) {
          const dateObject = new Date(data.start_time);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const filters = useMemo(() => {
    if (coursesList?.data?.filters) {
      const buttonGroups = coursesList?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedPremiumPlan = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showAddCoursesModal = true;
        state.selectedPremiumPlan = e?.[0];
        state.isEdit = true;
      })
    );
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handleSort = (label) => {
    if (coursesState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = coursesState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedCourse = "";
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showFilterModal = !state.showFilterModal;
        state.isFilter = !state.isFilter;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showDetailsModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/courses");
    setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
        })
      );
    }, 500);
  };

  return {
    drawerMenu,
    coursesState,
    showEditModal,
    tableFields,
    coursesList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    // handleDelete,
    handleDeleteModal,
    handleEditAction,
    handleSearch,
    // updateBranchFields,
    handleClearClick,
    handlePageSize,
    refetch,
    closeEditModal,
    updateTableFields,
    currentPage: coursesState.currentPage,
    handleFilterClick,
    closeModal,
    handleDashboardRedirect,
  };
};

export default useCourses;
