import React from "react";
import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useFAQs from "./useFAQs";
import AddFAQ from "./AddFAQ";
import FaqListFilter from "./FaqListFilter";
function FAQs() {
  const {
    faqsState,
    showEditModal,
    faqsList,
    isLoading,
    isFetching,
    paginationOptions,
    getRow,
    handleEditColumnsClick,
    handleSort,
    handlePagination,
    handleModalClick,
    handleEditAction,
    handleSearch,
    handlePageSize,
    refetch,
    closeEditModal,
    updateTableFields,
    handleFilterClick,
  } = useFAQs();

  return (
    <div>
      <HeadingGroup
        title={"FAQ"}
        className={`pro-mb-4`}
        buttonTitle={faqsList?.data?.create_permission && "Add new"}
        handleClick={handleModalClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row gx-2">
          <div className="col">
            <SearchFilters
              searchable={false}
              onSearchInput={handleSearch}
              showActions={true}
              handleActionClick={handleEditColumnsClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              // showClearFilters
              // handleClear={handleClearClick}
              searchInputProps={{ value: faqsState?.search }}
            />
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline pro-h-100 pro-px-5 `}
              onClick={handleFilterClick}
            >
              <span className="material-symbols-outlined">tune</span>
              <span>Filter</span>
            </button>
          </div>
        </div>
        <Table
          multiSelect={false}
          data={faqsList?.data?.faqs || {}}
          uniqueID={"_id"}
          fields={faqsList?.data?.fields}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          // deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={faqsState?.currentPageSize}
          clear={faqsState.clearSelection}
          assignable={false}
          handleEdit={handleEditAction}
          // handleDelete={handleDeleteModal}
          deletable={faqsList?.data?.delete_permission}
          editable={faqsList?.data?.edit_permission}
          showCheckBox={faqsList?.data?.edit_permission || faqsList?.data?.delete_permission}
        />
        {faqsList?.data?.faqs?.length > 0 && (
          <Pagination
            currentPage={faqsState?.currentPage}
            totalPageCount={Math.ceil(
              faqsList.data.filtered_count / faqsState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        {/* <ModalLayout
					show={faqsState?.showDeleteModal}
					handleClose={handleDeleteModal}
				>
					<div className="pro-m-5">
						<ConfirmationBox
							title={"Are you sure delete the selected premium ?"}
							subTitle={"This action can't be undo "}
							cancelText={"No"}
							cancelAction={handleDeleteModal}
							submitText={"Yes"}
							// submitAction={handleDelete}
							isRight={true}
						/>
					</div>
				</ModalLayout> */}

        <ModalLayout show={showEditModal} handleClose={closeEditModal}>
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(faqsList?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = faqsList?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={faqsList?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={faqsState?.showAddFAQModal}
          handleClose={handleModalClick}
          title={faqsState?.isEdit ? "Update FAQ" : "Add FAQ"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
        >
          <AddFAQ refetch={refetch} />
        </OffCanvasLayout>

        <OffCanvasLayout
          show={faqsState?.showFilterModal}
          handleClose={handleFilterClick}
          title={"Filters"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <FaqListFilter
            isStickyFooter
            handleFilterClick={handleFilterClick}
            // dropdown={couponList?.data?.coupon_types}
          />
        </OffCanvasLayout>
      </div>
    </div>
  );
}

export default FAQs;
