import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddPrivacy from "./useAddPrivacy";
import ItemField from "./ItemFieldClick";

const AddPrivacy = ({ refetch, handleModalClick }) => {
  const { getFieldError, handleAddField, handleContentChange, formik, isEdit } =
    useAddPrivacy(handleModalClick, refetch);

  return (
    <div className={`row`}>
      <Input
        label={"Header*"}
        type="text"
        id="header"
        name="header"
        className={`pro-input lg ${getFieldError("header") && " error"}`}
        {...formik.getFieldProps("header")}
        error={getFieldError("header")}
        errorMessage={getFieldError("header")}
      />
      <div className="col-12">
        {formik?.values?.content?.map((fields, index) => (
          <ItemField
            key={index}
            itmIndex={index}
            fields={fields}
            formik={formik}
            getFieldError={getFieldError}
            handleContentChange={handleContentChange}
            isEdit={isEdit}
          />
        ))}
        <Button
          className={`pro-btn-outline lg pro-w-100 pro-mt-4`}
          onClick={handleAddField}
          // disabled={!tempFilteredData?.[0]?.qc_fields?.length}
        >
          {` Add More`}
        </Button>
      </div>

      <div className={`col-12 pro-pt-4 pro-d-flex pro-justify-end  `}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleModalClick}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddPrivacy;
