import CommonLayout from "../../pages/Layouts/CommonLayout";
import {
  HeadingGroup,
  SearchFilters,
  Table,
  ModalLayout,
  Pagination,
} from "@wac-ui-dashboard/wac_component_library";
import useTutorReviews from "./useTutorReviews";
import EmptyData from "../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../Global/OrderColumn";

const TutorReviews = () => {
  const {
    drawerMenu,
    reviewState,
    tutorReviewList,
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    currentPage,
    handlePageSize,
    handlePagination,
    updateTableFields,
    closeEditModal,
    handleEditColumnsClick,
    refetch,
    getRow,
    handleSort,
    handleClearClick,
    handleSearch,
  } = useTutorReviews();
  return (
    <>
      {/* <CommonLayout drawerMenu={drawerMenu}> */}
        <HeadingGroup
          title={"Tutor Reviews"}
          className={`pro-mb-4`}
          // buttonTitle={"Add new"}
          // handleClick={handleModalClick}
        />

        <div className="col-auto pro-pt-3 pro-pb-6">
          <div className="row gx-2">
            <div className="col">
              <SearchFilters
                searchable={true}
                //  handleButtonGroupChange={handleFilter}
                onSearchInput={handleSearch}
                showActions={true}
                handleActionClick={handleEditColumnsClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: reviewState?.search }}
              />
            </div>
          </div>

          <div className={`pro-pt-3 pro-pb-5 `}>
            {tutorReviewList?.data?.reviews?.length === 0 ? (
              <EmptyData />
            ) : (
              <div
              //  className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
              >
                <Table
                  multiSelect={false}
                  data={tutorReviewList?.data?.reviews || []}
                  uniqueID={"_id"}
                  fields={tutorReviewList?.data?.fields}
                  SortIcon={<FaSort />}
                  editIcon={
                    <span className="material-symbols-outlined">edit</span>
                  }
                  deleteIcon={
                    <span className="material-symbols-outlined">delete</span>
                  }
                  handleSort={handleSort}
                  getRow={getRow}
                  loading={isFetching}
                  perpage={reviewState?.currentPageSize}
                  assignable={false}
                  //    handleEdit={handleEditAction}
                  //    handleDelete={handleDeleteModal}
                  deletable={false}
                  showCheckBox={false}
                />
              </div>
            )}
          </div>

          <ModalLayout show={showEditModal} handleClose={closeEditModal}>
            <div className="pro-m-5">
              <OrderColumn
                title={"Choose which columns you see"}
                refetch={refetch}
                tableFields={Object.keys(
                  tutorReviewList?.data?.fields ?? {}
                ).reduce((filteredObj, property) => {
                  filteredObj[property] =
                    tutorReviewList?.data?.fields[property];
                  return filteredObj;
                }, {})}
                moduleId={tutorReviewList?.data?.module_id}
                updateData={updateTableFields}
              />
            </div>
          </ModalLayout>
        </div>

        {tutorReviewList?.data?.reviews?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              tutorReviewList.data.filtered_count / reviewState?.currentPageSize
            )}
            onPageChange={handlePagination}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === reviewState?.currentPageSize
            )}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      {/* </CommonLayout> */}
    </>
  );
};

export default TutorReviews;
