import { getAxiosInstance } from "../../../api";

export const getTutorssData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/api/tutors/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const getAutoLocationData = async (searchVal) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/api/tutor-zipcode?query=${searchVal}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateTutorBasic = async (formData,id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`admin/api/tutors/${id}?section=basic_details`,formData);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getIsSafeValue = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/api/tutor/${id}/is-safe-to-disable`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};