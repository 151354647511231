import { getAxiosInstance } from "../../api";

export const getBannerData = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/api/banners/${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
