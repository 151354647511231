import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddTestimonials from "./useAddTestimonials";

const AddTestimonials = ({ refetch, handleModalClick }) => {
  const {
    getFieldError,
    closeModal,
    formik,
    handleProfileImage,
    handleUserTypeChange,
    isEdit,
    imagePreview,
    imageName,
    basicData,
    selectVal,userVal,
    handleInputChange,
    handleUserId,
    useDataOptions,
  } = useAddTestimonials(handleModalClick, refetch);
  const noOptionsMessage = () =>
    useDataOptions?.length > 0
      ? "No matching options"
      : "Search for a subject...";
  return (
    <div className={`row`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          User
        </label>
        <Select
          id="user_type"
          name="user_type"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("user_type") && " error"}`}
          classNamePrefix="pro-input"
          options={basicData ?? []}
          value={selectVal}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          onBlur={formik.handleBlur("user_type")}
          onChange={(e) => handleUserTypeChange(e?.value || null)}
          menuPlacement="auto"
        />
        {getFieldError("user_type") && (
          <span className="error-text">{getFieldError("user_type")}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Name
        </label>
        <Select
          id="user_id"
          placeholder="Search for a subject..."
          name="user_id"
          noOptionsMessage={noOptionsMessage}
          isClearable={false}
          options={useDataOptions}
          value={userVal || { value: formik.values.user_id, label: formik.values.name, id: formik.values.user_id }}
          onInputChange={(value) => handleInputChange(value)}
          className={`pro-input multi-select lg ${
            formik.errors.user_id && formik.touched.user_id && " error"
          }`}
          classNamePrefix="pro-input"
          onBlur={formik.handleBlur("user_id")}
          onChange={(value) => handleUserId(value)}
        ></Select>

        {getFieldError("user_id") && (
          <span className="error-text">{getFieldError("user_id")}</span>
        )}
      </div>

      <Input
        label={"Location"}
        type="text"
        id="location"
        name="location"
        className={`pro-input lg ${getFieldError("location") && " error"}`}
        {...formik.getFieldProps("location")}
        error={getFieldError("location")}
        errorMessage={getFieldError("location")}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Testimonial
        </label>
        <textarea
          id="testimonial"
          rows={4}
          name="testimonial"
          className={`pro-input lg ${getFieldError("testimonial") && " error"}`}
          {...formik.getFieldProps("testimonial")}
        ></textarea>
        {getFieldError("testimonial") && (
          <span className="error-text">{getFieldError("testimonial")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            className={`pro-input ${
              formik.errors.image && formik.touched.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleProfileImage(e)}
          />

          <span className="input-drag-box">
            <IconText
              title={
                imageName !== "" ? imageName : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={``}>
          <Image
            width={100}
            height={100}
            src={imagePreview}
            alt={`image - 01`}
          />
        </div>
      </div>
      <span className="pro-font-sm ">Max upload file size 5MB</span>
      <div className="pro-check-box pro-py-2">
        <input
          type="checkbox"
          className="pro-check"
          id="default"
          name="pro-checkbox"
          checked={formik.values.status === "PUBLISHED"}
          onChange={(e) => {
            formik.setFieldValue(
              "status",
              e.target.checked ? "PUBLISHED" : "UNPUBLISHED"
            );
          }}
        />
        <label htmlFor="default" className="pro-check-label">
          Is Published
        </label>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleModalClick}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default AddTestimonials;
