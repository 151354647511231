import { getAxiosInstance } from "../../../api";


export const updateContact = async (formData, id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`admin/api/contact-info`, formData);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
