import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../assets/Assets";
import Style from "../tutor.module.scss";

const usePayoutDetail = () => {
  const getRow = (feild, data) => {
    const rows = {
      amount: (field, data) => <p className="pro-mb-0">{"$" + data[field]}</p>,
      student_name: (field, data) => (
        <div
          className={`pro-d-flex avatar-container ${Style.avatar_container}`}
        >
          <div
            className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
          >
            <Image
              src={
                data?.student_image !== "" && data?.student_image
                  ? data?.student_image
                  : Assets.GENDER_IMAGE
              }
              width={16}
              height={16}
              alt={data?.tutor}
            />
          </div>
          <span
            className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
          >
            {data?.[feild]}
          </span>
        </div>
      ),
      payment_completed_at: (field, data) => {
        if (data?.payment_completed_at) {
          const dateObject = new Date(data.payment_completed_at);

          // Get the user's current timezone
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

          // Format the date with the current timezone
          const formattedDate = dateObject.toLocaleString("en-US", {
            timeZone: userTimezone,
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });

          // Extract day, month, and year from the formatted date
          const [month, day, year] = formattedDate.split("/");

          // Reformat to dd/mm/yyyy
          const formattedDateDMY = `${day}/${month}/${year}`;

          return <p className="pro-mb-0">{formattedDateDMY}</p>;
        }
      },
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return { getRow };
};

export default usePayoutDetail;
