import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&per_page=${params?.page_size || "10"}&${getParams("search")}page_no=${
            params?.page || 1
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };


export const settings = createApi({
    reducerPath: "settingsApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: [
      "Setting"
    ],
    endpoints: (builder) => ({
      // branches starts
      // getTermsListData: builder.query({
      //   query: (values) => ({
      //     method: "get",
      //     params: values,
      //     endpoint: `admin/api/terms-and-conditions`,
      //   }),
      //   providesTags: ["CMS"],
      // }),
      // getPrivacyListData: builder.query({
      //   query: (values) => ({
      //     method: "get",
      //     params: values,
      //     endpoint: `admin/api/privacy-and-policy`,
      //   }),
      //   providesTags: ["CMS"],
      // }),
      // getContactUsListData: builder.query({
      //   query: (values) => ({
      //     method: "get",
      //     params: values,
      //     endpoint: `admin/api/contact-info`,
      //   }),
      //   providesTags: ["CMS"],
      // }),
      // getTestimonialsListData: builder.query({
      //   query: (values) => ({
      //     method: "get",
      //     params: values,
      //     endpoint: `admin/api/testimonials`,
      //   }),
      //   providesTags: ["CMS"],
      // }),
      // updateTerms: builder.mutation({
      //   query: (body) => ({
      //     method: "post",
      //     body,
      //     endpoint: `admin/api/terms-and-conditions`,
      //   }),
      //   invalidatesTags: ["CMS"],
      // }),
      // updatePrivacy: builder.mutation({
      //   query: (body) => ({
      //     method: "post",
      //     body,
      //     endpoint: `admin/api/privacy-and-policy`,
      //   }),
      //   invalidatesTags: ["CMS"],
      // }),
      // updateContactUs: builder.mutation({
      //   query: (body) => ({
      //     method: "post",
      //     body,
      //     endpoint: `admin/api/contact-info`,
      //   }),
      //   invalidatesTags: ["CMS"],
      // }),
    //   updateTestimonials: builder.mutation({
    //     query: (body) => ({
    //       method: "post",
    //       body,
    //       endpoint: `admin/api/courses/status-change`,
    //     }),
    //     invalidatesTags: ["CMS"],
    //   }),
    })
  
      
  });

  export const { } = settings;