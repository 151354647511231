import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useTutor = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Active Tutors",
      label: "Active Tutors",
      link: "/tutors/active-tutors",
      iconAsset: "DrawerIcon1",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.25 10C8.25 11.2426 7.24264 12.25 6 12.25C4.75736 12.25 3.75 11.2426 3.75 10C3.75 8.75736 4.75736 7.75 6 7.75C7.24264 7.75 8.25 8.75736 8.25 10Z"
            stroke="#4B4751"
            stroke-width="1.5"
          />
          <path d="M8.5 3.5V2.5H21.5V3.5H8.5Z" stroke="#4B4751" />
          <path d="M12.5 7.5V6.5H21.5V7.5H12.5Z" stroke="#4B4751" />
          <path d="M16.5 11.5V10.5H21.5V11.5H16.5Z" stroke="#4B4751" />
          <path
            d="M10 19.5L15.27 13.14L13.23 11.36L9.41 15.94C8.95586 15.1992 8.27287 14.6265 7.46432 14.3084C6.65577 13.9903 5.76563 13.9441 4.9285 14.1769C4.09137 14.4096 3.35279 14.9086 2.82443 15.5983C2.29607 16.2881 2.00667 17.1311 2 18V22H10V19.5Z"
            stroke="#4B4751"
            stroke-width="1.5"
          />
        </svg>
      ),
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/active-tutors", true),
    },
    {
      title: "Inactive Tutors",
      label: "Inactive Tutors",
      link: "/tutors/inactive-tutors",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">toggle_off</span>,
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/inactive-tutors", true),
    },
    {
      title: "Deleted Tutors",
      label: "Deleted Tutors",
      link: "/tutors/deleted-tutors",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">delete</span>,
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/deleted-tutors", true),
    },
    {
      title: "Onboarding Tutors",
      label: "Onboarding Tutors",
      link: "/tutors/onboarding-tutors",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">airplane_ticket</span>,
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/onboarding-tutors", true),
    },
    {
      title: "Tutor Reviews",
      label: "Tutor Reviews",
      link: "/tutors/tutor-reviews",
      iconAsset: "DrawerIcon1",
      icon: <span className="material-symbols-outlined x4">rate_review</span>,
      active: checkIfActiveRoute("/tutors/tutor-reviews", true),
    },
    {
      title: "Tutor Bg Verification",
      label: "Tutor Bg Verification",
      link: "/tutors/bg-verification",
      iconAsset: "DrawerIcon1",
      icon: <span class="material-symbols-outlined x4">live_help</span>,
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/bg-verification", true),
    },
    {
      title: "Settled Payouts",
      label: "Settled Payouts",
      link: "/tutors/payouts-list",
      iconAsset: "DrawerIcon1",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 16H5C4.44772 16 4 16.4477 4 17V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V17C20 16.4477 19.5523 16 19 16H14"
            stroke="#4B4751"
            stroke-width="1.5"
          />
          <path
            d="M9 4C9.53575 4 9.03113 4 9.5 4C11.3811 4 12.8354 4 14.7568 4C15.1492 4 14.561 4 15 4L16 8H8L9 4Z"
            stroke="#4B4751"
            stroke-width="1.5"
          />
          <path
            d="M14 16H21C21.5523 16 22 15.5523 22 15V9C22 8.44772 21.5523 8 21 8H3C2.44772 8 2 8.44772 2 9V15C2 15.5523 2.44772 16 3 16H10"
            stroke="#4B4751"
            stroke-width="1.5"
          />
          <rect
            x="10"
            y="13"
            width="4"
            height="4"
            stroke="#4B4751"
            stroke-width="1.5"
          />
        </svg>
      ),
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/payouts-list", true),
    },
    {
      title: "Tax Form 1099",
      label: "Tax Form 1099",
      link: "/tutors/tax-form",
      iconAsset: "DrawerIcon1",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 8.5V19C20 20.1046 19.1046 21 18 21H5C3.89543 21 3 20.1046 3 19V6C3 4.89543 3.89543 4 5 4H16"
            stroke="#4B4751"
            stroke-width="1.5"
          />
          <path
            d="M11 13L20.35 3.65M22 2L20.9 3.1"
            stroke="#4B4751"
            stroke-width="1.5"
          />
        </svg>
      ),
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/tax-form", true),
    },
    {
      title: "Documents",
      label: "Documents",
      link: "/tutors/documents",
      iconAsset: "DrawerIcon1",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.3077 21.5C5.80257 21.5 5.375 21.325 5.025 20.975C4.675 20.625 4.5 20.1974 4.5 19.6923V4.3077C4.5 3.80257 4.675 3.375 5.025 3.025C5.375 2.675 5.80257 2.5 6.3077 2.5H14.25L19.5 7.74995V19.6923C19.5 20.1974 19.325 20.625 18.975 20.975C18.625 21.325 18.1974 21.5 17.6922 21.5H6.3077ZM13.5 8.49995V3.99998H6.3077C6.23077 3.99998 6.16024 4.03203 6.09612 4.09613C6.03202 4.16024 5.99997 4.23077 5.99997 4.3077V19.6923C5.99997 19.7692 6.03202 19.8397 6.09612 19.9038C6.16024 19.9679 6.23077 20 6.3077 20H17.6922C17.7692 20 17.8397 19.9679 17.9038 19.9038C17.9679 19.8397 18 19.7692 18 19.6923V8.49995H13.5Z"
            fill="#4B4751"
          />
        </svg>
      ),
      active:
        checkIfActiveRoute("/tutors", true) ||
        checkIfActiveRoute("/tutors/documents", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );

    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useTutor;
