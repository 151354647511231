import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/Testimonials/testimonialsSlice";
import { toast } from "react-toastify";
import { useMemo } from "react";
import {
  createTestimonial,
  getAutoCompleteUserData,
  updateTestimonial,
} from "../api";
import { useEffect } from "react";

const useAddTestimonials = (refetch, handleModalClick) => {
  const dispatch = useDispatch();

  const {
    isEdit,
    imageName,
    imagePreview,
    userData,
    selectedData,
    editTestimonialData,
  } = useSelector((state) => state.testimonials);

  const basicData = [
    { value: "STUDENT", label: "Student", _id: 1 },
    { value: "TUTOR", label: "Tutor", _id: 2 },
  ];
  const validation = Yup.object({
    name: Yup.string().required("Enter First Name"),
    user_type: Yup.string().required("Enter User Type"),
    image: Yup.string(),
    location: Yup.string()
      .trim()
      .required("Enter Location")
      .max(50, "Enter valid First Name"),
    testimonial: Yup.string()
      .trim()
      .required("Enter testimonial")
      .max(150, "Enter valid testimonial"),
  });
  const formik = useFormik({
    initialValues: {
      user_id: "",
      image: "",
      user_type: "",
      status: "PUBLISHED",
      testimonial: "",
      location: "",
      name: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });
      if (!isEdit) {
        createTestimonial(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            toast.success("New Testimonial Created");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        updateTestimonial(formData, selectedData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            refetch();
            toast.success(" Testimonial updated");
          } else if (response?.statusCode === 422) {
            const errors = response?.errors;
            errors.forEach((error) => {
              formik.setFieldError(error.field, error.message);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  const handleProfileImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleUserTypeChange = (newUserType) => {
    formik.setFieldValue("user_type", newUserType);

    formik.setFieldValue("user_id", "");
    formik.setFieldValue("name", "");
  };

  const handleInputChange = (item) => {
    if (item?.length >= 1 && formik.values.user_type) {
      let param = {
        type: formik.values.user_type,
        name: item,
      };
      getAutoCompleteUserData(param).then((response) => {
        dispatch(
          updateConfig((state) => {
            state.userData = response?.data?.data;
          })
        );
      });
    }
  };

  const handleUserId = (val) => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = val?.image || "";
      state.imageName = val?.image
        ?.split("/")
        .pop();
      })
    );
    formik.setFieldValue("location", val?.location ?? "");
    formik.setFieldValue("image", val?.image);
    formik.setFieldValue("user_id", val?.id);
    formik.setFieldValue("name", val?.value);
    dispatch(
      updateConfig((state) => {
        state.selectedUserId = val?.id;
      })
    );
  };
  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        user_id: editTestimonialData?.user_id || "",
        user_type: editTestimonialData?.user_type || "",
        location: editTestimonialData?.location || "",
        status: editTestimonialData?.status,
        testimonial: editTestimonialData?.testimonial || "",
        name: editTestimonialData?.name || "",
      });

      dispatch(
        updateConfig((state) => {
          state.imagePreview = editTestimonialData?.image_details?.url || "";
          state.imageName = editTestimonialData?.image_details?.name
            ?.split("/")
            .pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, editTestimonialData]);

  const useDataOptions = useMemo(
    () =>
      userData?.map((opt) => {
        return { value: opt?.name, label: opt?.name, id: opt?._id, location: opt?.location, image: opt?.image };
      }),
    // eslint-disable-next-line
    [userData, formik.values.user_type]
  );
  let userVal = useDataOptions.find(
    (item) => item.value === formik?.values?.user_id
  );
  let selectVal = basicData.find(
    (item) => item.value === formik?.values?.user_type
  );

  return {
    getFieldError,
    closeModal,
    formik,
    handleProfileImage,
    isEdit,
    imagePreview,
    imageName,
    testimonialData: null,
    selectVal,
    basicData,
    handleInputChange,
    handleUserId,
    useDataOptions,
    userVal,
    handleUserTypeChange,
  };
};

export default useAddTestimonials;
