import { getAxiosInstance } from "../../../api";
import { createApi } from "@reduxjs/toolkit/query/react";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("coupon_type")}${getParams(
            "sort_by"
          )}sort_order=${params?.sort_order || "asc"}&page_size=${
            params?.page_size || "10"
          }&${getParams("search")}page_no=${params?.page || 1}&timezone=${currentTimezone}`,
          body
        );

        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const couponRedeem = createApi({
  reducerPath: "couponRedeemApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["CouponRedeem"],
  endpoints: (builder) => ({
    getCouponList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/coupon-redeen-list`,
      }),
      providesTags: ["CouponRedeem"],
    }),
    updateCouponListTable: builder.mutation({
      query(body) {
        return {
          endpoint: `admin-panel/api/panel-listings`,
          method: "post",
          body,
        };
      },
      invalidatesTags: ["CouponRedeem"],
    }),
    updateCouponStatus: builder.mutation({
      query: (id) => ({
        method: "put",
        endpoint: `admin/api/coupon/${id}/toggle-status`,
      }),
      // invalidatesTags: ["coupon"],
    }),

    createCouponData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/api/coupon`,
      }),
      invalidatesTags: ["CouponRedeem"],
    }),

    updateCreateCouponData: builder.mutation({
      query(data) {
        const body = data.formData;
        const id = data.selectedCoupon;
        return {
          endpoint: `admin/api/coupon/${id}`,
          method: "put",
          body,
        };
      },
      invalidatesTags: ["CouponRedeem"],
    }),
  }),
});

export const {
  useUpdateCouponListTableMutation,
  useGetCouponListQuery,
  useUpdateCouponStatusMutation,
  useCreateCouponDataMutation,
  useUpdateCreateCouponDataMutation,
} = couponRedeem;
