import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useSpecializationList from "./useSpecializationList";
import AddSpecialization from "./AddSpecialization";
import { Link } from "react-router-dom";

const SpecializationList = () => {
  const {
    SpecializationList,
    specializationState,
    isLoading,
    isFetching,
    showEditModal,
    paginationOptions,
    handleCreateClick,
    handleEditColumnsClick,
    handlePagination,
    handleEditAction,
    handleDeleteModal,
    updateSpecializationFields,
    handleClearClick,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
    storedData,
    subjectID,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission
  } = useSpecializationList();
  return (
    <>
      <Link to={`/subjects`} className="pro-back-btn">
        <span className="material-symbols-outlined"> keyboard_arrow_left </span>
            {`Back`}
      </Link>
      <HeadingGroup
        title={storedData.name}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission ? "Add new" : ""}
        handleClick={handleCreateClick}
      />
      
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: specializationState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        <Table
          multiSelect={false}
          data={SpecializationList?.data?.specialization || []}
          uniqueID={"_id"}
          fields={SpecializationList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={specializationState?.clearSelection}
          perpage={specializationState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={hasDeletePermission}
          showCheckBox={hasEditPermission || hasDeletePermission}
        />
        {SpecializationList?.data?.specialization?.length > 0 && (
          <Pagination
            currentPage={specializationState?.currentPage}
            totalPageCount={Math.ceil(
              SpecializationList.data.filtered_count /
                specializationState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={specializationState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Tier ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={SpecializationList?.data?.fields || []}
              moduleId={SpecializationList?.data?.module_id}
              updateData={updateSpecializationFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={specializationState?.showCreateModal}
          handleClose={handleCreateClick}
          title={
            specializationState?.isEdit
              ? "Update Specialization"
              : "Add Specialization"
          }
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
        >
          <AddSpecialization
            refetch={refetch}
            handleClose={handleCreateClick}
            parendtId={subjectID}
         
          />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default SpecializationList;
