import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import {
  useGetPremiumPlanDataQuery,
  useGetTutorBasicDataQuery,
  useUpdateTutorStatusMutation,
} from "../../../store/queries/Tutor";

import {
  getEditTutorsData,
  updateConfig,
  updatePremiumPlanById,
} from "../../../store/slices/Tutor/Tutors/tutorsSlice";
import { getIsSafeValue } from "../Tutors/api";

const useTutorDetailsLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const dispatch = useDispatch();
  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const tutorsState = useSelector((state) => state.tutors);
  const { tutorID } = useParams();
  const activeProfile = tutorID ?? sessionStorage.getItem("active");
  const [popUpMessage, setPopUpMessage] = useState("");
  const [obj, setObj] = useState({});
  const [statusUpdate, setStatusUpdate] = useState(false);
  const [updateTutorStatus] = useUpdateTutorStatusMutation();

  const navigation = [
    {
      label: "Overview",
      title: "Overview",
      link: `/tutors-profile/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/tutors-profile/${activeProfile}`, true),
    },
    {
      label: "Classes",
      title: "Classes",
      link: `/tutors-profile/my-classes/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/my-classes/${activeProfile}`),
    },
    {
      label: "Reports",
      title: "Reports",
      link: `/tutors-profile/reports/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/reports/${activeProfile}`),
    },
    {
      label: "Inquiry ",
      title: "Inquiry",
      link: `/tutors-profile/enquiry/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/enquiry/${activeProfile}`),
    },
    {
      label: `Subject/Specialization`,
      title: "Subject/Specialization",
      link: `/tutors-profile/my-subjects/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/my-subjects/${activeProfile}`),
    },
    {
      label: `Students`,
      title: "Students",
      link: `/tutors-profile/students/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/students/${activeProfile}`),
    },
    {
      label: `Schedules`,
      title: "Schedules",
      link: `/tutors-profile/schedules/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/schedules/${activeProfile}`),
    },
    {
      label: `Payouts`,
      title: "Payouts",
      link: `/tutors-profile/payouts/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/payouts/${activeProfile}`),
    },
    {
      label: "Documents",
      title: "Documents",
      link: `/tutors-profile/documents/${activeProfile}${window.location.search}`,
      //   isButton: false,
      active: checkIfActiveRoute(`/documents/${activeProfile}`),
    },
  ];

  const navigate = useNavigate();
  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetTutorBasicDataQuery(activeProfile);

  const { data: basicData } = useGetPremiumPlanDataQuery();

  useEffect(() => {
    if (profileData?.statusCode === 400 || profileData === "") {
        toast.error("Something went wrong!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [profileData, activeProfile]);

  useEffect(() => {
    if (isError) {
        toast.error("You don't have the permission to access that page!");
      navigate("/");
    }
    // eslint-disable-next-line
  }, [isError]);

  

  // const hasUpdatePermission = useMemo(() => {
  //   let permission = profileData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_edit")
  //   );
  //   return permission?.[0]?.can_edit ?? 0;
  // }, [profileData]);

  const label = {
    firstName: "First Name",
    lastName: "Last Name",
    gender: "Gender",

    phone: "Mobile",
    address: "Address",
    city: "City",
    state: "State",
    pincode: "Zipcode",
    mode: "Mode of Class",
    vacation_mode: "Do not disturb mode",
    is_featured: "Is Featured",
  };

  const onboardLabel = {
    browser: "Browser",
    ip_addr: "IP Address",
    
  };

  // mode_of_class: (field, data) => {
  //   return (
  //     <>
  //       {data.mode_of_class === "ONLINE" ? (
  //         <span
  //           className={`pro-ms-2 pro-align-self-center pro-text-success pro-fw-medium`}
  //         >
  //           Online
  //         </span>
  //       ) : (
  //         <span
  //           className={`pro-ms-2 pro-align-self-center pro-text-warning pro-fw-medium`}
  //         >
  //           In Person
  //         </span>
  //       )}
  //     </>
  //   );
  // },

  const value = {
    firstName: `${
      profileData?.data?.tutorDetails?.basic_details?.first_name ||
      "Not specified"
    }`,
    lastName: `${
      profileData?.data?.tutorDetails?.basic_details?.last_name ||
      "Not specified"
    }`,
    gender: `${
      profileData?.data?.tutorDetails?.basic_details?.gender || "Not specified"
    }`,

    phone: `${
      profileData?.data?.tutorDetails?.basic_details?.contact_number ||
      "Not specified"
    }`,
    address: `${
      profileData?.data?.tutorDetails?.basic_details?.address?.address_one ||
      "Not specified, "
    } ${
      profileData?.data?.tutorDetails?.basic_details?.address?.address_two ||
      ""
    }`,
    city: `${
      profileData?.data?.tutorDetails?.basic_details?.city ||
      "Not specified"
    }`,
    state: `${
      profileData?.data?.tutorDetails?.basic_details?.state ||
      "Not specified"
    }`,
    pincode: `${
      profileData?.data?.tutorDetails?.basic_details?.zip_code ||
      "Not specified"
    }`,

    mode: `${
      profileData?.data?.tutorDetails?.basic_details?.mode_of_class ||
      "Not specified"
    }`,

    vacation_mode: `${
      profileData?.data?.tutorDetails?.basic_details?.vacation_mode
        ? "ON"
        : "OFF"
    }`,
    is_featured: `${
      profileData?.data?.tutorDetails?.basic_details?.is_featured ? "YES" : "NO"
    }`,
  };

  const onBoardValue = {
    browser: `${
      profileData?.data?.tutorDetails?.onboard_details?.browser ||
      "Not specified"
    }`,
    ip_addr: `${
      profileData?.data?.tutorDetails?.onboard_details?.ip_address ||
      "Not specified"
    }`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const onboardDetails = Object.keys(onBoardValue).map((key) => {
    return {
      label: onboardLabel[key],
      value:
        typeof onBoardValue[key] === "string"
          ? onBoardValue?.[key]
          : typeof onBoardValue[key] === "number"
          ? onBoardValue?.[key]
          : onBoardValue?.[key]?.name,
    };
  });

  const handleEditClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = true;
        state.isEdit = true;
        state.selectedTutor = profileData?.data?.tutorDetails?._id;
      })
    );
    dispatch(getEditTutorsData(profileData?.data?.tutorDetails?._id));
  };

  const updatePremiumPlan = (id) => {
    const body = {
      id: id,
      tutor_id: profileData?.data?.tutorDetails?._id,
    };
    dispatch(updatePremiumPlanById({ body })).then((res) => {
      if (res?.payload?.statusCode == 200) {
        toast.success(res?.payload?.message);
        refetch();
      } else {
        toast.error(res?.payload?.message);
      }
    });
  };

  let selectVal = basicData?.data?.premium_plans.find(
    (item) =>
      item._id ===
      profileData?.data?.tutorDetails?.basic_details?.premium_plan_id
  );

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAddTutorsModal = false;
        state.isEdit = false;
        state.selectedTutor = {};
        state.imagePreview = "";
        state.imageName = "";
        state.tutorsData = [];
      })
    );
  };

  const handleChangeStatus = (status, id) => {
    setObj({
      status: status,
      id: id,
    });
    getIsSafeValue(id).then((res) => {
      if (res?.data?.statusCode === 200) {
        setPopUpMessage(res?.data?.message);
        handleDeleteModal();
      }
    });
  };

  useEffect(() => {
    if (statusUpdate) {
      updateTutorStatus(obj?.id).then((res) => {
        if (res?.data?.statusCode === 200) {
          toast.success(res?.data?.message);
          refetch();
        } else {
          toast.error(res?.data?.message);
        }
      });

      handleDeleteModal();
    }
  }, [statusUpdate === true]);

  const handleDeleteModal = () => {
    setStatusUpdate(false);
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
      })
    );
  };
  return {
    show,
    activeProfile,
    isFetching,
    printRef,
    profileData,
    basicDetails,
    tutorsState,
    showDeleteImageModal,
    basicData,
    navigation,
    popUpMessage,
    selectVal,
    // hasUpdatePermission,
    closeModal,
    setShowDeleteImageModal,
    setShow,
    refetch,
    handleEditClick,
    updatePremiumPlan,
    handleChangeStatus,
    setStatusUpdate,
    handleDeleteModal,
    onboardDetails
  };
};

export default useTutorDetailsLayout;
