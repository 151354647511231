import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Coupon/couponSlice";

import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  useUpdateCreateCouponDataMutation,
  useCreateCouponDataMutation,
} from "../../../../store/queries/Coupon";

const useAddCoupon = ({ refetch, handleModalClick, dropdown }) => {
  const dispatch = useDispatch();
  const { couponData, isEdit, selectedCoupon } = useSelector(
    (state) => state.coupon
  );

  const [updateCouponData] = useUpdateCreateCouponDataMutation();
  const [updateCreateCouponData] = useCreateCouponDataMutation();

  const outputArray = dropdown?.map((item) => {
    return {
      value: item,
      label: item
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
    };
  });

  const validation = Yup.object({
    name: Yup.string().required("Enter name"),
    code: Yup.string()
      .min(2, "Code Must be atleast 2 Characters long")
      .max(30, "Code must not exceed 30 characters.")
      .required("Required"),
    users_count: Yup.number()
      .typeError("Count must be a number")
      .required("Required")
      .min(1, "Users count must be greater than 0"),
    date_from: Yup.string().required("Required"),
    // date_to: Yup.string().required("Required"),
    date_to: Yup.string().when(
      "date_from",
      (date_from, schema) =>
        date_from &&
        schema
          .test({
            test: function (value) {
              const { date_from } = this.parent;
              if (!date_from) {
                // If start date is not provided, no need for validation
                return true;
              }
              // Check if end date is provided
              return !!value;
            },
            message: "Select To Date",
          })
          .test({
            test: function (value) {
              const { date_from } = this.parent;
              if (!date_from) {
                // If start date is not provided, no need for validation
                return true;
              }
              // Check if end date is greater than or equal to start date
              const startDateObj = new Date(date_from);
              const endDateObj = new Date(value);
              return endDateObj >= startDateObj; // Changed operator to allow equality
            },
            message: "To date must be greater than or equal to From date", // Adjusted message
          })
    ),

    discount_amount: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      code: "",
      users_count: "",
      date_from: "",
      date_to: "",
      discount_amount: "",
      is_active: true,
      type: "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        return formData.append(key, values[key]);
      });

      if (isEdit) {
        updateCouponData({ selectedCoupon, formData }).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            toast.success("Coupon Details Updated");
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            formik.setErrors(errors);
            toast.error(response?.error?.data?.message);
          } else {
            toast.error("Something went wrong");
          }
        });
      } else {
        updateCreateCouponData(formData).then((response) => {
          if (response?.data?.statusCode === 200) {
            handleModalClick();
            toast.success("New Coupon Created");
            refetch();
          } else if (response?.error?.data?.statusCode === 422) {
            // displays backend errors
            const errors = response?.error?.data?.errors;
            toast.error(response?.error?.data?.message);
            Object.keys(errors).forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
          } else {
            toast.error("Something went wrong");
          }
        });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      if (couponData?.date_from) {
        const dateObject = new Date(couponData?.date_from);

        // Considering the timezone offset
        const timezoneOffset = dateObject.getTimezoneOffset() * 60000; // Offset in milliseconds
        const adjustedDate = new Date(dateObject.getTime() - timezoneOffset);

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = adjustedDate.toISOString().split("T")[0];
        formik.setFieldValue("date_from", formattedDate);
      }

      if (couponData?.date_to) {
        const dateObject = new Date(couponData?.date_to);

        // Considering the timezone offset
        const timezoneOffset = dateObject.getTimezoneOffset() * 60000; // Offset in milliseconds
        const adjustedDate = new Date(dateObject.getTime() - timezoneOffset);

        // Format the date as 'YYYY-MM-DD'
        const formattedDate = adjustedDate.toISOString().split("T")[0];

        formik.setFieldValue("date_to", formattedDate);
      }

      formik?.setFieldValue("name", couponData?.name);
      formik?.setFieldValue("code", couponData?.code);
      formik?.setFieldValue("users_count", couponData?.users_count);
      formik?.setFieldValue("discount_amount", couponData?.discount_amount);
      formik?.setFieldValue("is_active", couponData?.is_active);
      formik?.setFieldValue("type", couponData?.type);
    }
    // eslint-disable-next-line
  }, [isEdit, couponData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  let selectVal = outputArray.find((item) => item.value === formik.values.type);

  return {
    formik,
    couponData,
    getFieldError,
    isEdit,
    outputArray,
    selectVal,
  };
};

export default useAddCoupon;
