import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import ReactSelect from "react-select";
import usePremiumPlanPurchaseFilter from "./usePremiumPlanPurchaseFilter";

const PremiumPlanPurchaseFilter = ({ isStickyFooter, handleFilterClick }) => {
  const { formik, selectVal, basicData, clearFilter, getFieldError } =
    usePremiumPlanPurchaseFilter(handleFilterClick);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"From Date"}
          type="date"
          id="startDate"
          value={formik.values.startDate}
          name="startDate"
          className={`pro-input lg ${getFieldError("startDate") && " error"}`}
          onChange={(e) => formik.setFieldValue("startDate", e.target.value)}
          error={getFieldError("startDate")}
          errorMessage={getFieldError("startDate")}
        />
        <Input
          label={"To Date"}
          type="date"
          id="endDate"
          value={formik.values.endDate}
          name="endDate"
          min={formik.values.startDate}
          onChange={(e) => formik.setFieldValue("endDate", e.target.value)}
          disabled={!formik.values.startDate}
          className={`pro-input lg ${getFieldError("endDate") && " error"}`}
          error={getFieldError("endDate")}
          errorMessage={getFieldError("endDate")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Featured Tutor Plans
          </label>
          <ReactSelect
            id="featuredPlan"
            name="featuredPlan"
            placeholder={"Select Status"}
            className={`pro-input lg `}
            classNamePrefix="pro-input"
            options={basicData ?? []}
            value={selectVal}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?._id}
            onBlur={formik.handleBlur("featuredPlan")}
            onChange={(e) =>
              formik?.setFieldValue("featuredPlan", e?._id || null)
            }
            menuPlacement="auto"
          />
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button className={"pro-btn-link lg pro-px-5"} onClick={clearFilter}>
            Clear
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 `}
            type="submit"
            onClick={formik.handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PremiumPlanPurchaseFilter;
