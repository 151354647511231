import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isFilter: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  reportData: [],
  startDate:"",
  endDate:"",
  statusType:"all"
};

const reportedChatSlice = createSlice({
  name: "reportedChat",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = reportedChatSlice.actions;

export default reportedChatSlice.reducer;
