import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "asc"
          }&page_size=${params?.page_size || "10"}&${getParams(
            "search"
          )}page_no=${params?.page || 1}&timezone=${currentTimezone}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const blog = createApi({
  reducerPath: "blogApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["blog"],
  endpoints: (builder) => ({
    getListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/api/blog`,
      }),
      providesTags: ["blog"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        endpoint: `admin-panel/api/panel-listings`,
        method: "post",
        body,
      }),

      invalidatesTags: ["blog"],
    }),
    updateBlogStatus: builder.mutation({
      query: (body) => ({
        method: "put",
        endpoint: `admin/api/change-blog-status/${body.Id}?is_published=${body.is_published}`,
      }),
      // invalidatesTags: ["blog"],
    }),

    updateImageForUrl: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `v1/admin/blog/status`,
      }),

      invalidatesTags: ["blog"],
    }),
    getBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `api/common/blog-category`,
      }),
      providesTags: ["blog"],
    }),
  }),
});

export const {
  useGetListDataQuery,
  useUpdateBlogStatusMutation,
  useUpdateTableFieldsDataMutation,
  useUpdateStatusByIdMutation,
  useGetBasicDataQuery,
} = blog;
