import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useProfileEdit from "./useProfileEdit";
import AddFormFields from "../AddFormFields";

const ProfileDetails = ({
  setShowform,
  refetch,
  isStickyFooter,
  setProfileViewVisible,
}) => {
  const {
    formikEditPassword,
    loading,
    showNewPassword,
    handleShowNewPassword,
    handleShowConfirmPassword,
    showConfirmPassword,
    handleShowExistingPassword,
    showExistingPassword
  } = useProfileEdit({ setProfileViewVisible, refetch });

  return (
    <div className={`pro-w-100`}>
      <AddFormFields
        formikEditPassword={formikEditPassword}
        showNewPassword={showNewPassword}
        handleShowNewPassword={handleShowNewPassword}
        handleShowConfirmPassword={handleShowConfirmPassword}
        showConfirmPassword={showConfirmPassword}
        handleShowExistingPassword={handleShowExistingPassword}
        showExistingPassword={showExistingPassword}
      />
      <div
        className={`col-12 pro-mt-4 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setProfileViewVisible(false)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formikEditPassword.handleSubmit}
          type="submit"
        >
          {loading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Update
        </Button>
      </div>
    </div>
  );
};

export default ProfileDetails;
